const redirects = {
    FRENCH:{
      single:{
        "/20000marquepagespourcurie": { url: "/actualites", code:301},
        "/404": { url: "/", code:301},
        "/acces-tarifs-microscopie-electronique-pict": { url: "/", code:301},
        "/activite-scientifique-vesicules-extracellulaires": { url: "/", code:301},
        "/actualite/aacr/aacr-2018-forte-presence-de-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/aacr/adn-tumoral-circulant-un-revelateur-de-tumeurs": { url: "/actualites", code:301},
        "/actualite/aacr/cancers-du-sein-metastatiques-revelations-sur-des-traitements-distinctifs-avec": { url: "/actualites", code:301},
        "/actualite/aacr/des-petites-vesicules-pleines-despoir": { url: "/actualites", code:301},
        "/actualite/aacr/immunotherapie-des-resultats-prometteurs-dans-le-cancer-du-poumon-et-le-melanome": { url: "/actualites", code:301},
        "/actualite/aacr/mieux-connaitre-et-cerner-le-cancer": { url: "/actualites", code:301},
        "/actualite/archive/associations-de-patients-et-daidants-le-lien-avec-lexterieur-et-la-vie": { url: "/actualites", code:301},
        "/actualite/archive/chirurgie-ambulatoire-linstitut-curie-distingue": { url: "/actualites", code:301},
        "/actualite/archive/disparition-dethel-moustacchi": { url: "/actualites", code:301},
        "/actualite/archive/lensemble-hospitalier-certifie-par-la-has": { url: "/actualites", code:301},
        "/actualite/archive/les-chercheurs-de-linstitut-curie-ont-le-declic": { url: "/actualites", code:301},
        "/actualite/archive/les-espoirs-des-medecins-chercheurs": { url: "/actualites", code:301},
        "/actualite/archive/les-voeux-de-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/archive/recherche-en-soins-une-necessaire-interdisciplinarite": { url: "/actualites", code:301},
        "/actualite/ascb-embo/focus-sur-les-sentinelles-sur-systeme-immunitaire": { url: "/actualites", code:301},
        "/actualite/ascb-embo/le-fuseau-mitotique-revele-des-variations-insoupconnees": { url: "/actualites", code:301},
        "/actualite/ascb-embo/les-cellules-se-deforment-entre-les-mains-de-p-bassereau": { url: "/actualites", code:301},
        "/actualite/ascb-embo/quand-du-glutamate-saccumule-sur-les-voies": { url: "/actualites", code:301},
        "/actualite/asco/asco-2018-limmunotherapie-en-plein-essor": { url: "/actualites", code:301},
        "/actualite/asco/asco-2018-linstitut-curie-present-en-force": { url: "/actualites", code:301},
        "/actualite/asco/asco-des-avancees-majeures-dans-la-lutte-contre-les-cancers-du-sein-et-pediatriques": { url: "/actualites", code:301},
        "/actualite/asco/la-chimiotherapie-arterielle-contre-le-retinoblastome": { url: "/actualites", code:301},
        "/actualite/asco/linstitut-curie-present-au-congres-de-lasco-2019": { url: "/actualites", code:301},
        "/actualite/asco/sarcomes-de-linteret-detre-pris-en-charge-dans-un-centre-de-reference": { url: "/actualites", code:301},
        "/actualite/cancer-de-lovaire/nouveau-plan-dattaque-contre-les-cancers-de-lovaire": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/cancers-de-lenfant-et-recherche-le-point-sur-les-dernieres-innovations": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/cancers-de-lenfant-un-duo-prometteur": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/cancers-des-enfants-linstitut-curie-au-congres-siop-2019": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/cancers-pediatriques-de-linteret-de-ladn-tumoral-circulant-pour-le": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/cancers-pediatriques-ladn-tumoral-circulant-pour-decrypter-le": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/course-enfants-sans-cancer-2016-go-fight-win": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/dans-loeil-du-medulloblastome": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/etude-micchado-3-questions-au-dr-schleiermarcher": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/la-plastique-des-tumeurs-dewing-devoilee": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/olivier-delattre-recompense-par-le-prix-fondation-arc-leopold-griffuel": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/paul-et-enzo-un-jour-lhopital": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/siredo-guerir-plus-denfants-atteints-de-cancer": { url: "/actualites", code:301},
        "/actualite/cancers-de-lenfant/soigner-plus-de-cancers-haut-risque": { url: "/actualites", code:301},
        "/actualite/cancers-digestifs/cancer-canal-anal-des-pistes-pour-ameliorer-la-prise-en-charge-des": { url: "/actualites", code:301},
        "/actualite/cancers-digestifs/cancer-du-colon-prendre-5-minutes-pour-le-detecter": { url: "/actualites", code:301},
        "/actualite/cancers-digestifs/mars-bleu-lexpertise-de-linstitut-curie-sur-les-cancers-digestifs": { url: "/actualites", code:301},
        "/actualite/cancers-du-poumon/cancer-du-poumon-de-nouvelles-strategies-prometteuses-presentees-lasco": { url: "/actualites", code:301},
        "/actualite/cancers-du-poumon/cancer-du-poumon-des-traitements-personnalises-et-innovants-pour": { url: "/actualites", code:301},
        "/actualite/cancers-du-poumon/journee-internationale-des-cancers-du-poumon-les-patients-prennent-part": { url: "/actualites", code:301},
        "/actualite/cancers-du-poumon/linstitut-curie-rejoint-le-reseau-national-rythmic-pour-la-prise-en": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/ameliorer-le-quotidien-des-femmes-operees": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/anne-sophie-hamy-petit-femme-elle-de-lannee-2019": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancer-du-sein-comment-les-fibroblastes-favorisent-lapparition-de": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancer-du-sein-contraception-et-fertilite-conference-le-19-decembre": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancer-du-sein-et-cellules-dendritiques-sous-influence": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancer-du-sein-et-demain-comment-soignera-t": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancer-du-sein-les-ctc-facteur-principal-du-risque-de-thrombose": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancer-du-sein-triple-negatif-les-liaisons-dangereuses-entre-cellules": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancer-du-sein-un-nouvel-indicateur-du-risque-de-recidive": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancers-du-sein-deploiement-dune-charte-de-prise-en-charge": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancers-du-sein-les-alterations-genetiques-presque-toutes-identifiees": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancers-du-sein-rapprochements-entre-sous-familles": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancers-du-sein-triple-negatif-vers-une-desescalade-therapeutique": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancers-du-sein-triple-negatifs-une-innovation-therapeutique-efficace": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/cancers-du-sein-une-piste-pour-eviter-certaines-rechutes-pour-les-triple": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/chuis-pas-docteur-le-cancer-explique-tous-par-lili-sohn": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/etude-canto-et-cancer-du-sein-vers-une-meilleure-prise-en-charge-des": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/fatima-mechta-grigoriou-son-engagement-contre-les-cancers-du-sein": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/haut-risque-de-cancer-du-sein-une-prise-en-charge-par-lassurance-maladie": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/identification-de-nouveaux-partenaires-dans-le-developpement-des-cancers": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/isabelle-fromantin-la-recherche-en-soins-infirmiers-comme-leitmotiv": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/la-jeunesse-facteur-de-bon-pronostic-pour-les-femmes-atteintes-de-cancers": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/linstitut-curie-soutient-le-film-ma-ma-avec-penelope-cruz": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/mon-reseau-cancer-du-sein-votre-source-dinformation-cet-ete": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/observatoire-cancer-institut-curie-viavoice-2020-perceptions-sur-les": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/philip-poortmans-co-auteur-dune-prestigieuse-synthese-sur-le-cancer-du": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/predisposition-aux-cancers-du-sein-et-de-lovaire-plus-de-genes-analyser": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/predisposition-genetique-et-cancer-de-nouveaux-facteurs-identifies": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/predisposition-genetique-un-nouveau-cancer-concerne": { url: "/actualites", code:301},
        "/actualite/cancers-du-sein/resistance-la-chimiotherapie-dans-les-cancers-du-sein-de-nouveaux": { url: "/actualites", code:301},
        "/actualite/cancers-orl/campagne-makesense-ensemble-prenons-le-cancer-la-gorge": { url: "/actualites", code:301},
        "/actualite/cancers-pediatriques/cancer-de-lenfant-mieux-caracteriser-le-medulloblastome": { url: "/actualites", code:301},
        "/actualite/cancers-pediatriques/cancer-pediatrique-lorigine-du-medulloblastome-detectee-un-espoir": { url: "/actualites", code:301},
        "/actualite/cancers-pediatriques/le-crucial-diagnostic-moleculaire": { url: "/actualites", code:301},
        "/actualite/cancers-pediatriques/linclusion-du-1er-patient-dans-lessai-clinique-evaluant-une-molecule": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/cancers-pediatriques/nouveaux-decryptages-des-processus-metastatiques-dans-le-sarcome": { url: "/actualites", code:301},
        "/actualite/covid-19/abivax-son-candidat-medicament-declare-priorite-nationale-de-recherche-pour-sa": { url: "/actualites", code:301},
        "/actualite/covid-19/covid-19-et-biologie-cellulaire-decrypter-les-moyens-de-transport-du-virus-dans": { url: "/actualites", code:301},
        "/actualite/covid-19/covid-19-linstitut-curie-prend-part-la-recherche": { url: "/actualites", code:301},
        "/actualite/covid-19/piste-therapeutique-des-leurres-biologiques-sous-forme-de-vesicules-pour-stopper": { url: "/actualites", code:301},
        "/actualite/depistage/biomarqueurs-circulants-lavenir": { url: "/actualites", code:301},
        "/actualite/derniere-minute/4-questions-au-dr-anne-vincent-salomon-cheffe-du-pole-de-medecine": { url: "/actualites", code:301},
        "/actualite/derniere-minute/coronavirus-et-cancer-du-poumon-les-risques": { url: "/actualites", code:301},
        "/actualite/derniere-minute/coronavirus-et-cancers-pediatriques-5-questions-au-dr-daniel-orbach": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-4-questions-au-pr-nathalie-cassoux": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-et-biologie-cellulaire-4-questions-ludger-johannes": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-et-biologie-cellulaire-cibler-de-nouveaux-mecanismes-en-jeu-dans": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-et-biologie-cellulaire-nouveaux-tests-de-depistage-et-nouvelles": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-et-immunologie-des-pistes-vers-de-nouvelles-strategies": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-et-radiotherapie-comment-poursuivre-les-soins": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-et-soins-de-support-entretien-avec-le-dr-carole-bouleuc": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-linstitut-curie-mobilise-pour-accueillir-des-patients-covid": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-linstitut-curie-se-mobilise": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-quelles-consequences-pour-les-patients-de-linstitut-curie-venus": { url: "/actualites", code:301},
        "/actualite/derniere-minute/covid-19-risques-et-precautions-pour-les-femmes-traitees-pour-un-cancer": { url: "/actualites", code:301},
        "/actualite/derniere-minute/curie-o-sa-linstitut-curie-lance-une-etude-sur-les-serologies-covid-19": { url: "/actualites", code:301},
        "/actualite/derniere-minute/deuxieme-vague-le-pr-thierry-philip-prend-la-parole": { url: "/actualites", code:301},
        "/actualite/derniere-minute/journee-internationale-des-femmes-et-des-filles-de-science-2021": { url: "/actualites", code:301},
        "/actualite/derniere-minute/la-solidarite-notre-moteur-face-lepidemie-du-covid-19-pr-pierre-fumoleau": { url: "/actualites", code:301},
        "/actualite/derniere-minute/linstitut-curie-et-le-covid-19-le-pr-thierry-philip-prend-la-parole": { url: "/actualites", code:301},
        "/actualite/derniere-minute/linstitut-curie-lance-une-serie-de-podcasts-pour-decrypter-le-covid-19": { url: "/actualites", code:301},
        "/actualite/distinction/7-projets-de-recherche-de-lensemble-hospitalier-finances-par-le-ministere-de": { url: "/actualites", code:301},
        "/actualite/distinction/anr-2019-la-reussite-remarquable-des-equipes-de-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/distinction/bourses-loreal-unesco-pour-les-femmes-et-la-science-2018-5-laureates": { url: "/actualites", code:301},
        "/actualite/distinction/celine-vallot-medaille-de-bronze-du-cnrs-2018": { url: "/actualites", code:301},
        "/actualite/distinction/chirurgie-du-sein-un-essai-clinique-pour-reduire-les-douleurs-post": { url: "/actualites", code:301},
        "/actualite/distinction/daniele-fachinetti-decroche-le-prix-emergences-2018": { url: "/actualites", code:301},
        "/actualite/distinction/daniele-fachinetti-recoit-un-financement-pour-ses-recherches-sur-les": { url: "/actualites", code:301},
        "/actualite/distinction/depister-le-cancer-du-sein-grace-au-flair-canin-bientot-une-realite": { url: "/actualites", code:301},
        "/actualite/distinction/deux-financements-pour-le-retinoblastome-et-la-chimiotherapie-arterielle": { url: "/actualites", code:301},
        "/actualite/distinction/deux-jeunes-chercheurs-de-linstitut-curie-futures-elites-de-la-biologie": { url: "/actualites", code:301},
        "/actualite/distinction/le-chercheur-jean-leon-maitre-recoit-le-prix-claude-paoletti-2019": { url: "/actualites", code:301},
        "/actualite/distinction/le-defi-dalena-shkumatava-passer-au-crible-larn-non-codant": { url: "/actualites", code:301},
        "/actualite/distinction/le-professeur-philip-poortmans-recoit-le-prix-jens-overgaard-legacy-au": { url: "/actualites", code:301},
        "/actualite/distinction/les-drs-allison-bardin-et-valerie-borde-elues-membres-de-lorganisation": { url: "/actualites", code:301},
        "/actualite/distinction/les-tumeurs-rhabdoides-declenchent-une-reaction-immunitaire-chez-les-enfants": { url: "/actualites", code:301},
        "/actualite/distinction/lheterogeneite-des-tumeurs-cle-de-comprehension-de-la-cancerogenese-pour": { url: "/actualites", code:301},
        "/actualite/distinction/linca-consacre-25-millions-deuros-des-projets-de-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/distinction/linstitut-au-palmares-2017-des-hopitaux-et-cliniques": { url: "/actualites", code:301},
        "/actualite/distinction/linstitut-curie-se-place-en-tete-des-plus-grands-centres-europeens-de-lutte": { url: "/actualites", code:301},
        "/actualite/distinction/lymphome-tester-deux-nouvelles-combinaisons-de-traitement": { url: "/actualites", code:301},
        "/actualite/distinction/marianne-burbage-laureate-de-la-bourse-loreal-unesco-pour-les-femmes-et-la": { url: "/actualites", code:301},
        "/actualite/distinction/nouveau-succes-de-chercheurs-de-linstitut-curie-aupres-du-conseil-europeen-de": { url: "/actualites", code:301},
        "/actualite/distinction/olivier-delattre-recoit-un-erc-synergy-grant-avec-le-projet-kill-or": { url: "/actualites", code:301},
        "/actualite/distinction/phrc-k-prt-k-risp-8-projets-de-recherche-finances-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/distinction/prix-inserm-2018-la-recherche-pour-la-sante-lhonneur": { url: "/actualites", code:301},
        "/actualite/distinction/prix-transatlantique-pour-chercheur-transdisciplinaire": { url: "/actualites", code:301},
        "/actualite/distinction/raphael-rodriguez-recoit-le-2019-tetrahedron-young-investigator-award": { url: "/actualites", code:301},
        "/actualite/distinction/recherche-interventionnelle-en-sante-publique-deux-financements-importants-de": { url: "/actualites", code:301},
        "/actualite/distinction/tumeurs-du-thymus-le-pr-nicolas-girard-elu-la-presidence-litmig": { url: "/actualites", code:301},
        "/actualite/distinction/un-test-urinaire-pour-detecter-le-cancer-de-la-prostate": { url: "/actualites", code:301},
        "/actualite/e-sante/cancer-du-sein-linfo-disponible-en-toute-mobilite": { url: "/actualites", code:301},
        "/actualite/e-sante/e-sante-linstitut-curie-20": { url: "/actualites", code:301},
        "/actualite/e-sante/lintelligence-artificielle-pour-analyser-les-dossiers-cliniques": { url: "/actualites", code:301},
        "/actualite/enseignement/12-offres-de-doctorat-linstitut-curie-candidatez-maintenant": { url: "/actualites", code:301},
        "/actualite/enseignement/faites-votre-phd-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/alerte-sur-les-tests-genetiques-disponibles-en-ligne-un": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/allison-bardin-medaillee-de-bronze-du-cnrs": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/congres-sur-les-telomeres": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/crispr-cas9-linstitut-curie-sequipe-de-ciseaux-genetiques": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/deborah-bourchis-recompensee-par-le-prix-liliane-bettencourt": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/decrypter-les-programmes-genetiques-des-cellules-souches-pour": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/edith-heard-sur-france-culture-au-dela-des-genes": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/fact-controleur-qualite-du-coeur-des-chromosomes": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/la-pr-edith-heard-elue-directeur-general-de-lembl": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/lart-de-la-methylation": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/le-grand-prix-inserm-2017-pour-edith-heard": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/le-metronome-de-lembryon": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/maladie-genetique-infantile-pour-un-sucre-de-trop": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/mieux-comprendre-le-noyau-en-4d": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/une-doctorante-sur-les-plus-hautes-marches": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/une-etoile-pour-la-galaxie-epigenesys-le-reseau-coordonne-par": { url: "/actualites", code:301},
        "/actualite/epigenetique-et-genetique/voyage-au-coeur-des-paysages-moleculaires-du-chromosome-x": { url: "/actualites", code:301},
        "/actualite/esmo/esmo-2018-50-collaborateurs-de-linstitut-curie-lhonneur": { url: "/actualites", code:301},
        "/actualite/esmo/linstitut-curie-present-au-congres-de-lesmo-barcelone": { url: "/actualites", code:301},
        "/actualite/esmo/linstitut-curie-sur-tous-les-fronts-au-congres-annuel-de-lesmo": { url: "/actualites", code:301},
        "/actualite/evenement/2e-open-de-golf-de-linstitut-curie-entreprises-mobilisez-vous-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/evenement/2e-open-de-golf-de-linstitut-curie-journee-golfique-contre-le-cancer-du-sein": { url: "/actualites", code:301},
        "/actualite/evenement/2e-open-de-golf-de-linstitut-curie-les-entreprises-golferont-solidaire": { url: "/actualites", code:301},
        "/actualite/evenement/3eme-open-de-golf-solidaire-de-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/evenement/appel-projet-solidaire-creez-le-sac-collector-jonquille-2020": { url: "/actualites", code:301},
        "/actualite/evenement/concert-caritatif-exceptionnel-du-world-doctors-orchestra-paris": { url: "/actualites", code:301},
        "/actualite/evenement/course-de-la-jonquille-contre-le-cancer-2020-les-inscriptions-sont-ouvertes": { url: "/actualites", code:301},
        "/actualite/evenement/course-des-lumieres-2019-dernieres-semaines-pour-profiter-des-premiers-tarifs": { url: "/actualites", code:301},
        "/actualite/evenement/en-mars-2021-la-jonquille-contre-le-cancer-fleurit": { url: "/actualites", code:301},
        "/actualite/evenement/la-course-de-la-jonquille-contre-le-cancer-2024": { url: "/actualites", code:301},
        "/actualite/evenement/linstitut-curie-partenaire-officiel-de-la-cancer-pride": { url: "/actualites", code:301},
        "/actualite/evenement/linstitut-curie-prend-officiellement-part-au-health-data-hub-2019": { url: "/actualites", code:301},
        "/actualite/evenement/linstitut-curie-present-au-congres-de-laacr-2019-atlanta": { url: "/actualites", code:301},
        "/actualite/evenement/linstitut-curie-present-au-congres-de-lascb-embo-washington": { url: "/actualites", code:301},
        "/actualite/evenement/mobilisation-20-pour-la-course-des-lumieres-2020": { url: "/actualites", code:301},
        "/actualite/evenement/muse-ic-quand-science-et-musique-se-rencontrent": { url: "/actualites", code:301},
        "/actualite/evenement/nikon-imaging-center-institut-curie-cnrs-une-plateforme-dimagerie-scientifique": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/evenement/s3odeon-levenement-scientifique-et-societal-de-la-rentree": { url: "/actualites", code:301},
        "/actualite/evenement/sommet-ecco-2019-linstitut-curie-en-premiere-ligne": { url: "/actualites", code:301},
        "/actualite/evenement/un-record-de-mobilisation-pour-la-course-des-lumieres-2019": { url: "/actualites", code:301},
        "/actualite/generosite/1er-open-de-golf-institut-curie-au-golf-de-joyenval-les-entreprises-sengagent": { url: "/actualites", code:301},
        "/actualite/generosite/course-des-lumieres-2016-les-inscriptions-sont-ouvertes": { url: "/actualites", code:301},
        "/actualite/generosite/course-des-lumieres-2016-lespoir-brille-au-coeur-de-paris": { url: "/actualites", code:301},
        "/actualite/generosite/des-femmes-donnent-aux-femmes-des-hommes-aussi-plus-de-56-000-eu-collectes": { url: "/actualites", code:301},
        "/actualite/generosite/giving-tuesday-arrive-en-france": { url: "/actualites", code:301},
        "/actualite/generosite/grace-mutuelle-bleue-votre-sourire-aide-financierement-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/generosite/insufflez-un-vent-de-solidarite-contre-le-cancer-dans-votre-entreprise": { url: "/actualites", code:301},
        "/actualite/generosite/la-course-de-la-jonquille-prenez-le-depart-pour-faire-fleurir-lespoir": { url: "/actualites", code:301},
        "/actualite/generosite/la-course-des-lumieres-2017-choisissez-votre-allure-solidaire": { url: "/actualites", code:301},
        "/actualite/generosite/la-course-des-lumieres-2017-les-inscriptions-sont-ouvertes": { url: "/actualites", code:301},
        "/actualite/generosite/la-course-des-lumieres-2017-succes-pour-la-lutte-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/generosite/la-course-des-lumieres-2018-les-inscriptions-sont-ouvertes": { url: "/actualites", code:301},
        "/actualite/generosite/lamphitheatre-du-site-dorsay-baptise-nelly-riguet": { url: "/actualites", code:301},
        "/actualite/generosite/le-sac-jonquille-antik-batik-un-sac-solidaire-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/generosite/linstitut-curie-partenaire-de-la-course-enfants-sans-cancer-2016": { url: "/actualites", code:301},
        "/actualite/generosite/merci-pour-eux-ou-comment-se-mobiliser-contre-le-cancer-en-pharmacie": { url: "/actualites", code:301},
        "/actualite/generosite/microdon-paris-appelle-la-generosite-pour-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/generosite/monoprix-soutient-la-lutte-contre-le-cancer-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/generosite/nicolas-soutient-la-recherche-sur-le-neuroblastome": { url: "/actualites", code:301},
        "/actualite/generosite/octobre-rose-des-femmes-donnent-aux-femmes-des-hommes-aussi": { url: "/actualites", code:301},
        "/actualite/generosite/plus-que-jamais-la-recherche-sur-le-cancer-doit-continuer-davancer": { url: "/actualites", code:301},
        "/actualite/generosite/rejoignez-la-course-des-lumieres": { url: "/actualites", code:301},
        "/actualite/generosite/roland-garros-et-linstitut-curie-ca-matche-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/generosite/semi-et-marathon-de-paris-2017-decrochez-votre-dossard-avec-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/generosite/une-jonquille-pour-curie-2017-510-000-euros-collectes": { url: "/actualites", code:301},
        "/actualite/generosite/une-jonquille-pour-curie-2018-rejoignez-lequipe-des-benevoles": { url: "/actualites", code:301},
        "/actualite/generosite/vente-aux-encheres-81650-euros-collectes-grace-des-femmes-et-des-hommes-de": { url: "/actualites", code:301},
        "/actualite/generosite/visite-dangelina-jolie-linstitut-curie-un-moment-tres-touchant-et-tres-humain": { url: "/actualites", code:301},
        "/actualite/grace-vous/650-000-euros-de-generosite-relancent-lactivite-scientifique-impactee-par-la": { url: "/actualites", code:301},
        "/actualite/grace-vous/course-des-lumieres-2020-les-inscriptions-sont-ouvertes": { url: "/actualites", code:301},
        "/actualite/grace-vous/la-tendance-du-printemps-le-sac-jonquille-2019-par-natacha-birds": { url: "/actualites", code:301},
        "/actualite/grace-vous/le-don-indispensable-pour-financer-la-recherche": { url: "/actualites", code:301},
        "/actualite/grace-vous/le-don-sur-salaire-pour-un-engagement-conjoint-des-entreprises-et-des-salaries": { url: "/actualites", code:301},
        "/actualite/grace-vous/lespoir-est-aussi-dans-vos-legs-0": { url: "/actualites", code:301},
        "/actualite/grace-vous/une-carte-bancaire-pour-soutenir-la-lutte-contre-le-cancer-du-sein": { url: "/actualites", code:301},
        "/actualite/grace-vous/une-jonquille-pour-curie-2018-creez-votre-evenement-de-generosite": { url: "/actualites", code:301},
        "/actualite/grace-vous/vos-dons-sont-essentiels-pour-prendre-le-cancer-de-vitesse": { url: "/actualites", code:301},
        "/actualite/immunotherapie/ameliorer-la-prise-en-charge-du-myelome-multiple-recidivant": { url: "/actualites", code:301},
        "/actualite/immunotherapie/cancer-de-lenfant-un-espoir-pour-les-tumeurs-rhabdoides": { url: "/actualites", code:301},
        "/actualite/immunotherapie/comment-se-scelle-le-destin-des-lymphocytes-t": { url: "/actualites", code:301},
        "/actualite/immunotherapie/comprendre-la-mise-en-action-du-systeme-immunitaire": { url: "/actualites", code:301},
        "/actualite/immunotherapie/detection-du-vih-une-nouvelle-proteine-lanceuse-dalerte-mis-en-lumiere": { url: "/actualites", code:301},
        "/actualite/immunotherapie/eliane-piaggio-limmunotherapie-pour-tous": { url: "/actualites", code:301},
        "/actualite/immunotherapie/immuno-oncologie-linstitut-curie-et-mnemo-therapeutics-mettent-en-evidence": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/immunotherapie/immunologie-comment-le-lapin-eclaire-dun-jour-nouveau-le-systeme": { url: "/actualites", code:301},
        "/actualite/immunotherapie/immunotherapie-25-des-patients-obtiennent-une-reponse-prolongee": { url: "/actualites", code:301},
        "/actualite/immunotherapie/immunotherapie-explorer-de-nouvelles-pistes-pour-lutter-contre-les-cancers": { url: "/actualites", code:301},
        "/actualite/immunotherapie/immunotherapie-les-resultats-de-lasco-2016": { url: "/actualites", code:301},
        "/actualite/immunotherapie/immunotherapie-maitriser-lart-de-la-presentation-des-antigenes": { url: "/actualites", code:301},
        "/actualite/immunotherapie/limmunotherapie-une-revolution-mieux-apprehender": { url: "/actualites", code:301},
        "/actualite/immunotherapie/nouvel-essai-clinique-vers-des-immunotherapies-adaptees": { url: "/actualites", code:301},
        "/actualite/immunotherapie/partenariat-en-immuno-oncologie-et-onco-pediatre": { url: "/actualites", code:301},
        "/actualite/immunotherapie/plongez-au-coeur-du-futur-centre-dimmunotherapie-des-cancers-de-linstitut": { url: "/actualites", code:301},
        "/actualite/immunotherapie/predire-lefficacite-des-immunotherapies": { url: "/actualites", code:301},
        "/actualite/immunotherapie/premiers-patients-traites-linstitut-curie-dans-un-essai-clinique-combinant": { url: "/actualites", code:301},
        "/actualite/immunotherapie/recital-de-piano-au-profit-du-centre-dimmunotherapie-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/immunotherapie/speed-dating-entre-cellules-presentatrices-dantigenes-et-lymphocytes": { url: "/actualites", code:301},
        "/actualite/innovation/21-projets-pour-lutter-contre-le-covid-19-voient-le-jour-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/innovation/30-representants-dacteurs-de-la-sante-sur-scene": { url: "/actualites", code:301},
        "/actualite/innovation/56-brevets-delivres-en-2021-linstitut-curie-celebre-la-qualite-de-ses": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/accelerer-linnovation-en-oncologie-linstitut-curie-et-janssen-sassocient": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/ai-health-magic-lemp-remporte-la-2e-edition-du-challenge-organise-par-la": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/alain-fuchs-elu-president-de-paris-sciences-lettres-psl-0": { url: "/actualites", code:301},
        "/actualite/innovation/ascb-6-presentations-orales-pour-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/innovation/asco-dr-piperno-presente-les-resultats-de-foteadj": { url: "/actualites", code:301},
        "/actualite/innovation/asco-le-point-sur-les-grandes-avancees-en-cancerologie": { url: "/actualites", code:301},
        "/actualite/innovation/asco-resultats-dune-etude-sur-les-sarcomes-synoviaux": { url: "/actualites", code:301},
        "/actualite/innovation/association-institut-curie-et-linstitut-mutualiste-montsouris": { url: "/actualites", code:301},
        "/actualite/innovation/auriculotherapie-en-cancerologie": { url: "/actualites", code:301},
        "/actualite/innovation/avatar-medical-laureat-de-leic-accelerator-une-subvention-de-25-meu-pour-sa": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/avatar-medical-recompensee-pour-sa-solution-dimagerie-medicale-en-realite": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/big-data-faut-il-exploiter-les-donnees-de-sante": { url: "/actualites", code:301},
        "/actualite/innovation/biomarqueur-potentiel-pour-les-lymphomes-non-hodgkiniens-cerebraux": { url: "/actualites", code:301},
        "/actualite/innovation/bourses-human-frontier-science-program-deux-chercheurs-de-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/innovation/brca2-le-role-inattendu-de-ce-gene-suppresseur-de-tumeurs": { url: "/actualites", code:301},
        "/actualite/innovation/bruno-goud-nomme-directeur-du-centre-de-recherche": { url: "/actualites", code:301},
        "/actualite/innovation/cancer-de-la-prostate-la-curietherapie-lheure-du-bilan": { url: "/actualites", code:301},
        "/actualite/innovation/cancer-de-la-prostate-le-point-sur-les-avancees-des-traitements": { url: "/actualites", code:301},
        "/actualite/innovation/cancer-du-canal-anal-ladn-tumoral-circulant-pour-predire-les-rechutes": { url: "/actualites", code:301},
        "/actualite/innovation/cancer-du-col-de-luterus-1er-essai-europeen-et-1ers-resultats": { url: "/actualites", code:301},
        "/actualite/innovation/cancer-du-col-de-luterus-vers-des-traitements-personnalises": { url: "/actualites", code:301},
        "/actualite/innovation/cancer-du-sein-les-acteurs-de-la-propagation-des-tumeurs-dans-les-ganglions": { url: "/actualites", code:301},
        "/actualite/innovation/cancer-du-sein-une-alternative-aux-chimiotherapies-chez-les-femmes-menopausees": { url: "/actualites", code:301},
        "/actualite/innovation/cancers-orl-de-linteret-de-consulter-rapidement": { url: "/actualites", code:301},
        "/actualite/innovation/charlene-estrada-decroche-la-prestigieuse-bourse-loreal-unesco-pour-les-femmes": { url: "/actualites", code:301},
        "/actualite/innovation/coup-de-pouce-europeen-pour-danijela-vignjevic": { url: "/actualites", code:301},
        "/actualite/innovation/coup-de-projecteur-sur-lincubation-dentreprises-linstitut-curie-moteur-0": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/daniele-fachinetti-perce-les-mysteres-du-centromere": { url: "/actualites", code:301},
        "/actualite/innovation/de-nouvelles-structures-3d-des-chromosomes-mises-en-lumiere-aux-premiers": { url: "/actualites", code:301},
        "/actualite/innovation/decrypter-la-formation-de-vesicules-au-plus-pres-des-membranes": { url: "/actualites", code:301},
        "/actualite/innovation/defendre-et-promouvoir-les-droits-des-femmes-avoir-des-responsabilites-en": { url: "/actualites", code:301},
        "/actualite/innovation/deficits-immunitaires-primaires-linstitut-curie-est-partenaire-du-projet": { url: "/actualites", code:301},
        "/actualite/innovation/deformation-des-membranes-cellulaires-lactine-tout-faire": { url: "/actualites", code:301},
        "/actualite/innovation/deux-start-de-chercheurs-de-linstitut-curie-remportent-le-concours-i-lab-2019": { url: "/actualites", code:301},
        "/actualite/innovation/devenir-une-reference-du-transfert-de-technologies-en-cancerologie": { url: "/actualites", code:301},
        "/actualite/innovation/dosisoft-premiere-spin-de-linstitut-curie-celebre-20-ans-dinnovation-en": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/erc-leurope-mise-sur-jean-louis-viovy-pour-tisser-loncologie-de-demain": { url: "/actualites", code:301},
        "/actualite/innovation/fracturation-hydraulique-dans-lembryon": { url: "/actualites", code:301},
        "/actualite/innovation/frm-3-nouvelles-equipes-labelisees-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/innovation/genuage-visualisation-et-analyse-en-4-dimensions-des-donnees-de-microscopie-de": { url: "/actualites", code:301},
        "/actualite/innovation/giorgio-seano-sur-les-plus-hautes-marches-de-la-science-europeenne": { url: "/actualites", code:301},
        "/actualite/innovation/immunotherapie-accord-majeur-entre-les-instituts-curie-et-roche": { url: "/actualites", code:301},
        "/actualite/innovation/immunotherapie-explorer-le-genome-gris-pour-de-nouvelles-cibles-therapeutiques": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/innovation-une-construction-collective-du-transfert-de-technologie-linstitut": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/isabelle-fromantin-entre-soins-et-recherche": { url: "/actualites", code:301},
        "/actualite/innovation/journee-mondiale-de-la-propriete-intellectuelle-les-femmes-mises-lhonneur": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/la-parite-dans-les-sciences-moteur-de-linnovation": { url: "/actualites", code:301},
        "/actualite/innovation/lapplication-mycurie-recompensee-par-le-prix-du-systeme-dinformation": { url: "/actualites", code:301},
        "/actualite/innovation/le-label-institut-carnot-de-linstitut-curie-renouvele": { url: "/actualites", code:301},
        "/actualite/innovation/le-projet-de-societe-emglev-therapeutics-laureat-i-lab-2022-vers-la-29e-start": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/le-rapport-annuel-2016-est-en-ligne": { url: "/actualites", code:301},
        "/actualite/innovation/le-rapport-annuel-2017-est-en-ligne": { url: "/actualites", code:301},
        "/actualite/innovation/les-essais-cliniques-precoces-patients-daujourdhui-traitements-de-demain": { url: "/actualites", code:301},
        "/actualite/innovation/les-interactions-arn-proteines-revelees-par-la-luminescence": { url: "/actualites", code:301},
        "/actualite/innovation/les-promesses-des-cellules-dendritiques-pour-contrer-les-cellules-tumorales": { url: "/actualites", code:301},
        "/actualite/innovation/les-voies-de-transport-intracellulaire-de-nouvelles-strategies-therapeutiques": { url: "/actualites", code:301},
        "/actualite/innovation/leucemie-decouverte-dun-interrupteur-qui-provoque-la-mort-des-cellules": { url: "/actualites", code:301},
        "/actualite/innovation/libra-promouvoir-legalite-des-genres-dans-le-monde-la-recherche": { url: "/actualites", code:301},
        "/actualite/innovation/lifetime-leurope-se-tourne-vers-les-cellules-pour-un-avenir-meilleur": { url: "/actualites", code:301},
        "/actualite/innovation/linstitut-curie-annonce-la-creation-dhoning-biosciences": { url: "/actualites", code:301},
        "/actualite/innovation/linstitut-curie-au-congres-de-laacr-2020": { url: "/actualites", code:301},
        "/actualite/innovation/linstitut-curie-catalyseur-dinnovation-avec-101-inventeurs-son-actif": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/linstitut-curie-cree-son-tech-transfer-advisory-committee-pour-challenger-sa": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/linstitut-curie-de-nouveau-labellise-site-de-recherche-integree-sur-le-cancer": { url: "/actualites", code:301},
        "/actualite/innovation/linstitut-curie-developpe-son-offre-de-radiotherapie-dans-le-sud-ouest": { url: "/actualites", code:301},
        "/actualite/innovation/linstitut-curie-gustave-roussy-et-lap-hp-sallient-pour-un-projet-phare-de": { url: "/actualites", code:301},
        "/actualite/innovation/linstitut-curie-partenaire-du-congres-mondial-sur-le-cancer": { url: "/actualites", code:301},
        "/actualite/innovation/linstitut-curie-present-en-force-lasco-2017": { url: "/actualites", code:301},
        "/actualite/innovation/linstitut-curie-renforce-son-pole-urologie": { url: "/actualites", code:301},
        "/actualite/innovation/linstitut-curie-se-dote-dun-bloc-operatoire-integre-de-11-salles": { url: "/actualites", code:301},
        "/actualite/innovation/meilleurs-voeux-pour-2018": { url: "/actualites", code:301},
        "/actualite/innovation/meiogenix-ou-comment-donner-un-coup-de-pouce-la-diversite-genetique": { url: "/actualites", code:301},
        "/actualite/innovation/melanome-de-luvee-decouverte-dun-nouveau-gene-de-predisposition": { url: "/actualites", code:301},
        "/actualite/innovation/melanome-une-nouvelle-cible-pour-contrer-la-resistance-aux-traitements": { url: "/actualites", code:301},
        "/actualite/innovation/mieux-evaluer-les-toxicites-pour-developper-des-medicaments-plus-efficaces": { url: "/actualites", code:301},
        "/actualite/innovation/nanoparticules-et-radiotherapie-cocktail-gagnant-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/innovation/nouvelle-piste-therapeutique": { url: "/actualites", code:301},
        "/actualite/innovation/olivier-lantz-laureat-dun-erc-advanced-grant-2019": { url: "/actualites", code:301},
        "/actualite/innovation/partenariat-novateur-en-immuno-oncologie-avec-pierre-fabre": { url: "/actualites", code:301},
        "/actualite/innovation/permedcoe-lintelligence-artificielle-au-service-de-la-medecine-personnalisee": { url: "/actualites", code:301},
        "/actualite/innovation/pour-plus-de-transparence-sur-le-prix-des-medicaments": { url: "/actualites", code:301},
        "/actualite/innovation/pour-une-medecine-de-precision-toujours-plus-fine": { url: "/actualites", code:301},
        "/actualite/innovation/prairie-le-role-cle-de-linstitut-curie-dans-le-developpement-de-lia": { url: "/actualites", code:301},
        "/actualite/innovation/predisposition-au-cancer-du-sein-de-petites-modifications-de-brca2-qui": { url: "/actualites", code:301},
        "/actualite/innovation/prix-unicancer-linstitut-curie-partenaire-de-deux-projets-recompenses": { url: "/actualites", code:301},
        "/actualite/innovation/puja-singh-les-microtubules-comme-fil-conducteur": { url: "/actualites", code:301},
        "/actualite/innovation/radiotherapie-flash-linstitut-curie-et-thales-sassocient-pour-creer-une": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/recherche-lifetime-une-proposition-visionnaire-pour-une-initiative-phare-de": { url: "/actualites", code:301},
        "/actualite/innovation/reconstituer-des-ecosystemes-tumoraux-pour-tester-la-reponse-aux-medicaments": { url: "/actualites", code:301},
        "/actualite/innovation/resistance-limmunotherapie-un-nouveau-type-cellulaire-dans-le-micro": { url: "/actualites", code:301},
        "/actualite/innovation/retrouvez-linstitut-curie-sur-instagram": { url: "/actualites", code:301},
        "/actualite/innovation/sensibiliser-aux-cancers-des-voies-aero-digestives-superieures": { url: "/actualites", code:301},
        "/actualite/innovation/simulation-en-sante-une-methode-pedagogique-innovante-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/innovation/single-cell-la-biologie-change-dechelle": { url: "/actualites", code:301},
        "/actualite/innovation/syndrome-de-bloom-une-maladie-rare-mais-des-decouvertes-universelles": { url: "/actualites", code:301},
        "/actualite/innovation/systeme-immunitaire-des-cellules-t-teleguidees-par-notre-microbiote": { url: "/actualites", code:301},
        "/actualite/innovation/un-google-maps-du-systeme-immunitaire-pour-predire-la-reponse-aux-traitements": { url: "/actualites", code:301},
        "/actualite/innovation/un-nouveau-site-web-pour-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/innovation/un-nouveau-talon-dachille-des-cellules-souches-cancereuses": { url: "/actualites", code:301},
        "/actualite/innovation/un-pas-de-plus-dans-laccompagnement-de-linnovation-linstitut-curie-lance-son": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/un-succes-confirme-pour-les-inventions-brevetees-de-linstitut-curie": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/une-equipe-de-linstitut-curie-decroche-un-trone-de-fer": { url: "/actualites", code:301},
        "/actualite/innovation/une-molecule-issue-de-travaux-menes-linstitut-curie-et-developpee-par-abivax": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/une-nouvelle-cible-therapeutique-dans-le-medulloblastome": { url: "/actualites", code:301},
        "/actualite/innovation/une-nouvelle-methode-de-microfluidique-pour-etudier-lepigenetique-en-cellule": { url: "/actualites", code:301},
        "/actualite/innovation/une-nouvelle-strategie-en-immunotherapie-linstitut-curie-salue-la-levee-de": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/une-origine-importante-de-fragilite-de-ladn-enfin-elucidee": { url: "/actualites", code:301},
        "/actualite/innovation/vaccin-therapeutique-contre-le-cancer-des-resultats-tres-prometteurs-pour-les": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/innovation/vih-une-cle-vers-la-guerison-fonctionnelle": { url: "/actualites", code:301},
        "/actualite/innovation/xose-fernandez-fait-parler-les-donnees-pour-aider-les-patients": { url: "/actualites", code:301},
        "/actualite/innovation/yohanns-bellaiche-decroche-le-graal-de-la-recherche-europeenne": { url: "/actualites", code:301},
        "/actualite/institutionnel/fatima-mechta-grigoriou-et-matthieu-piel-membres-de-lembo": { url: "/actualites", code:301},
        "/actualite/institutionnel/la-recherche-fondamentale-linstitut-curie-est-une-source-inestimable": { url: "/actualites", code:301},
        "/actualite/institutionnel/le-futur-hopital-de-linstitut-curie-saint-cloud": { url: "/actualites", code:301},
        "/actualite/institutionnel/linstitut-curie-plus-que-jamais-engage-dans-la-lutte-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/institutionnel/linstitut-curie-vous-presente-ses-meilleurs-voeux-pour-2021": { url: "/actualites", code:301},
        "/actualite/institutionnel/luniversite-paris-sciences-et-lettres-perennisee": { url: "/actualites", code:301},
        "/actualite/institutionnel/meilleurs-voeux-pour-2019": { url: "/actualites", code:301},
        "/actualite/institutionnel/meilleurs-voeux-pour-2020": { url: "/actualites", code:301},
        "/actualite/institutionnel/nomination-alain-puisieux-nouveau-directeur-du-centre-de-recherche-de": { url: "/actualites", code:301},
        "/actualite/institutionnel/palmares-des-hopitaux-2020-du-point-le-classement-de-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/institutionnel/pascal-hersen-nouveau-directeur-de-lunite-physico-chimie-curie": { url: "/actualites", code:301},
        "/actualite/institutionnel/projet-de-lextension-de-linstitut-curie-saint-cloud-les-travaux-battent": { url: "/actualites", code:301},
        "/actualite/institutionnel/quatre-nouvelles-equipes-au-centre-de-recherche": { url: "/actualites", code:301},
        "/actualite/institutionnel/rapport-annuel-2018-une-annee-tremplin": { url: "/actualites", code:301},
        "/actualite/institutionnel/rapport-annuel-2019-un-pas-de-plus-vers-le-comprehensive-cancer-center-de": { url: "/actualites", code:301},
        "/actualite/institutionnel/recombinaison-de-ladn-un-verrou-cede-grace-alphafold2": { url: "/actualites", code:301},
        "/actualite/institutionnel/trois-nouveaux-directeurs-dunite-au-centre-de-recherche": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/apres-20-ans-dinterface-physique-biologie-lavenir-se-profile": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/chez-les-cellules-comme-en-recherche-tout-est-affaire-dechanges": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/congres-prevention-et-traitement-de-la-douleur-chronique-liee-au": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/contre-sens-cellulaires": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/de-lart-dassembler-le-coeur-des-chromosomes": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/deces-de-maxime-dahan-directeur-de-lunite-de-physico-chimie-curie": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/des-laboratoires-sur-puce-pour-etudier-les-neurones": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/dessine-moi-un-cancer": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/dossier-special-palmares-2016-des-hopitaux-et-cliniques": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/embryon-sous-pression": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/fete-de-la-science-street-art-visite-de-labo-et-experience": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/hiv-cure-and-cancer-renforcer-les-collaborations-au-benefice-des": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/institut-convergences-un-projet-de-linstitut-curie-selectionne": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/integrite-et-recherche": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/jean-louis-viovy-le-diagnostic-par-electrophorese-recompensees": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/la-marche-rapide-de-la-myosine-x": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/lambition-de-q-life-predire-lavenir-des-organismes": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/les-microtubules-ont-presque-50-ans-et-ils-se-portent-bien": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/lextraordinaire-pierre-gilles-de-gennes": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/mecanique-cancer-et-lembryogenese": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/modeler-la-membrane-des-cellules": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/mounira-amor-gueret-nommee-au-comite-consultatif-national-dethique": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/nouveau-numero-de-rose-magazine": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/projet-mc21-accelerateur-dinnovations-en-cancerologie": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/rapport-annuel-2015-retour-sur-une-annee-tremplin": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/sommet-de-recherche-franco-israelien-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/toujours-plus-de-collaborations-europeennes": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/trois-prix-psl-nouvelle-equipe-2015-remis-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/un-pas-de-plus-dans-la-nanoscopie": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/vih-decouverte-des-cles-dentree-dans-le-noyau-cellulaire": { url: "/actualites", code:301},
        "/actualite/interdisciplinarite/virus-zika-quels-sont-les-mecanismes-lorigine-de-la-microcephalie": { url: "/actualites", code:301},
        "/actualite/international/international-lexpertise-de-linstitut-curie-en-partage": { url: "/actualites", code:301},
        "/actualite/international/linstitut-curie-partenaire-de-lagence-francaise-de-developpement-dans-la": { url: "/actualites", code:301},
        "/actualite/international/observatoire-cancer-cout-des-traitements": { url: "/actualites", code:301},
        "/actualite/journee-internationale-des-cancers-de-lenfant/humanitaire-linstitut-curie-sengage-contre": { url: "/actualites", code:301},
        "/actualite/journee-mondiale-contre-le-cancer/big-data-et-intelligence-artificielle-une-revolution": { url: "/actualites", code:301},
        "/actualite/journee-mondiale-contre-le-cancer/cancer-du-pancreas-mieux-le-connaitre": { url: "/actualites", code:301},
        "/actualite/journee-mondiale-contre-le-cancer/journee-mondiale-contre-le-cancer-2021": { url: "/actualites", code:301},
        "/actualite/journee-mondiale-contre-le-cancer/une-combinaison-tres-prometteuse": { url: "/actualites", code:301},
        "/actualite/journee-mondiale-des-cancers-de-lenfant/cancers-pediatriques-linstitut-curie-federe-ses": { url: "/actualites", code:301},
        "/actualite/journee-mondiale-des-cancers-de-lenfant/le-developpement-du-cervelet-et-du-medullobastome": { url: "/actualites", code:301},
        "/actualite/le-projet-du-futur-hopital-parisien-de-linstitut-curie-devoile": { url: "/actualites", code:301},
        "/actualite/le-site-de-linstitut-curie-saint-cloud-sagrandit": { url: "/actualites", code:301},
        "/actualite/mars-bleu/comment-traiter-les-metastases-de-cancer-colo-rectal": { url: "/actualites", code:301},
        "/actualite/mars-bleu/mars-bleu-linstitut-curie-offre-une-prise-en-charge-sur-mesure-aux-patient": { url: "/actualites", code:301},
        "/actualite/mars-bleu/sur-tous-les-fronts-du-cancer-colorectal": { url: "/actualites", code:301},
        "/actualite/medecine-de-precision/adn-tumoral-circulant-pour-le-suivi-des-patients-des-resultats-et": { url: "/actualites", code:301},
        "/actualite/medecine-de-precision/asco-lheure-de-la-medecine-de-precision": { url: "/actualites", code:301},
        "/actualite/medecine-de-precision/cancer-de-la-vessie-un-nouveau-pas-franchi-vers-la-medecine-de": { url: "/actualites", code:301},
        "/actualite/medecine-de-precision/cancer-profil-genomique-et-choix-des-traitements": { url: "/actualites", code:301},
        "/actualite/medecine-de-precision/faire-de-la-medecine-de-precision-une-realite-pour-tous": { url: "/actualites", code:301},
        "/actualite/medecine-de-precision/jacques-prost-de-la-physique-la-cellule": { url: "/actualites", code:301},
        "/actualite/medecine-de-precision/maud-kamal-lart-de-la-coordination": { url: "/actualites", code:301},
        "/actualite/medecine-de-precision/scandare-vers-plus-de-precision": { url: "/actualites", code:301},
        "/actualite/medecine-de-precision/tout-savoir-sur-la-medecine-de-precision": { url: "/actualites", code:301},
        "/actualite/medecine-de-precision/un-tout-en-un-miniaturise-pour-la-medecine-de-demain": { url: "/actualites", code:301},
        "/actualite/melanomes-de-loeil/cours-international-sur-la-pathologie-du-melanome": { url: "/actualites", code:301},
        "/actualite/melanomes-de-loeil/immunotherapie-un-nouveau-marqueur-biologique-devoile-pour-le-melanome": { url: "/actualites", code:301},
        "/actualite/melanomes-de-loeil/lamcc-combat-le-retinoblastome-en-afrique": { url: "/actualites", code:301},
        "/actualite/melanomes-de-loeil/les-espoirs-des-medecins-chercheurs-de-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/melanomes-de-loeil/linstitut-curie-et-paris-descartes-lancent-un-diplome-universitaire": { url: "/actualites", code:301},
        "/actualite/melanomes-de-loeil/linstitut-curie-reconnu-pour-son-expertise-des-cancers-de-loeil": { url: "/actualites", code:301},
        "/actualite/melanomes-de-loeil/melanome-de-luvee-mieux-connaitre-pour-mieux-traiter": { url: "/actualites", code:301},
        "/actualite/melanomes-de-loeil/melanome-de-luvee-plaidoyer-pour-une-recherche-adaptee": { url: "/actualites", code:301},
        "/actualite/melanomes-de-loeil/melanome-de-luvee-un-projet-europeen-pour-le-traitement-des-formes": { url: "/actualites", code:301},
        "/actualite/melanomes-de-luvee/melanome-de-luvee-une-piste-prometteuse-pour-traiter-les-formes": { url: "/actualites", code:301},
        "/actualite/melanomes-de-luvee/simon-saule-360deg-sur-loeil": { url: "/actualites", code:301},
        "/actualite/mobilisation/associations-entreprises-portez-les-couleurs-de-la-jonquille-en-mars": { url: "/actualites", code:301},
        "/actualite/mobilisation/avec-truffaut-les-jonquilles-contre-le-cancer-fleurissent-la-france": { url: "/actualites", code:301},
        "/actualite/mobilisation/course-de-la-jonquille-2019-chaussez-vos-baskets-au-profit-de-la-lutte": { url: "/actualites", code:301},
        "/actualite/mobilisation/course-des-lumieres-2019-appel-la-generosite": { url: "/actualites", code:301},
        "/actualite/mobilisation/course-des-lumieres-2019-les-inscriptions-sont-ouvertes": { url: "/actualites", code:301},
        "/actualite/mobilisation/la-course-des-lumieres-2018-courir-ou-marcher-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/mobilisation/la-course-des-lumieres-2018-rejoignez-ce-formidable-elan-de-solidarite": { url: "/actualites", code:301},
        "/actualite/mobilisation/lancement-dune-jonquille-pour-curie-2019-face-au-cancer-tous-mobilises": { url: "/actualites", code:301},
        "/actualite/mobilisation/made-curie-une-vente-aux-encheres-inedite-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/mobilisation/monoprix-renouvelle-son-soutien-la-lutte-contre-le-cancer-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/mobilisation/mutuelle-bleue-embarque-linstitut-curie-pour-la-transat-ag2r-la-mondiale": { url: "/actualites", code:301},
        "/actualite/mobilisation/swiss-life-mobilise-une-chaine-de-solidarite-pour-faire-fleurir-lespoir": { url: "/actualites", code:301},
        "/actualite/mobilisation/un-grand-merci-monoprix-ses-equipes-et-ses-clients-pour-leur-mobilisation": { url: "/actualites", code:301},
        "/actualite/mobilisation/un-record-de-generosite-pour-la-course-des-lumieres-2018": { url: "/actualites", code:301},
        "/actualite/mobilisation/une-jonquille-pour-curie-2019-630-000-eu-au-profit-de-la-recherche": { url: "/actualites", code:301},
        "/actualite/mobilisation/une-jonquille-pour-curie-2019-la-france-se-mobilise": { url: "/actualites", code:301},
        "/actualite/mobilisation/vente-aux-encheres-made-curie-un-elan-de-solidarite-au-benefice-des-patients": { url: "/actualites", code:301},
        "/actualite/nos-patients-notre-priorite/quand-limage-vient-au-chevet-des-patients": { url: "/actualites", code:301},
        "/actualite/notre-histoire/la-methode-curie-la-bande-dessinee-numerique-du-musee-curie": { url: "/actualites", code:301},
        "/actualite/notre-histoire/les-100-ans-de-la-fondation-curie": { url: "/actualites", code:301},
        "/actualite/octobre-rose/10-000-grues-pour-sensibiliser-aux-cancers-du-sein-hereditaires-le-grand": { url: "/actualites", code:301},
        "/actualite/octobre-rose/10-000-messages-despoir-pour-sensibiliser-aux-cancers-du-sein-des-femmes": { url: "/actualites", code:301},
        "/actualite/octobre-rose/cancer-du-sein-le-futur-du-depistage": { url: "/actualites", code:301},
        "/actualite/octobre-rose/cancer-du-sein-triple-negatif-associer-radiotherapie-et-inhibiteurs-de-parp": { url: "/actualites", code:301},
        "/actualite/octobre-rose/cancers-du-sein-triple-negatifs-repousser-les-frontieres-de-la-lutte": { url: "/actualites", code:301},
        "/actualite/octobre-rose/de-nouvelles-armes-therapeutiques-contre-les-cancers-du-sein-des-femmes": { url: "/actualites", code:301},
        "/actualite/octobre-rose/fatima-mechta-grigoriou-grand-prix-ruban-rose": { url: "/actualites", code:301},
        "/actualite/octobre-rose/fatima-mechta-grigoriou-son-combat-contre-les-cancers-du-sein-triple-negatif": { url: "/actualites", code:301},
        "/actualite/octobre-rose/invadopodes-comment-une-cellule-tumorale-devient-invasive": { url: "/actualites", code:301},
        "/actualite/octobre-rose/le-prix-ruban-rose-avenir-2019-decerne-francois-clement-bidard": { url: "/actualites", code:301},
        "/actualite/octobre-rose/octobre-rose-2018-ensemble-mobilises-contre-les-cancers-du-sein-hereditaires": { url: "/actualites", code:301},
        "/actualite/octobre-rose/octobre-rose-2019-le-cancer-du-sein-chez-les-jeunes-femmes": { url: "/actualites", code:301},
        "/actualite/octobre-rose/octobre-rose-2020-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/octobre-rose/octobre-rose-conferences-ateliers-voici-le-programme": { url: "/actualites", code:301},
        "/actualite/octobre-rose/octobre-rose-des-femmes-et-des-hommes-de-coeur-sengagent": { url: "/actualites", code:301},
        "/actualite/octobre-rose/octobre-rose-tricotons-ensemble-un-ruban-rose-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/octobre-rose/octobre-rose-un-bracelet-pour-soutenir-la-lutte-contre-le-cancer-du-sein": { url: "/actualites", code:301},
        "/actualite/octobre-rose/un-prix-nobel-de-medecine-qui-resonne-doublement-pendant-octobre-rose": { url: "/actualites", code:301},
        "/actualite/octobre-rose/un-ruban-rose-pour-sensibiliser-au-cancer-du-sein": { url: "/actualites", code:301},
        "/actualite/octobre-rose/une-carte-bancaire-pour-soutenir-la-lutte-contre-le-cancer-du-sein": { url: "/actualites", code:301},
        "/actualite/ouverture/22-avril-la-marche-pour-les-sciences": { url: "/actualites", code:301},
        "/actualite/ouverture/de-limportance-de-la-recherche-fondamentale": { url: "/actualites", code:301},
        "/actualite/ouverture/exposition-marie-curie-une-femme-au-pantheon": { url: "/actualites", code:301},
        "/actualite/ouverture/initiative-lifetime-ameliorer-les-soins-et-les-systemes-de-sante-en-europe": { url: "/actualites", code:301},
        "/actualite/ouverture/institut-du-thorax-lexcellence-de-la-recherche-aux-soins": { url: "/actualites", code:301},
        "/actualite/ouverture/itcc-p4-un-accelerateur-dinnovations-pour-les-cancers-de-lenfant-et-de": { url: "/actualites", code:301},
        "/actualite/ouverture/le-10e-numero-de-rose-magazine-est-disponible": { url: "/actualites", code:301},
        "/actualite/ouverture/le-point-linstitut-curie-au-palmares-des-hopitaux-et-cliniques": { url: "/actualites", code:301},
        "/actualite/ouverture/le-pr-fumoleau-nouveau-directeur-de-lensemble-hospitalier": { url: "/actualites", code:301},
        "/actualite/ouverture/le-programme-national-daccueil-des-scientifiques-en-exil": { url: "/actualites", code:301},
        "/actualite/ouverture/levons-nous-pour-les-droits-des-femmes": { url: "/actualites", code:301},
        "/actualite/ouverture/linstitut-curie-bien-classe-au-palmares-des-hopitaux-du-point": { url: "/actualites", code:301},
        "/actualite/ouverture/linstitut-curie-et-servier-prolongent-et-renforcent-leur-collaboration": { url: "/actualites", code:301},
        "/actualite/ouverture/linstitut-curie-exporte-son-programme-patient-partenaire-linternational": { url: "/actualites", code:301},
        "/actualite/ouverture/linstitut-curie-rejoint-la-marque-french-healthcare": { url: "/actualites", code:301},
        "/actualite/ouverture/linstitut-curie-sassocie-lhommage-national-simone-veil": { url: "/actualites", code:301},
        "/actualite/ouverture/marie-curie-le-combat-continue": { url: "/actualites", code:301},
        "/actualite/ouverture/novembre-mon-mois-sans-tabac-notre-mois-sans-fumer": { url: "/actualites", code:301},
        "/actualite/ouverture/observatoire-2019-les-inegalites-face-au-cancer": { url: "/actualites", code:301},
        "/actualite/ouverture/observatoire-cancer-cancers-hereditaires-predire-pour-mieux-prevenir": { url: "/actualites", code:301},
        "/actualite/ouverture/paillasses-et-blouses-blanches-la-nouvelle-exposition-du-musee-curie": { url: "/actualites", code:301},
        "/actualite/ouverture/promouvoir-la-recherche-en-europe": { url: "/actualites", code:301},
        "/actualite/ouverture/recherche-sur-le-cancer-progres-promesses-et-politique": { url: "/actualites", code:301},
        "/actualite/ouverture/reconstruction-mammaire-lexpertise-de-linstitut-curie-transposee-aux-antipodes": { url: "/actualites", code:301},
        "/actualite/ouverture/soins-palliatifs-linstitut-curie-exporte-son-expertise-au-kenya-et-en-tanzanie": { url: "/actualites", code:301},
        "/actualite/parcours-patient/apres-cancer-reecoutez-priorite-sante-avec-claude-boiron-et-jean": { url: "/actualites", code:301},
        "/actualite/parcours-patient/blu-magazine-pour-les-hommes-touches-par-le-cancer": { url: "/actualites", code:301},
        "/actualite/parcours-patient/cancer-de-la-prostate-depister-ou-ne-pas-depister": { url: "/actualites", code:301},
        "/actualite/parcours-patient/cancer-ne-pas-oublier-les-aidants": { url: "/cancer-ne-pas-oublier-les-aidants", code:301},
        "/actualite/parcours-patient/cancerologie-quand-la-recherche-se-penche-sur-lhumain": { url: "/actualites", code:301},
        "/actualite/parcours-patient/consultation-doncogenetique-une-etape-fort-retentissement-psychologique": { url: "/actualites", code:301},
        "/actualite/parcours-patient/custom-pour-une-meilleure-prise-en-charge-des-sarcomes": { url: "/actualites", code:301},
        "/actualite/parcours-patient/deficit-en-dpd-avant-une-chimiotherapie-linstitut-curie-met-en-place-un": { url: "/actualites", code:301},
        "/actualite/parcours-patient/depistage-le-26-mai-exposez-votre-peau-au-regard-du-dermatologue": { url: "/actualites", code:301},
        "/actualite/parcours-patient/double-distinction-pour-anne-vincent-salomon": { url: "/actualites", code:301},
        "/actualite/parcours-patient/droit-loubli-le-president-thierry-philip-invite-du-telephone-sonne-sur": { url: "/actualites", code:301},
        "/actualite/parcours-patient/innovation-digitale-la-transformation-numerique-acceleree-par-la-crise": { url: "/actualites", code:301},
        "/actualite/parcours-patient/journee-feminite-linstitut-curie-le-2-mai-2017": { url: "/actualites", code:301},
        "/actualite/parcours-patient/journee-mondiale-sans-tabac-nouvelle-opportunite-pour-diminuer-sa": { url: "/actualites", code:301},
        "/actualite/parcours-patient/les-infirmieres-maillons-essentiels-de-la-chaine-de-soins": { url: "/actualites", code:301},
        "/actualite/parcours-patient/linstitut-curie-poursuit-ses-investissements-technologiques-avec-un": { url: "/actualites", code:301},
        "/actualite/parcours-patient/mammographie-un-nouvel-outil-pour-des-examens-moins-douloureux-et-plus": { url: "/actualites", code:301},
        "/actualite/parcours-patient/partenariat-avec-lhopital-delafontaine": { url: "/actualites", code:301},
        "/actualite/parcours-patient/pendant-et-apres-un-cancer-la-maison-des-patients-et-des-proches-fete": { url: "/actualites", code:301},
        "/actualite/parcours-patient/pour-une-meilleure-prise-en-charge-dune-tumeur-rare": { url: "/actualites", code:301},
        "/actualite/parcours-patient/promouvoir-la-vaccination-des-patients": { url: "/actualites", code:301},
        "/actualite/parcours-patient/reunions-nutritionnelles-vous-informer-pendant-et-apres-votre-traitement": { url: "/actualites", code:301},
        "/actualite/parcours-patient/soins-de-support-envol-des-cooperations-internationales": { url: "/actualites", code:301},
        "/actualite/parcours-patient/soins-esthetiques-un-moment-de-bien-etre-pour-les-patients-grace": { url: "/actualites", code:301},
        "/actualite/parcours-patient/un-rendez-vous-linstitut-curie-en-un-clic-grace-doctolib": { url: "/actualites", code:301},
        "/actualite/parcours-patient/vaincre-la-douleur-une-priorite-pour-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/parcours-patient/vaincre-la-douleur-une-priorite-pour-linstitut-curie-0": { url: "/actualites", code:301},
        "/actualite/paroles-dexpert/au-plus-pres-du-patient-les-consultations-infirmieres": { url: "/actualites", code:301},
        "/actualite/partenariat/campagne-une-jonquille-pour-curie-truffaut-un-partenaire-historique": { url: "/actualites", code:301},
        "/actualite/partenariat/defi-solidaire-octobre-rose-mondial-tissus-soutient-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/partenariat/des-jouets-et-des-jeux-offerts-aux-jeunes-patients-de-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/partenariat/le-label-carnot-de-linstitut-curie-renouvele-pour-4-ans": { url: "/actualites", code:301},
        "/actualite/partenariat/linstitut-arthur-vernes-et-linstitut-curie-sunissent-pour-les-patients": { url: "/actualites", code:301},
        "/actualite/partenariat/linstitut-curie-choisit-intel-comme-partenaire-principal": { url: "/actualites", code:301},
        "/actualite/partenariat/linstitut-curie-et-bioderma-sassocient-et-ouvrent-un-espace-de-soins-et": { url: "/actualites", code:301},
        "/actualite/partenariat/linstitut-curie-partenaire-du-film-radioactive-de-marjane-satrapi": { url: "/actualites", code:301},
        "/actualite/partenariat/mutuelle-bleue-offre-des-voitures-electriques-aux-enfants-hospitalises": { url: "/actualites", code:301},
        "/actualite/partenariat/mutuelle-bleue-partenaire-de-linstitut-curie-depuis-10-ans": { url: "/actualites", code:301},
        "/actualite/partenariat/nos-quatre-laboratoires-dexcellence-renouveles": { url: "/actualites", code:301},
        "/actualite/partenariat/partenariat-historique-entre-linstitut-weizmann-des-sciences-et-linstitut": { url: "/actualites", code:301},
        "/actualite/partenariat/un-nouveau-parcours-de-soins-dedie-la-prise-en-charge-des-metastases-osseuses": { url: "/actualites", code:301},
        "/actualite/publication/cancer-du-col-de-luterus-de-nouveaux-biomarqueurs-identifies-pour-predire": { url: "/actualites", code:301},
        "/actualite/publication/cancer-un-nouveau-mecanisme-de-regulation-de-lactivite-cellulaire-impliquant": { url: "/actualites", code:301},
        "/actualite/publication/cancers-du-sein-metastatiques-chimiotherapie-et-hormonotherapie-les-cellules": { url: "/actualites", code:301},
        "/actualite/publication/comment-les-spermatozoides-gardent-le-cap": { url: "/actualites", code:301},
        "/actualite/publication/dechiffrer-les-mecanismes-des-centromeres-pour-comprendre-le-developpement": { url: "/actualites", code:301},
        "/actualite/publication/deciphering-centromeres-understand-how-cancer-cells-develop": { url: "/la-recherche-linstitut-curie", code:301},
        "/actualite/publication/espoir-autour-dune-nouvelle-strategie-therapeutique-dans-le-cancer-du-sein": { url: "/actualites", code:301},
        "/actualite/publication/immunotherapie-deux-populations-de-precurseurs-decouvertes-dans-le-cancer-du": { url: "/actualites", code:301},
        "/actualite/publication/instabilite-genomique-et-lesions-precancereuses-le-role-des-r-loops-peu-peu": { url: "/actualites", code:301},
        "/actualite/publication/la-microscopie-electronique-pour-decrire-levolution-des-moteurs-moleculaires": { url: "/actualites", code:301},
        "/actualite/publication/larchitecture-du-noyau-au-secours-du-bon-deroulement-de-la-replication-de": { url: "/actualites", code:301},
        "/actualite/publication/lequipe-de-celine-vallot-honoree-par-un-prestigieux-financement-europeen": { url: "/actualites", code:301},
        "/actualite/publication/les-cellules-cancereuses-persistantes-le-defi-des-annees-venir": { url: "/actualites", code:301},
        "/actualite/publication/les-dessous-de-lunion-paisible-entre-nos-genes-et-les-retrotransposons": { url: "/actualites", code:301},
        "/actualite/publication/lheterogeneite-du-sarcome-dewing-mieux-comprise-grace-la-technologie-single": { url: "/actualites", code:301},
        "/actualite/publication/melanome-uveal-de-nouveaux-eclairages-sur-des-cibles-therapeutiques": { url: "/actualites", code:301},
        "/actualite/publication/mise-en-evidence-dune-enzyme-cle-pour-des-immunotherapies-plus-efficaces-et": { url: "/actualites?fieldDomainNews[]=272&page=1", code:301},
        "/actualite/publication/resultats-encourageants-pour-un-essai-dimmunotherapie-dans-les-cancers": { url: "/actualites", code:301},
        "/actualite/publication/un-nouveau-mecanisme-sous-jacent-linfertilite-masculine": { url: "/actualites", code:301},
        "/actualite/publication/vers-une-nouvelle-therapie-ciblee-contre-les-leucemies-lymphoblastiques": { url: "/actualites", code:301},
        "/actualite/radiotherapie/anne-helene-monsoro-burq-nommee-linstitut-universitaire-de-france": { url: "/actualites", code:301},
        "/actualite/radiotherapie/cap-sur-la-radiotherapie-de-demain": { url: "/actualites", code:301},
        "/actualite/radiotherapie/changement-de-pratiques-pour-les-plasmocytomes-solitaires": { url: "/actualites", code:301},
        "/actualite/radiotherapie/dbait-les-efforts-se-poursuivent": { url: "/actualites", code:301},
        "/actualite/radiotherapie/deploiement-dune-toute-nouvelle-technologie-en-protontherapie": { url: "/actualites", code:301},
        "/actualite/radiotherapie/la-radiotherapie-innove-dans-la-prise-en-charge-des-cancers-de-la-prostate": { url: "/actualites", code:301},
        "/actualite/radiotherapie/la-radiotherapie-noumea-grace-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/radiotherapie/le-1er-accelerateur-de-radiotherapie-halcyon-en-ile-de-france-installe": { url: "/actualites", code:301},
        "/actualite/radiotherapie/le-prix-guy-lazorthes-2016-marie-dutreix": { url: "/actualites", code:301},
        "/actualite/radiotherapie/les-espoirs-dune-oncologue-radiotherapeute": { url: "/actualites", code:301},
        "/actualite/radiotherapie/melanome-de-la-peau-booster-la-radiotherapie-grace-aux-dbait": { url: "/actualites", code:301},
        "/actualite/radiotherapie/philip-poortmans-continuer-le-combat-de-marie-curie": { url: "/actualites", code:301},
        "/actualite/radiotherapie/pour-une-radiotherapie-encore-plus-personnalisee": { url: "/actualites", code:301},
        "/actualite/radiotherapie/radiobiologie-experimentale-un-nouveau-lieu-pour-dambitieuses-recherches": { url: "/actualites", code:301},
        "/actualite/radiotherapie/radiotherapie-flash-leffervescence-dun-tournant-prometteur": { url: "/actualites", code:301},
        "/actualite/radiotherapie/radiotherapie-poursuivre-linnovation": { url: "/actualites", code:301},
        "/actualite/radiotherapie/trop-de-desoxycytidine-provoque-la-division-prematuree-des-cellules": { url: "/actualites", code:301},
        "/actualite/recherche-clinique/cancer-du-col-de-luterus-nicol-le-nouvel-essai-therapeutique": { url: "/actualites", code:301},
        "/actualite/recherche-clinique/cancer-les-biomarqueurs-revolutionnent-la-prise-en-charge": { url: "/actualites", code:301},
        "/actualite/recherche-clinique/celecoxib-eviter-pendant-la-chimiotherapie-du-cancer-du-sein": { url: "/actualites", code:301},
        "/actualite/recherche-clinique/esmo-2017-linstitut-curie-present-en-force-madrid": { url: "/actualites", code:301},
        "/actualite/recherche-clinique/la-recherche-clinique-passe-un-nouveau-cap-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/recherche-clinique/neoelasto-predire-lefficacite-de-la-chimiotherapie-grace-une": { url: "/actualites", code:301},
        "/actualite/recherche-clinique/shiva02-un-essai-cle-pour-la-medecine-de-precision-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/recherche-clinique/un-anticorps-pour-lutter-contre-les-cancers-ovariens": { url: "/actualites", code:301},
        "/actualite/sarcomes/chirurgie-des-sarcomes-50-experts-se-perfectionnent-linstitut-curie": { url: "/actualites", code:301},
        "/actualite/sarcomes/le-parcours-custom-retour-dexperience": { url: "/actualites", code:301},
        "/actualite/sarcomes/osteosarcome-de-nouvelles-recherches-simposent": { url: "/actualites", code:301},
        "/actualite/sarcomes/quel-est-le-point-commun-entre-un-sarcome-et-des-cristaux-liquides": { url: "/actualites", code:301},
        "/actualite/sarcomes/sarcome-linstitut-curie-dispose-de-toutes-les-techniques": { url: "/actualites", code:301},
        "/actualite/sarcomes/sarcomes-des-molecules-prometteuses": { url: "/actualites", code:301},
        "/actualite/sarcomes/sarcomes-des-nanoparticules-pour-optimiser-la-prise-en-charge": { url: "/actualites", code:301},
        "/actualite/sarcomes/sarcomes-le-premier-diplome-universitaire-francais": { url: "/actualites", code:301},
        "/actualite/sarcomes/sarcomes-une-annee-riche-en-avancees": { url: "/actualites", code:301},
        "/actualite/transmission-des-savoirs/il-y-80-ans-irene-curie-premiere-femme-au-gouvernement": { url: "/actualites", code:301},
        "/actualite/transmission-des-savoirs/le-programme-medecinesciences-ouvert": { url: "/actualites", code:301},
        "/actualite/transmission-des-savoirs/melanomes-succes-du-cours-international": { url: "/actualites", code:301},
        "/actualite/transmission/esprits-curieux-des-livres-pour-faire-decouvrir-la-science-aux-enfants": { url: "/actualites", code:301},
        "/actualite/une-jonquille-contre-le-cancer/en-mars-faisons-fleurir-lespoir-contre-le-cancer": { url: "/actualites", code:301},
        "/actualite/une-jonquille-contre-le-cancer/une-jonquille-pour-curie-2018-ensemble-faisons-fleurir": { url: "/actualites", code:301},
        "/ajax/candidature-spontanee/1": { url: "/candidature-spontanee", code:301},
        "/alain-puisieux": { url: "/pr-alain-puisieux-president-du-directoire-de-linstitut-curie", code:301},
        "/analyse-genetique-et-genomique-du-melanome-uveal-mu": { url: "/melanome-uveal", code:301},
        "/annuaire-medecins": { url: "/annuaire", code:301},
        "/architecture-nucleaire-et-mecanismes-de-reparation-des-fourches-de-replication": { url: "/", code:301},
        "/assurance-vie-curie": { url: "/nommer-linstitut-curie-beneficiaire-de-son-assurance-vie-pour-la-recherche-contre-le-cancer", code:301},
        "/atlas-cancer-signalling-network-and-navicell": { url: "/", code:301},
        "/avemap": { url: "/", code:301},
        "/axes-de-recherche": { url: "/axes-de-recherche-thematiques", code:301},
        "/axes-de-recherche?profile=3": { url: "/axes-de-recherche-thematiques", code:301},
        "/biogenese-et-degradation-des-longs-arn-non-codants-chez-la-levure": { url: "/", code:301},
        "/biogenese-et-destin-des-granules-pigmentaires-dans-la-peau-humaine": { url: "/", code:301},
        "/biogenese-et-fonctions-des-vesicules-extracellulaires-dans-la-communication-intercellulaire": { url: "/", code:301},
        "/brevet-ep2946022": { url: "/equipe/mahuteau", code:301},
        "/brevet-ep3058940": { url: "/equipe/mahuteau", code:301},
        "/brevet-ep3262037": { url: "/equipe/mahuteau", code:301},
        "/brevet-ep3262415": { url: "/equipe/mahuteau", code:301},
        "/brevet-ep3521283": { url: "/equipe/mahuteau", code:301},
        "/brevet-ep3536691": { url: "/equipe/mahuteau", code:301},
        "/brevet-ep3820848": { url: "/equipe/mahuteau", code:301},
        "/brevet-ep3820849": { url: "/equipe/mahuteau", code:301},
        "/brevet-ep3820850": { url: "/equipe/mahuteau", code:301},
        "/brevet-ep3820852": { url: "/equipe/mahuteau", code:301},
        "/cancer-du-sein-chez-les-jeunes-femmes": { url: "/cancer-sein", code:301},
        "/cancer-du-sein-et-activite-physique": { url: "/cancer-sein", code:301},
        "/cancer-genome-evolution": { url: "/", code:301},
        "/caracterisation-de-bulk-ev": { url: "/", code:301},
        "/caracterisation-de-single-ev": { url: "/", code:301},
        "/caracterisation-des-mecanismes-favorisant-laccumulation-des-tregs-tumoraux-chez-les-patients": { url: "/", code:301},
        "/caracterisation-fonctionnelle-de-molecules-specifiques-des-tregs-tumoraux-dans-une-optique": { url: "/", code:301},
        "/collaborations-equipe-anton-granzhan": { url: "/", code:301},
        "/comedications-et-cancer": { url: "/", code:301},
        "/comment-les-cellules-interagissent-avec-leur-environnement": { url: "/", code:301},
        "/comprendre-la-division-cellulaire-au-sein-des-tissus-epitheliaux": { url: "/cancer-sein", code:301},
        "/contacts-microscopie-electronique-pict": { url: "/", code:301},
        "/controle-hors-equilibre-de-lorganisation-interne-des-bacteries": { url: "/", code:301},
        "/coralie-chevallier": { url: "/coralie-chevallier-membre-du-directoire-de-linstitut-curie", code:301},
        "/criblage-cellulaire-haut-contenu": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/criblage-genetique-sur-la-lignee-cellulaire": { url: "/", code:301},
        "/crise-du-centromere-reponse-laneuploidie-et-vieillissement": { url: "/", code:301},
        "/curie-coretech-vesicules-extracellulaires": { url: "/", code:301},
        "/dechiffrer-lheterogeneite-inter-et-intra-tumorale-du-cancer-du-sein": { url: "/cancer-sein", code:301},
        "/dispatch/curiecoretech-les-plateformes-technologiques": { url: "/plateformes", code:301},
        "/dispatch/les-equipes-et-les-groupes-de-recherche": { url: "/equipes-recherche", code:301},
        "/dispatch/les-unites-mixtes-de-recherche": { url: "/unites-recherche", code:301},
        "/donation-curie": { url: "/faire-une-donation-linstitut-curie", code:301},
        "/dossier-pedagogique/100-ans-de-la-fondation-un-siecle-de-soutien-la-lutte-contre-le-cancer": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/actifs-accompagner-le-retour-au-travail": { url: "/", code:301},
        "/dossier-pedagogique/adolescents-et-jeunes-adultes-la-prise-en-charge-des-cancers-aja": { url: "/les-cancers-des-adolescents-et-jeunes-adultes", code:301},
        "/dossier-pedagogique/anatomie-comprendre-le-cancer-des-voies-aerodigestives-superieures-vads": { url: "/cancers-digestifs", code:301},
        "/dossier-pedagogique/anticorps-monoclonaux-limmunotherapie-contre-le-cancer-institut-curie": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/approches-complementaires-lequilibre-autrement": { url: "/approches-complementaires-linstitut-curie", code:301},
        "/dossier-pedagogique/apres-un-cancer-du-sein-faites-du-sport": { url: "/soins-de-support-du-cancer-du-sein", code:301},
        "/dossier-pedagogique/apres-un-cancer-le-retour-au-travail-une-etape-negocier": { url: "/", code:301},
        "/dossier-pedagogique/cancer-de-la-prostate-differentes-strategies-therapeutiques-et-traitements": { url: "/strategies-therapeutiques-et-traitements-du-cancer-de-la-prostate", code:301},
        "/dossier-pedagogique/cancer-de-la-prostate-examens-et-diagnostic": { url: "/symptomes-et-diagnostic-du-cancer-de-la-prostate", code:301},
        "/dossier-pedagogique/cancer-de-la-prostate-la-chirurgie-traitement-de-reference": { url: "/strategies-therapeutiques-et-traitements-du-cancer-de-la-prostate", code:301},
        "/dossier-pedagogique/cancer-de-la-prostate-la-radiotherapie-externe": { url: "/strategies-therapeutiques-et-traitements-du-cancer-de-la-prostate", code:301},
        "/dossier-pedagogique/cancer-de-la-prostate-le-plus-frequent-en-france": { url: "/cancer-prostate", code:301},
        "/dossier-pedagogique/cancer-de-la-prostate-les-facteurs-de-risque": { url: "/cancer-prostate", code:301},
        "/dossier-pedagogique/cancer-de-la-prostate-les-ultrasons-focalises-de-haute-intensite": { url: "/strategies-therapeutiques-et-traitements-du-cancer-de-la-prostate", code:301},
        "/dossier-pedagogique/cancer-de-la-prostate-lhormonotherapie-et-la-chimiotherapie-par-injection": { url: "/strategies-therapeutiques-et-traitements-du-cancer-de-la-prostate", code:301},
        "/dossier-pedagogique/cancer-de-la-prostate-ne-pas-confondre-avec-lhypertrophie-ou-la-prostatite": { url: "/cancer-prostate", code:301},
        "/dossier-pedagogique/cancer-de-la-prostate-symptomes-et-circonstances-de-decouverte": { url: "/symptomes-et-diagnostic-du-cancer-de-la-prostate", code:301},
        "/dossier-pedagogique/cancer-de-la-vessie-les-hommes-beaucoup-plus-touches": { url: "/quest-ce-que-les-cancers-urogenitaux", code:301},
        "/dossier-pedagogique/cancer-de-lenfant-soutenir-lenfant-et-son-entourage": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/cancer-de-lovaire": { url: "/cancer-ovaire", code:301},
        "/dossier-pedagogique/cancer-de-lovaire-les-avancees-de-la-recherche": { url: "/cancer-ovaire", code:301},
        "/dossier-pedagogique/cancer-de-lovaire-les-traitements-disponibles": { url: "/traitements-du-cancer-de-lovaire", code:301},
        "/dossier-pedagogique/cancer-de-lovaire-plusieurs-types-de-cancers": { url: "/cancer-ovaire", code:301},
        "/dossier-pedagogique/cancer-de-lovaire-qui-est-concerne": { url: "/cancer-ovaire", code:301},
        "/dossier-pedagogique/cancer-de-lovaire-symptomes-et-diagnostic": { url: "/symptomes-et-diagnostic-du-cancer-de-lovaire", code:301},
        "/dossier-pedagogique/cancer-du-foie-epidemiologie": { url: "/cancer-foie", code:301},
        "/dossier-pedagogique/cancer-du-foie-les-outils-du-diagnostic": { url: "/symptomes-et-diagnostic-du-cancer-du-foie", code:301},
        "/dossier-pedagogique/cancer-du-foie-traiter-en-fonction-de-la-tumeur": { url: "/traitements-du-cancer-du-foie", code:301},
        "/dossier-pedagogique/cancer-du-poumon-des-signes-cliniques-divers-difficiles-identifier": { url: "/symptomes-et-diagnostic-du-cancer-du-poumon", code:301},
        "/dossier-pedagogique/cancer-du-poumon-des-traitements-de-plus-en-plus-personnalises": { url: "/traitements-du-cancer-du-poumon", code:301},
        "/dossier-pedagogique/cancer-du-poumon-les-donnees-epidemiologiques": { url: "/cancer-poumon", code:301},
        "/dossier-pedagogique/cancer-du-poumon-les-outils-du-diagnostic": { url: "/symptomes-et-diagnostic-du-cancer-du-poumon", code:301},
        "/dossier-pedagogique/cancer-du-rein-des-tumeurs-decouvertes-par-hasard": { url: "/quest-ce-que-les-cancers-urogenitaux", code:301},
        "/dossier-pedagogique/cancer-du-sein-en-france-un-depistage-organise-pour-les-femmes-entre-50-et-74": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/cancer-du-sein-la-biopsie-pour-confirmer-le-diagnostic": { url: "/symptomes-et-diagnostic-cancer-du-sein", code:301},
        "/dossier-pedagogique/cancer-du-sein-le-point-sur-les-facteurs-de-risque-environnementaux": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/cancer-du-sein-les-traitements-chimiotherapie-hormonotherapie-immunotherapie": { url: "/traitements-du-cancer-du-sein", code:301},
        "/dossier-pedagogique/cancer-du-sein-les-traitements-locaux": { url: "/traitements-du-cancer-du-sein", code:301},
        "/dossier-pedagogique/cancer-du-sein-optimiser-la-prise-en-charge": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/cancer-du-sein-se-faire-reconstruire-ou-pas": { url: "/la-reconstruction-mammaire-apres-un-cancer-du-sein", code:301},
        "/dossier-pedagogique/cancer-du-testicule-un-cancer-rare-et-de-tres-bon-pronostic": { url: "/quest-ce-que-les-cancers-urogenitaux", code:301},
        "/dossier-pedagogique/cancer-et-douleur-3-questions-au-dr-abdelmalek-ghimouz": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/cancer-et-douleur-le-choix-des-medicaments": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/cancer-et-douleur-toutes-les-solutions": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/cancer-et-fertilite-le-point-sur-les-connaissances": { url: "/", code:301},
        "/dossier-pedagogique/cancer-et-preservation-de-la-fertilite-comment-ca-marche": { url: "/", code:301},
        "/dossier-pedagogique/cancer-quand-la-cellule-semballe": { url: "/", code:301},
        "/dossier-pedagogique/cancers-chaque-age-son-approche": { url: "/", code:301},
        "/dossier-pedagogique/cancers-de-lenfant-de-limportance-dun-diagnostic-precis": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/cancers-de-lenfant-la-leucemie": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/cancers-de-lenfant-le-lymphome": { url: "/lymphome-enfant-adolescent-jeune-adulte", code:301},
        "/dossier-pedagogique/cancers-de-lenfant-le-nephroblastome": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/cancers-de-lenfant-le-neuroblastome": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/cancers-de-lenfant-le-retinoblastome-une-tumeur-de-la-retine": { url: "/retinoblastome", code:301},
        "/dossier-pedagogique/cancers-de-lenfant-le-sarcome-dewing": { url: "/sarcomes", code:301},
        "/dossier-pedagogique/cancers-de-lenfant-les-rhabdomyosarcomes": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/cancers-de-lenfant-les-tumeurs-cerebrales": { url: "/tumeurs-cerebrales-enfant", code:301},
        "/dossier-pedagogique/cancers-de-lenfant-progresser-grace-la-recherche-clinique": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/cancers-de-loeil-comprendre-pour-mieux-prevenir": { url: "/tumeurs-oeil", code:301},
        "/dossier-pedagogique/cancers-du-sang-le-myelome-multiple": { url: "/myelome", code:301},
        "/dossier-pedagogique/cancers-du-sang-les-facteurs-de-risque": { url: "/symptomes-et-diagnostic-des-cancers-hematologiques", code:301},
        "/dossier-pedagogique/cancers-du-sang-les-leucemies": { url: "/leucemies-aigues-myeloides-myelodysplasies", code:301},
        "/dossier-pedagogique/cancers-du-sang-les-leucemies-aigues": { url: "/traitements-des-leucemies-aigues-myeloides-et-des-myelodysplasies", code:301},
        "/dossier-pedagogique/cancers-du-sang-les-leucemies-chroniques": { url: "/leucemies-aigues-myeloides-myelodysplasies", code:301},
        "/dossier-pedagogique/cancers-du-sang-les-lymphomes": { url: "/lymphome", code:301},
        "/dossier-pedagogique/cancers-du-sang-ou-en-est-la-recherche": { url: "/cancers-hematologiques", code:301},
        "/dossier-pedagogique/cancers-du-sein-les-facteurs-de-risque-hereditaires": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/cancers-familiaux-la-recherche-progresse": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/cancers-orl-quels-sont-les-symptomes": { url: "/symptomes-et-diagnostic-des-cancers-orl", code:301},
        "/dossier-pedagogique/cerner-la-complexite-du-systeme-immunitaire": { url: "/", code:301},
        "/dossier-pedagogique/combiner-les-approches": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/comment-la-cellule-tumorale-influence-son-environnement": { url: "/", code:301},
        "/dossier-pedagogique/comment-se-deroule-une-seance-de-protontherapie": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/comprendre-et-mesurer-la-douleur-du-patient": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/comprendre-la-cellule-tumorale-pour-la-maitriser": { url: "/", code:301},
        "/dossier-pedagogique/comprendre-le-cancer-de-la-prostate": { url: "/cancer-prostate", code:301},
        "/dossier-pedagogique/contraception-apres-un-cancer-du-sein": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/contre-le-cancer-la-puissance-des-data": { url: "/", code:301},
        "/dossier-pedagogique/couple-travail-vie-sociale-les-femmes-jeunes-sont-elles-plus-impactees": { url: "/", code:301},
        "/dossier-pedagogique/dautres-cancers-rares-de-loeil": { url: "/tumeurs-oeil", code:301},
        "/dossier-pedagogique/de-lechantillon-la-prise-de-decision-medicale-un-circuit-complexe": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/de-nombreux-acteurs": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/de-nombreux-defis-relever": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/de-nouvelles-ambitions-contre-les-cancers-pediatriques": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/depistage-et-pronostic-lapport-des-biomarqueurs-circulants": { url: "/les-traitements", code:301},
        "/dossier-pedagogique/des-apports-au-dela-de-la-qualite-de-vie": { url: "/", code:301},
        "/dossier-pedagogique/des-cancers-part": { url: "/", code:301},
        "/dossier-pedagogique/des-equipes-dediees-et-structurees": { url: "/essais-cliniques", code:301},
        "/dossier-pedagogique/des-essais-tres-nombreux": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/des-perspectives-pour-les-malades": { url: "/", code:301},
        "/dossier-pedagogique/des-recherches-aux-enjeux-tres-varies": { url: "/tumeurs-oeil", code:301},
        "/dossier-pedagogique/des-traitements-qui-impactent-le-cycle-la-fertilite-la-sexualite": { url: "/", code:301},
        "/dossier-pedagogique/developper-de-nouveaux-traitements-mieux-toleres": { url: "/tumeurs-oeil", code:301},
        "/dossier-pedagogique/deverrouiller-le-systeme-immunitaire-contre-les-cellules-cancereuses": { url: "/les-traitements", code:301},
        "/dossier-pedagogique/diagnostic-des-sarcomes-limportance-dune-prise-en-charge-specialisee": { url: "/sarcomes", code:301},
        "/dossier-pedagogique/diagnostic-etre-vigilante-quel-que-soit-lage": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/dou-vient-la-douleur-cancereuse": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/dou-vient-le-cancer": { url: "/", code:301},
        "/dossier-pedagogique/dou-vient-le-cancer-0": { url: "/", code:301},
        "/dossier-pedagogique/douleur-et-cancer-halte-aux-idees-recues": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/du-concept-la-revolution": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/electricite-rayonnements-ciment-des-techniques-antalgiques-lefficacite": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/entretien-avec-le-pr-roman-rouzier-directeur-medical-du-pole-senologie": { url: "/", code:301},
        "/dossier-pedagogique/entretien-avec-marie-dutreix": { url: "/", code:301},
        "/dossier-pedagogique/epidemiologie-des-sarcomes-qui-est-touche": { url: "/sarcomes", code:301},
        "/dossier-pedagogique/esprit-de-curiosite": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/esprit-dengagement": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/esprit-dhumanite": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/esprit-douverture": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/esprit-pionnier": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/essais-precoces-patients-daujourdhui-et-medecine-de-demain": { url: "/essais-cliniques", code:301},
        "/dossier-pedagogique/et-apres": { url: "/", code:301},
        "/dossier-pedagogique/et-apres-un-programme-europeen-dedie-au-melanome-de-luvee": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/ethique-et-garantie-humaine": { url: "/", code:301},
        "/dossier-pedagogique/etudier-les-cellules-une-par-une": { url: "/", code:301},
        "/dossier-pedagogique/examens-de-la-fertilite": { url: "/", code:301},
        "/dossier-pedagogique/expliquer-les-effets-des-immunotherapies": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/femmes-jeunes-et-cancer-du-sein": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/femmes-jeunes-et-cancer-du-sein-0": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/femmes-jeunes-preserver-la-fertilite": { url: "/", code:301},
        "/dossier-pedagogique/grace-vous-la-campagne-mc21-pour-le-projet-big-data": { url: "/", code:301},
        "/dossier-pedagogique/grace-vous-la-medecine-de-precision-avance": { url: "/essais-cliniques", code:301},
        "/dossier-pedagogique/grace-vous-la-prise-en-charge-et-la-recherche-avancent": { url: "/", code:301},
        "/dossier-pedagogique/grace-vous-la-prise-en-charge-et-la-recherche-avancent-0": { url: "/", code:301},
        "/dossier-pedagogique/grace-vous-la-recherche-en-oncogenetique-avance": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/grace-vous-la-recherche-en-oncopediatrie-avance": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/grace-vous-la-recherche-fondamentale-avance": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/grace-vous-la-recherche-sur-limmunotherapie-avance": { url: "/", code:301},
        "/dossier-pedagogique/grace-vous-une-offre-de-soins-de-support-toujours-plus-elargie": { url: "/soins", code:301},
        "/dossier-pedagogique/grossesse-apres-un-cancer-du-sein-un-risque-de-rechute-accru": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/guider-le-choix-therapeutique-grace-aux-biomarqueurs": { url: "/les-traitements", code:301},
        "/dossier-pedagogique/hypnose-psychotherapie-quand-lesprit-aide-surmonter-la-douleur": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/icgex-au-dela-de-linstitut-curie": { url: "/", code:301},
        "/dossier-pedagogique/immunotherapie-des-cancers-une-revolution-en-marche": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/immunotherapie-focus-sur-les-projets-de-recherche-en-cours": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/immunotherapie-la-revolution-attendue-contre-le-cancer": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/immunotherapie-sur-la-voie-du-progres": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/integration-de-la-genomique-du-cancer-grande-echelle-la-recherche-experimentale": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/interview-avec-le-dr-catherine-daniel": { url: "/", code:301},
        "/dossier-pedagogique/interview-du-pr-dominique-stoppa-lyonnet": { url: "/", code:301},
        "/dossier-pedagogique/investir-sur-lavenir-0": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/la-cellule-accumule-les-anomalies-genetiques": { url: "/les-traitements", code:301},
        "/dossier-pedagogique/la-chimiotherapie-des-sarcomes": { url: "/traitements-des-sarcomes", code:301},
        "/dossier-pedagogique/la-chirurgie-des-cancers-de-la-tete-et-du-cou": { url: "/cancers-orl-tete-cou", code:301},
        "/dossier-pedagogique/la-desescalade-therapeutique": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/la-douleur-question-de-definitions": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/la-premiere-consultation-dorl": { url: "/cancers-orl-tete-cou", code:301},
        "/dossier-pedagogique/la-prise-en-charge-des-recidives-du-cancer-du-sein": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/la-prise-en-charge-des-sarcomes-linstitut-curie": { url: "/sarcomes", code:301},
        "/dossier-pedagogique/la-prise-en-charge-du-retinoblastome-linstitut-curie": { url: "/retinoblastome", code:301},
        "/dossier-pedagogique/la-protontherapie": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/la-radiotherapie-comment-ca-marche": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/la-radiotherapie-dans-les-cancers-orl": { url: "/traitements-des-cancers-orl", code:301},
        "/dossier-pedagogique/la-radiotherapie-de-demain": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/la-recherche-au-service-de-linnovation": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/la-recherche-avance": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/la-recherche-clinique-sur-les-rechutes-du-cancer-du-sein": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/la-recherche-dans-les-cancers-des-adolescents-et-jeunes-adultes": { url: "/les-cancers-des-adolescents-et-jeunes-adultes", code:301},
        "/dossier-pedagogique/la-recherche-en-immunotherapie": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/la-recherche-fondamentale-essentielle-dans-le-combat-contre-le-cancer": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/la-recherche-fondamentale-et-clinique": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/la-recherche-fondamentale-sur-les-recidives-du-cancer-du-sein": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/la-recherche-menee-linstitut-curie": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/la-recherche-sur-les-cancers-de-loeil": { url: "/tumeurs-oeil", code:301},
        "/dossier-pedagogique/la-recherche-sur-les-cancers-du-poumon": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/la-reunion-de-concertation-pluridisciplinaire": { url: "/cancers-orl-tete-cou", code:301},
        "/dossier-pedagogique/laccompagnement-des-femmes-au-coeur-de-la-prise-en-charge": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/laccompagnement-des-femmes-en-rechute": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/laccompagnement-psychosocial-des-adolescents-et-jeunes-adultes": { url: "/les-cancers-des-adolescents-et-jeunes-adultes", code:301},
        "/dossier-pedagogique/lalcool-principal-facteur-de-risque-de-cancer-du-foie-primitif": { url: "/cancer-foie", code:301},
        "/dossier-pedagogique/le-cancer-chez-les-nourrissons": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/le-cancer-de-la-prostate": { url: "/cancer-prostate", code:301},
        "/dossier-pedagogique/le-cancer-du-foie": { url: "/cancer-foie", code:301},
        "/dossier-pedagogique/le-cancer-du-poumon": { url: "/cancer-poumon", code:301},
        "/dossier-pedagogique/le-cancer-du-poumon-en-quelques-points-cles": { url: "/cancer-poumon", code:301},
        "/dossier-pedagogique/le-cancer-du-sein": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/le-cancer-une-maladie-des-genes": { url: "/", code:301},
        "/dossier-pedagogique/le-centre-de-protontherapie-de-linstitut-curie-un-lieu-tourne-vers-lavenir": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/le-centre-de-protontherapie-de-linstitut-curie-une-histoire-singuliere": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/le-centre-de-protontherapie-un-lieu-de-haute-technologie": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/le-lymphome-intraoculaire": { url: "/lymphome", code:301},
        "/dossier-pedagogique/le-lymphome-intraoculaire-une-tumeur-rare-et-souvent-tres-agressive": { url: "/lymphome", code:301},
        "/dossier-pedagogique/le-melanome-de-luvee": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/le-melanome-de-luvee-des-pronostics-tres-variables": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/le-point-avec-le-dr-remi-dendale-responsable-du-centre-de-protontherapie": { url: "/", code:301},
        "/dossier-pedagogique/le-retinoblastome-une-tumeur-essentiellement-pediatrique": { url: "/retinoblastome", code:301},
        "/dossier-pedagogique/le-role-essentiel-de-la-reunion-de-concertation-pluridisciplinaire": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/le-tabagisme-est-le-principal-facteur-de-risque-de-cancer-du-poumon": { url: "/cancer-poumon", code:301},
        "/dossier-pedagogique/le-terreau-davancees-majeures": { url: "/", code:301},
        "/dossier-pedagogique/le-traitement-de-la-douleur-linstitut-curie": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/lepigenetique-au-dela-des-genes": { url: "/", code:301},
        "/dossier-pedagogique/lequilibre-immunitaire-en-ligne-de-mire": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/les-biomarqueurs-circulants-cest-quoi": { url: "/les-traitements", code:301},
        "/dossier-pedagogique/les-cancers-de-la-tete-et-du-cou": { url: "/cancers-orl-tete-cou", code:301},
        "/dossier-pedagogique/les-cancers-de-lenfant": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/les-cancers-des-adolescents-et-jeunes-adultes": { url: "/les-cancers-des-adolescents-et-jeunes-adultes", code:301},
        "/dossier-pedagogique/les-cancers-du-sang-ou-cancers-hematologiques": { url: "/cancers-hematologiques", code:301},
        "/dossier-pedagogique/les-cancers-traites-par-protontherapie-chordomes-et-les-chondrosarcomes": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/les-cancers-traites-par-protontherapie-les-cancers-de-lenfant": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/les-cancers-traites-par-protontherapie-les-melanomes-de-loeil": { url: "/tumeurs-oeil", code:301},
        "/dossier-pedagogique/les-cancers-urologiques-un-enjeu-majeur-de-la-recherche": { url: "/quest-ce-que-les-cancers-urogenitaux", code:301},
        "/dossier-pedagogique/les-causes-et-facteurs-de-risque-des-cancers-orl": { url: "/cancers-orl-tete-cou", code:301},
        "/dossier-pedagogique/les-consultations-donco-fertilite-et-donco-genetique": { url: "/", code:301},
        "/dossier-pedagogique/les-couts-dune-haute-technicite": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/les-donnees-une-source-intarissable": { url: "/", code:301},
        "/dossier-pedagogique/les-essais-cliniques-chez-les-adolescents-et-jeunes-adultes": { url: "/les-cancers-des-adolescents-et-jeunes-adultes", code:301},
        "/dossier-pedagogique/les-innovations-therapeutiques": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/les-lymphomes-intraoculaires-isoles-primitifs-liop-ou-lymphomes-vitroretiniens": { url: "/lymphome", code:301},
        "/dossier-pedagogique/les-missions-du-reseau-lymphomes-oculocerebraux": { url: "/lymphome", code:301},
        "/dossier-pedagogique/les-ovaires-comment-ca-marche": { url: "/cancer-ovaire", code:301},
        "/dossier-pedagogique/les-premieres-retombees-des-projets-de-sequencage-icgex": { url: "/", code:301},
        "/dossier-pedagogique/les-projets-de-recherche": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/les-projets-de-recherche-en-cours-sur-les-cancers-urologiques": { url: "/quest-ce-que-les-cancers-urogenitaux", code:301},
        "/dossier-pedagogique/les-recidives-du-cancer-du-sein": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/les-soins-de-support-la-force-de-la-bienveillance-pour-mieux-vivre-son-cancer": { url: "/soins", code:301},
        "/dossier-pedagogique/les-traitements-medicaux-des-cancers-orl": { url: "/cancers-orl-tete-cou", code:301},
        "/dossier-pedagogique/lesprit-curie-plus-vivant-que-jamais": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/lessor-de-la-medecine-de-precision": { url: "/essais-cliniques", code:301},
        "/dossier-pedagogique/limmunotherapie-cest-quoi-definition-de-ce-traitement-contre-le-cancer-institut": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/limmunotherapie-un-outil-complet-dans-la-lutte-anticancer": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/limmunotherapie-une-efficacite-geometrie-variable": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/linstitut-curie-fait-progresser-la-recherche-au-benefice-des-patientes": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/linstitut-curie-mobilise-contre-les-cancers-du-sein-metastatiques": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/linterdisciplinarite-ligne-de-front-des-connaissances": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/lymphome-intraoculaire-les-traitements": { url: "/lymphome", code:301},
        "/dossier-pedagogique/marie-curie-une-femme-de-premieres": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/medicaments-de-la-douleur-choisir-le-mode-dadministration": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/melanome-de-luvee-affiner-le-diagnostic": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/melanome-de-luvee-decouvrir-de-nouvelles-cibles-therapeutiques": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/melanome-de-luvee-forte-mobilisation-des-medecins-et-des-chercheurs-linstitut": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/melanome-de-luvee-identifier-des-biomarqueurs-pronostiques": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/melanome-de-luvee-les-facteurs-de-risque": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/melanome-de-luvee-les-traitements-disponibles": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/melanome-de-luvee-mieux-voir-les-metastases-hepatiques": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/melanome-de-luvee-quels-symptomes-surveillance-du-naevus-choroidien-institut": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/mieux-comprendre-le-risque": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/oncogeriatrie-quand-geriatres-et-specialistes-du-cancer-sallient": { url: "/oncogeriatrie-le-cancer-chez-les-personnes-agees", code:301},
        "/dossier-pedagogique/osteosarcome-symptomes-et-traitements-des-cancers-de-los-et-du-genou-chez": { url: "/sarcomes", code:301},
        "/dossier-pedagogique/parole-dexpert-dr-emanuela-romano-directrice-medicale-du-centre-dimmunotherapie": { url: "/", code:301},
        "/dossier-pedagogique/parole-dexpert-entretien-avec-le-pr-yves-allory": { url: "/", code:301},
        "/dossier-pedagogique/parole-dexpert-nathalie-cassoux-cheffe-du-departement-doncologie-chirurgicale": { url: "/", code:301},
        "/dossier-pedagogique/parole-dexpert-sebastian-amigorena-directeur-du-centre-dimmunotherapie-des": { url: "/", code:301},
        "/dossier-pedagogique/paroles-dexpert-dr-carole-bouleuc-cheffe-du-departement-disspo": { url: "/", code:301},
        "/dossier-pedagogique/paroles-dexpert-pr-nicolas-girard-oncologue-pneumologue-coresponsable-du-volet": { url: "/", code:301},
        "/dossier-pedagogique/paroles-dexperte-dr-gudrun-schleiermacher": { url: "/", code:301},
        "/dossier-pedagogique/paroles-dexperte-leila-perie-codirectrice-de-la-plateforme-cellule-unique": { url: "/", code:301},
        "/dossier-pedagogique/pas-encore-des-adultes-mais-plus-des-enfants": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/pas-un-mais-des-cancers-du-foie-difference-entre-cancer-primitif-et": { url: "/cancer-foie", code:301},
        "/dossier-pedagogique/pas-un-mais-des-cancers-du-sein": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/patients-internationaux-linstitut-curie-mobilise-contre-le-retinoblastome-en": { url: "/tumeurs-oeil", code:301},
        "/dossier-pedagogique/plus-de-chimio-moins-de-radiotherapie": { url: "/la-chimiotherapie-et-lhormonotherapie", code:301},
        "/dossier-pedagogique/plusieurs-types-de-cancers-du-poumon": { url: "/cancer-poumon", code:301},
        "/dossier-pedagogique/pourquoi-le-systeme-immunitaire-ne-fonctionne-t-il-pas-pour-lutter-contre-les": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/prendre-soin-de-son-corps-et-de-son-image-0": { url: "/soins", code:301},
        "/dossier-pedagogique/progres-dans-la-prise-en-charge-des-sarcomes-3-questions-au-dr-sylvie-bonvalot": { url: "/sarcomes", code:301},
        "/dossier-pedagogique/progresser-phase-apres-phase": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/quatre-soins-de-support-primordiaux": { url: "/soins", code:301},
        "/dossier-pedagogique/que-sait-quelles-sont-ses-particularites": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/quelle-surveillance-apres-un-retinoblastome": { url: "/tumeurs-oeil", code:301},
        "/dossier-pedagogique/quels-sont-les-effets-secondaires-de-la-protontherapie": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/quest-ce-que-le-systeme-immunitaire": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/radiotherapie-des-seances-millimetrees": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/radiotherapie-dhier-et-daujourdhui": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/radiotherapie-un-plateau-technique-de-pointe": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/radiotherapie-une-technique-de-pointe-toujours-en-developpement": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/dossier-pedagogique/recherche-sur-le-cancer-du-sein-les-avancees-recentes": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/references": { url: "/melanome-uveal", code:301},
        "/dossier-pedagogique/retinoblastome": { url: "/retinoblastome", code:301},
        "/dossier-pedagogique/retinoblastome-comment-etablit-le-diagnostic": { url: "/retinoblastome", code:301},
        "/dossier-pedagogique/retinoblastome-la-consultation-dinformation-genetique": { url: "/retinoblastome", code:301},
        "/dossier-pedagogique/retinoblastome-quels-sont-les-facteurs-de-risque": { url: "/retinoblastome", code:301},
        "/dossier-pedagogique/retinoblastome-quels-sont-les-symptomes-focus-sur-la-leucorie-et-le-strabisme": { url: "/retinoblastome", code:301},
        "/dossier-pedagogique/retinoblastome-quels-sont-les-traitements-chirurgicaux": { url: "/traitement-retinoblastome", code:301},
        "/dossier-pedagogique/retinoblastome-quels-sont-les-traitements-conservateurs": { url: "/traitement-retinoblastome", code:301},
        "/dossier-pedagogique/sadapter-aux-particularites-des-cancers-pediatriques": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/sarcome-la-chirurgie-et-les-traitements-complementaires": { url: "/traitements-des-sarcomes", code:301},
        "/dossier-pedagogique/sarcome-quel-suivi-apres-les-traitements-et-la-guerison-institut-curie": { url: "/sarcomes", code:301},
        "/dossier-pedagogique/sarcomes-causes-symptomes-et-evolutions": { url: "/symptomes-et-diagnostic-des-sarcomes", code:301},
        "/dossier-pedagogique/sarcomes-la-prise-en-charge-linstitut-curie": { url: "/sarcomes", code:301},
        "/dossier-pedagogique/sarcomes-le-point-sur-la-recherche": { url: "/sarcomes", code:301},
        "/dossier-pedagogique/sebastian-amigorena-limmunotherapie-souleve-beaucoup-despoirs": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/sexualite-apres-un-cancer-du-sein": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/soulager-la-douleur-et-les-effets-secondaires-le-role-des-soins-de-support": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/dossier-pedagogique/stimuler-le-systeme-immunitaire": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/structurer-et-analyser-les-donnees": { url: "/les-traitements", code:301},
        "/dossier-pedagogique/succes-contre-les-cancers-du-sein-triple-negatifs": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/symptomes-du-cancer-du-foie-des-signes-cliniques-diffus": { url: "/symptomes-et-diagnostic-du-cancer-du-foie", code:301},
        "/dossier-pedagogique/tomber-enceinte-apres-un-cancer-du-sein-cest-possible": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/traitements-medicamenteux-du-cancer-de-la-prostate-les-progres-en-marche": { url: "/strategies-therapeutiques-et-traitements-du-cancer-de-la-prostate", code:301},
        "/dossier-pedagogique/traquer-le-cancer-avec-une-simple-prise-de-sang": { url: "/les-traitements", code:301},
        "/dossier-pedagogique/trois-questions-au-pr-christophe-le-tourneau-directeur-du-d3i": { url: "/", code:301},
        "/dossier-pedagogique/trois-questions-bruno-goud": { url: "/", code:301},
        "/dossier-pedagogique/trois-questions-xose-fernandez-directeur-des-data-linstitut-curie": { url: "/", code:301},
        "/dossier-pedagogique/trouver-des-sequences-et-des-combinaisons-efficaces": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/un-cancer-du-sein-avant-40-ans-pourquoi": { url: "/cancer-sein", code:301},
        "/dossier-pedagogique/un-des-defis-detecter-les-biomarqueurs-circulants": { url: "/les-traitements", code:301},
        "/dossier-pedagogique/un-encadrement-rigoureux": { url: "/essais-cliniques", code:301},
        "/dossier-pedagogique/un-fort-soutien-la-recherche": { url: "/", code:301},
        "/dossier-pedagogique/une-cellule-devenue-sourde-aux-signaux-exterieurs": { url: "/", code:301},
        "/dossier-pedagogique/une-cellule-influente": { url: "/", code:301},
        "/dossier-pedagogique/une-organisation-mutualisee": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/une-prise-en-charge-medicale-du-risque": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/une-prise-en-charge-specifique": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/dossier-pedagogique/une-recherche-dexcellence": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/dossier-pedagogique/une-source-dinspiration": { url: "/linstitut-curie", code:301},
        "/dossier-pedagogique/une-sur-representation-des-formes-plus-agressives": { url: "/", code:301},
        "/dossier-pedagogique/une-unite-de-recherche-clinique-en-soins-de-support": { url: "/soins", code:301},
        "/dossier-pedagogique/vaccinotherapie": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/vers-limmunotherapie-de-precision": { url: "/limmunotherapie", code:301},
        "/dossier-pedagogique/vers-une-generalisation-de-la-biopsie-liquide": { url: "/les-traitements", code:301},
        "/dossier-pedagogique/voir-plus-grand-pour-mieux-soigner-les-cancers-de-lenfant": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/embryonic-development-murine-mammary-gland": { url: "/", code:301},
        "/equipe-alena-shkumatava": { url: "/equipes-recherche", code:301},
        "/equipe-ana-maria-lennon-dumenil": { url: "/equipes-recherche", code:301},
        "/equipe-antoine-coulon": { url: "/equipes-recherche", code:301},
        "/equipe-antonin-morillon": { url: "/equipes-recherche", code:301},
        "/equipe-arturo-londono-vallejo": { url: "/equipes-recherche", code:301},
        "/equipe-aurelien-latouche": { url: "/equipes-recherche", code:301},
        "/equipe-celio-pouponnot": { url: "/equipes-recherche", code:301},
        "/equipe-christophe-lamaze": { url: "/equipes-recherche", code:301},
        "/equipe-claire-hivroz": { url: "/equipes-recherche", code:301},
        "/equipe-daniel-levy": { url: "/equipes-recherche", code:301},
        "/equipe-deborah-bourchis": { url: "/equipes-recherche", code:301},
        "/equipe-eliane-piaggio": { url: "/equipes-recherche", code:301},
        "/equipe-emmanuel-barillot": { url: "/equipes-recherche", code:301},
        "/equipe-florence-cavalli": { url: "/equipes-recherche", code:301},
        "/equipe-franck-perez": { url: "/equipes-recherche", code:301},
        "/equipe-francois-radvanyi": { url: "/equipes-recherche", code:301},
        "/equipe-genevieve-almouzni": { url: "/equipes-recherche", code:301},
        "/equipe-graca-raposo": { url: "/equipes-recherche", code:301},
        "/equipe-herve-isambert": { url: "/equipes-recherche", code:301},
        "/equipe-jean-salamero": { url: "/equipes-recherche", code:301},
        "/equipe-joshua-waterfall": { url: "/equipes-recherche", code:301},
        "/equipe-lambert": { url: "/equipes-recherche", code:301},
        "/equipe-lionel-larue": { url: "/equipes-recherche", code:301},
        "/equipe-ludger-johannes": { url: "/equipes-recherche", code:301},
        "/equipe-maitre-0": { url: "/equipe/maitre", code:301},
        "/equipe-marie-paule-teulade-fichou": { url: "/equipes-recherche", code:301},
        "/equipe-nathalie-dostatni": { url: "/equipes-recherche", code:301},
        "/equipe-olivier-lantz": { url: "/equipes-recherche", code:301},
        "/equipe-pascal-hersen": { url: "/equipes-recherche", code:301},
        "/equipe-patricia-bassereau": { url: "/equipes-recherche", code:301},
        "/equipe-philippe-chavrier": { url: "/equipes-recherche", code:301},
        "/equipe-pierre-leopold": { url: "/equipes-recherche", code:301},
        "/equipe-pierre-sens": { url: "/equipes-recherche", code:301},
        "/equipe-raphael-margueron": { url: "/equipes-recherche", code:301},
        "/equipe-toledo": { url: "/equipes-recherche", code:301},
        "/equipe-valerie-borde": { url: "/equipes-recherche", code:301},
        "/equipe-yolanda-prezado": { url: "/equipes-recherche", code:301},
        "/equipe/carreira": { url: "/equipes-recherche", code:301},
        "/equipe/goud": { url: "/equipes-recherche", code:301},
        "/equipement-chimiotheque": { url: "/plateforme/curiecoretech-chimiotheque", code:301},
        "/equipement-metabolomique-et-lipidomique": { url: "/plateforme/curiecoretech-metabolomique-et-lipidomique", code:301},
        "/equipement-microscopie-electronique-pict": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/equipement-microscopie-photonique-pict": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/equipements-microscopie-photonique": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/equipements-pole-microscopie-ionique": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/eri": { url: "/espaces-patients-proches", code:301},
        "/eric-nicolas": { url: "/eric-nicolas-membre-du-directoire-de-linstitut-curie", code:301},
        "/essai-clinique/219885-219885-galaxies-hn-202": { url: "/essais-cliniques", code:301},
        "/essai-clinique/aas-lynch": { url: "/essais-cliniques", code:301},
        "/essai-clinique/acceleret-blu-667-2303": { url: "/essais-cliniques", code:301},
        "/essai-clinique/acse-nivolumab": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ago-ovar-229": { url: "/essais-cliniques", code:301},
        "/essai-clinique/alienor": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ambre": { url: "/essais-cliniques", code:301},
        "/essai-clinique/amg-20110261": { url: "/essais-cliniques", code:301},
        "/essai-clinique/amg-2019000925004": { url: "/essais-cliniques", code:301},
        "/essai-clinique/amg20170543": { url: "/essais-cliniques", code:301},
        "/essai-clinique/amg20190294": { url: "/essais-cliniques", code:301},
        "/essai-clinique/amg20200491-dellphi-301": { url: "/essais-cliniques", code:301},
        "/essai-clinique/amgen20210004-amg-757": { url: "/essais-cliniques", code:301},
        "/essai-clinique/anita": { url: "/essais-cliniques", code:301},
        "/essai-clinique/arasens": { url: "/essais-cliniques", code:301},
        "/essai-clinique/arianes": { url: "/essais-cliniques", code:301},
        "/essai-clinique/arrow-blu-667-1101": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ascent-07": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ascent-immu-132-05": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ascordia01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/astefania-wo42633": { url: "/essais-cliniques", code:301},
        "/essai-clinique/atalante": { url: "/essais-cliniques", code:301},
        "/essai-clinique/atalk-ml39349": { url: "/essais-cliniques", code:301},
        "/essai-clinique/attain-nktr-15-102-14": { url: "/essais-cliniques", code:301},
        "/essai-clinique/bet115521": { url: "/essais-cliniques", code:301},
        "/essai-clinique/bgb-hnscc-201": { url: "/essais-cliniques", code:301},
        "/essai-clinique/bi-1336-0011": { url: "/essais-cliniques", code:301},
        "/essai-clinique/bi-13361": { url: "/essais-cliniques", code:301},
        "/essai-clinique/bi128018": { url: "/essais-cliniques", code:301},
        "/essai-clinique/bp40657": { url: "/essais-cliniques", code:301},
        "/essai-clinique/br31-ifct1401": { url: "/essais-cliniques", code:301},
        "/essai-clinique/breastimmune03-ca209-9xd": { url: "/essais-cliniques", code:301},
        "/essai-clinique/brocade-3-m12-914": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ca009-002-immuno": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ca017-003": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ca045-020-pivot-io-020": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ca209-73l": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ca209-848": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ca209-9t9-pronihn": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ca223-001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ca224-104-relativity": { url: "/essais-cliniques", code:301},
        "/essai-clinique/canopy-1-cacz885u2301": { url: "/essais-cliniques", code:301},
        "/essai-clinique/canopy-cacz885t2301": { url: "/essais-cliniques", code:301},
        "/essai-clinique/canto-paris": { url: "/essais-cliniques", code:301},
        "/essai-clinique/card-lps14201": { url: "/essais-cliniques", code:301},
        "/essai-clinique/carmen-lc03-efc15858": { url: "/essais-cliniques", code:301},
        "/essai-clinique/cc-4047-brn-001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/cc-95775-st-001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/cdrb436g2201": { url: "/essais-cliniques", code:301},
        "/essai-clinique/chacry": { url: "/essais-cliniques", code:301},
        "/essai-clinique/checkmate-384-ca209-384": { url: "/essais-cliniques", code:301},
        "/essai-clinique/checkmate-592-ca209-592": { url: "/essais-cliniques", code:301},
        "/essai-clinique/checkmate-651-ca209-651": { url: "/essais-cliniques", code:301},
        "/essai-clinique/checkmate-722-ca209-722": { url: "/essais-cliniques", code:301},
        "/essai-clinique/checkmate-816-ca209-816": { url: "/essais-cliniques", code:301},
        "/essai-clinique/checkmate-908-ca209-908": { url: "/essais-cliniques", code:301},
        "/essai-clinique/checkup": { url: "/essais-cliniques", code:301},
        "/essai-clinique/chrysalis-2-73841937nsc1001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/cica-rt": { url: "/essais-cliniques", code:301},
        "/essai-clinique/circa-01-ic-2014-05": { url: "/essais-cliniques", code:301},
        "/essai-clinique/circa-hpv-ic-2017-01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/clag525b2101": { url: "/essais-cliniques", code:301},
        "/essai-clinique/clxs196x2101": { url: "/essais-cliniques", code:301},
        "/essai-clinique/co40151": { url: "/essais-cliniques", code:301},
        "/essai-clinique/combinair3": { url: "/essais-cliniques", code:301},
        "/essai-clinique/compleement-1": { url: "/essais-cliniques", code:301},
        "/essai-clinique/copan-orl-06": { url: "/essais-cliniques", code:301},
        "/essai-clinique/cotesarc-pediatrie": { url: "/essais-cliniques", code:301},
        "/essai-clinique/cpdr001x2103": { url: "/essais-cliniques", code:301},
        "/essai-clinique/cpzp034x2203-veg116731": { url: "/essais-cliniques", code:301},
        "/essai-clinique/cupisco-mx39795": { url: "/essais-cliniques", code:301},
        "/essai-clinique/d3610c00001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/d419ec00001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/debio-1143-201": { url: "/essais-cliniques", code:301},
        "/essai-clinique/debio-1143-202": { url: "/essais-cliniques", code:301},
        "/essai-clinique/defifrance": { url: "/essais-cliniques", code:301},
        "/essai-clinique/dermaclic-ic-2019-12": { url: "/essais-cliniques", code:301},
        "/essai-clinique/destiny-breast-09": { url: "/essais-cliniques", code:301},
        "/essai-clinique/destiny-breast06-d9670c00001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/df1001-001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/diciple-ifct-1701": { url: "/essais-cliniques", code:301},
        "/essai-clinique/driiv-1-ox2016-203-01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ds8201-u301-destiny-breast02": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ds8201-u302-destiny-breast03": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ds8201-u303-destiny-breast04": { url: "/essais-cliniques", code:301},
        "/essai-clinique/e7080-g000-230-olie": { url: "/essais-cliniques", code:301},
        "/essai-clinique/early-together-ic-2019-09": { url: "/essais-cliniques", code:301},
        "/essai-clinique/edi-1001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/elderly": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ember-j2j-mc-jzla": { url: "/essais-cliniques", code:301},
        "/essai-clinique/emerald": { url: "/essais-cliniques", code:301},
        "/essai-clinique/endola": { url: "/essais-cliniques", code:301},
        "/essai-clinique/entree-lung-gsk-205801": { url: "/essais-cliniques", code:301},
        "/essai-clinique/eortc-1447-stbsg": { url: "/essais-cliniques", code:301},
        "/essai-clinique/epik-b2-cbyl719g12301": { url: "/essais-cliniques", code:301},
        "/essai-clinique/epik-b3-cbyl719h12301": { url: "/essais-cliniques", code:301},
        "/essai-clinique/epock": { url: "/essais-cliniques", code:301},
        "/essai-clinique/eremiss-1801": { url: "/essais-cliniques", code:301},
        "/essai-clinique/es4-uic-ic-2020-18": { url: "/essais-cliniques", code:301},
        "/essai-clinique/estrotimp": { url: "/essais-cliniques", code:301},
        "/essai-clinique/euronet-phl-c2": { url: "/essais-cliniques", code:301},
        "/essai-clinique/eusa-pass-eusa-db-0001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/express": { url: "/essais-cliniques", code:301},
        "/essai-clinique/fdg-immun": { url: "/essais-cliniques", code:301},
        "/essai-clinique/first-3000-03-005engot-ov44": { url: "/essais-cliniques", code:301},
        "/essai-clinique/fraction-lung-ca018-001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/geometry-iii-cinc280a2301": { url: "/essais-cliniques", code:301},
        "/essai-clinique/gerico-14": { url: "/essais-cliniques", code:301},
        "/essai-clinique/gm102": { url: "/essais-cliniques", code:301},
        "/essai-clinique/go30103-tigit": { url: "/essais-cliniques", code:301},
        "/essai-clinique/go40871": { url: "/essais-cliniques", code:301},
        "/essai-clinique/great-1550-c081": { url: "/essais-cliniques", code:301},
        "/essai-clinique/hector-ic-2018-09": { url: "/essais-cliniques", code:301},
        "/essai-clinique/her2climb-ont-380-206": { url: "/essais-cliniques", code:301},
        "/essai-clinique/her2climb02-sgntuc-016": { url: "/essais-cliniques", code:301},
        "/essai-clinique/herseq-puma-ner-9501": { url: "/essais-cliniques", code:301},
        "/essai-clinique/herthena-lung01-u31402-u201": { url: "/essais-cliniques", code:301},
        "/essai-clinique/hypog-01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/i3y-mc-jpcs": { url: "/essais-cliniques", code:301},
        "/essai-clinique/i4t-mc-jvdf": { url: "/essais-cliniques", code:301},
        "/essai-clinique/icing-orl": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ifm-2017-03": { url: "/essais-cliniques", code:301},
        "/essai-clinique/imcgp100-202": { url: "/essais-cliniques", code:301},
        "/essai-clinique/immuno-addict-gfpc-06-2018": { url: "/essais-cliniques", code:301},
        "/essai-clinique/impassion-030-big16-05wo39391": { url: "/essais-cliniques", code:301},
        "/essai-clinique/impassion-131-mo39196": { url: "/essais-cliniques", code:301},
        "/essai-clinique/impassion-132-mo39193": { url: "/essais-cliniques", code:301},
        "/essai-clinique/incagn-2385-203": { url: "/essais-cliniques", code:301},
        "/essai-clinique/incb-86550-102": { url: "/essais-cliniques", code:301},
        "/essai-clinique/induce-1-gsk-204691": { url: "/essais-cliniques", code:301},
        "/essai-clinique/induce-3-gsk-209229": { url: "/essais-cliniques", code:301},
        "/essai-clinique/induce-4-gsk-209227": { url: "/essais-cliniques", code:301},
        "/essai-clinique/j10-mc-jzhb": { url: "/essais-cliniques", code:301},
        "/essai-clinique/j1o-mc-jzhd": { url: "/essais-cliniques", code:301},
        "/essai-clinique/j1s-mc-jv01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/j1s-mc-jv02": { url: "/essais-cliniques", code:301},
        "/essai-clinique/javelin-hn100-b9991016": { url: "/essais-cliniques", code:301},
        "/essai-clinique/list-ca209-7hx": { url: "/essais-cliniques", code:301},
        "/essai-clinique/loxo-bcl-20001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/loxo-ext-17005": { url: "/essais-cliniques", code:301},
        "/essai-clinique/luminosity-m14-239": { url: "/essais-cliniques", code:301},
        "/essai-clinique/lung-art": { url: "/essais-cliniques", code:301},
        "/essai-clinique/lym1001-67856633lym1001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/m15-819": { url: "/essais-cliniques", code:301},
        "/essai-clinique/m16-074": { url: "/essais-cliniques", code:301},
        "/essai-clinique/m19-037": { url: "/essais-cliniques", code:301},
        "/essai-clinique/m19-894": { url: "/essais-cliniques", code:301},
        "/essai-clinique/m20-111": { url: "/essais-cliniques", code:301},
        "/essai-clinique/magtrivacsein-projet-magtn3": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mariposa-2-61186372nsc3002": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mariposa-73841937nsc3003": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mcla-158-cl01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/medea": { url: "/essais-cliniques", code:301},
        "/essai-clinique/melrose": { url: "/essais-cliniques", code:301},
        "/essai-clinique/meru-m16-298": { url: "/essais-cliniques", code:301},
        "/essai-clinique/metro-pd1-1708": { url: "/essais-cliniques", code:301},
        "/essai-clinique/micchado-ic-2017-02": { url: "/essais-cliniques", code:301},
        "/essai-clinique/midas-ifm-202-02": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk-1454-001-sting": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk-7902-013": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk3475-365": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk3475-522": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk3475-689-keynote-689": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk3475-756-keynote-756": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk3475-867-keynote-867": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk4621-002": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk7684a-005": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk7902-007-leap-007": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk7902-008-leap-008": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mk7902-011-leap-011": { url: "/essais-cliniques", code:301},
        "/essai-clinique/monarche-i3y-mc-jpcf": { url: "/essais-cliniques", code:301},
        "/essai-clinique/moovcare": { url: "/essais-cliniques", code:301},
        "/essai-clinique/motivate": { url: "/essais-cliniques", code:301},
        "/essai-clinique/movie-m17-gep-15": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ms1000070-176": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ms200647-0005": { url: "/essais-cliniques", code:301},
        "/essai-clinique/mucila": { url: "/essais-cliniques", code:301},
        "/essai-clinique/multisarc-c16-40": { url: "/essais-cliniques", code:301},
        "/essai-clinique/nanorad2": { url: "/essais-cliniques", code:301},
        "/essai-clinique/nanoray-312": { url: "/essais-cliniques", code:301},
        "/essai-clinique/nant-2015-02-ic-2018-05": { url: "/essais-cliniques", code:301},
        "/essai-clinique/natalee-clee011o12301c": { url: "/essais-cliniques", code:301},
        "/essai-clinique/nbtxr3-102": { url: "/essais-cliniques", code:301},
        "/essai-clinique/neo-checkray": { url: "/essais-cliniques", code:301},
        "/essai-clinique/neovab": { url: "/essais-cliniques", code:301},
        "/essai-clinique/nicol-ic-2016-08": { url: "/essais-cliniques", code:301},
        "/essai-clinique/nipinec-gco-001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/nivoglio": { url: "/essais-cliniques", code:301},
        "/essai-clinique/nivopost-op-gortec-2018-01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/nivothym-eortc-1525": { url: "/essais-cliniques", code:301},
        "/essai-clinique/oncosnipe": { url: "/essais-cliniques", code:301},
        "/essai-clinique/oreo": { url: "/essais-cliniques", code:301},
        "/essai-clinique/pacha-01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/pact-01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/pada-1": { url: "/essais-cliniques", code:301},
        "/essai-clinique/pairs-aja-ic2017-10": { url: "/essais-cliniques", code:301},
        "/essai-clinique/palatine-uc-01401814": { url: "/essais-cliniques", code:301},
        "/essai-clinique/papillon-61186372nsc3001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/patina": { url: "/essais-cliniques", code:301},
        "/essai-clinique/peace-1-getug-afu-21": { url: "/essais-cliniques", code:301},
        "/essai-clinique/peace-2-getug-afu-23": { url: "/essais-cliniques", code:301},
        "/essai-clinique/pecati-medopp341": { url: "/essais-cliniques", code:301},
        "/essai-clinique/pedialock": { url: "/essais-cliniques", code:301},
        "/essai-clinique/penelope-b": { url: "/essais-cliniques", code:301},
        "/essai-clinique/pevosqp17": { url: "/essais-cliniques", code:301},
        "/essai-clinique/phitt-rg15-114": { url: "/essais-cliniques", code:301},
        "/essai-clinique/pisarro": { url: "/essais-cliniques", code:301},
        "/essai-clinique/pravaprev-01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/radioparp": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ragnar-42756493can2002": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ramp-201-vs-6766-201-engot-ov60": { url: "/essais-cliniques", code:301},
        "/essai-clinique/reach-gortec-2017-01": { url: "/essais-cliniques", code:301},
        "/essai-clinique/realysa": { url: "/essais-cliniques", code:301},
        "/essai-clinique/regiri-prodige-58": { url: "/essais-cliniques", code:301},
        "/essai-clinique/revcci": { url: "/essais-cliniques", code:301},
        "/essai-clinique/rocsan": { url: "/essais-cliniques", code:301},
        "/essai-clinique/rosaline": { url: "/essais-cliniques", code:301},
        "/essai-clinique/safir-02-lung": { url: "/essais-cliniques", code:301},
        "/essai-clinique/safir-pi3k": { url: "/essais-cliniques", code:301},
        "/essai-clinique/safir-tor": { url: "/essais-cliniques", code:301},
        "/essai-clinique/safir02-breast": { url: "/essais-cliniques", code:301},
        "/essai-clinique/samco-ffcd1603-prodige-54": { url: "/essais-cliniques", code:301},
        "/essai-clinique/santal-gortec-2016-02": { url: "/essais-cliniques", code:301},
        "/essai-clinique/sarcome-13": { url: "/essais-cliniques", code:301},
        "/essai-clinique/sarcome-13-pediatrie": { url: "/essais-cliniques", code:301},
        "/essai-clinique/savannah-d5084c00007": { url: "/essais-cliniques", code:301},
        "/essai-clinique/scarce-c17-02-prodige-60": { url: "/essais-cliniques", code:301},
        "/essai-clinique/seal-kcp-330-020": { url: "/essais-cliniques", code:301},
        "/essai-clinique/senticol-iii": { url: "/essais-cliniques", code:301},
        "/essai-clinique/serena-2-d8530c00002": { url: "/essais-cliniques", code:301},
        "/essai-clinique/serena-6-d8534c00001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/shiva02-ic-2016-06": { url: "/essais-cliniques", code:301},
        "/essai-clinique/skyscraper-06-bo42592": { url: "/essais-cliniques", code:301},
        "/essai-clinique/skyscraper-09-bo42533": { url: "/essais-cliniques", code:301},
        "/essai-clinique/sophia-cp-mgah22-04": { url: "/essais-cliniques", code:301},
        "/essai-clinique/startrk-2-go40782": { url: "/essais-cliniques", code:301},
        "/essai-clinique/stream-eortc-1559-hncg": { url: "/essais-cliniques", code:301},
        "/essai-clinique/sudoped": { url: "/essais-cliniques", code:301},
        "/essai-clinique/synergy": { url: "/essais-cliniques", code:301},
        "/essai-clinique/temokids": { url: "/essais-cliniques", code:301},
        "/essai-clinique/tet-sel": { url: "/essais-cliniques", code:301},
        "/essai-clinique/tg400112": { url: "/essais-cliniques", code:301},
        "/essai-clinique/tg405001": { url: "/essais-cliniques", code:301},
        "/essai-clinique/topnivo-orl09": { url: "/essais-cliniques", code:301},
        "/essai-clinique/topology": { url: "/essais-cliniques", code:301},
        "/essai-clinique/trilynx-debio-1143-scchn-301": { url: "/essais-cliniques", code:301},
        "/essai-clinique/triton2-co-338-052": { url: "/essais-cliniques", code:301},
        "/essai-clinique/triton3-co-338-063": { url: "/essais-cliniques", code:301},
        "/essai-clinique/trizell-rad-ifn-mm-301": { url: "/essais-cliniques", code:301},
        "/essai-clinique/tropics-02-immu132-09": { url: "/essais-cliniques", code:301},
        "/essai-clinique/tropion-lung01-ds1062-u301": { url: "/essais-cliniques", code:301},
        "/essai-clinique/tropion-lung04-ds1062-u104": { url: "/essais-cliniques", code:301},
        "/essai-clinique/tropion-lung05-ds1062-u202": { url: "/essais-cliniques", code:301},
        "/essai-clinique/u31287-u203-patritumab": { url: "/essais-cliniques", code:301},
        "/essai-clinique/ultimate": { url: "/essais-cliniques", code:301},
        "/essai-clinique/unirad": { url: "/essais-cliniques", code:301},
        "/essai-clinique/utola": { url: "/essais-cliniques", code:301},
        "/essai-clinique/vivrovaire": { url: "/essais-cliniques", code:301},
        "/essai-clinique/vraiment": { url: "/essais-cliniques", code:301},
        "/essai-clinique/xenera-1-bi-1280-0022": { url: "/essais-cliniques", code:301},
        "/essai-clinique/xl-184021": { url: "/essais-cliniques", code:301},
        "/essai-clinique/zn-c3-003": { url: "/essais-cliniques", code:301},
        "/etude-de-lheterogeneite-temporelle-et-intra-tumorale-des-gliomes-idh-mutes": { url: "/equipe/cavalli", code:301},
        "/etude-des-parametres-physiques-qui-sous-tendent-le-transport-intracellulaire": { url: "/", code:301},
        "/evenement/4e-congres-national-deducation-therapeutique-organise-par-linstitut-curie-et-unicancer": { url: "/actualites", code:301},
        "/evenement/agenda-octobre-rose": { url: "/actualites", code:301},
        "/evenement/atelier-palpation": { url: "/actualites", code:301},
        "/evenement/conference-douleurs-articulaires-et-hormonotherapie": { url: "/actualites", code:301},
        "/evenement/conference-femmes-jeunes-et-cancer-du-sein": { url: "/actualites", code:301},
        "/evenement/conference-genes-ethiquement-votre-ensemble-pour-une-oncogenetique-raisonnee": { url: "/actualites", code:301},
        "/evenement/exposition-au-pantheon-150-ans-de-marie-curie": { url: "/actualites", code:301},
        "/evenement/journee-cancer-de-la-femme-2018": { url: "/actualites", code:301},
        "/evenement/le-musee-curie-participe-la-nuit-europeenne-des-musees-2018": { url: "/actualites", code:301},
        "/evenement/melanome-uveal-3eme-edition-de-notre-journee-dechanges-en-ligne": { url: "/actualites", code:301},
        "/evenement/melanome-uveal-une-journee-dediee-pour-mieux-sinformer": { url: "/actualites", code:301},
        "/evenement/octobre-rose-linstitut-curie-paris": { url: "/actualites", code:301},
        "/evenement/octobre-rose-linstitut-curie-saint-cloud": { url: "/actualites", code:301},
        "/evenement/save-date-dialogue-cell-based-interceptive-medicine": { url: "/actualites", code:301},
        "/evenement/table-ronde-cancers-heredite-et-predispositions-genetiques": { url: "/actualites", code:301},
        "/evenement/vivre-avec-le-cancer-dun-enfant-parents-soignants-chercheurs-agir-ensemble": { url: "/actualites", code:301},
        "/evenement/voyage-au-coeur-du-sein": { url: "/actualites", code:301},
        "/evenements-scientifiques?profile=3": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/1er-cours-des-cancers-urotheliaux-2023-heterogeneite-tumorale-et": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/1er-seminaire-institut-curie-cooperation-ville-hopital-femmes-haut-risque": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/1st-ferroptosis-france-symposium": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/2-day-institut-curie-institut-pasteur-symposium": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/2nd-symposium-tribute-maxime-dahan": { url: "", code:301},
        "/evenements-scientifiques/3d-quantitative-imaging-different-scales-using-single-objective-light": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/3d-reconstruction-proteins-and-cell-components-high-resolution-and-low": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/3rd-psl-chemical-biology-symposium": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/4th-sunrise-meeting": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/8th-spatial-transcriptomics-odyssey-seminar-deciphering-impact-mycn": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/active-mechanical-and-electrohydraulic-properties-tissues": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/active-nematic-order-morphogenesis-hydra": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/acute-and-chronic-endothelial-cell-responses-modulate-radiation-therapy": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/advanced-course-immunity-and-cancer-immunotherapy-2024-0": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/advances-human-topoisomerases-iiia-and-iiib-most-ancestral-and-least-known": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/analyse-spatiale-de-donnees-multimodales-quand-les-omiques-rencontrent-les": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/atypical-genome-folding-and-gene-regulation-smc-complexes-nematode-c": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/bees-flowers-and-electrostatics-window-new-sensory-ecology": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/bioengineering-human-embryo-and-organ-models": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/bioinfo-singlecell-seminars": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/biologie-cellulaire-et-cancer-4e-edition": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/biologie-des-systemes-du-cancer-integration-de-donnees-multimodales": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/biologie-du-developpement-des-cellules-souches-la-morphogenese": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/biophotonics-robots-ghosts": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/brca1-palb2-brca2-axis-dna-damage-response-and-cancer-development": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/build-cell-atlases-human-respiratory-system": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/building-multimodal-atlas-vertebrate-development": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/cancer-evolution-through-chromatin-plasticity": { url: "", code:301},
        "/evenements-scientifiques/car-t-cell-therapy-long-sought-cure-cancer": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/caracterisation-des-tumeurs-cerebrales-en-irm-de-lacquisition-au": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/cell-biological-mechanisms-mediating-optimal-immunosurveillance": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/cellular-and-environmental-mechanisms-regulating-protein-absorption": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/characterizing-vibrio-cholerae-pathogenesis-natural-zebrafish-infection": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/chatgpt-scientific-writing": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/chemical-biology-tools-unravel-biological-functions-nucleic-acids": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/choanoflagellates-and-origin-animal-morphogenesis": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/chromatin-biomolecular-condensate": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/circuit-approach-understand-aging": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/collective-epigenetic-processes-development-and-ageing": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/combining-coevolution-driven-predictions-and-experiments-elucidate": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/comment-le-neocortex-humain-acquiert-il-la-taille-et-la-forme-voulues-le": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/common-threads-regeneration-mechano-chemical-insights-jellyfish-model": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/comprendre-le-mecanisme-moleculaire-de-la-protection-des-chromosomes": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/computational-systems-biology-cancer-models-data-data-models": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/congres-gynecologie-medicale-et-cancer-femme-et-homme-inegaux-en-sante": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/control-alternative-mrna-splicing-chromatin-and-transcription-and-cure": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/controle-de-la-taille-des-cellules": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/coping-mechanical-stress-tissue-dynamics-during-development-homeostasis": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/countoscope-measuring-dynamics-counting-particles-boxes": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/cracking-92-code-motile-cilia": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/cross-talk-between-cell-mechanics-cell-shape-and-cell-fate": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/cutting-and-pasting-dna-create-our-adaptive-immune-system": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/cytiva-solutions-advanced-cell-therapy-manufacturing-and-logistics": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/dangerous-combination-dna-structures-and-chromosome-gaps-implications": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/deciphering-gene-regulatory-networks-and-enhancer-logic-single-cell-multi": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/deconstruct-reconstruct-decode-cancer-immune-crosstalk-probe-organoids": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/define-your-ngs-project-rims-tool": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/demystifying-machine-and-deep-learning-and-overview-their-applications": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/design-principles-tissue-organization-during-organoid-development": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/developmental-biology-stem-cells-morphogenesis-14th-edition": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/developmental-robustness-growth-heterogeneity-different-scales-and": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/dissecting-and-modulating-reactive-anti-tumor-immunity-human-cancers": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/double-raphael-double-trouble-double-impact": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/dynamic-remodelling-microtubule-cytoskeleton-throughout-mitosis": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/dynamical-systems-and-control-theory-approach-cell-reprogramming": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/dynamique-et-effets-de-la-methylation-de-ladn-des-mammiferes": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/eat-animals-what-nature-teaches-us-about-science-healthy-eating": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/engineering-fluorescent-biosensors-spy-cell-signaling-and-metabolism": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/enhance-software-zoo-exploring-human-functional-connectome-using-whole": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/enhancer-promoter-interaction-dynamics-and-transcriptional-control-lessons": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/ensemble-pour-linclusion-linstitut-curie-lautisme-au-travail": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/epigenetic-factor-competition-can-reshape-emt-landscape-breast-cancer": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/establishing-paternal-chromosome-identity-drosophila-male-germline": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/eukaryotic-gephyrin-and-cognate-membrane-receptor-coordinate": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/expanding-frontiers-simple-method-complex-functional-immunometabolic": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/exploiting-self-inflicted-dna-breaks-evade-growth-limits-imposed-genotoxic": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/functional-and-molecular-characterization-cancer-derived-extracellular": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/functions-biomolecular-condensates-assembled-response-dna-damage": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/garden-forking-paths-branching-switching-and-reversal-meiotic": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/garden-forking-paths-branching-switching-and-reversal-meiotic-0": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/generation-neuronal-diversity-and-its-evolution": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/generation-neuronal-diversity-and-its-evolution-0": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/genetic-regulation-developmental-timing-c-elegans": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/genetics-together-next-generation-un-symposium-pour-celebrer-la-carriere": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/genome-instability-human-germline-molecular-mechanisms-reproductive-health": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/genome-non-codant": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/genome-proteome-protein-binders-study-morphogens": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/geometry-nutrition": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/geomxr-digital-spatial-profiler-nanostring-technology-application-cancer": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/getting-closer-dying-clinical-relevance-ferroptosis-and-necroptosis": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/high-speed-imaging-droplet-and-vesicle-formation-during-emulsion-transfer": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/how-do-motile-bacteria-navigate-their-environments": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/how-gut-microbiome-promotes-drosophila-inflammaging": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/hydra-morphogenesis-phase-transition-dynamics": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/illuminating-path-chembio-toolbox-biological-processes": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/immune-activation-dna-through-cgas-sting-pathway": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/immunological-control-ferroptosis-tryptophan-metabolism": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/impossibility-whales-somatic-evolution-across-tree-life": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/initiation-dna-replication-visualised-cryo-em": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/inner-life-adhesion-sites-deciphering-molecular-ballet-integrin-based": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/institut-curie-optical-genome-mapping-ogm-meeting": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/intelligence-artificielle-et-radiomique-pour-levaluation-de-la-reponse": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/introduction-technological-platforms-research-centre-mode-demploi": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/inverse-blebs-blastocyst-formation": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/investigating-cellular-logistics-live-cell-sted-super-resolution": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/journee-criblage-crispr-dans-la-recherche-contre-le-cancer": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/journee-trans-departements-dom-disspo-lannonce-en-oncologie-de-comment": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/journey-tyrosine-histidine-phosphorylation-uncovering-new-cancer": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/junctional-force-patterning-drives-both-positional-and-orientational-order": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/kinase-misshapenmap4k4-coordinates-collective-cell-migrations": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/ku-paradox-telomeres-working-less-working-better-ensure-end-protection": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/learning-dynamical-models-stochastic-trajectories": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/learning-statistical-folding-bacterial-chromosomes": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/les-ifn-de-type-i-dans-la-sante-et-la-maladie-de-la-trisomie-21-au-cancer": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/lessons-covid-19-vaccines-high-dimensional-analyisis-specific-t-and-b-cell": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/live-cell-force-dynamics-do-cell-membranes-support-or-resist-tension": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/live-single-cell-transcriptomics": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/long-read-sequencing-methods-studying-genome-organization-evolution-and": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/machine-learning-reverse-engineering-biology-embryology-and-aging": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/mecanique-des-tissus-memoire-et-metabolisme-6e-edition-biologie-cellulaire": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/mechanical-and-biochemical-signals-influencing-dendritic-cell-functions": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/mechanism-receptor-choice-olfactory-system-ants": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/mechanisms-organelle-remodeling-cellular-function": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/mechanisms-transcriptional-regulation-drosophila-mammalian-cells": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/mechanochemical-bistability-intestinal-organoids": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/mechanotransduction-homeostasis-and-disease-vascular-system": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/melanoma-metastatic-niche-formation-extracellular-vesicles-mirna-immune": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/metabolism-driven-epigenetics": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/metabolism-neural-stem-cell-niche-interactions": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/methodes-de-segmentation-et-detection-de-lesions-par-cnn-exemples-en": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/microbial-influence-linear-growth": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/microbiota-immune-interactions-health-and-disease": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/microenvironmental-coordination-control-human-cancers": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/microtubule-methylation-second-language-chromatin-readers-writers-and": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/minibar-est-un-double-effecteur-rac-et-rab-qui-controle-la-longueur-des": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/minibar-est-un-double-effecteur-rac-et-rab-qui-controle-la-longueur-des-0": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/modeling-myc-driven-malignant-pediatric-brain-tumors-and-novel-treatment": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/modern-fluorophores-and-hypoxia-targeting": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/molecular-assembly-and-mechanics-single-vimentin-intermediate-filaments": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/molecular-atlassing-merscope-reveals-spatial-organization-mouse-and-human": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/molecular-engineering-light-activated-probes-precision-diagnostics-therapy": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/molecular-imaging-and-microphysiological-culture-systems-towards-improved": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/molecular-mechanisms-evolutionary-innovation": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/mtor-signaling-growth-and-metabolism": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/multiple-roads-cell-fate-decisions-integrating-transcription-factors-rna": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/nanoscopy-powered-machine-learning-novel-insight-subcellular-structure": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/nanotechnology-cell-communication": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/necroaxoptosis-therapeutic-target-halt-age-dependent-cognitive-decline-and": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/network-inference-decipher-molecular-mechanisms-play-during-innate-sensing": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/next-generation-molecular-tumor-boards-systems-biology-and-functional": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/next-generation-single-cell-here": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/nucleosomes-and-dna-methylation-implications-immunodeficiency-centromeric": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/optimized-single-molecule-fish-visualize-genome-organization-trace": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/overcoming-experimental-and-computational-limitations-multiplexed-imaging": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/paired-multiplex-imaging-and-spatial-transcriptomics-decipher-tumor-cells": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/personalising-cancer-risk-prediction-prevention-and-early-detection": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/phase-separation-progesterone-receptor-single-molecule-study": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/plant-derived-chemical-probes-and-leads-target-osbp-protein-key-player": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/predictive-models-stochasticity-cancer-immunotherapy-single-lymphocytes": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/prevention-des-violences-sexistes-et-sexuelles-0": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/prevention-des-violences-sexistes-et-sexuelles-1": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/prevention-des-violences-sexistes-et-sexuelles-2": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/prevention-des-violences-sexistes-et-sexuelles-3": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/prevention-des-violences-sexistes-et-sexuelles-4": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/proteger-et-recycler-comment-les-cellules-regulent-la-dormance": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/proximity-induced-targeted-rna-degradation-mapping-rna-modifications-and": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/radiomique-theorie-et-pratique": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/raserk-pathway-regulates-patterning-drosophila-and-serves-model-system": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/regulation-and-immunotherapy-responses-nk-cells-and-t-cells-against-tumors": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/regulation-microtubule-dynamics-mitotic-spindle-requires-rtel1-helicase": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/role-innate-immune-response-and-micro-environment-regenerative-potential": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/role-new-rad51dmc1-remodeler-mammalian-recombination": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/scenarios-collective-dynamics-evolving-tissues": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/see-biology-new-ways-10x-genomics": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/seeing-believing-break-induced-mitotic-recombination-1936-2022": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/segregation-membrane-cargoes-b-secretase-bace1-and-amyloid-precursor": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/self-assembled-active-actomyosin-gels-spontaneously-curve-and-wrinkle": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/seminaire-ferroptosis-basic-mechanisms-therapeutic-opportunities": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/sex-and-gender-bias-research-career-path-test-tube": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/sex-and-gender-dimension-science-and-medicine": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/sexist-and-sexual-violence-prevention-0": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/sexist-and-sexual-violence-prevention-1": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/single-cell-and-spatial-isoform-transcriptomics-sit": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/single-cell-and-spatial-technologies-study-retinoblastoma-cells-population": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/single-cell-and-spatial-transcriptomicsepigenomics-oligodendroglia": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/single-cell-multi-omics-solid-tumor-and-haematological-malignancies": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/slide-tags-enables-single-nucleus-barcoding-multimodal-spatial-genomics": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/solar-radiation-and-pollution-mitochondrial-perspective": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/spatial-multiomics-atlas-human-lungs-reveals-novel-iga-immune-niche": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/spatial-transcriptomics-odyssey-2nd-seminar-development-computational": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/spatial-transcriptomics-without-compromising-data-quality": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/spatiotemporal-models-mammalian-development-epigeneticists-dream-or": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/spliceosome-mutations-myeloid-malignancy": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/structural-and-functional-investigation-smc56-complex": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/structural-neutrophils": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/structures-situ-des-sarcomeres-musculaires-et-des-proteines-sarcomeres": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/taille-des-cellules-et-ploidie-de-la-cellule-unique-la-physiologie-des": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/tango1-builds-secretory-route-collagen": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/targeting-chromatin-regulators-cancer": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/tcren-predicting-tcr-peptide-recognition-based-residue-level-pairwise": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/temtia-10th-international-congress-cellular-and-molecular-emt": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/three-dimensional-architecture-human-genome-understanding-physical": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/time-we-trust-how-precise-temporal-regulation-genome-important": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/together-inclusion-institut-curie-autism-work": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/transcription-shapes-spatial-organization-eukaryotic-genes": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/triage-des-proteines-secretoires-au-niveau-du-reseau-transgolgien": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/tumor-associated-monocytes-and-neutrophils-targets-immunotherapy": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/tuning-actin-remodeling-driving-force-adaptable-activities-t-lymphocytes": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/type-i-ifns-health-and-disease-trisomy-21-cancer": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/type-lamins-t-cell-mediated-immune-response": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/understanding-and-modeling-aging": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/understanding-immune-aging-mice-and-humans": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/unlocking-spatial-biology-institut-curie-orion-technology": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/user-day-fixed-rna-profiling-and-chromium-ix-presentation": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/using-genomics-invesigate-radiaion-related-thyroid-cancer-following": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/various-layers-regulating-genomic-instability-cancer": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/vimentin-intermediate-filaments-network-architecture-and-crosstalk": { url: "/evenements-scientifiques", code:301},
        "/evenements-scientifiques/visualisation-genomic-data-using-ucsc-genome-browser": { url: "/evenements-scientifiques", code:301},
        "/exploiter-letat-de-limagerie-de-pointe": { url: "/", code:301},
        "/fachinetti-lab": { url: "/equipe/fachinetti", code:301},
        "/faire-un-legs-curie": { url: "/faire-un-legs-linstitut-curie", code:301},
        "/formation-de-formes-dans-les-cristaux-liquides-actifs": { url: "/", code:301},
        "/formation-de-proteo-guvs": { url: "/", code:301},
        "/formation-et-fonction-des-centromeres-le-role-de-ladn-centromerique": { url: "/", code:301},
        "/fr/aide": { url: "/", code:301},
        "/fr/flux-rss-0": { url: "/", code:301},
        "/fr/recherche": { url: "/", code:301},
        "/gfp@curie.fr": { url: "/", code:301},
        "/groupe-fabien-reyal": { url: "/", code:301},
        "/groupe/melanome-uveal": { url: "/equipes-recherche", code:301},
        "/hub-cancer-col-uterus": { url: "/cancer-col-uterus", code:301},
        "/hub-cancer-colorectal": { url: "/cancer-colorectal", code:301},
        "/hub-cancer-endometre": { url: "/cancer-endometre", code:301},
        "/hub-cancer-foie": { url: "/cancer-foie", code:301},
        "/hub-cancer-ovaire": { url: "/cancer-ovaire", code:301},
        "/hub-cancer-pancreas": { url: "/cancer-pancreas", code:301},
        "/hub-cancer-poumon": { url: "/cancer-poumon", code:301},
        "/hub-cancer-prostate": { url: "/cancer-prostate", code:301},
        "/hub-cancer-sein": { url: "/cancer-sein", code:301},
        "/hub-cancers-digestifs": { url: "/cancers-digestifs", code:301},
        "/hub-cancers-gynecologiques": { url: "/cancers-gynecologiques", code:301},
        "/hub-cancers-hematologiques": { url: "/cancers-hematologiques", code:301},
        "/hub-cancers-orl-tete-cou": { url: "/cancers-orl-tete-cou", code:301},
        "/hub-leucemies-aigues-myeloides-myelodysplasies": { url: "/leucemies-aigues-myeloides-myelodysplasies", code:301},
        "/hub-lymphome": { url: "/lymphome", code:301},
        "/hub-lymphome-enfant-adolescent-jeune-adulte": { url: "/lymphome-enfant-adolescent-jeune-adulte", code:301},
        "/hub-melanome-uveal": { url: "/melanome-uveal", code:301},
        "/hub-myelome": { url: "/myelome", code:301},
        "/hub-oncogenetique": { url: "/oncogenetique", code:301},
        "/hub-retinoblastome": { url: "/retinoblastome", code:301},
        "/hub-sarcomes": { url: "/sarcomes", code:301},
        "/hub-tumeurs-cerebrales-enfant": { url: "/tumeurs-cerebrales-enfant", code:301},
        "/hub-tumeurs-oeil": { url: "/tumeurs-oeil", code:301},
        "/ihu-cancers-femmes.org": { url: "/", code:301},
        "/immucan-integrated-european-immuno-oncology-profiling-platform": { url: "/", code:301},
        "/instabilites-dans-les-tissus-et-les-agregats-cellulaires": { url: "/", code:301},
        "/instabilites-genomiques-et-transcriptomiques-dans-les-pathologies-humaines": { url: "/", code:301},
        "/interactions-between-epithelium-and-stroma-during-mammary-gland-development": { url: "/", code:301},
        "/ipaediatriccure": { url: "/", code:301},
        "/isolation-dev": { url: "/", code:301},
        "/jobdating": { url: "/", code:301},
        "/journee-apa": { url: "/", code:301},
        "/journeeAPA2023": { url: "/", code:301},
        "/la-chimiotherapie-et-lhormonotherapie": { url: "/la-chimiotherapie", code:301},
        "/la-migration-des-cellules-epitheliales-dans-lhomeostasie-intestinale": { url: "/", code:301},
        "/lab-retreat-2021": { url: "/", code:301},
        "/lab-retreats-2016-2017": { url: "/", code:301},
        "/lab-retreats-2018-2019": { url: "/", code:301},
        "/laboratoire-dinvestigation-preclinique-lip": { url: "/", code:301},
        "/landing/accompagnement-patients": { url: "/les-soins-de-support", code:301},
        "/landing/ai4curie": { url: "/actualite/innovation/challenge-ai4curie-lia-au-service-de-limmunotherapie-dans-les-cancers-du", code:301},
        "/landing/siredo": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/landing/siredo?profile=2": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/legs-curie": { url: "/faire-un-legs-linstitut-curie", code:301},
        "/les-longs-arn-non-codants-dans-le-cancer-decouverte-caracterisation-fonctionnelle-et-pertinence": { url: "/", code:301},
        "/lier-lexpression-des-genes-la-dynamique-cellulaire-et-les-forces-mecaniques": { url: "/", code:301},
        "/lipocanpredict-multi-omics-biomarker-signatures-identification-cancer-associated-adipocytes": { url: "/", code:301},
        "/liste_actualites/3": { url: "/actualites", code:301},
        "/liste-de-liens/covid-19-nos-experts-prennent-la-parole": { url: "/", code:301},
        "/liste-de-liens/les-cooperations-de-lensemble-hospitalier": { url: "/", code:301},
        "/liste-de-liens/les-essais-cliniques-de-linstitut-curie": { url: "/essais-cliniques", code:301},
        "/liste-de-liens/les-parcours-de-soins": { url: "/preparer-votre-venue", code:301},
        "/liste-de-liens/les-soins-de-support": { url: "/les-soins-de-support", code:301},
        "/liste/cancers-de-ladulte": { url: "/cancers-pris-en-charge", code:301},
        "/liste/centre-de-protontherapie": { url: "/centre-de-protontherapie-dorsay", code:301},
        "/liste/comprendre-le-cancer": { url: "/", code:301},
        "/liste/departements": { url: "/les-equipes-de-lensemble-hospitalier", code:301},
        "/liste/droits-et-devoirs": { url: "/droits-du-patient", code:301},
        "/liste/essais-cliniques": { url: "/essais-cliniques", code:301},
        "/liste/etudes-cliniques": { url: "/essais-cliniques", code:301},
        "/liste/etudes-cliniques?profile=5": { url: "/essais-cliniques", code:301},
        "/liste/evenements": { url: "/actualites", code:301},
        "/liste/hopital-de-paris": { url: "/hopital-de-paris", code:301},
        "/liste/hopital-de-saint-cloud": { url: "/hopital-de-saint-cloud", code:301},
        "/liste/ils-nous-soutiennent": { url: "/ils-nous-soutiennent", code:301},
        "/liste/le-centre-de-protontherapie": { url: "/centre-de-protontherapie-dorsay", code:301},
        "/liste/les-approches-psycho-corporelles": { url: "/approches-complementaires-linstitut-curie", code:301},
        "/liste/les-associations": { url: "/ils-nous-soutiennent", code:301},
        "/liste/les-cancers-de-lenfant-traites-linstitut-curie": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/liste/les-cancers-pris-en-charge-linstitut-curie": { url: "/cancers-pris-en-charge", code:301},
        "/liste/les-espaces-patients": { url: "/espaces-patients-proches", code:301},
        "/liste/les-traitements-disponibles": { url: "/les-traitements", code:301},
        "/liste/nos-hopitaux": { url: "/lensemble-hospitalier-de-linstitut-curie", code:301},
        "/liste/nos-hopitaux?profile=5": { url: "/lensemble-hospitalier-de-linstitut-curie", code:301},
        "/liste/nos-mecenes": { url: "/ils-nous-soutiennent", code:301},
        "/liste/nos-projets-strategiques-2015-2020": { url: "/", code:301},
        "/liste/notre-organisation": { url: "/gouvernance-et-organisation", code:301},
        "/liste/nous-soutenir": { url: "/nous-soutenir", code:301},
        "/liste/octobre-rose-2017-linstitut-curie-mobilise-contre-les-cancers-du-sein": { url: "/actualites?q=octobre+rose&page=1", code:301},
        "/liste/programmes-incitatifs-et-collaboratifs-favoriser-linnovation": { url: "/", code:301},
        "/liste/qui-sommes-nous": { url: "/linstitut-curie", code:301},
        "/liste/sinvestir-autrement": { url: "/nous-soutenir", code:301},
        "/liste/un-accompagnement-au-long-cours": { url: "/les-soins-de-support", code:301},
        "/liste/vos-actions-de-generosite": { url: "/actualites?fieldDomainNews[]=273&page=1", code:301},
        "/liste/vous-aider-apres-les-traitements": { url: "/les-soins-de-support", code:301},
        "/liste/vous-aider-pendant-les-traitements": { url: "/les-soins-de-support", code:301},
        "/logiciel-diva": { url: "/equipe/coppey", code:301},
        "/logiciel-genuage": { url: "/equipe/coppey", code:301},
        "/logiciel-inferencemap": { url: "/equipe/coppey", code:301},
        "/logiciel-visp": { url: "/equipe/coppey", code:301},
        "/logiciels-equipe-locco": { url: "/equipe/coppey", code:301},
        "/maison-patients-et-proches": { url: "/espaces-patients-proches", code:301},
        "/manipradio": { url: "/", code:301},
        "/marlene-rasschaert": { url: "/", code:301},
        "/mdm4-telomeres": { url: "/", code:301},
        "/medulloblastome": { url: "/", code:301},
        "/melanome": { url: "/melanome-uveal", code:301},
        "/methodes": { url: "/", code:301},
        "/microscopie-electonique-et-cryo-em": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/modele-stick-slip-pour-les-protrusions-cellulaires-la-polarisation-cellulaire-et-la-reptation": { url: "/", code:301},
        "/modelisation-des-organites-cellulaires-lies-une-membrane-avec-une-dynamique-hors-equilibre": { url: "/", code:301},
        "/modelling-immunogenic-cell-death-modiced": { url: "/", code:301},
        "/nikon-imaging-center-pict": { url: "/", code:301},
        "/node/1667": { url: "/", code:301},
        "/node/1692": { url: "/", code:301},
        "/node/1708": { url: "/", code:301},
        "/node/20007": { url: "/", code:301},
        "/node/20254": { url: "/", code:301},
        "/node/20644": { url: "/", code:301},
        "/node/20756": { url: "/", code:301},
        "/node/20770": { url: "/", code:301},
        "/node/20828": { url: "/", code:301},
        "/node/20840": { url: "/", code:301},
        "/node/21189": { url: "/", code:301},
        "/node/21236": { url: "/", code:301},
        "/node/21369": { url: "/", code:301},
        "/node/2855": { url: "/", code:301},
        "/node/3116": { url: "/", code:301},
        "/nos-liens": { url: "/", code:301},
        "/nos-modeles-pdx": { url: "/", code:301},
        "/nos-videos": { url: "/", code:301},
        "/nous_rejoindre/3": { url: "/rejoindre-linstitut-curie", code:301},
        "/nous_rejoindre/3?profile=3": { url: "/rejoindre-linstitut-curie", code:301},
        "/offre-emploi/agent-de-laverie-autoclave-fh": { url: "/offres-emploi", code:301},
        "/offre-emploi/assistant-administratif-en-alternance-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/assistant-specialiste-en-oncologie-medicale-unite-dinvestigation-clinique-uic-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/assistante-contrats-cdd-4-mois": { url: "/offres-emploi", code:301},
        "/offre-emploi/assistante-de-direction-hf-cdi": { url: "/offres-emploi", code:301},
        "/offre-emploi/assistante-medicale-polyvalente-en-genetique-et-anatomocytopathologie-fh": { url: "/offres-emploi", code:301},
        "/offre-emploi/assistante-medicale-service-doncologie-medicale-saint-cloud": { url: "/offres-emploi", code:301},
        "/offre-emploi/assistante-sociale-cdi-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/attache-de-recherche-clinique-hf-paris": { url: "/offres-emploi", code:301},
        "/offre-emploi/bioinformaticien-recherche-sur-le-cancer-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/cdd-chargee-ressources-humaines-anglais-courant-fh": { url: "/offres-emploi", code:301},
        "/offre-emploi/cdd-magasinier-mi-temps-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/cdd-magasinier-temps-partiel-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/charge-de-facturation-technicien-de-recherche-clinique-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/charge-de-gestion-des-subventions-de-recherche-hf-3": { url: "/offres-emploi", code:301},
        "/offre-emploi/charge-des-affaires-sociales-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/chargee-de-mission-doctorants-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/chef-de-projet-hf-projet-national-cell-id": { url: "/offres-emploi", code:301},
        "/offre-emploi/chef-de-projet-hf-projets-internationaux-de-recherche-oncologie-pediatrique": { url: "/offres-emploi", code:301},
        "/offre-emploi/comptable-fournisseur-et-ndf-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/comptable-fournisseur-hf-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/concepteur-multimedia-en-alternance-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/coordinateur-des-services-techniques-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/data-analyst-hf-cod-36-mois": { url: "/offres-emploi", code:301},
        "/offre-emploi/doctorant-hf-offre-de-these-dynamique-de-la-transcription-et-de-lacquisition-du-destin": { url: "/offres-emploi", code:301},
        "/offre-emploi/dosimetriste-hf-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/gestionnaire-administratif-hf-3": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmier-de-bloc-operatoire-hf-paris": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmier-de-en-recherche-clinique-hf-cdi-saint-cloud": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmier-en-hematologie-cdi-saint-cloud": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmier-en-hospitalisation-de-medecine-oncologique-hf-cdi-saint-cloud": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmier-en-hospitalisation-de-medecine-oncologique-hf-cdi-saint-cloud-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmier-en-oncologie-pediatrique-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmier-en-sspi-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmier-hf-1": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmier-hf-vacations": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmier-transversal-referent-en-soins-palliatifs-hf-site-de-st-cloud": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-chirurgie-hospitalisation-ambulatoire-cdi-paris-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-consultations-et-soins-externes-cdd-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-de-bloc-operatoire-hf-saint-cloud": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-de-cdi-hopital-de-jour-oncologie-medicale-saint-cloud": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-de-en-recherche-clinique-irc-cdi": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-de-en-recherche-clinique-irc-cdi-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-de-jour-en-hospitalisation-oncologie-medicale-paris": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-en-chirurgie-hospitalisation-ambulatoire-site-st-cloud-cdi-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-hopital-de-jour-oncologie-medicale-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-hopital-de-jour-oncologie-medicale-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-hygieniste-st-cloud": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-nuit-en-medecine-oncologique-cdi-saint-cloud": { url: "/offres-emploi", code:301},
        "/offre-emploi/infirmiere-plateforme-consultations-et-soins-externes-paris-cdi": { url: "/offres-emploi", code:301},
        "/offre-emploi/ingenieur-bioinformatique-en-therapie-cellulaire-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/ingenieur-de-laboratoire-expert-en-biologie-moleculaire-fh": { url: "/offres-emploi", code:301},
        "/offre-emploi/ingenieur-de-recherche-en-immunologie-fh": { url: "/offres-emploi", code:301},
        "/offre-emploi/ingenieur-de-recherche-synthese-chimique-chemobiologie-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/ingenieur-detudeingenieur-de-recherche-mf": { url: "/offres-emploi", code:301},
        "/offre-emploi/ingenieur-en-biologie-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/ingenieur-en-imagerie-radiotherapie-preclinique-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/ingenieur-ie-en-biologie-moleculaire-et-cellulaire-mf": { url: "/offres-emploi", code:301},
        "/offre-emploi/ingenieure-cytometrie-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/ingenieure-detudes-en-biologie-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/juriste-propriete-intellectuelle-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/manipulateur-en-imagerie-medicale-hf-site-de-paris": { url: "/offres-emploi", code:301},
        "/offre-emploi/manipulateur-en-imagerie-medicale-hf-site-de-saint-cloud": { url: "/offres-emploi", code:301},
        "/offre-emploi/manipulateur-en-medecine-nucleaire-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/manipulateur-en-radiotherapie-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/medecin-du-travail-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/post-doctorat-dans-lequipe-reparation-dadn-et-melanome-uveal-fh-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/post-doctorat-en-bioinformatique-hf-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/post-doctorat-en-biologie-de-larn-hf-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/post-doctorat-en-genetique-moleculaire-et-cellulaire-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/post-doctorat-en-genomics-bio-informatique-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/post-doctorat-en-genomics-hf-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/post-doctorat-en-genomics-wet-lab-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/post-doctorat-en-physique-medicale-mf-0": { url: "/offres-emploi", code:301},
        "/offre-emploi/postdoctoral-regeneration-cardiaque-microscopie-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/postdoctoral-regeneration-cardiaque-proteomique-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/postdoctorat-en-biologie-hf-mecanismes-de-la-radiotherapie-flash": { url: "/offres-emploi", code:301},
        "/offre-emploi/postdoctorat-en-oncologie-mf": { url: "/offres-emploi", code:301},
        "/offre-emploi/praticien-consultation-non-programmee-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/preparateur-en-pharmacie-paris-hf": { url: "/offres-emploi", code:301},
        "/offre-emploi/stage-master-ii-developpement-durable-fm": { url: "/offres-emploi", code:301},
        "/offre-emploi/technicien-de-laboratoire-confirme-en-pathologie-hf-cdi-paris": { url: "/offres-emploi", code:301},
        "/offre-emploi/these-doctorat-projet-hf": { url: "/offres-emploi", code:301},
        "/optimisation-des-reponses-immunitaires-anti-tumorales-dans-les-patients-atteints-de-tumeurs": { url: "/", code:301},
        "/organismes-scientifiques": { url: "/", code:301},
        "/outils-pour-la-communaute-medicale-et-scientifique": { url: "/", code:301},
        "/p53-fanconi-genes": { url: "/", code:301},
        "/page/150-ans-de-marie-curie-exposition-inedite-au-pantheon": { url: "/actualites", code:301},
        "/page/20e-trophee-ralph-aguerreber-au-profit-de-linstitut-curie": { url: "/", code:301},
        "/page/21eme-trophee-ralph-aguerreber-au-profit-de-linstitut-curie": { url: "/", code:301},
        "/page/59-000-rubans-roses-pour-curie-soutenir-les-femmes-atteintes-de-cancer-du-sein-et-leur-offrir": { url: "/actualites", code:301},
        "/page/accompagnement-psychologique-linstitut-curie-mieux-vivre-son-cancer": { url: "/accompagnement-psychologique-linstitut-curie", code:301},
        "/page/actions-leucemies": { url: "/les-associations-dentraide", code:301},
        "/page/adn-mitochondrial-une-autre-cible-pour-la-radiotherapie": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/page/adresser-un-patient": { url: "/", code:301},
        "/page/af3m": { url: "/les-associations-dentraide", code:301},
        "/page/ag2r-la-mondiale": { url: "/ils-nous-soutiennent", code:301},
        "/page/aida-pour-de-lutter-contre-le-cancer-pediatrique": { url: "/les-associations-dentraide", code:301},
        "/page/allianz-france": { url: "/ils-nous-soutiennent", code:301},
        "/page/anesthesie-reanimation-douleur": { url: "/anesthesie-reanimation-douleur", code:301},
        "/page/apaesic-faire-le-lien-avec-linstitut-curie": { url: "/les-associations-dentraide", code:301},
        "/page/archimed-et-la-fondation-eureka": { url: "/ils-nous-soutiennent", code:301},
        "/page/asp-fondatrice": { url: "/les-associations-dentraide", code:301},
        "/page/association-109": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-abigael": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-adam": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-autrement-karate-do": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-christelle-bouillot": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-coeur-rose": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-courir-pour-la-vie-courir-pour-curie": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-courir-pour-mathieu": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-fee-kdo": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-flash": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-hubert-gouin": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-la-fleur-disa": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-la-jonquille-de-lespoir": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-laon-bike": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-les-amis-de-claire": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-les-bagouz-manon": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-m-la-vie-avec-lisa": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-marabout-de-ficelle": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-meghanora": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-oligocyte": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-passion-du-chant": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-postures": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-retinostop": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-theatre-contre-le-cancer": { url: "/ils-nous-soutiennent", code:301},
        "/page/association-un-autre-regard": { url: "/ils-nous-soutiennent", code:301},
        "/page/atelier-du-tricot": { url: "/ils-nous-soutiennent", code:301},
        "/page/aubay": { url: "/ils-nous-soutiennent", code:301},
        "/page/aura-carreira-prix-avenir-ruban-rose-2018": { url: "/", code:301},
        "/page/auto-assemblage-moleculaire-reproduisant-le-mouvement-ondulatoire-des-flagelles-responsables": { url: "/actualites", code:301},
        "/page/avac-vaincre-le-chordome": { url: "/les-associations-dentraide", code:301},
        "/page/avoir-des-donnees-de-qualite-structurees-et-exploitables-un-defi-majeur": { url: "/", code:301},
        "/page/banque-populaire-rives-de-paris": { url: "/ils-nous-soutiennent", code:301},
        "/page/belle-et-bien": { url: "/les-associations-dentraide", code:301},
        "/page/bioderma": { url: "/ils-nous-soutiennent", code:301},
        "/page/brisure-de-ladn-une-danse-multi-echelle": { url: "/", code:301},
        "/page/cancer-du-pancreas-une-etude-originale-sur-les-benefices-de-lactivite-physique-adaptee-pour": { url: "/actualites", code:301},
        "/page/cancer-du-poumon-le-patient-auteur-et-acteur-de-sa-prise-en-soin": { url: "/cancer-poumon", code:301},
        "/page/cancer-du-poumon-profiler-les-patients-pour-adapter-les-traitements": { url: "/cancer-poumon", code:301},
        "/page/cancer-du-sein-bilateral-la-premiere-etude-complete-revele-que-les-tumeurs-sont-independantes": { url: "/cancer-sein", code:301},
        "/page/cancer-du-sein-bloquer-les-premiers-pas-de-linvasion-tumorale": { url: "/cancer-sein", code:301},
        "/page/cancer-du-sein-et-de-lovaire-des-cellules-saines-empecheraient-laction-de-certains-medicaments": { url: "/cancer-sein", code:301},
        "/page/cancer-du-sein-metastatique-pacosme-un-parcours-patientes-specifique-linstitut-curie": { url: "/cancer-sein", code:301},
        "/page/cancer-du-sein-metastatique-premier-point-detape-pour-le-projet-de-structuration-dun-parcours": { url: "/cancer-sein", code:301},
        "/page/cancer-du-sein-une-simple-prise-de-sang-pour-ameliorer-les-choix-therapeutiques": { url: "/cancer-sein", code:301},
        "/page/cancer-les-programmes-dactivite-physique-adaptee-pendant-et-apres-les-traitements": { url: "/lactivite-physique-adaptee", code:301},
        "/page/cancers-de-lenfant/cancers-de-lenfant-enjeux-ethiques-des-essais-cliniques": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/cancers-de-lenfant/cancers-de-lenfant-mettre-disposition-de-nouvelles-therapies-plus": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/cancers-de-lenfant/micchado-lessai-qui-peut-changer-la-donne-dans-les-cancers-pediatriques-de": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/cancers-de-lenfant/portrait-franck-bourdeaut-pediatre-oncologue-chercheur-siredo": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/cancers-de-loeil-traitements-et-recherches-sur-tumeurs-malignes-des-yeux-et-de-la-paupiere": { url: "/tumeurs-oeil", code:301},
        "/page/cancers-du-poumon/cancer-du-poumon-limmunotherapie-change-la-donne": { url: "/traitements-du-cancer-du-poumon", code:301},
        "/page/cancers-du-sein": { url: "/cancer-sein", code:301},
        "/page/cancers-du-sein-lexpertise-de-linstitut-curie": { url: "/la-recherche-sur-le-cancer-du-sein", code:301},
        "/page/cancers-pediatriques-en-route-vers-une-medecine-de-precision": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/cancers-pediatriques/lessai-micchado-en-images": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/cancers-pediatriques/neuroblastome-une-tumeur-pediatrique-tres-heterogene": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/cancers-pediatriques/siredo-en-images": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/casiopeea-le-sport-pour-vaincre": { url: "/les-associations-dentraide", code:301},
        "/page/cellules-contorsionnistes-ou-comment-faire-passer-un-chameau-par-le-trou-dune-aiguille": { url: "/", code:301},
        "/page/centre-communal-daction-sociale-de-charenton-le-pont": { url: "/ils-nous-soutiennent", code:301},
        "/page/centre-de-protontherapie-informations-pratiques": { url: "/centre-de-protontherapie-dorsay", code:301},
        "/page/centre-dimmunotherapie-des-cancers": { url: "/lensemble-hospitalier-de-linstitut-curie", code:301},
        "/page/centromere-et-centrosome-deux-entites-eloignees-mais-qui-travaillent-ensemble": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/centromeres-centrosomes-et-division-cellulaire": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/chacun-son-cap": { url: "/les-associations-dentraide", code:301},
        "/page/chacun-son-everest-apres-la-maladie-place-la-vie": { url: "/les-associations-dentraide", code:301},
        "/page/chez-les-molecules-aussi-lunion-fait-la-force": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/chimiotherapie-et-hormonotherapie": { url: "/la-chimiotherapie-et-lhormonotherapie", code:301},
        "/page/chirurgie": { url: "/la-chirurgie-linstitut-curie", code:301},
        "/page/chirurgie-reconstructrice": { url: "/la-chirurgie-linstitut-curie", code:301},
        "/page/christophe-court-pour-linstitut-curie": { url: "/ils-nous-soutiennent", code:301},
        "/page/club-des-jardiniers-de-dassault": { url: "/ils-nous-soutiennent", code:301},
        "/page/comment-les-cellules-acquierent-le-sens-de-lorientation-ou-le-perdent": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/comment-utiliser-la-plateforme-dediee-aux-patients-internationaux": { url: "/comment-utiliser-la-plateforme-dediee-aux-patients-internationaux", code:301},
        "/page/compagnie-des-fromages-richesmonts": { url: "/ils-nous-soutiennent", code:301},
        "/page/comprendre-comment-la-cellule-tumorale-detourne-son-environnement": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/comprendre-les-essais-cliniques-precoces-tester-les-medicaments-de-demain": { url: "/", code:301},
        "/page/comprendre-les-metastases-des-melanome-de-la-choroide-un-cancer-de-loeil": { url: "/tumeurs-oeil", code:301},
        "/page/comprendre-lorganisation-du-noyau-cellulaire-dans-le-temps-et-dans-lespace": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/corasso": { url: "/les-associations-dentraide", code:301},
        "/page/couleur-jade": { url: "/ils-nous-soutiennent", code:301},
        "/page/course-de-lespoir-du-mont-valerien": { url: "/ils-nous-soutiennent", code:301},
        "/page/course-enfants-sans-cancer-2019-inscrivez-vous": { url: "/actualites?fieldDomainNews[]=273&page=1", code:301},
        "/page/coussin-coeur-une-operation-solidaire-pour-linstitut-curie": { url: "/", code:301},
        "/page/covid-19-informations-destination-de-nos-patients-linternational": { url: "/", code:301},
        "/page/covid-19-les-remerciements-de-nos-communautes-sur-les-reseaux-sociaux": { url: "/", code:301},
        "/page/credit-agricole-ile-de-france-mecenat": { url: "/ils-nous-soutiennent", code:301},
        "/page/dans-lintestin-grele-les-cellules-jouent-des-coudes": { url: "/", code:301},
        "/page/data-et-intelligence-artificielle-lexique": { url: "/", code:301},
        "/page/de-la-cellule-lembryon-ces-heures-cruciales-qui-nous-faconnent": { url: "/", code:301},
        "/page/defi-pour-soutenir-les-projets-des-adultes-malades": { url: "/", code:301},
        "/page/depistage-genetique": { url: "/depistage-et-diagnostic", code:301},
        "/page/des-collaborations-nombreuses-et-variees-avec-les-start-et-lindustrie": { url: "/", code:301},
        "/page/des-initiatives-solidaires-mobilisees-pour-octobre-rose": { url: "/", code:301},
        "/page/des-recherches-revolutionnaires-en-radiotherapie": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/page/devenir-mecene-pour-aider-la-recherche-contre-le-cancer": { url: "/ils-nous-soutiennent", code:301},
        "/page/emerald-international-phd-program-medical-doctors-lappel-candidatures-est-ouvert": { url: "/enseignement", code:301},
        "/page/enseignement-preparer-lavenir-de-la-recherche-et-de-la-medecine": { url: "/enseignement", code:301},
        "/page/enseignement-preparer-lavenir-de-la-recherche-et-de-la-medecine?profile=4": { url: "/enseignement", code:301},
        "/page/entre-ciel-mer-0": { url: "/les-associations-dentraide", code:301},
        "/page/entrepreneurs-go": { url: "/ils-nous-soutiennent", code:301},
        "/page/essai-clinique-des-resultats-prometteurs-pour-booster-leffet-de-la-radiotherapie": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/page/essais-cliniques-precoces-le-parcours-du-patient-au-sein-dune-uic": { url: "/essais-cliniques-cancer-du-sein", code:301},
        "/page/essais-precoces": { url: "/linstitut-curie", code:301},
        "/page/est-la-un-vecu-partager": { url: "/les-associations-dentraide", code:301},
        "/page/europa-donna-france-contre-le-cancer-du-sein": { url: "/les-associations-dentraide", code:301},
        "/page/eurotech-international": { url: "/ils-nous-soutiennent", code:301},
        "/page/explorer-les-consequences-de-la-fibrose-radio-induite": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/faire-un-don-depuis-letranger-pour-soutenir-la-recherche-francaise-dans-sa-lutte-contre-le": { url: "/faire-un-don-depuis-letranger-lutte-cancer", code:301},
        "/page/faire-un-don-linstitut-curie": { url: "/faire-un-don", code:301},
        "/page/fern-groupe-sponsor-du-3eme-open-de-golf-de-linstitut-curie": { url: "/ils-nous-soutiennent", code:301},
        "/page/financeurs-tutelles-et-labels": { url: "/linstitut-curie", code:301},
        "/page/fondation-apicil": { url: "/ils-nous-soutiennent", code:301},
        "/page/fondation-dentreprise-michelin": { url: "/ils-nous-soutiennent", code:301},
        "/page/fondation-loccitane": { url: "/ils-nous-soutiennent", code:301},
        "/page/fondation-meeschaert-pour-la-petite-enfance": { url: "/ils-nous-soutiennent", code:301},
        "/page/fondation-pfg": { url: "/ils-nous-soutiennent", code:301},
        "/page/fondation-swiss-life": { url: "/ils-nous-soutiennent", code:301},
        "/page/fonds-de-dotation-milk-good": { url: "/ils-nous-soutiennent", code:301},
        "/page/fonds-de-dotations-afer-pour-la-recherche-medicale": { url: "/ils-nous-soutiennent", code:301},
        "/page/france-lymphome-espoir": { url: "/les-associations-dentraide", code:301},
        "/page/franck-un-rayon-de-soleil": { url: "/ils-nous-soutiennent", code:301},
        "/page/genes-brca-nouvelles-applications-nouvelles-precautions": { url: "/", code:301},
        "/page/geneticancer-contre-les-cancers-genetiques": { url: "/les-associations-dentraide", code:301},
        "/page/genetique-et-epigenetique": { url: "/linstitut-curie", code:301},
        "/page/giphar": { url: "/ils-nous-soutiennent", code:301},
        "/page/grace-vous/ifi-2024-linnovation-notre-puissance-face-au-cancer": { url: "/ils-nous-soutiennent", code:301},
        "/page/groupe-nuxe": { url: "/ils-nous-soutiennent", code:301},
        "/page/groupe-reynaers": { url: "/ils-nous-soutiennent", code:301},
        "/page/guillaume-lesaffre": { url: "/ils-nous-soutiennent", code:301},
        "/page/hematologie-une-nouvelle-association-de-traitements-benefique-pour-les-patients-atteints-de": { url: "/actualites", code:301},
        "/page/hopital-de-paris-informations-pratiques": { url: "/hopital-de-paris", code:301},
        "/page/hopital-de-saint-cloud-les-informations-pratiques": { url: "/hopital-de-saint-cloud", code:301},
        "/page/hopital-de-saint-cloud-visite-et-hebergement-des-proches": { url: "/hopital-de-saint-cloud", code:301},
        "/page/hopital-rene-huguenin-de-saint-cloud-les-informations-pratiques": { url: "/hopital-de-saint-cloud", code:301},
        "/page/hpv-lintegration-de-ladn-du-virus-au-crible-dans-le-cancer-anal": { url: "/", code:301},
        "/page/identifier-les-vesicules-amies-et-ennemies": { url: "/", code:301},
        "/page/imagerie-medicale": { url: "/departement-dimagerie-medicale", code:301},
        "/page/imagine-margo-tous-contre-les-cancers-pediatriques": { url: "/les-associations-dentraide", code:301},
        "/page/imagyn-initiative-des-malades-atteintes-de-cancers-gynecologiques": { url: "/les-associations-dentraide", code:301},
        "/page/immuno-oncologie-une-nouvelle-approche-pour-identifier-des-cibles-therapeutiques-inconnues": { url: "/actualites", code:301},
        "/page/immunotherapie-chez-lenfant-lespoir-des-tumeurs-rhabdoides": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/index-egalite-professionnelle": { url: "/index-egalite-professionnelle", code:301},
        "/page/inflammation-et-cancer-lidentification-du-role-du-cuivre-ouvre-la-voie-de-nouvelles": { url: "/actualites", code:301},
        "/page/info-sarcomes": { url: "/sarcomes", code:301},
        "/page/ingenico": { url: "/ils-nous-soutiennent", code:301},
        "/page/innovation/mycurie-un-espace-personnalise-et-securise-pour-une-information-sur-votre-parcours": { url: "/", code:301},
        "/page/innover-pour-depister-et-diagnostiquer-le-cancer-toujours-plus-tot": { url: "/actualites", code:301},
        "/page/institut-du-thorax": { url: "/institut-du-thorax", code:301},
        "/page/intelligence-artificielle-les-enjeux-du-traitement-des-donnees": { url: "/", code:301},
        "/page/intelligence-artificielle-les-projets-en-cours-linstitut-curie": { url: "/", code:301},
        "/page/interface-avec-lensemble-hospitalier": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/jeune-et-rose-contre-le-cancer-du-sein-chez-la-femme-jeune": { url: "/les-associations-dentraide", code:301},
        "/page/jeunes-solidarite-cancer-briser-lisolement-des-ados-et-des-jeunes": { url: "/", code:301},
        "/page/journee-internationale-des-infirmieres-et-infirmiers-12-mai-2023-linstitut-curie-recrute": { url: "/actualites", code:301},
        "/page/journee-mondiale-des-cancers-de-lenfant/recherche-onco-pediatrique": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/juste-humain-apporter-lart-et-la-culture-aux-enfants-hospitalises": { url: "/les-associations-dentraide", code:301},
        "/page/kartesia": { url: "/ils-nous-soutiennent", code:301},
        "/page/la-charte-de-la-personne-hospitalisee": { url: "/la-charte-de-la-personne-hospitalisee", code:301},
        "/page/la-chirurgie": { url: "/la-chirurgie-linstitut-curie", code:301},
        "/page/la-commission-des-usagers": { url: "/commission-des-usagers-cdu", code:301},
        "/page/la-course-de-lespoir-du-mont-valerien": { url: "/", code:301},
        "/page/la-course-de-timo": { url: "/ils-nous-soutiennent", code:301},
        "/page/la-course-des-lumieres": { url: "/ils-nous-soutiennent", code:301},
        "/page/la-course-enfants-sans-cancer-fete-ses-10-ans-go-fight-win": { url: "/ils-nous-soutiennent", code:301},
        "/page/la-cryotherapie": { url: "/", code:301},
        "/page/la-defiscalisation-des-dons": { url: "/avantages-fiscaux", code:301},
        "/page/la-dietetique-et-la-nutrition-en-oncologie-mieux-vivre-son-cancer": { url: "/la-dietetique-et-la-nutrition-en-oncologie", code:301},
        "/page/la-fondation-adolphe-de-rotschild-et-linstitut-curie-signent-une-convention-cadre-de": { url: "/", code:301},
        "/page/la-gestion-des-effets-secondaires": { url: "/", code:301},
        "/page/la-lutte-contre-la-douleur-chronique": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/page/la-lutte-contre-les-douleurs-liees-aux-soins": { url: "/le-traitement-de-la-douleur-linstitut-curie", code:301},
        "/page/la-mecanique-sinvite-dans-le-monde-cellulaire": { url: "/", code:301},
        "/page/la-piot-poulette": { url: "/ils-nous-soutiennent", code:301},
        "/page/la-prise-en-charge-des-symptomes-persistants": { url: "/", code:301},
        "/page/la-prise-en-charge-du-patient-dans-un-essai-clinique": { url: "/", code:301},
        "/page/la-protontherapie": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/page/la-protontherapie-pour-traiter-les-melanomes-de-loeil": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/page/la-qualite-et-la-securite-des-soins-notre-priorite": { url: "/la-qualite-et-la-securite-des-soins", code:301},
        "/page/la-radiotherapie": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/page/la-reeducation-fonctionnelle-mieux-vivre-son-cancer": { url: "/la-reeducation-fonctionnelle-mieux-vivre-son-cancer", code:301},
        "/page/la-relaxation-en-groupe": { url: "/approches-complementaires-linstitut-curie", code:301},
        "/page/la-reponse-vos-questions-les-plus-frequentes-faq": { url: "/faq-patients-internationaux", code:301},
        "/page/la-sophrologie-pour-mieux-vivre-son-cancer": { url: "/approches-complementaires-linstitut-curie", code:301},
        "/page/la-teleconsultation-linstitut-curie": { url: "/", code:301},
        "/page/labex-dcbiol": { url: "/labex-dcbiol", code:301},
        "/page/laccompagnement-social": { url: "/accompagnement-social", code:301},
        "/page/lactivite-physique-et-sportive": { url: "/lactivite-physique-adaptee", code:301},
        "/page/lassociation-marabout-de-ficelle-remet-23-000-euros-pour-la-recherche-pediatrique": { url: "/ils-nous-soutiennent", code:301},
        "/page/lathletic-club-de-boulogne-billancourt-section-aviron-sante": { url: "/les-associations-dentraide", code:301},
        "/page/lauriculotherapie": { url: "/approches-complementaires-linstitut-curie", code:301},
        "/page/le-cancer-chez-les-personnes-agees": { url: "/oncogeriatrie-le-cancer-chez-les-personnes-agees", code:301},
        "/page/le-cancer-colorectal": { url: "/cancer-colorectal", code:301},
        "/page/le-cancer-de-la-peau-traitement-des-carcinomes-et-des-melanomes": { url: "/melanome-uveal", code:301},
        "/page/le-centre-de-recherche-de-linstitut-curie": { url: "/le-centre-de-recherche", code:301},
        "/page/le-centre-dimmunotherapie": { url: "/linstitut-curie", code:301},
        "/page/le-comite-des-patients": { url: "/le-comite-de-lexperience-patient", code:301},
        "/page/le-d3i-et-la-direction-des-data-lancent-le-projet-de-medecine-personnalisee-pevodata": { url: "/", code:301},
        "/page/le-d3i-un-departement-dexcellence-dedie-aux-essais-precoces-linstitut-curie": { url: "/departement-drug-development-and-innovation-d3i", code:301},
        "/page/le-departement-de-chirurgie": { url: "/la-chirurgie-linstitut-curie", code:301},
        "/page/le-dossier-medical": { url: "/dossier-medical", code:301},
        "/page/le-dr-pouget-prend-la-tete-du-service-chirurgie-gynecologique-et-senologique-linstitut-curie": { url: "/la-chirurgie-linstitut-curie", code:301},
        "/page/le-fil-et-la-main": { url: "/les-associations-dentraide", code:301},
        "/page/le-groupe-casino": { url: "/ils-nous-soutiennent", code:301},
        "/page/le-label-hr-excellence-research": { url: "/", code:301},
        "/page/le-lymphome-de-hodgkin": { url: "/lymphome-enfant-adolescent-jeune-adulte", code:301},
        "/page/le-parcours-nodule-thyroidien-linstitut-curie": { url: "/le-parcours-nodule-thyroidien-linstitut-curie", code:301},
        "/page/le-paris-saclay-cancer-cluster-est-laureat-du-programme-biocluster-france-2030": { url: "/actualites", code:301},
        "/page/le-pembrolizumab-une-nouvelle-immunotherapie-ciblee-pour-les-cancers-orl-en-recidive": { url: "/cancers-orl-tete-cou", code:301},
        "/page/le-prelevement-la-source-mode-demploi": { url: "/ils-nous-soutiennent", code:301},
        "/page/le-programme-medico-scientifique-de-linstitut-curie": { url: "/linstitut-curie", code:301},
        "/page/le-projet-bidifly-du-lysa-lysarc-coordonne-par-linstitut-curie-obtient-14-meu-pour-mieux": { url: "/", code:301},
        "/page/le-projet-patient-partenaire-la-recherche": { url: "/", code:301},
        "/page/le-regard-du-clown-un-peu-de-legerete-face-la-maladie": { url: "/les-associations-dentraide", code:301},
        "/page/le-retinoblastome": { url: "/retinoblastome", code:301},
        "/page/le-retour-au-travail": { url: "/", code:301},
        "/page/le-sequencage-tumoral-pour-acceder-des-nouveaux-medicaments": { url: "/", code:301},
        "/page/lenvol-redonner-de-la-force-et-le-desir-de-sa-battre": { url: "/les-associations-dentraide", code:301},
        "/page/lequipe-de-rugby-du-stade-francais-paris-sengage-pour-transformer-lessai-contre-le-cancer": { url: "/actualites", code:301},
        "/page/lequipe-du-centre-de-protontherapie": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/page/les-20km-de-paris-2021-soutiennent-linstitut-curie": { url: "/", code:301},
        "/page/les-associations-dentraide": { url: "/les-associations-dentraide", code:301},
        "/page/les-blouses-roses-animation-loisirs-lhopital": { url: "/les-associations-dentraide", code:301},
        "/page/les-bonnes-fees": { url: "/les-associations-dentraide", code:301},
        "/page/les-cancers-de-la-tete-et-du-cou": { url: "/cancers-orl-tete-cou", code:301},
        "/page/les-cancers-digestifs": { url: "/cancers-digestifs", code:301},
        "/page/les-cancers-du-poumon": { url: "/cancer-poumon", code:301},
        "/page/les-cancers-du-sang-et-de-la-moelle-osseuse": { url: "/cancers-hematologiques", code:301},
        "/page/les-cancers-du-sang-et-de-la-moelle-osseuse-0": { url: "/cancers-hematologiques", code:301},
        "/page/les-cancers-gynecologiques": { url: "/cancers-gynecologiques", code:301},
        "/page/les-cancers-urogenitaux": { url: "/quest-ce-que-les-cancers-urogenitaux", code:301},
        "/page/les-centres-de-beaute-de-cew": { url: "/les-associations-dentraide", code:301},
        "/page/les-compositeurs-du-projet-muse-ic": { url: "/", code:301},
        "/page/les-dernieres-avancees-de-linstitut-curie-au-congres-de-laacr-2023": { url: "/actualites", code:301},
        "/page/les-directives-anticipees": { url: "/les-directives-anticipees", code:301},
        "/page/les-enfants-de-curie-lassociation-qui-accompagne-les-enfants-soignes-linstitut-curie-et-leurs": { url: "/les-associations-dentraide", code:301},
        "/page/les-formalites-pour-votre-premier-rendez-vous": { url: "/", code:301},
        "/page/les-g-quadruplexes-contre-le-cancer": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/les-mamans-de-villennes": { url: "/ils-nous-soutiennent", code:301},
        "/page/les-osteosarcomes-et-les-tumeurs-dewing": { url: "/sarcomes", code:301},
        "/page/les-premieres-vibrations-de-lunivers-et-la-quete-des-premiers-amas-de-galaxies": { url: "/", code:301},
        "/page/les-prestations-proposees-aux-patients-internationaux": { url: "/prestations-internationales", code:301},
        "/page/les-sarcomes": { url: "/sarcomes", code:301},
        "/page/les-seintinelles-contre-le-cancer-tous-volontaires": { url: "/les-associations-dentraide", code:301},
        "/page/les-services-en-cancerologie-dedies-aux-patients-internationaux": { url: "/actualites", code:301},
        "/page/les-soins-palliatifs-et-les-soins-de-support-pendant-un-cancer": { url: "/les-soins-palliatifs-pendant-un-cancer", code:301},
        "/page/les-therapies-ciblees": { url: "/", code:301},
        "/page/les-tumeurs-cerebrales-de-lenfant": { url: "/symptomes-et-diagnostic-des-tumeurs-cerebrales-de-lenfant", code:301},
        "/page/les-tumeurs-du-systeme-nerveux-central-et-de-la-base-du-crane": { url: "/", code:301},
        "/page/lespace-fournisseurs": { url: "/", code:301},
        "/page/lhypnose-medicale": { url: "/approches-complementaires-linstitut-curie", code:301},
        "/page/limmunotherapie": { url: "/limmunotherapie", code:301},
        "/page/limprimante-3d-au-service-de-la-cancerologie": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/lincubation-dentreprises-linstitut-curie-veritable-tremplin-pour-linnovation-et-la-creation": { url: "/actualites", code:301},
        "/page/linformation-et-le-consentement": { url: "/linformation-et-le-consentement", code:301},
        "/page/linstitut-curie-luniversite-psl-et-linserm-creent-linstitut-des-cancers-des-femmes-grace": { url: "/actualites", code:301},
        "/page/linstitut-curie-porte-lonu-la-voix-de-la-solidarite-internationale-pour-lutter-contre-le": { url: "/actualites", code:301},
        "/page/linstitut-curie-salue-la-levee-de-fonds-de-75-millions-deuros-realisee-par-sa-spin-mnemo": { url: "/actualites", code:301},
        "/page/linstitut-curie-sengage": { url: "/", code:301},
        "/page/logo-unicancer": { url: "/", code:301},
        "/page/logoIC": { url: "/", code:301},
        "/page/lorganisation-du-centre-de-recherche": { url: "/le-centre-de-recherche", code:301},
        "/page/lutep-unite-transversale-deducation-therapeutique-du-patient": { url: "/", code:301},
        "/page/magie-lhopital-de-levasion-pour-les-enfants-et-leur-famille": { url: "/les-associations-dentraide", code:301},
        "/page/malakoff-humanis": { url: "/ils-nous-soutiennent", code:301},
        "/page/medecine-diagnostique-et-theranostique": { url: "/pole-de-medecine-diagnostique-et-theranostique", code:301},
        "/page/medecins-de-limaginaire": { url: "/les-associations-dentraide", code:301},
        "/page/melanome-de-luvee": { url: "/melanome-uveal", code:301},
        "/page/melanome-de-luvee-et-risque-de-metastases": { url: "/melanome-uveal", code:301},
        "/page/melanomes-de-loeil/portrait-samar-alsafadi-manager-de-lequipe-melanome-uveal": { url: "/melanome-uveal", code:301},
        "/page/melanomes-de-luvee": { url: "/melanome-uveal", code:301},
        "/page/mentions-legales": { url: "/mentions-legales", code:301},
        "/page/mentions-legales-0": { url: "/mentions-legales", code:301},
        "/page/mettez-votre-impot-au-service-de-linnovation-contre-le-cancer": { url: "/actualites", code:301},
        "/page/miao-merveille": { url: "/les-associations-dentraide", code:301},
        "/page/mobilisation/lancement-de-ledition-2020-de-la-campagne-nationale-de-solidarite-de-linstitut": { url: "/actualites", code:301},
        "/page/modes-dhospitalisation": { url: "/modes-dhospitalisation", code:301},
        "/page/mon-reseau-cancer-du-sein-une-initiative-participative": { url: "/les-associations-dentraide", code:301},
        "/page/monoprix-engage-aux-cotes-de-linstitut-curie": { url: "/ils-nous-soutiennent", code:301},
        "/page/msd-avenir": { url: "/ils-nous-soutiennent", code:301},
        "/page/muse-ic-musique-et-science-deux-univers-qui-se-rencontrent": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/mutuelle-bleue": { url: "/ils-nous-soutiennent", code:301},
        "/page/mutuelle-interiale": { url: "/ils-nous-soutiennent", code:301},
        "/page/nathalie-guenard-nous-dinstaller-un-climat-de-confiance-avec-professionnalisme-compassion-et": { url: "/", code:301},
        "/page/nemo-pour-noel-des-enfants-malades-organisation": { url: "/les-associations-dentraide", code:301},
        "/page/nexans": { url: "/ils-nous-soutiennent", code:301},
        "/page/noc-nous-cree-arts-plastiques-lhopital": { url: "/les-associations-dentraide", code:301},
        "/page/nos-equipes-de-recherche": { url: "/equipes-recherche", code:301},
        "/page/nos-missions": { url: "/les-trois-missions-de-linstitut-curie", code:301},
        "/page/nos-parrains": { url: "/ils-nous-soutiennent", code:301},
        "/page/nos-partenaires-0": { url: "/lensemble-hospitalier-de-linstitut-curie", code:301},
        "/page/notre-conseil-scientifique": { url: "/le-conseil-scientifique-international", code:301},
        "/page/notre-gouvernance": { url: "/gouvernance-et-organisation", code:301},
        "/page/notre-histoire": { url: "/linstitut-curie", code:301},
        "/page/notre-modele-economique": { url: "/linstitut-curie", code:301},
        "/page/nous-rejoindre-en-tant-quetudiant": { url: "/", code:301},
        "/page/nouveau-programme-doctoral-international-vos-candidatures": { url: "/enseignement", code:301},
        "/page/nuit-europeenne-des-musees-du-13-mai-2023-au-musee-curie-la-decouverte-dun-musee-de-legende": { url: "/actualites", code:301},
        "/page/octobre-rose-2020-linstitut-curie-donne-la-parole-aux-patientes-aux-medecins-et-aux-chercheurs": { url: "/actualites", code:301},
        "/page/octobre-rose-2021-desescalade-therapeutique-et-innovations-pour-les-femmes-atteintes-de-cancer": { url: "/actualites", code:301},
        "/page/octobre-rose-des-initiatives-solidaires-toujours-engages": { url: "/", code:301},
        "/page/oncogenetique-curie": { url: "/oncogenetique", code:301},
        "/page/oncologie-medicale": { url: "/departement-doncologie-medicale", code:301},
        "/page/oncologie-pediatrique-adolescents-et-jeunes-adultes": { url: "/les-cancers-des-adolescents-et-jeunes-adultes", code:301},
        "/page/ou-vont-vos-dons": { url: "/quoi-servent-vos-dons", code:301},
        "/page/page-403": { url: "/", code:301},
        "/page/page-404": { url: "/", code:301},
        "/page/parcours-patient/departement-interdisciplinaire-des-soins-de-support": { url: "/departement-interdisciplinaire-des-soins-de-support-pour-le-patient-en-oncologie", code:301},
        "/page/paris-informations-pratiques": { url: "/hopital-de-paris", code:301},
        "/page/paris-pierre-sponsor-de-lopen-de-golf-de-linstitut-curie-au-golf-de-joyenavl": { url: "/ils-nous-soutiennent", code:301},
        "/page/paris-visite-et-hebergement-des-proches": { url: "/lensemble-hospitalier-de-linstitut-curie", code:301},
        "/page/partenaires-institutionnels-et-certifications": { url: "/linstitut-curie", code:301},
        "/page/patient-international-les-etapes-de-votre-parcours": { url: "/patient-international-les-etapes-de-votre-parcours", code:301},
        "/page/patients-internationaux": { url: "/patients-internationaux", code:301},
        "/page/patients-internationaux-ce-quils-disent-de-nous": { url: "/patients-internationaux", code:301},
        "/page/patients-internationaux-une-equipe-dediee": { url: "/patients-internationaux-une-equipe-dediee", code:301},
        "/page/patients-internationaux?profile=2": { url: "/patients-internationaux", code:301},
        "/page/pause-fruitee": { url: "/ils-nous-soutiennent", code:301},
        "/page/pharmacie": { url: "/pharmacie", code:301},
        "/page/phoenix-et-dragons-dragons-ladies-de-paris": { url: "/les-associations-dentraide", code:301},
        "/page/plateforme-de-pathologie-experimentale-pathex": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/play-again": { url: "/ils-nous-soutiennent", code:301},
        "/page/politique-donnees-personnelles": { url: "/politique-donnees-personnelles", code:301},
        "/page/portrait-amandine-trouchet-responsable-de-la-future-plateforme-single-cell": { url: "/", code:301},
        "/page/portrait-amel-yahou-developpeuse-informatique-en-data": { url: "/", code:301},
        "/page/portrait-antoine-de-pauw-conseiller-en-genetique": { url: "/", code:301},
        "/page/portrait-dominique-stoppa-lyonnet-au-service-des-femmes-predisposees": { url: "/", code:301},
        "/page/portrait-dr-alexandre-matet-ophtalmologue-linstitut-curie": { url: "/", code:301},
        "/page/portrait-dr-olivier-choussy-chef-du-service-dorl-de-linstitut-curie": { url: "/", code:301},
        "/page/portrait-isabelle-allan-assistante-medicale": { url: "/", code:301},
        "/page/portrait-laetitia-chanas-responsable-de-lequipe-conseil-et-services-en-donnees-de-sante": { url: "/", code:301},
        "/page/portrait-linda-haroun-data-manager-clinique": { url: "/", code:301},
        "/page/portrait-nadine-andrieu-cheffe-de-lequipe-epidemiologie-genetique-des-cancers": { url: "/", code:301},
        "/page/portrait-sandrine-caputo-chercheuse-en-oncogenetique": { url: "/", code:301},
        "/page/portrait-sandy-azzi-hatem-manager-et-chargee-daffaires-du-pms-oncopediatrie": { url: "/", code:301},
        "/page/portrait-sarah-lagha-technicienne-de-recherche-clinique": { url: "/", code:301},
        "/page/portrait-sophie-houzard-medecin-de-sante-publique": { url: "/", code:301},
        "/page/portrait-thomas-balezeau-ingenieur-data": { url: "/", code:301},
        "/page/pour-un-meilleur-usage-de-la-radiotherapie-chez-les-enfants": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/page/pourquoi-donner-linstitut-curie": { url: "/nous-soutenir", code:301},
        "/page/predispositions-aux-cancers-du-sein-et-de-lovaire-gros-plan-sur-linterpretation": { url: "/exemple-des-predispositions-aux-cancers-du-sein-et-de-lovaire", code:301},
        "/page/premier-open-de-golf-institut-curie-un-succes": { url: "/", code:301},
        "/page/premiers-de-cordee-une-association-dediee-aux-sports": { url: "/les-associations-dentraide", code:301},
        "/page/prendre-un-premier-rendez-vous-linstitut-curie": { url: "/demander-un-premier-rendez-vous-linstitut-curie", code:301},
        "/page/prevenir-la-recidive-du-cancer-du-sein-le-temoignage-de-corinne": { url: "/", code:301},
        "/page/prevlink": { url: "/ils-nous-soutiennent", code:301},
        "/page/princesse-margot": { url: "/les-associations-dentraide", code:301},
        "/page/prisma-media": { url: "/ils-nous-soutiennent", code:301},
        "/page/prix-ruban-rose-2020-pour-elisabetta-marangoni": { url: "/", code:301},
        "/page/projet-de-lensemble-hospitalier": { url: "/les-projets-strategiques-de-lensemble-hospitalier", code:301},
        "/page/projet-de-lensemble-hospitalier?profile=2": { url: "/les-projets-strategiques-de-lensemble-hospitalier", code:301},
        "/page/projet-giant-nb-mieux-comprendre-le-neuroblastome-pour-developper-de-nouvelles-armes": { url: "/", code:301},
        "/page/projet-pevodata-3-questions-xose-fernandez-directeur-des-data-linstitut-curie": { url: "/", code:301},
        "/page/protontherapie-un-lieu-de-haute-technologie": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/page/protontherapie-une-expertise-curie-inegalee-et-ultraprecise-pour-soigner-les-enfants": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/page/quand-ladn-fait-des-boucles": { url: "/", code:301},
        "/page/quand-nos-cellules-sommeillent-quiescence-et-renaissance": { url: "/", code:301},
        "/page/quelle-histoire-ca-conte-lhopital": { url: "/les-associations-dentraide", code:301},
        "/page/quest-ce-que-la-recherche-clinique-les-differentes-etapes-de-letude": { url: "/", code:301},
        "/page/quest-ce-quun-essai-clinique": { url: "/essais-cliniques", code:301},
        "/page/quest-ce-quun-essai-clinique-les-recherches-observationnelles-et-interventionnelles": { url: "/", code:301},
        "/page/quoi-servent-vos-dons": { url: "/quoi-servent-vos-dons", code:301},
        "/page/radio-pharmacologie": { url: "/lensemble-hospitalier-de-linstitut-curie", code:301},
        "/page/radiologie-interventionnelle": { url: "/la-radiologie-interventionnelle-linstitut-curie", code:301},
        "/page/radiotherapie-et-biologie-des-radiations": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/page/radiotherapie-oncologique": { url: "/la-radiotherapie-linstitut-curie", code:301},
        "/page/rallye-daumale": { url: "/ils-nous-soutiennent", code:301},
        "/page/rapport-annuel-2016-une-annee-douverture": { url: "/", code:301},
        "/page/rapport-annuel-2017-une-annee-de-transition": { url: "/", code:301},
        "/page/recherche-et-developpement-au-centre-de-protontherapie": { url: "/la-protontherapie-linstitut-curie", code:301},
        "/page/recherches-sur-les-donnees-de-sante": { url: "/", code:301},
        "/page/recit-sur-mon-cancer-du-sein": { url: "/", code:301},
        "/page/relations-internationales": { url: "/linstitut-curie", code:301},
        "/page/resultats-de-la-plus-vaste-etude-jamais-realisee-pour-personnaliser-le-traitement-du-cancer-du": { url: "/actualites", code:301},
        "/page/retinostop-pour-les-parents-denfants-atteints-de-retinoblastome": { url: "/les-associations-dentraide", code:301},
        "/page/retinostop-un-soutien-indefectible-la-cause-des-familles-et-de-la-recherche-sur-le": { url: "/les-associations-dentraide", code:301},
        "/page/retinostop-un-soutien-indefectible-la-cause-des-familles-et-de-la-recherche-sur-le-0": { url: "/les-associations-dentraide", code:301},
        "/page/rotaract-club-essec": { url: "/ils-nous-soutiennent", code:301},
        "/page/rotaract-cosne-sancerre": { url: "/ils-nous-soutiennent", code:301},
        "/page/rovalec-sponsor-du-3eme-open-de-golf-de-linstitut-curie": { url: "/ils-nous-soutiennent", code:301},
        "/page/rs-diep": { url: "/les-associations-dentraide", code:301},
        "/page/sante-intestinale-des-macrophages-chefs-dorchestre-du-dialogue-entre-le-microbiote-et-le-colon": { url: "/actualites", code:301},
        "/page/sarcome-dewing-vers-une-nouvelle-cible-therapeutique": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/sarcomes": { url: "/sarcomes", code:301},
        "/page/sarcomes-des-tissus-mous": { url: "/sarcomes", code:301},
        "/page/sarcomes-dewing-sur-la-piste-epigenetique": { url: "/sarcomes", code:301},
        "/page/sarcomes-retroperitoneaux-les-benefices-dune-radiotherapie": { url: "/sarcomes", code:301},
        "/page/scorpgym-boxing": { url: "/ils-nous-soutiennent", code:301},
        "/page/service-et-amitie": { url: "/les-associations-dentraide", code:301},
        "/page/simmons-simmons": { url: "/ils-nous-soutiennent", code:301},
        "/page/single-cell-une-technologie-disruptive": { url: "/", code:301},
        "/page/skin-une-experience-immersive-et-innovante": { url: "/les-associations-dentraide", code:301},
        "/page/soutenez-les-grands-programmes-dinnovation-de-linstitut-curie": { url: "/", code:301},
        "/page/temoignage-jai-decouvert-une-boule-dans-mon-sein-7-mois-et-demi-de-grossesse": { url: "/", code:301},
        "/page/temoignage-si-je-pouvais-permettre-de-sauver-des-vies-je-serais-deja-la-plus-heureuse-des": { url: "/", code:301},
        "/page/temoignages-patients-affronter-ces-cancers-avec-courage-et-determination": { url: "/", code:301},
        "/page/tour-du-monde-en-gyroroue-au-profit-de-linstitut-curie": { url: "/", code:301},
        "/page/touring-club-francilien": { url: "/", code:301},
        "/page/tournoi-des-six-nations-deux-matchs-solidaires-pour-transformer-lessai-contre-le-cancer": { url: "/actualites", code:301},
        "/page/tout-le-monde-contre-le-cancer": { url: "/les-associations-dentraide", code:301},
        "/page/tout-voir-du-noyau-dune-cellule": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/traquer-les-cellules-leucemiques-jusque-dans-leur-nid": { url: "/leucemies-aigues-myeloides-myelodysplasies", code:301},
        "/page/truffaut-et-la-fondation-truffaut": { url: "/ils-nous-soutiennent", code:301},
        "/page/un-esprit-de-collaborations": { url: "/enseignement", code:301},
        "/page/un-moment-de-gourmandise-pour-aider-la-recherche-sur-le-cancer": { url: "/", code:301},
        "/page/un-nouvel-espoir-contre-les-cancers-de-lenfant-haut-risque": { url: "/cancers-de-lenfant-et-de-ladolescent", code:301},
        "/page/un-pas-en-avant-vers-les-cancers-pediatriques-grace-letoile-de-martin": { url: "/ils-nous-soutiennent", code:301},
        "/page/un-soutien-scolaire-pour-les-enfants-et-adolescents-hospitalises": { url: "/soutien-scolaire", code:301},
        "/page/un-soutien-social-adapte-pour-les-jeunes-patients": { url: "/laccompagnement-psychosocial-des-adolescents-et-jeunes-adultes", code:301},
        "/page/une-jonquille-pour-curie": { url: "/actualites", code:301},
        "/page/une-jonquille-pour-curie-2019-630-000-euros-collectes-au-profit-de-la-recherche-fondamentale": { url: "/actualites", code:301},
        "/page/une-nouvelle-strategie-therapeutique-contre-le-cancer-du-sein-triple-negatif": { url: "/cancer-sein", code:301},
        "/page/une-proteine-scrutee-la-loupe-pour-mieux-comprendre-et-prendre-un-charge-les-maladies-des": { url: "/", code:301},
        "/page/une-recherche-ouverte-sur-le-monde": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/page/urilco-favoriser-la-reinsertion-des-stomises": { url: "/les-associations-dentraide", code:301},
        "/page/vaincre-avec-elles": { url: "/ils-nous-soutiennent", code:301},
        "/page/vente-aux-encheres": { url: "/ils-nous-soutiennent", code:301},
        "/page/vente-aux-encheres-de-la-ferte-sous-jouarre-seine-et-marne": { url: "/ils-nous-soutiennent", code:301},
        "/page/vivacto-un-mecene-precieux-de-linstitut-curie": { url: "/ils-nous-soutiennent", code:301},
        "/page/vivre-comme-avant-partenaires-aupres-des-patients": { url: "/les-associations-dentraide", code:301},
        "/page/vous-souhaitez-beneficier-dun-essai-clinique": { url: "/", code:301},
        "/page/zeldathon-fr-le-maratahon-caritatif-legend-zelda-soutient-les-cancers-pediatriques-linstitut": { url: "/ils-nous-soutiennent", code:301},
        "/partenariats-collaborations-et-soutiens": { url: "/", code:301},
        "/participation-aux-congres-equipe-larue": { url: "/", code:301},
        "/permedcoe-exascale-ready-cell-level-simulations-european-personalised-medicine": { url: "/", code:301},
        "/personne/aaliya-khan": { url: "/annuaire", code:301},
        "/personne/achille-joliot": { url: "/annuaire", code:301},
        "/personne/alain-fourquet": { url: "/annuaire", code:301},
        "/personne/alain-labib": { url: "/annuaire", code:301},
        "/personne/alain-livartowski": { url: "/annuaire", code:301},
        "/personne/alexandre-devaux": { url: "/annuaire", code:301},
        "/personne/alia-ifrah": { url: "/annuaire", code:301},
        "/personne/alice-williart": { url: "/annuaire", code:301},
        "/personne/amandine-saint-martin": { url: "/annuaire", code:301},
        "/personne/amartya-chakraborty": { url: "/annuaire", code:301},
        "/personne/amaury-martin-0": { url: "/personne/amaury-martin", code:301},
        "/personne/ana-joaquina-jimenez-salazar": { url: "/annuaire", code:301},
        "/personne/ana-maria-lennon": { url: "/personne/ana-maria-lennon-dumenil", code:301},
        "/personne/andrea-avila-avila": { url: "/annuaire", code:301},
        "/personne/anne-bredart-autier": { url: "/personne/anne-bredart", code:301},
        "/personne/anne-sophie-petit": { url: "/personne/anne-sophie-hamy-petit", code:301},
        "/personne/anthony-rabate": { url: "/annuaire", code:301},
        "/personne/artem-fokin": { url: "/annuaire", code:301},
        "/personne/aruni-senaratne": { url: "/annuaire", code:301},
        "/personne/audrey-huot": { url: "/annuaire", code:301},
        "/personne/aurelie-descamps": { url: "/annuaire", code:301},
        "/personne/aurelie-rejon": { url: "/annuaire", code:301},
        "/personne/aya-mikdache": { url: "/annuaire", code:301},
        "/personne/benedicte-lefevre": { url: "/annuaire", code:301},
        "/personne/benjamin-bonsang": { url: "/annuaire", code:301},
        "/personne/brault": { url: "/personne/philippe-brault", code:301},
        "/personne/bruna-pages": { url: "/annuaire", code:301},
        "/personne/camila-leite-nascimento": { url: "/annuaire", code:301},
        "/personne/camille-coulibaly": { url: "/annuaire", code:301},
        "/personne/camille-gelot": { url: "/annuaire", code:301},
        "/personne/candice-merle": { url: "/annuaire", code:301},
        "/personne/carla-matta": { url: "/annuaire", code:301},
        "/personne/carole-kalogeropoulos": { url: "/annuaire", code:301},
        "/personne/caroline-giuglaris": { url: "/annuaire", code:301},
        "/personne/caroline-mencarelli": { url: "/annuaire", code:301},
        "/personne/catalina-salinas-luypaert": { url: "/personne/catalina-francisca-salinas-luypaert", code:301},
        "/personne/catherine-ricatte": { url: "/annuaire", code:301},
        "/personne/catherine-ricatte-0": { url: "/annuaire", code:301},
        "/personne/celio pouponnot": { url: "/personne/celio-pouponnot", code:301},
        "/personne/chafia-chassaing": { url: "/annuaire", code:301},
        "/personne/chandramouli-natarajan": { url: "/annuaire", code:301},
        "/personne/changting-li": { url: "/annuaire", code:301},
        "/personne/chloe-sybillin": { url: "/annuaire", code:301},
        "/personne/christelle-masdeu": { url: "/annuaire", code:301},
        "/personne/christine-levy": { url: "/annuaire", code:301},
        "/personne/clement-hua": { url: "/annuaire", code:301},
        "/personne/clement-martet": { url: "/annuaire", code:301},
        "/personne/clothilde-roy": { url: "/annuaire", code:301},
        "/personne/coraline-dubot": { url: "/annuaire", code:301},
        "/personne/corentin-melisse": { url: "/annuaire", code:301},
        "/personne/cynthia-occhipinti": { url: "/annuaire", code:301},
        "/personne/david-gentien": { url: "/annuaire", code:301},
        "/personne/david-remy": { url: "/annuaire", code:301},
        "/personne/delphine-hequet": { url: "/annuaire", code:301},
        "/personne/delphine-naud": { url: "/annuaire", code:301},
        "/personne/didier-decaudin-0": { url: "/personne/didier-decaudin", code:301},
        "/personne/didier-decaudin-0?page=5": { url: "/personne/didier-decaudin", code:301},
        "/personne/didier-meseure-0": { url: "/personne/didier-meseure", code:301},
        "/personne/dominika-foretek": { url: "/annuaire", code:301},
        "/personne/dorra-limam": { url: "/annuaire", code:301},
        "/personne/eleanor-hawkins": { url: "/annuaire", code:301},
        "/personne/emeline-ravalli": { url: "/annuaire", code:301},
        "/personne/emile-gasser": { url: "/annuaire", code:301},
        "/personne/emmanuelle-fourme": { url: "/personne/emmanuelle-mouret-fourme", code:301},
        "/personne/eve-moutaux": { url: "/annuaire", code:301},
        "/personne/evelyne-ruff": { url: "/annuaire", code:301},
        "/personne/fabien-reyal-0": { url: "/personne/fabien-reyal", code:301},
        "/personne/fabien-romano": { url: "/annuaire", code:301},
        "/personne/fabiola-garcia-fernandez": { url: "/annuaire", code:301},
        "/personne/fabrice-glibert": { url: "/annuaire", code:301},
        "/personne/faina-kuperstein": { url: "/annuaire", code:301},
        "/personne/farid-goudjil": { url: "/annuaire", code:301},
        "/personne/federica-scotto-di-carlo": { url: "/annuaire", code:301},
        "/personne/fereshteh-farkhondeh": { url: "/annuaire", code:301},
        "/personne/frances-edwards": { url: "/annuaire", code:301},
        "/personne/franck-bourdeaut-0": { url: "/personne/franck-bourdeaut", code:301},
        "/personne/frederique-boutte": { url: "/annuaire", code:301},
        "/personne/gabriele-cancila": { url: "/annuaire", code:301},
        "/personne/genevieve-almouzni wikipedia": { url: "/personne/genevieve-almouzni", code:301},
        "/personne/geraldine-gubitta-wane-0": { url: "/personne/geraldine-gubitta-wane", code:301},
        "/personne/gerard-zalcman": { url: "/annuaire", code:301},
        "/personne/giulia-vanoni": { url: "/annuaire", code:301},
        "/personne/guadalupe-suarez": { url: "/annuaire", code:301},
        "/personne/gudrun-schleiermacher-0": { url: "/personne/gudrun-schleiermacher", code:301},
        "/personne/helene-pacquement": { url: "/annuaire", code:301},
        "/personne/huot-audrey": { url: "/annuaire", code:301},
        "/personne/ilham-aboulfath-ladid*": { url: "/personne/ilham-aboulfath-ladid", code:301},
        "/personne/ilyes-hamitouche": { url: "/annuaire", code:301},
        "/personne/iro-triantafyllakou": { url: "/annuaire", code:301},
        "/personne/jaime-osuna-luque": { url: "/annuaire", code:301},
        "/personne/jan-timon-werle": { url: "/annuaire", code:301},
        "/personne/jane-merlevede": { url: "/annuaire", code:301},
        "/personne/jason-cosgrove": { url: "/annuaire", code:301},
        "/personne/jean-chatain": { url: "/annuaire", code:301},
        "/personne/jean-de-seze": { url: "/annuaire", code:301},
        "/personne/jean-salamero": { url: "/annuaire", code:301},
        "/personne/jeanne-contal": { url: "/annuaire", code:301},
        "/personne/jeremie-tournay": { url: "/annuaire", code:301},
        "/personne/jimmy-mullaert-0": { url: "/personne/jimmy-mullaert", code:301},
        "/personne/joseph-josephides": { url: "/annuaire", code:301},
        "/personne/judith-souphron": { url: "/annuaire", code:301},
        "/personne/julian-ostermaier": { url: "/annuaire", code:301},
        "/personne/julien-masliah-planchon-0": { url: "/personne/julien-masliah-planchon", code:301},
        "/personne/kossi-agbetiafa": { url: "/annuaire", code:301},
        "/personne/laetitia-gaujal": { url: "/annuaire", code:301},
        "/personne/lamia-lamrani": { url: "/annuaire", code:301},
        "/personne/laura-boulan": { url: "/annuaire", code:301},
        "/personne/laura-loriente-gulias": { url: "/annuaire", code:301},
        "/personne/laurence-desjardins": { url: "/annuaire", code:301},
        "/personne/leila-heidsieck": { url: "/annuaire", code:301},
        "/personne/leonid-velikovsky": { url: "/annuaire", code:301},
        "/personne/liliana-mirabal": { url: "/annuaire", code:301},
        "/personne/liliane-khadige": { url: "/annuaire", code:301},
        "/personne/lorea-iturri": { url: "/annuaire", code:301},
        "/personne/louis-rebaud": { url: "/annuaire", code:301},
        "/personne/louise-massing": { url: "/annuaire", code:301},
        "/personne/louise-musikas": { url: "/annuaire", code:301},
        "/personne/magali-frah": { url: "/annuaire", code:301},
        "/personne/manuela-baquero-perez": { url: "/annuaire", code:301},
        "/personne/marco-ruscone": { url: "/annuaire", code:301},
        "/personne/maria-balakireva": { url: "/annuaire", code:301},
        "/personne/maria-da-graca-raposo": { url: "/personne/maria-da-graca-benedetti-raposo", code:301},
        "/personne/maria-rodrigo-riestra": { url: "/annuaire", code:301},
        "/personne/marie-catherine-queinnec": { url: "/annuaire", code:301},
        "/personne/marie-dutreix-1": { url: "/personne/marie-dutreix", code:301},
        "/personne/marie-osdoit": { url: "/annuaire", code:301},
        "/personne/marieke-vromman": { url: "/annuaire", code:301},
        "/personne/marion-espenel": { url: "/annuaire", code:301},
        "/personne/markus-schliffka": { url: "/annuaire", code:301},
        "/personne/mathieu-deygas": { url: "/annuaire", code:301},
        "/personne/mathilde-saint-ghislain": { url: "/annuaire", code:301},
        "/personne/maxime-fondin": { url: "/annuaire", code:301},
        "/personne/maylis-dugert": { url: "/annuaire", code:301},
        "/personne/melissa-saichi": { url: "/annuaire", code:301},
        "/personne/moencopi-bernheim-dennery": { url: "/annuaire", code:301},
        "/personne/narayani-subramanian": { url: "/annuaire", code:301},
        "/personne/ngoc-minh-nguyen": { url: "/annuaire", code:301},
        "/personne/niccolo-schintu": { url: "/annuaire", code:301},
        "/personne/nicolas-captier": { url: "/annuaire", code:301},
        "/personne/nicolas-girard-0": { url: "/personne/nicolas-girard", code:301},
        "/personne/nicolas-girard2": { url: "/personne/nicolas-girard", code:301},
        "/personne/nina-oufkir": { url: "/annuaire", code:301},
        "/personne/noam-prudhomme": { url: "/annuaire", code:301},
        "/personne/nouritza-torossian": { url: "/annuaire", code:301},
        "/personne/oceane-saibou": { url: "/annuaire", code:301},
        "/personne/octave-joliot": { url: "/annuaire", code:301},
        "/personne/oleg-mikhajlov": { url: "/annuaire", code:301},
        "/personne/olivier-delattre-0": { url: "/personne/olivier-delattre", code:301},
        "/personne/olivier-lantz-0": { url: "/personne/olivier-lantz", code:301},
        "/personne/pedro-monteiro": { url: "/annuaire", code:301},
        "/personne/peta-bradbury": { url: "/annuaire", code:301},
        "/personne/pierre-fumoleau": { url: "/annuaire", code:301},
        "/personne/pierre-simonin": { url: "/annuaire", code:301},
        "/personne/priscillia-pierre-elies": { url: "/annuaire", code:301},
        "/personne/rana-mhaidly": { url: "/annuaire", code:301},
        "/personne/reini-luco": { url: "/personne/reini-fernandez-de-luco", code:301},
        "/personne/rodrigo-salinas": { url: "/annuaire", code:301},
        "/personne/salma-fantar": { url: "/annuaire", code:301},
        "/personne/samar-alsafadi": { url: "/annuaire", code:301},
        "/personne/sandy-azzi-hatem": { url: "/annuaire", code:301},
        "/personne/sarah-manoury": { url: "/personne/sarah-manoury-battais", code:301},
        "/personne/selma-djender": { url: "/annuaire", code:301},
        "/personne/severine-cessac": { url: "/annuaire", code:301},
        "/personne/simli-dey": { url: "/annuaire", code:301},
        "/personne/solene-gregoire": { url: "/annuaire", code:301},
        "/personne/sophie-gardrat-pare": { url: "/annuaire", code:301},
        "/personne/sophie-leboucher": { url: "/annuaire", code:301},
        "/personne/sylvia fre": { url: "/annuaire", code:301},
        "/personne/sylvie-glaisner": { url: "/annuaire", code:301},
        "/personne/sylvie-wascheul": { url: "/annuaire", code:301},
        "/personne/thi-lan-anh-nguyen": { url: "/annuaire", code:301},
        "/personne/valerie-royer-garabige": { url: "/annuaire", code:301},
        "/personne/veronique-becette": { url: "/annuaire", code:301},
        "/personne/victoire-gueroult": { url: "/annuaire", code:301},
        "/personne/yann-neuzillet": { url: "/annuaire", code:301},
        "/personne/yao-xu": { url: "/annuaire", code:301},
        "/personne/yu-luan": { url: "/annuaire", code:301},
        "/personne/zahraa-alraies": { url: "/annuaire", code:301},
        "/personne/zhi-qian-wu": { url: "/annuaire", code:301},
        "/pertinence-clinique-des-arn-des-vesicules-extracellulaires-en-tant-que-biomarqueurs-pronostiques": { url: "/", code:301},
        "/pharmacie": { url: "/actualites", code:301},
        "/photo-dequipe-2016-0": { url: "/", code:301},
        "/photos-de-lequipe-yohanns-bellaiche": { url: "/", code:301},
        "/photos-equipe-carsten-janke": { url: "/", code:301},
        "/popin/2022": { url: "/equipes-recherche", code:301},
        "/popin/2022s-team": { url: "/equipes-recherche", code:301},
        "/popin/2024-team": { url: "/equipes-recherche", code:301},
        "/popin/aberrations-du-centromere-et-instabilite-du-genome-causes-et-consequences": { url: "/equipes-recherche", code:301},
        "/popin/academic-collaborators": { url: "/equipes-recherche", code:301},
        "/popin/acces": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/popin/acces-et-tarifs-pole-microscopie-ionique": { url: "/equipes-recherche", code:301},
        "/popin/acces-reservation-des-equipements-et-tarifs-microscopie-photonique": { url: "/equipes-recherche", code:301},
        "/popin/acces-tarifs-microscopie-photonique-pict": { url: "/equipes-recherche", code:301},
        "/popin/agents-photosensibilisants": { url: "/equipes-recherche", code:301},
        "/popin/all-our-publications": { url: "/equipes-recherche", code:301},
        "/popin/alumni-0": { url: "/equipes-recherche", code:301},
        "/popin/alumni-equipe-physicobiologie-aux-mesoechelles": { url: "/equipes-recherche", code:301},
        "/popin/alumni-notch-signaling-stem-cells-and-tumors-team": { url: "/equipes-recherche", code:301},
        "/popin/analyse-de-donnees": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/analyse-de-donnees-single-cell": { url: "/plateforme/initiative-single-cell", code:301},
        "/popin/analyse-des-donnees-single-cell": { url: "/plateforme/initiative-single-cell", code:301},
        "/popin/analyse-des-flux-de-transport": { url: "/equipes-recherche", code:301},
        "/popin/analyse-des-mecanismes-impliques-dans-les-effets-inhibiteurs-de-pd-1": { url: "/equipes-recherche", code:301},
        "/popin/analyse-en-cellule-unique-des-transcriptomes-viraux": { url: "/equipes-recherche", code:301},
        "/popin/analyse-integrative-des-donnees-multiomiques-des-mb": { url: "/equipes-recherche", code:301},
        "/popin/analyse-spatiale-des-tissus-des-donnees-brutes-la-biologie": { url: "/equipes-recherche", code:301},
        "/popin/anciens-membres-de-lequipe": { url: "/equipes-recherche", code:301},
        "/popin/antoine-bergamaschi-2017": { url: "/equipes-recherche", code:301},
        "/popin/applications-translationnelles": { url: "/equipes-recherche", code:301},
        "/popin/auto-organisation-dun-systeme-acto-myosine-minimal-en-faisceaux-de-filaments-polaires": { url: "/equipes-recherche", code:301},
        "/popin/awards-raphael-rodriguez": { url: "/equipes-recherche", code:301},
        "/popin/axe-11-role-de-la-polyadenylation-intronique-dans-la-reponse-aux-dommages-de-ladn-et-aux": { url: "/equipes-recherche", code:301},
        "/popin/barcoding-cellulaire": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/beyond-dna-chromatin-impacts-rna-splicing": { url: "/equipes-recherche", code:301},
        "/popin/biochimie-et-biologie-des-drogues-anti-cancereuses": { url: "/equipes-recherche", code:301},
        "/popin/biogenic-photo-responsive-vectors-nanomedicine": { url: "/equipes-recherche", code:301},
        "/popin/biomarqueurs-circulants": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/biophysique-des-proteines-membranaires-lechelle-de-la-molecule-unique": { url: "/equipes-recherche", code:301},
        "/popin/biosketch-team-luco": { url: "/equipes-recherche", code:301},
        "/popin/blaise-dumat-2012": { url: "/equipes-recherche", code:301},
        "/popin/bookings": { url: "/equipes-recherche", code:301},
        "/popin/brevet-wo2021224186": { url: "/equipes-recherche", code:301},
        "/popin/brevet-wo2022189618": { url: "/equipes-recherche", code:301},
        "/popin/brevet-wo2022233824": { url: "/equipes-recherche", code:301},
        "/popin/brevets": { url: "/equipes-recherche", code:301},
        "/popin/camsaps-organize-acentrosomal-microtubule-network-basal-varicosities-radial-glial-cells": { url: "/equipes-recherche", code:301},
        "/popin/caracterisation-de-la-reponse-lymphocytaire-t-aux-neo-antigenes-dans-trois-types-de-cancer": { url: "/equipe/piaggio", code:301},
        "/popin/cell-fate-decision-map-reveals-abundant-direct-neurogenesis-human-developing-neocortex": { url: "/equipes-recherche", code:301},
        "/popin/cell-fate-transitions-dynamic-signalling-environments": { url: "/equipes-recherche", code:301},
        "/popin/cellular-barcoding": { url: "/equipes-recherche", code:301},
        "/popin/certainty-cellular-immunotherapy-virtual-twin-personalized-cancer-treatment": { url: "/equipes-recherche", code:301},
        "/popin/charles-henri-buffet-2024": { url: "/equipes-recherche", code:301},
        "/popin/charles-henri-buffet-2024-0": { url: "/equipes-recherche", code:301},
        "/popin/chemical-biology-laboratory": { url: "/equipes-recherche", code:301},
        "/popin/chloe-breton-patient-2022": { url: "/equipes-recherche", code:301},
        "/popin/choice-technology": { url: "/equipes-recherche", code:301},
        "/popin/choix-de-la-technologie": { url: "/plateforme/initiative-single-cell", code:301},
        "/popin/chromgenesis": { url: "/equipes-recherche", code:301},
        "/popin/circulating-biomarkers": { url: "/equipes-recherche", code:301},
        "/popin/collaborations": { url: "/equipes-recherche", code:301},
        "/popin/collaborations-0": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/comprendre-la-crete-neurale-une-voie-vers-la-multipotence-et-le-patterning": { url: "/equipe/monsoro-burq", code:301},
        "/popin/conditions-generales-de-la-plateforme-ngs": { url: "/equipes-recherche", code:301},
        "/popin/congratulations-genevieve-receiving-loreal-unesco-women-science-2024-award-europe": { url: "/equipes-recherche", code:301},
        "/popin/contact": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/contact-0": { url: "/equipes-recherche", code:301},
        "/popin/contacts-pict": { url: "/equipes-recherche", code:301},
        "/popin/contributions": { url: "/equipes-recherche", code:301},
        "/popin/coralie-caron-2019": { url: "/equipes-recherche", code:301},
        "/popin/cytometrie-conventionelle": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/cytometrie-en-image": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/cytometrie-spectrale": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/data-analysis": { url: "/equipes-recherche", code:301},
        "/popin/de-la-cellule-unique-lorganisme": { url: "/equipes-recherche", code:301},
        "/popin/decembre-2021": { url: "/equipes-recherche", code:301},
        "/popin/decoding-genetic-circuits-developmental-timing-and-stem-cell-fate": { url: "/equipes-recherche", code:301},
        "/popin/deepanjan-ghosh-2020": { url: "/equipes-recherche", code:301},
        "/popin/design-de-panels": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/design-dexperiences": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/determination-des-parametres-mecaniques-et-physiques-qui-controlent-louverture-des-canaux": { url: "/equipe/martin", code:301},
        "/popin/developing-vitro-models-tumor-tumor-microenvironment-evaluate-and-improve-therapeutic": { url: "/equipes-recherche", code:301},
        "/popin/developpement-et-caracterisation-fonctionnelle-de-molecules-qui-radio-sensibilisent-les": { url: "/equipes-recherche", code:301},
        "/popin/dna-repair": { url: "/equipes-recherche", code:301},
        "/popin/electron-cryo-microscopy-facility-equipment": { url: "/equipes-recherche", code:301},
        "/popin/elodie-morel-2015": { url: "/equipes-recherche", code:301},
        "/popin/elucider-le-role-de-mark2-dans-lactivation-des-cellules-t": { url: "/equipes-recherche", code:301},
        "/popin/en-savoir-plus": { url: "/equipes-recherche", code:301},
        "/popin/en-videos": { url: "/equipes-recherche", code:301},
        "/popin/endosomal-trafficking-defects-cause-microcephaly": { url: "/equipes-recherche", code:301},
        "/popin/equipe-alexandre-baffet": { url: "/equipes-recherche", code:301},
        "/popin/equipe-angela-taddei": { url: "/equipes-recherche", code:301},
        "/popin/equipe-anne-helene-monsoro-burq": { url: "/equipes-recherche", code:301},
        "/popin/equipe-anne-houdusse": { url: "/equipe/houdusse", code:301},
        "/popin/equipe-ayrault": { url: "/equipes-recherche", code:301},
        "/popin/equipe-daniele-fachinetti": { url: "/equipes-recherche", code:301},
        "/popin/equipe-de-stephan-vagner": { url: "/equipes-recherche", code:301},
        "/popin/equipe-enzo-poirier": { url: "/equipes-recherche", code:301},
        "/popin/equipe-fatima-mechta-grigoriou": { url: "/equipes-recherche", code:301},
        "/popin/equipe-londono": { url: "/equipes-recherche", code:301},
        "/popin/equipe-olivier-ayrault": { url: "/equipes-recherche", code:301},
        "/popin/equipe-olivier-delattre": { url: "/equipes-recherche", code:301},
        "/popin/equipe-pascal-martin": { url: "/equipes-recherche", code:301},
        "/popin/equipe-pedro-hernandez": { url: "/equipes-recherche", code:301},
        "/popin/equipe-perie": { url: "/equipes-recherche", code:301},
        "/popin/equipe-pouponnot-1": { url: "/equipes-recherche", code:301},
        "/popin/equipe-renata-basto": { url: "/equipes-recherche", code:301},
        "/popin/equipe-sebastian-amigorena": { url: "/equipes-recherche", code:301},
        "/popin/equipe-thomas-walter": { url: "/equipe/walter", code:301},
        "/popin/equipe-yohanns-bellaiche": { url: "/equipes-recherche", code:301},
        "/popin/equipement-et-reservation": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/popin/equipements-du-lsmp": { url: "/equipes-recherche", code:301},
        "/popin/etude-de-linteraction-entre-le-complexe-dna-pk-et-le-facteur-de-transcription-gli2": { url: "/equipes-recherche", code:301},
        "/popin/etude-du-trafic-intracellulaire-des-molecules-de-signalisation": { url: "/equipes-recherche", code:301},
        "/popin/experimental-design": { url: "/equipes-recherche", code:301},
        "/popin/fabien-hammerer-2012": { url: "/equipes-recherche", code:301},
        "/popin/formations-du-lsmp": { url: "/equipes-recherche", code:301},
        "/popin/gouvernance": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/popin/heterogeneite-des-cellules-tumorales": { url: "/equipe/mechta-grigoriou", code:301},
        "/popin/heterogeneite-et-plasticite-du-microenvironnement-tumoral": { url: "/equipe/mechta-grigoriou", code:301},
        "/popin/heterogeneite-spatiale-des-gliomes-de-haut-grades": { url: "/equipes-recherche", code:301},
        "/popin/horizon-2020-innovative-training-network-neucrest-1": { url: "/equipes-recherche", code:301},
        "/popin/human-fetal-brain": { url: "/equipes-recherche", code:301},
        "/popin/impact-tubulin-code-lifelong-neuronal-function-and-homeostasis": { url: "/equipes-recherche", code:301},
        "/popin/indrop-rna-seq": { url: "/equipes-recherche", code:301},
        "/popin/isolation-de-cellules-uniques": { url: "/plateforme/initiative-single-cell", code:301},
        "/popin/jaime-franco-pinto-2021": { url: "/equipes-recherche", code:301},
        "/popin/joel-lefebvre-2017": { url: "/equipes-recherche", code:301},
        "/popin/join-us": { url: "/equipes-recherche", code:301},
        "/popin/juillet-2021": { url: "/equipes-recherche", code:301},
        "/popin/juin-2019": { url: "/equipes-recherche", code:301},
        "/popin/julie-le-bescont-2020": { url: "/equipes-recherche", code:301},
        "/popin/la-reponse-mecanique-des-cellules-myeloides-0": { url: "/equipes-recherche", code:301},
        "/popin/lab-crest": { url: "/equipes-recherche", code:301},
        "/popin/lab-escape-2023": { url: "/equipes-recherche", code:301},
        "/popin/lab-retraite-2023": { url: "/equipes-recherche", code:301},
        "/popin/laboratoire-dimagerie-translationnelle-en-oncologie-u1288": { url: "/equipes-recherche", code:301},
        "/popin/le-paradoxe-de-la-polyploidie-0": { url: "/equipe/basto", code:301},
        "/popin/le-projet-de-la-radiotherapie": { url: "/equipes-recherche", code:301},
        "/popin/le-travail-de-vasco-en-une-de-jcs": { url: "/equipes-recherche", code:301},
        "/popin/les-alterations-du-nombre-de-centrosomes-dans-le-cancer": { url: "/equipe/basto", code:301},
        "/popin/les-cellules-myeloides-comme-sentinelles-intestinales": { url: "/equipes-recherche", code:301},
        "/popin/les-tumeurs-cerebrales-embryonnaires-avec-alteration-du-gene-bcor-types-cellulaires-et-genes": { url: "/equipes-recherche", code:301},
        "/popin/liens-de-lequipe-piel": { url: "/equipes-recherche", code:301},
        "/popin/linda-silina-2020": { url: "/equipes-recherche", code:301},
        "/popin/macrophages-au-sein-des-tumeurs-solides": { url: "/equipes-recherche", code:301},
        "/popin/macrophages-et-virus-une-relation-complexe-et-riche-denseignements": { url: "/equipes-recherche", code:301},
        "/popin/magnetic-tissue-bioengineering-0": { url: "/equipes-recherche", code:301},
        "/popin/magnetic-tissue-stretcher": { url: "/equipes-recherche", code:301},
        "/popin/maitre-lab-picture": { url: "/equipes-recherche", code:301},
        "/popin/marie-auvray-2021": { url: "/equipes-recherche", code:301},
        "/popin/materiel-de-depart": { url: "/plateforme/initiative-single-cell", code:301},
        "/popin/materiels": { url: "/equipes-recherche", code:301},
        "/popin/matrice-extracellulaire-et-migration-des-cellules-immunitaires-0": { url: "/equipes-recherche", code:301},
        "/popin/mecanismes-de-regeneration-cardiaque": { url: "/equipes-recherche", code:301},
        "/popin/meet-team": { url: "/equipes-recherche", code:301},
        "/popin/microfluidic-based-bioanalysis": { url: "/equipes-recherche", code:301},
        "/popin/microfluidique": { url: "/equipes-recherche", code:301},
        "/popin/microscopie-photonique": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/popin/mission-pour-les-initiatives-transverses-et-interdisciplinaires": { url: "/equipes-recherche", code:301},
        "/popin/modelisation-tumorale-par-systeme-microfluidique-en-3d-les-tumeurs-chip": { url: "/equipes-recherche", code:301},
        "/popin/modphosphonet": { url: "/equipes-recherche", code:301},
        "/popin/modphosphonet-0": { url: "/equipes-recherche", code:301},
        "/popin/morphogenese-et-fonction-du-fuseau-mitotique-0": { url: "/equipes-recherche", code:301},
        "/popin/myochip-project": { url: "/equipes-recherche", code:301},
        "/popin/nano-therapies": { url: "/equipes-recherche", code:301},
        "/popin/nanobiomag": { url: "/equipes-recherche", code:301},
        "/popin/nanobiomag-0": { url: "/equipes-recherche", code:301},
        "/popin/nanobodies": { url: "/equipes-recherche", code:301},
        "/popin/nanoparticles-fate-and-transport-extracellular-vesicles": { url: "/equipes-recherche", code:301},
        "/popin/neocortical-organoids": { url: "/equipes-recherche", code:301},
        "/popin/neural-stem-cell-tropism-zika-virus": { url: "/equipes-recherche", code:301},
        "/popin/new-doctor": { url: "/equipes-recherche", code:301},
        "/popin/new-results-histone-chaperone-asf1": { url: "/equipes-recherche", code:301},
        "/popin/new-team-members": { url: "/equipes-recherche", code:301},
        "/popin/news-atitheb-won-hfsp-long-term-postdoctoral-fellowship": { url: "/equipes-recherche", code:301},
        "/popin/news-team-luco": { url: "/equipes-recherche", code:301},
        "/popin/newsletter": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/popin/nos-brevets": { url: "/equipes-recherche", code:301},
        "/popin/nos-equipements-et-reservation": { url: "/equipes-recherche", code:301},
        "/popin/nos-poles-dexpertise": { url: "/equipes-recherche", code:301},
        "/popin/notre-plateforme": { url: "/equipes-recherche", code:301},
        "/popin/offers": { url: "/equipes-recherche", code:301},
        "/popin/offres": { url: "/equipes-recherche", code:301},
        "/popin/oksana-reznichenko-2021": { url: "/equipes-recherche", code:301},
        "/popin/omiques-spatiales": { url: "/plateforme/initiative-single-cell", code:301},
        "/popin/omiques-spatiales-0": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/oncologics": { url: "/equipes-recherche", code:301},
        "/popin/oncologics-0": { url: "/equipes-recherche", code:301},
        "/popin/online-now-everything-you-want-know-about-h3-variants": { url: "/equipes-recherche", code:301},
        "/popin/organisation-du-noyau-en-quiescence": { url: "/equipes-recherche", code:301},
        "/popin/panel-design-0": { url: "/equipes-recherche", code:301},
        "/popin/pannet-predictive-models-therapy-response-pancreatic-neuroendocrine-tumors": { url: "/equipes-recherche", code:301},
        "/popin/partenaires-academiques-1": { url: "/equipes-recherche", code:301},
        "/popin/patterning-cancer-letrange-voyage-autour-dun-regulateur-du-metabolisme": { url: "/equipes-recherche", code:301},
        "/popin/patterning-multipotency-migration-cellulaire-cancer": { url: "/equipes-recherche", code:301},
        "/popin/photos-de-lequipe-jean-leon-maitre": { url: "/equipes-recherche", code:301},
        "/popin/photos-equipe-taddei-2022": { url: "/equipes-recherche", code:301},
        "/popin/photos-laboratoire": { url: "/equipes-recherche", code:301},
        "/popin/plateforme-de-cytometrie-cytpic": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/pole-analyse-et-traitement-dimages": { url: "/equipes-recherche", code:301},
        "/popin/pole-cryo-microscopie-electronique": { url: "/equipes-recherche", code:301},
        "/popin/pole-microscopie-ionique": { url: "/equipes-recherche", code:301},
        "/popin/pole-microscopie-photonique": { url: "/equipes-recherche", code:301},
        "/popin/politique-scientifique-et-qualite-de-la-plateforme-ngs": { url: "/equipes-recherche", code:301},
        "/popin/postdoc-position": { url: "/equipes-recherche", code:301},
        "/popin/postdoctoral-position-developmental-genetics-and-physiology": { url: "/equipes-recherche", code:301},
        "/popin/postdoctoral-position-molecular-cellular-genetics": { url: "/equipes-recherche", code:301},
        "/popin/postes-pourvoir": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-la-plateforme-chimiotheque": { url: "/plateforme/curiecoretech-chimiotheque", code:301},
        "/popin/presentation-de-la-plateforme-crisprit": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-la-plateforme-custom-single-cell-omics": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-la-plateforme-de-proteines-recombinantes": { url: "/plateforme/curiecoretech-proteines-recombinantes", code:301},
        "/popin/presentation-de-la-plateforme-dimagerie-cellulaire-et-tissulaire-pict": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/popin/presentation-de-la-plateforme-genomique": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-la-plateforme-histologie": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-la-plateforme-radexp": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-la-plateforme-tabip": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lequipe-de-matthieu-piel": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lequipe-macromolecules-et-microsystemes-en-biologie-et-en-medecine-mmbm": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lu932": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lumr168": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lumr3215-u934": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lumr3244": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lumr3347-u1021": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lumr3348": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lumr3664": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lumr3666-u1143": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lumr9187-u1196": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lunite-u830": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-lunite-u900": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-notre-plateforme-biophenics": { url: "/equipes-recherche", code:301},
        "/popin/presentation-de-notre-plateforme-sequencage-adn-haut-debit": { url: "/equipes-recherche", code:301},
        "/popin/presentation-du-groupe-thierry-dubois": { url: "/equipes-recherche", code:301},
        "/popin/presentation-equipe-celine-vallot": { url: "/equipes-recherche", code:301},
        "/popin/presentation-equipe-emmanuel-farge": { url: "/equipes-recherche", code:301},
        "/popin/presentation-equipe-florence-mahuteau-betzer": { url: "/equipes-recherche", code:301},
        "/popin/presentation-equipe-ines-anna-drinnenberg": { url: "/equipes-recherche", code:301},
        "/popin/presentation-equipe-silberzanbuguin": { url: "/equipes-recherche", code:301},
        "/popin/presentation-groupe": { url: "/equipes-recherche", code:301},
        "/popin/presentation-groupe-melanome-uveal": { url: "/equipes-recherche", code:301},
        "/popin/presentation-pole-microscopie-photonique": { url: "/equipes-recherche", code:301},
        "/popin/presentation-team-wolfgang-keil": { url: "/equipes-recherche", code:301},
        "/popin/prestations": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/project-single-cell": { url: "/equipes-recherche", code:301},
        "/popin/projets-de-lequipe-benaroch": { url: "/equipes-recherche", code:301},
        "/popin/protocoles-methodes-et-ressources-de-lequipe-piel": { url: "/equipes-recherche", code:301},
        "/popin/publications-de-lequipe": { url: "/equipes-recherche", code:301},
        "/popin/qualite": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/quality": { url: "/equipes-recherche", code:301},
        "/popin/rab6-and-dynein-transport-prevents-neuronal-progenitor-delamination": { url: "/equipes-recherche", code:301},
        "/popin/regulation-et-fonction-du-facteur-de-transcription-atoh1": { url: "/equipes-recherche", code:301},
        "/popin/regulation-spatiale-de-lexpression-des-genes": { url: "/equipes-recherche", code:301},
        "/popin/reparation-de-ladn": { url: "/equipes-recherche", code:301},
        "/popin/reseau-europeen-itn-neucrest": { url: "/equipes-recherche", code:301},
        "/popin/reservations": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/retraite-de-labo-en-savoie": { url: "/equipes-recherche", code:301},
        "/popin/retraite-scientifique-2022": { url: "/equipes-recherche", code:301},
        "/popin/role-physiologique-de-rab6-laide-de-modeles-murins": { url: "/equipes-recherche", code:301},
        "/popin/rt-superes-imaging-rejoignez-nous": { url: "/equipes-recherche", code:301},
        "/popin/samar-ali-2015": { url: "/equipes-recherche", code:301},
        "/popin/sauvegarde-des-donnees": { url: "/equipes-recherche", code:301},
        "/popin/scanner-de-lames": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/scchip-seq": { url: "/equipes-recherche", code:301},
        "/popin/sciences-humaines-et-sociales-and-outcome-research": { url: "/equipes-recherche", code:301},
        "/popin/seminaires-et-congres-organises": { url: "/equipes-recherche", code:301},
        "/popin/sequencage-arn-en-single-cell": { url: "/plateforme/initiative-single-cell", code:301},
        "/popin/services": { url: "/equipes-recherche", code:301},
        "/popin/shaping-and-controlling-organ-chips-models": { url: "/equipes-recherche", code:301},
        "/popin/single-cell-en-plaques": { url: "/equipes-recherche", code:301},
        "/popin/single-cell-epigenomique": { url: "/plateforme/initiative-single-cell", code:301},
        "/popin/single-cell-genomique": { url: "/plateforme/initiative-single-cell", code:301},
        "/popin/single-cell-phenotyping": { url: "/plateforme/initiative-single-cell", code:301},
        "/popin/slide-scan": { url: "/equipes-recherche", code:301},
        "/popin/smart-seq3": { url: "/equipes-recherche", code:301},
        "/popin/sondes-ciblant-les-acides-nucleiques-g-quadruplexes": { url: "/equipes-recherche", code:301},
        "/popin/sondes-fluorescentes-pour-le-marquage-dadn": { url: "/equipes-recherche", code:301},
        "/popin/spatial-omic": { url: "/equipes-recherche", code:301},
        "/popin/stephanie-lemaitre-2017": { url: "/equipes-recherche", code:301},
        "/popin/structure-et-objectifs": { url: "/equipes-recherche", code:301},
        "/popin/su-chen-2016": { url: "/equipes-recherche", code:301},
        "/popin/tarif-pole-analyse-et-traitement-dimages": { url: "/equipes-recherche", code:301},
        "/popin/tarifs-pole-cryo-microscopie-electronique": { url: "/equipes-recherche", code:301},
        "/popin/thibaut-masson-2021": { url: "/equipes-recherche", code:301},
        "/popin/thibaut-masson-2021-0": { url: "/equipes-recherche", code:301},
        "/popin/tom-baladi-2016": { url: "/equipes-recherche", code:301},
        "/popin/traitement-dimages": { url: "/plateforme/curiecoretech-imagerie-cellulaire-et-tissulaire-pict", code:301},
        "/popin/translational-research-pediatric-oncology-rtop": { url: "/equipes-recherche", code:301},
        "/popin/travaux-de-lequipe-0": { url: "/equipes-recherche", code:301},
        "/popin/tri-cellulaire": { url: "/plateforme/curiecoretech-cytometrie-cytpic", code:301},
        "/popin/un-service-integre": { url: "/equipes-recherche", code:301},
        "/popin/une-culture-de-transfert-dinformations-et-dexpertises-entre-la-recherche-et-le-soin": { url: "/equipes-recherche", code:301},
        "/popin/unit-retraite": { url: "/equipes-recherche", code:301},
        "/popin/variants-de-la-cytokine-il-2-genetiquement-modifiee-avec-des-fonctions-selectives": { url: "/equipes-recherche", code:301},
        "/popin/vulgarisation-perception-des-sons-par-loreille": { url: "/equipes-recherche", code:301},
        "/popin/xiao-xie-2015": { url: "/equipes-recherche", code:301},
        "/popin/yu-luo-2023": { url: "/equipes-recherche", code:301},
        "/popin/yu-luo-2023-0": { url: "/equipes-recherche", code:301},
        "/presentation-du-lsmp": { url: "/", code:301},
        "/presentation-equipe-allison-bardin": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-anton-granzhan": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-aura-carreira": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-ballesta": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-carsten-janke": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-chunlong-chen": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-clotilde-thery": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-helene-salmon": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-locco": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-mounira-amor-gueret": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-nadine-andrieu": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-phong-tran": { url: "/equipes-recherche", code:301},
        "/presentation-equipe-seano": { url: "/equipes-recherche", code:301},
        "/presentation-pole-microscopie-ionique": { url: "/equipes-recherche", code:301},
        "/presentation-team-raphael-ceccaldi": { url: "/equipes-recherche", code:301},
        "/prestations-du-lsmp": { url: "/", code:301},
        "/prix-et-disctinctions": { url: "/", code:301},
        "/profil/chercheurs": { url: "/", code:301},
        "/profil/chercheurs?profile=3": { url: "/", code:301},
        "/profil/chercheurs?profile=4": { url: "/", code:301},
        "/profil/etudiants": { url: "/", code:301},
        "/profil/grand-public-et-donateurs": { url: "/", code:301},
        "/profil/patients-et-proches": { url: "/", code:301},
        "/profil/professionnels-de-sante": { url: "/professionnels-de-sante-rejoignez-linstitut-curie", code:301},
        "/programmes-et-partenariats": { url: "/", code:301},
        "/projets-donnees": { url: "/plateforme/curiecoretech-genomique", code:301},
        "/publications/1531p-multi-omic-characterization-dedifferentiated-liposarcoma-towards-new-model": { url: "/publications", code:301},
        "/publications/18f-fdg-pet-dissemination-features-diffuse-large-b-cell-lymphoma-are-predictive": { url: "/publications", code:301},
        "/publications/18f-fdg-pet-maximum-intensity-projections-and-artificial-intelligence-win-win": { url: "/publications", code:301},
        "/publications/18ffdg-petct-deep-learning-method-fully-automated-detection-pathological-mediastinal": { url: "/publications", code:301},
        "/publications/2d-map-projections-visualization-and-quantitative-analysis-3d-fluorescence-micrographs": { url: "/publications", code:301},
        "/publications/3d-bioprinted-vascularized-glioblastoma-chip-studying-impact-simulated-microgravity": { url: "/publications", code:301},
        "/publications/3d-magnetic-tissue-stretcher-remote-mechanical-control-embryonic-stem-cell": { url: "/publications", code:301},
        "/publications/3d-organization-synthetic-and-scrambled-chromosomes": { url: "/publications", code:301},
        "/publications/53bp1-interacts-rna-primer-okazaki-fragments-support-their-processing-during": { url: "/publications", code:301},
        "/publications/aberrant-erbb4-src-signaling-hallmark-group-4-medulloblastoma-revealed-integrative": { url: "/publications", code:301},
        "/publications/absence-chromosome-axis-protein-recruitment-prevents-meiotic-recombination-chromosome": { url: "/publications", code:301},
        "/publications/absolute-quantification-drug-vector-delivery-cytosol-0": { url: "/publications", code:301},
        "/publications/abstract-3390-predictive-and-prognostic-value-circulating-tumor-dna-ctdna-compared": { url: "/publications", code:301},
        "/publications/accumulation-lncrnas-cytoplasm-upon-dis3-depletion-leads-production-cryptic-peptides": { url: "/publications", code:301},
        "/publications/achieving-high-mass-resolution-nanosims-50-while-preserving-signal-transmission": { url: "/publications", code:301},
        "/publications/acridine-o6-benzylguanine-hybrids-synthesis-dna-binding-mgmt-inhibition-and": { url: "/publications", code:301},
        "/publications/actin-dynamics-drive-membrane-reorganization-and-scission-clathrin-independent": { url: "/publications", code:301},
        "/publications/activated-i-bar-irsp53-clustering-controls-formation-vasp-actin-based-membrane-0": { url: "/publications", code:301},
        "/publications/active-t1-transitions-cellular-networks": { url: "/publications", code:301},
        "/publications/active-wetting-epithelial-tissues": { url: "/publications", code:301},
        "/publications/acto-myosin-network-geometry-defines-centrosome-position": { url: "/publications", code:301},
        "/publications/additional-layer-regulation-convergent-gene-orientation-yeasts": { url: "/publications", code:301},
        "/publications/adherence-french-and-esgo-quality-indicators-ovarian-cancer-surgery-ad-hoc-analysis": { url: "/publications", code:301},
        "/publications/adhesion-functions-cell-sorting-mechanically-coupling-cortices-adhering-cells": { url: "/publications", code:301},
        "/publications/adipocyte-extracellular-vesicles-decrease-p16ink4a-melanoma-additional-link-between": { url: "/publications", code:301},
        "/publications/alcohol-consumption-cigarette-smoking-and-risk-breast-cancer-brca1-and-brca2-mutation": { url: "/publications", code:301},
        "/publications/altering-temporal-regulation-one-transcription-factor-drives-evolutionary-trade-offs": { url: "/publications", code:301},
        "/publications/alternative-d-melanogaster-7sk-snrnp": { url: "/publications", code:301},
        "/publications/amuse-bouche-stem-cell-regulation-underlying-principles-and-mechanisms-adult": { url: "/publications", code:301},
        "/publications/amyloid-hfq-interaction-single-stranded-dna-involvement-recombination-and-replication": { url: "/publications", code:301},
        "/publications/analysis-recombination-dependent-processing-blocked-replication-forks-bidimensional": { url: "/publications", code:301},
        "/publications/androgen-signaling-regulates-sars-cov-2-receptor-levels-and-associated-severe-covid-19": { url: "/publications", code:301},
        "/publications/angiopoietin-2-marker-endothelial-activation-good-predictor-factor-intensive-care-unit": { url: "/publications", code:301},
        "/publications/anti-angiogenic-therapy-albumin-binding-proteins-could-mediate-mechanisms-underlying": { url: "/publications", code:301},
        "/publications/anti-cancer-and-radio-sensitizing-properties-new-bimetallic-n-heterocyclic-carbene": { url: "/publications", code:301},
        "/publications/anti-inflammatory-activity-superoxide-dismutase-mimics-functionalized-cell-penetrating": { url: "/publications", code:301},
        "/publications/anti-telomerase-cd4-th1-immunity-and-monocytic-myeloid-derived-suppressor-cells-are": { url: "/publications", code:301},
        "/publications/apical-size-and-deltaa-expression-predict-adult-neural-stem-cell-decisions-along": { url: "/publications", code:301},
        "/publications/apical-stress-fibers-enable-scaling-between-cell-mechanical-response-and-area": { url: "/publications", code:301},
        "/publications/apkci-triggers-basal-extrusion-luminal-mammary-epithelial-cells-tuning-contractility": { url: "/publications", code:301},
        "/publications/armand-tavitian-1931-2020": { url: "/publications", code:301},
        "/publications/asidna-treatment-induces-cumulative-antitumor-efficacy-low-probability-acquired": { url: "/publications", code:301},
        "/publications/asidnatm-decoy-mimicking-dsbs-protects-normal-tissue-radiation-toxicity-through-dna": { url: "/publications", code:301},
        "/publications/assessing-personalized-responses-anti-pd-1-treatment-using-patient-derived-lung-tumor": { url: "/publications", code:301},
        "/publications/assessment-psychosocial-difficulties-genetic-clinicians-and-distress-women-high-risk": { url: "/publications", code:301},
        "/publications/associations-between-sex-age-and-spiritual-well-being-scores-eortc-qlq-swb32-patients": { url: "/publications", code:301},
        "/publications/asta-signaling-functions-evolutionary-conserved-mechanism-timing-juvenile-adult": { url: "/publications", code:301},
        "/publications/asymmetric-division-contractile-domains-couples-cell-positioning-and-fate": { url: "/publications", code:301},
        "/publications/atoh1-controls-primary-cilia-formation-allow-shh-triggered-granule-neuron-progenitor": { url: "/publications", code:301},
        "/publications/atypical-teratoidrhabdoid-tumors-atrts-smarca4-mutation-are-molecularly-distinct": { url: "/publications", code:301},
        "/publications/autoantibodies-neutralizing-type-i-ifns-are-present-4-uninfected-individuals-over-70": { url: "/publications", code:301},
        "/publications/autophagy-polarized-toward-cell-front-during-migration-and-spatially-perturbed": { url: "/publications", code:301},
        "/publications/balanced-pyrimidine-pool-required-optimal-chk1-activation-prevent-ultrafine-anaphase": { url: "/publications", code:301},
        "/publications/bases-antisense-lncrna-associated-regulation-gene-expression-fission-yeast": { url: "/publications", code:301},
        "/publications/bet-and-cdk-inhibition-reveal-differences-proliferation-control-sympathetic-ganglion": { url: "/publications", code:301},
        "/publications/bin1m-amphiphysin2-induces-clustering-phosphoinositides-recruit-its-downstream-partner": { url: "/publications", code:301},
        "/publications/biological-effects-scattered-versus-scanned-proton-beams-normal-tissues-total-body": { url: "/publications", code:301},
        "/publications/biosynthesis-magnetic-nanoparticles-nano-degradation-products-revealed-human-stem": { url: "/publications", code:301},
        "/publications/bipartite-tad-organization-x-inactivation-center-ensures-opposing-developmental": { url: "/publications", code:301},
        "/publications/bloc-1-brings-together-actin-and-microtubule-cytoskeletons-generate-recycling": { url: "/publications", code:301},
        "/publications/bml-265-and-tyrphostin-ag1478-disperse-golgi-apparatus-and-abolish-protein-transport": { url: "/publications", code:301},
        "/publications/bmp-signaling-enhanced-intracellularly-fhl3-controlling-wnt-dependent-spatiotemporal": { url: "/publications", code:301},
        "/publications/bottom-vitro-methods-assay-ultrastructural-organization-membrane-reshaping-and": { url: "/publications", code:301},
        "/publications/brca2-promotes-dna-rna-hybrid-resolution-ddx5-helicase-dna-breaks-facilitate-their": { url: "/publications", code:301},
        "/publications/brcaness-slfn11-and-rb1-loss-predict-response-topoisomerase-i-inhibitors-triple": { url: "/publications", code:301},
        "/publications/brdu-immuno-tagged-g-quadruplex-ligands-new-ligand-guided-immunofluorescence-approach": { url: "/publications", code:301},
        "/publications/breaking-photoswitch-activation-depth-limit-using-ionising-radiation-stimuli-adapted": { url: "/publications", code:301},
        "/publications/brief-history-nearly-ev-erything-rise-and-rise-extracellular-vesicles": { url: "/publications", code:301},
        "/publications/brn2-suppresses-apoptosis-reprograms-dna-damage-repair-and-associated-high-somatic": { url: "/publications", code:301},
        "/publications/bystander-ifn-g-activity-promotes-widespread-and-sustained-cytokine-signaling-altering": { url: "/publications", code:301},
        "/publications/c2c12-myoblastosteoblast-transdifferentiation-steps-enhanced-epigenetic-inhibition": { url: "/publications", code:301},
        "/publications/c57bl6-congenic-mouse-nras-q61k-melanoma-cell-lines-are-highly-sensitive-combination": { url: "/publications", code:301},
        "/publications/camsaps-organize-acentrosomal-microtubule-network-basal-varicosities-radial-glial": { url: "/publications", code:301},
        "/publications/cancer-associated-fibroblast-heterogeneity-axillary-lymph-nodes-drives-metastases": { url: "/publications", code:301},
        "/publications/cancer-associated-fibroblasts-actively-compress-cancer-cells-and-modulate": { url: "/publications", code:301},
        "/publications/cancer-associated-fibroblasts-induce-metalloprotease-independent-cancer-cell-invasion": { url: "/publications", code:301},
        "/publications/cancer-associated-sf3b1-mutations-affect-alternative-splicing-promoting-alternative": { url: "/publications", code:301},
        "/publications/cancer-epidermoide-du-canal-anal-metastatique-en-2020": { url: "/publications", code:301},
        "/publications/cancer-genome-atlas-comprehensive-molecular-characterization-renal-cell-carcinoma": { url: "/publications", code:301},
        "/publications/capecitabine-efficacy-correlated-tymp-and-rb1-expression-pdx-established-triple": { url: "/publications", code:301},
        "/publications/capsids-hiv-1-and-hiv-2-determine-immune-detection-viral-cdna-innate-sensor-cgas": { url: "/publications", code:301},
        "/publications/car-t-cells-and-bites-solid-tumors-challenges-and-perspectives": { url: "/publications", code:301},
        "/publications/cardiotoxicity-associated-radiotherapy-breast-cancer-question-based-review-current": { url: "/publications", code:301},
        "/publications/carfilzomib-weekly-plus-melphalan-and-prednisone-newly-diagnosed-transplant-ineligible": { url: "/publications", code:301},
        "/publications/case-only-study-identify-genetic-modifiers-breast-cancer-risk-brca1brca2-mutation": { url: "/publications", code:301},
        "/publications/catabolism-lysosome-related-organelles-color-changing-spiders-supports-intracellular": { url: "/publications", code:301},
        "/publications/caveolae-dress-code-structure-and-signaling": { url: "/publications", code:301},
        "/publications/caveolin-1-protects-endothelial-cells-extensive-expansion-transcellular-tunnel": { url: "/publications", code:301},
        "/publications/caveolin-1-protects-endothelial-cells-extensive-expansion-transcellular-tunnel-0": { url: "/publications", code:301},
        "/publications/ccr5-adopts-three-homodimeric-conformations-control-cell-surface-delivery": { url: "/publications", code:301},
        "/publications/cd73-mediated-immunosuppression-linked-specific-fibroblast-population-paves-way-new": { url: "/publications", code:301},
        "/publications/cdkn1a-regulates-langerhans-cell-survival-and-promotes-treg-cell-generation-upon": { url: "/publications", code:301},
        "/publications/celecoxib-neoadjuvant-chemotherapy-breast-cancer-might-worsen-outcomes-differentially": { url: "/publications", code:301},
        "/publications/cell-clusters-adopt-collective-amoeboid-mode-migration-confined-nonadhesive": { url: "/publications", code:301},
        "/publications/cell-competition-modifies-adult-stem-cell-and-tissue-population-dynamics-jak-stat": { url: "/publications", code:301},
        "/publications/cell-density-sensing-alters-tgf-b-signaling-cell-type-specific-manner-independent": { url: "/publications", code:301},
        "/publications/cell-fate-decision-map-reveals-abundant-direct-neurogenesis-bypassing-intermediate": { url: "/publications", code:301},
        "/publications/cell-fragmentation-mouse-preimplantation-embryos-induced-ectopic-activation-polar-0": { url: "/publications", code:301},
        "/publications/cell-fragmentation-mouse-preimplantation-embryos-induced-ectopic-activation-polar-body": { url: "/publications", code:301},
        "/publications/cell-patient-machine-learning-transfer-approach-uncovers-novel-basal-breast-cancer": { url: "/publications", code:301},
        "/publications/cell-size-scaling-laws-unified-theory": { url: "/publications", code:301},
        "/publications/cellular-blebs-and-membrane-invaginations-are-coupled-through-membrane-tension": { url: "/publications", code:301},
        "/publications/cellular-detection-mitochondria-targeted-brominated-vinyl-triphenylamine-optical-probe": { url: "/publications", code:301},
        "/publications/cenp-dispensable-mitotic-centromere-function-after-initial-centromerekinetochore": { url: "/publications", code:301},
        "/publications/cenp-subnuclear-localization-pattern-marker-predicting-curability-chemoradiation": { url: "/publications", code:301},
        "/publications/centrosome-amplification-causes-microcephaly": { url: "/publications", code:301},
        "/publications/centrosome-amplification-disrupts-renal-development-and-causes-cystogenesis": { url: "/publications", code:301},
        "/publications/centrosome-nuclear-axis-repositioning-drives-assembly-bipolar-spindle-scaffold-ensure": { url: "/publications", code:301},
        "/publications/chikungunya-virus-superinfection-exclusion-mediated-block-viral-replication-and-does": { url: "/publications", code:301},
        "/publications/chimiotheque-nationale": { url: "/publications", code:301},
        "/publications/choreography-centrosomal-mrnas-reveals-conserved-localization-mechanism-involving": { url: "/publications", code:301},
        "/publications/chromatin-dynamics-after-dna-damage-legacy-access-repair-restore-model": { url: "/publications", code:301},
        "/publications/chromatin-state-transitions-drosophila-intestinal-lineage-identify-principles-cell": { url: "/publications", code:301},
        "/publications/chromosome-segregation-fidelity-requires-microtubule-polyglutamylation-cancer": { url: "/publications", code:301},
        "/publications/chromosome-structural-anomalies-due-aberrant-spindle-forces-exerted-gene-editing-sites": { url: "/publications", code:301},
        "/publications/cigarette-smoke-induced-extracellular-vesicles-dendritic-cells-alter-t-cell-activation": { url: "/publications", code:301},
        "/publications/circular-rna-profiling-distinguishes-medulloblastoma-groups-and-shows-aberrant-rmst": { url: "/publications", code:301},
        "/publications/circulating-tumor-dna-prognostic-factor-patients-resectable-hepatic-metastases-uveal": { url: "/publications", code:301},
        "/publications/clec12b-decreases-melanoma-proliferation-repressing-signal-transducer-and-activator": { url: "/publications", code:301},
        "/publications/clinical-characteristics-and-outcomes-children-wagr-syndrome-and-wilms-tumor-andor": { url: "/publications", code:301},
        "/publications/clinical-interest-combining-transcriptomic-and-genomic-signatures-high-grade-serous": { url: "/publications", code:301},
        "/publications/clinical-management-atypical-ductal-hyperplasia-vacuum-assisted-biopsy": { url: "/publications", code:301},
        "/publications/clinical-transfer-aguixr-based-radiation-treatments-locally-advanced-cervical-cancer": { url: "/publications", code:301},
        "/publications/clinicopathologic-characteristics-endometrial-cancer-lynch-syndrome": { url: "/publications", code:301},
        "/publications/clonal-analysis-notch1-expressing-cells-reveals-existence-unipotent-stem-cells-retain": { url: "/publications", code:301},
        "/publications/clonesig-can-jointly-infer-intra-tumor-heterogeneity-and-mutational-signature-activity": { url: "/publications", code:301},
        "/publications/collective-stresses-drive-competition-between-monolayers-normal-and-ras-transformed": { url: "/publications", code:301},
        "/publications/comment-ibrahim-et-al-effects-plane-spatial-resolution-ct-based-radiomic-features": { url: "/publications", code:301},
        "/publications/common-and-unique-transcription-signatures-yap-and-taz-gastric-cancer-cells": { url: "/publications", code:301},
        "/publications/common-gene-variants-within-3-untranslated-regions-modulators-multiple-myeloma-risk": { url: "/publications", code:301},
        "/publications/common-transcriptomic-program-acquired-thymus-defines-tissue-residency-mait-and-nkt": { url: "/publications", code:301},
        "/publications/community-partnership-respond-heroin-and-opioid-abuse-epidemic": { url: "/publications", code:301},
        "/publications/comparative-analysis-durable-responses-immune-checkpoint-inhibitors-versus-other": { url: "/publications", code:301},
        "/publications/comparing-phylogenetic-approaches-reconstructing-cell-lineage-microsatellites-missing": { url: "/publications", code:301},
        "/publications/comprehensive-analysis-relationship-between-dose-rate-and-biological-effects": { url: "/publications", code:301},
        "/publications/comprehensive-evaluation-normalization-methods-illumina-high-throughput-rna-sequencing": { url: "/publications", code:301},
        "/publications/compromised-nuclear-envelope-integrity-drives-trex1-dependent-dna-damage-and-tumor": { url: "/publications", code:301},
        "/publications/computational-framework-study-sub-cellular-rna-localization": { url: "/publications", code:301},
        "/publications/concluding-remarks-peptide-membrane-interactions": { url: "/publications", code:301},
        "/publications/conservation-and-topology-protein-interaction-networks-under-duplication-divergence": { url: "/publications", code:301},
        "/publications/conservation-antiviral-systems-across-domains-life-reveals-novel-immune-mechanisms": { url: "/publications", code:301},
        "/publications/conserved-noncoding-locus-regulates-random-monoallelic-xist-expression-across": { url: "/publications", code:301},
        "/publications/constitutive-siglec-1-expression-confers-susceptibility-hiv-1-infection-human": { url: "/publications", code:301},
        "/publications/continuous-chemical-operations-and-modifications-magnetic-g-fe2o3-nanoparticles": { url: "/publications", code:301},
        "/publications/continuum-model-magnetic-particle-flows-microfluidics-applicable-dilute-packed": { url: "/publications", code:301},
        "/publications/contraception-breast-cancer-survivors-feeric-case-control-study-performed-behalf": { url: "/publications", code:301},
        "/publications/contractile-dynamics-change-morphological-cues-during-fluorescence-illumination": { url: "/publications", code:301},
        "/publications/contribution-resident-and-circulating-precursors-tumor-infiltrating-cd8-t-cell": { url: "/publications", code:301},
        "/publications/control-src-molecular-dynamics-encodes-distinct-cytoskeletal-responses-specifying": { url: "/publications", code:301},
        "/publications/converging-proton-minibeams-magnetic-fields-optimized-radiation-therapy-proof-concept": { url: "/publications", code:301},
        "/publications/conversion-3-bromo-2h-coumarins-3-benzofuran-2-yl-2h-coumarins-under-palladium": { url: "/publications", code:301},
        "/publications/cooperative-pro-tumorigenic-adaptation-oncogenic-ras-through-epithelial-mesenchymal": { url: "/publications", code:301},
        "/publications/copper-alkyne-complexation-responsible-nucleolar-localization-quadruplex-nucleic-acid": { url: "/publications", code:301},
        "/publications/correction-developing-advanced-gut-chip-model-enabling-study-epithelial-cellfibroblast": { url: "/publications", code:301},
        "/publications/correction-metformin-reveals-mitochondrial-copper-addiction-mesenchymal-cancer-cells": { url: "/publications", code:301},
        "/publications/correlative-afm-and-fluorescence-imaging-demonstrate-nanoscale-membrane-remodeling-and": { url: "/publications", code:301},
        "/publications/correlative-infrared-nanospectroscopy-and-transmission-electron-microscopy-investigate": { url: "/publications", code:301},
        "/publications/cortical-softening-elicits-zygotic-contractility-during-mouse-preimplantation": { url: "/publications", code:301},
        "/publications/coupling-dna-damage-and-repair-essential-safeguard-during-programmed-dna-double-strand": { url: "/publications", code:301},
        "/publications/covariate-specific-time-dependent-receiver-operating-characteristic-curve-correlated": { url: "/publications", code:301},
        "/publications/covid-19-disease-map-building-computational-repository-sars-cov-2-virus-host": { url: "/publications", code:301},
        "/publications/critical-role-trim28-and-hp1bg-epigenetic-control-t-cell-metabolic-reprograming-and": { url: "/publications", code:301},
        "/publications/crohns-disease-related-bacterial-strain-lf82-assembles-biofilm-communities-protect": { url: "/publications", code:301},
        "/publications/cryo-soft-x-ray-tomography-explore-escherichia-coli-nucleoid-remodeling-hfq-master": { url: "/publications", code:301},
        "/publications/curietherapie-en-france-en-2020-synthese-et-perspectives-du-groupe-curietherapie-de-la": { url: "/publications", code:301},
        "/publications/current-perspectives-external-control-arms-oncology-clinical-trials-analysis-ema": { url: "/publications", code:301},
        "/publications/cxcr6-deficiency-impairs-cancer-vaccine-efficacy-and-cd8-resident-memory-t-cell": { url: "/publications", code:301},
        "/publications/cytidine-deaminase-deficiency-tumor-cells-associated-sensitivity-naphthol-derivative": { url: "/publications", code:301},
        "/publications/deciphering-spatial-landscape-and-plasticity-immunosuppressive-fibroblasts-breast": { url: "/publications", code:301},
        "/publications/declic-patient-education-program-assessment-through-population-health-intervention": { url: "/publications", code:301},
        "/publications/deep-learning-18f-fdg-uptake-classification-enables-total-metabolic-tumor-volume": { url: "/publications", code:301},
        "/publications/deep-learning-approach-identify-mrna-localization-patterns": { url: "/publications", code:301},
        "/publications/deep-learning-identifies-morphological-patterns-homologous-recombination-deficiency": { url: "/publications", code:301},
        "/publications/deep-learning-improves-macromolecule-identification-3d-cellular-cryo-electron": { url: "/publications", code:301},
        "/publications/des-mecanismes-moleculaires-aux-applications-cliniques-lessentiel-du-colloque": { url: "/publications", code:301},
        "/publications/detection-interactions-tumour-derived-extracellular-vesicles-immune-cells-dependent-ev": { url: "/publications", code:301},
        "/publications/determination-ion-collection-efficiency-razor-nano-chamber-ultra-high-dose-rate": { url: "/publications", code:301},
        "/publications/developing-advanced-gut-chip-model-enabling-study-epithelial-cellfibroblast": { url: "/publications", code:301},
        "/publications/developmental-bifurcation-human-t-follicular-regulatory-cells": { url: "/publications", code:301},
        "/publications/diacylglycerol-kinase-z-promotes-actin-cytoskeleton-remodeling-and-mechanical-forces-b": { url: "/publications", code:301},
        "/publications/dicer-2-dependent-generation-viral-dna-defective-genomes-rna-viruses-modulates": { url: "/publications", code:301},
        "/publications/differences-mitotic-spindle-architecture-mammalian-neural-stem-cells-influence-mitotic": { url: "/publications", code:301},
        "/publications/different-activities-rna-g-quadruplex-structures-are-controlled-flanking-sequences": { url: "/publications", code:301},
        "/publications/different-pigmentation-risk-loci-high-risk-monosomy-3-and-low-risk-disomy-3-uveal": { url: "/publications", code:301},
        "/publications/different-tcr-induced-t-lymphocyte-responses-are-potentiated-stiffness-variable": { url: "/publications", code:301},
        "/publications/differential-kinase-activation-peripheral-blood-mononuclear-cells-non-small-cell-lung": { url: "/publications", code:301},
        "/publications/differential-normal-skin-transcriptomic-response-total-body-irradiated-mice-exposed": { url: "/publications", code:301},
        "/publications/differential-remodeling-oxylipin-pool-after-flash-versus-conventional-dose-rate": { url: "/publications", code:301},
        "/publications/direct-imaging-and-automatic-analysis-tumor-chip-reveal-cooperative-antitumoral": { url: "/publications", code:301},
        "/publications/dis3-ribonuclease-prevents-cytoplasmic-accumulation-lncrnas-carrying-non-canonical": { url: "/publications", code:301},
        "/publications/discovering-hidden-messages-within-cell-trajectories-using-deep-learning-approach": { url: "/publications", code:301},
        "/publications/discovery-druggable-copper-signaling-pathway-drives-cell-plasticity-and-inflammation": { url: "/publications", code:301},
        "/publications/discussion-suite-la-communication-utilisation-de-longs-arn-non-codants-pour-le": { url: "/publications", code:301},
        "/publications/disordered-regions-and-folded-modules-caf-1-promote-histone-deposition": { url: "/publications", code:301},
        "/publications/dissect-tool-segment-and-explore-cell-and-tissue-mechanics-highly-deformed-3d": { url: "/publications", code:301},
        "/publications/dissection-intercellular-communication-using-transcriptome-based-framework-icellnet": { url: "/publications", code:301},
        "/publications/distinct-roles-and-b-tubulin-polyglutamylation-controlling-axonal-transport-and": { url: "/publications", code:301},
        "/publications/distinction-between-2-and-3-phosphate-isomers-fluorescent-nadph-analogue-led-strong": { url: "/publications", code:301},
        "/publications/diverse-and-heritable-lineage-imprinting-early-haematopoietic-progenitors": { url: "/publications", code:301},
        "/publications/diversification-human-plasmacytoid-predendritic-cells-response-single-stimulus": { url: "/publications", code:301},
        "/publications/dna-damage-induces-nuclear-envelope-rupture-through-atr-mediated-phosphorylation-lamin": { url: "/publications", code:301},
        "/publications/dna-damage-rna-binding-proteins-protect-near-and-far": { url: "/publications", code:301},
        "/publications/dna-methylation-restrains-transposons-adopting-chromatin-signature-permissive-meiotic": { url: "/publications", code:301},
        "/publications/dna-polymerase-d-single-pol31-polymorphism-suppresses-strain-background-specific": { url: "/publications", code:301},
        "/publications/dnmt3a-dependent-dna-methylation-required-spermatogonial-stem-cells-commit": { url: "/publications", code:301},
        "/publications/dosimetry-and-radioprotection-evaluations-very-high-energy-electron-beams": { url: "/publications", code:301},
        "/publications/drug-target-identification-covid-19-disease-mechanisms-using-computational-systems": { url: "/publications", code:301},
        "/publications/drugging-histone-methyltransferases-cancer": { url: "/publications", code:301},
        "/publications/dual-protein-mrna-localization-screen-reveals-compartmentalized-translation-and": { url: "/publications", code:301},
        "/publications/dual-role-integrin-alpha-6-glioblastoma-supporting-stemness-proneural-stem-cells-while": { url: "/publications", code:301},
        "/publications/dual-targeting-higher-order-dna-structures-azacryptands-induces-dna-junction-mediated": { url: "/publications", code:301},
        "/publications/dye-functionalized-phosphate-binding-macrocycles-nucleotide-g-quadruplex-recognition": { url: "/publications", code:301},
        "/publications/dynamic-boolean-modeling-molecular-and-cellular-interactions-psoriasis-predicts-drug": { url: "/publications", code:301},
        "/publications/dynamic-enhancer-partitioning-instructs-activation-growth-related-gene-during-exit": { url: "/publications", code:301},
        "/publications/dynamic-evolution-de-novo-dna-methyltransferases-rodent-and-primate-genomes": { url: "/publications", code:301},
        "/publications/dynamics-chromatin-accessibility-and-long-range-interactions-response-glucocorticoid": { url: "/publications", code:301},
        "/publications/dystrophy-associated-caveolin-3-mutations-reveal-caveolae-couple-il6stat3-signaling": { url: "/publications", code:301},
        "/publications/e2a-modulates-stemness-metastasis-and-therapeutic-resistance-breast-cancer": { url: "/publications", code:301},
        "/publications/editorial-overview-how-cells-extract-information-environmental-cues": { url: "/publications", code:301},
        "/publications/editorial-reciprocal-links-between-rna-metabolism-and-dna-damage-special-issue-july": { url: "/publications", code:301},
        "/publications/effective-antitumor-therapy-based-novel-antibody-drug-conjugate-targeting-tn": { url: "/publications", code:301},
        "/publications/effects-iron-modulation-mesenchymal-stem-cell-induced-drug-resistance-estrogen": { url: "/publications", code:301},
        "/publications/efficient-oral-vaccination-bioengineering-virus-particles-protozoan-surface-proteins": { url: "/publications", code:301},
        "/publications/endocytic-membrane-repair-escrt-iii-controls-antigen-export-cytosol-during-antigen": { url: "/publications", code:301},
        "/publications/endocytosis-switch-controlled-transmembrane-osmotic-pressure-and-phospholipid-number": { url: "/publications", code:301},
        "/publications/endosomal-trafficking-defects-alter-neural-progenitor-proliferation-and-cause-0": { url: "/publications", code:301},
        "/publications/environmental-exposures-related-parental-habits-perinatal-period-and-risk-wilms-tumor": { url: "/publications", code:301},
        "/publications/epigenetic-control-transposable-elements-and-imprinted-genes-newborns-affected-mode": { url: "/publications", code:301},
        "/publications/epigenetic-priming-mechanism-mediated-nutrient-sensing-regulates-transcriptional": { url: "/publications", code:301},
        "/publications/epigenetic-switch-ensures-transposon-repression-upon-dynamic-loss-dna-methylation": { url: "/publications", code:301},
        "/publications/epigenetically-controlled-tumor-antigens-derived-splice-junctions-between-exons-and": { url: "/publications", code:301},
        "/publications/epigenome-and-cancer-stem-cell-fate-connected-linker-histone-variant": { url: "/publications", code:301},
        "/publications/epigenomic-mapping-identifies-enhancer-repertoire-regulates-cell-identity-bladder": { url: "/publications", code:301},
        "/publications/erbb3-mutations-cancer-biological-aspects-prevalence-and-therapeutics": { url: "/publications", code:301},
        "/publications/erg-transcription-factors-have-splicing-regulatory-function-involving-rbfox2-altered": { url: "/publications", code:301},
        "/publications/erythropoietin-directly-remodels-clonal-composition-murine-hematopoietic-multipotent": { url: "/publications", code:301},
        "/publications/escrt-iii-repairs-nuclear-envelope-ruptures-during-cell-migration-limit-dna-damage-and": { url: "/publications", code:301},
        "/publications/escrt-machinery-required-plasma-membrane-repair": { url: "/publications", code:301},
        "/publications/essential-dynamic-interdependence-ftsz-and-sepf-z-ring-and-septum-formation": { url: "/publications", code:301},
        "/publications/etat-des-lieux-de-la-protontherapie-en-france-en-2019": { url: "/publications", code:301},
        "/publications/european-mappyacts-trial-precision-medicine-program-pediatric-and-adolescent-patients": { url: "/publications", code:301},
        "/publications/ev-track-transparent-reporting-and-centralizing-knowledge-extracellular-vesicle": { url: "/publications", code:301},
        "/publications/evaluating-patient-derived-colorectal-cancer-xenografts-preclinical-models-comparison": { url: "/publications", code:301},
        "/publications/evaluation-role-bacterial-amyloid-nucleoid-structure-using-cryo-soft-x-ray-tomography": { url: "/publications", code:301},
        "/publications/evidence-sars-cov-2-induces-lung-cell-senescence-potential-impact-covid-19-lung": { url: "/publications", code:301},
        "/publications/evolution-and-genomic-signatures-spontaneous-somatic-mutation-drosophila-intestinal": { url: "/publications", code:301},
        "/publications/evolutionary-emergence-first-animal-organisms-triggered-environmental-mechano-0": { url: "/publications", code:301},
        "/publications/exact-solving-and-sensitivity-analysis-stochastic-continuous-time-boolean-models": { url: "/publications", code:301},
        "/publications/excessive-tubulin-polyglutamylation-causes-neurodegeneration-and-perturbs-neuronal": { url: "/publications", code:301},
        "/publications/exosomes-and-extracellular-vesicles-path-forward": { url: "/publications", code:301},
        "/publications/exosomes-released-keratinocytes-modulate-melanocyte-pigmentation": { url: "/publications", code:301},
        "/publications/expanding-heterochromatin-reveals-discrete-subtelomeric-domains-delimited-chromatin": { url: "/publications", code:301},
        "/publications/expansion-and-activation-cd103-dendritic-cell-progenitors-tumor-site-enhances-tumor": { url: "/publications", code:301},
        "/publications/experimentally-generated-ground-truth-detecting-cell-types-image-based-immunotherapy": { url: "/publications", code:301},
        "/publications/exploring-isofunctional-molecules-design-benchmark-and-evaluation-prediction": { url: "/publications", code:301},
        "/publications/extracellular-vesicles-and-co-isolated-endogenous-retroviruses-differently-affect": { url: "/publications", code:301},
        "/publications/extracellular-vesicles-containing-ace2-efficiently-prevent-infection-sars-cov-2-spike": { url: "/publications", code:301},
        "/publications/extracellular-vesicles-triple-negative-breast-cancer-promote-pro-inflammatory": { url: "/publications", code:301},
        "/publications/ezrin-enrichment-curved-membranes-requires-specific-conformation-or-interaction": { url: "/publications", code:301},
        "/publications/family-affair-ral-exocyst-centered-network-links-ras-rac-rho-signaling-control-cell": { url: "/publications", code:301},
        "/publications/fancd2-maintains-fork-stability-brca12-deficient-tumors-and-promotes-alternative-end": { url: "/publications", code:301},
        "/publications/fgfr3-mutational-activation-can-induce-luminal-papillary-bladder-tumor-formation-and": { url: "/publications", code:301},
        "/publications/fgfr3myc-positive-feedback-loop-provides-new-opportunities-targeted-therapies-bladder": { url: "/publications", code:301},
        "/publications/final-twist-endocytic-membrane-scission": { url: "/publications", code:301},
        "/publications/first-class-polymerase-theta-inhibitor-selectively-targets-homologous-recombination": { url: "/publications", code:301},
        "/publications/first-evaluation-temporal-and-spatial-fractionation-proton-minibeam-radiation-therapy": { url: "/publications", code:301},
        "/publications/flash-irradiation-spares-lung-progenitor-cells-and-limits-incidence-radio-induced": { url: "/publications", code:301},
        "/publications/flowmetering-microfluidics": { url: "/publications", code:301},
        "/publications/fluorescence-cumulants-analysis-non-ideal-observation-profiles": { url: "/publications", code:301},
        "/publications/fluorescence-exclusion-measurement-volume-live-cells": { url: "/publications", code:301},
        "/publications/fluorescent-polymersomes-aggregation-induced-emission": { url: "/publications", code:301},
        "/publications/french-early-breast-cancer-cohort-fresh-resource-breast-cancer-research-and": { url: "/publications", code:301},
        "/publications/frequency-and-prognostic-impact-alk-amplifications-and-mutations-european": { url: "/publications", code:301},
        "/publications/frequent-somatic-mutation-adult-intestinal-stem-cells-drives-neoplasia-and-genetic": { url: "/publications", code:301},
        "/publications/full-assembly-hiv-1-particles-requires-assistance-membrane-curvature-factor-irsp53": { url: "/publications", code:301},
        "/publications/full-length-plasmodium-falciparum-myosin-and-essential-light-chain-pfelc-structures": { url: "/publications", code:301},
        "/publications/fulvestrant-and-everolimus-efficacy-after-cdk46-inhibitor-prospective-study": { url: "/publications", code:301},
        "/publications/functional-activity-h33-histone-chaperone-complex-hira-requires-trimerization-hira": { url: "/publications", code:301},
        "/publications/functional-characterization-5p1533-risk-locus-uveal-melanoma-reveals-rs452384": { url: "/publications", code:301},
        "/publications/functional-characterization-n-acetylmuramyl-l-alanine-amidase-ami1-mycobacterium": { url: "/publications", code:301},
        "/publications/fuzzy-tandem-repeats-containing-p53-response-elements-may-define-species-specific-p53": { url: "/publications", code:301},
        "/publications/g-quadruplexes-helminth-parasites": { url: "/publications", code:301},
        "/publications/g4access-identifies-g-quadruplexes-and-their-associations-open-chromatin-and": { url: "/publications", code:301},
        "/publications/ganglion-sentinelle-et-chimiotherapie-neoadjuvante-dans-le-cancer-du-sein": { url: "/publications", code:301},
        "/publications/gene-and-pathway-level-analyses-icogs-variants-highlight-novel-signaling-pathways": { url: "/publications", code:301},
        "/publications/gene-replacement-strategies-validate-use-functional-tags-centromeric-chromatin-and": { url: "/publications", code:301},
        "/publications/generation-schwann-cell-derived-melanocytes-hpscs-identifies-pro-metastatic-factors": { url: "/publications", code:301},
        "/publications/genetic-inhibition-card9-accelerates-development-atherosclerosis-mice-through-cd36": { url: "/publications", code:301},
        "/publications/genetically-determined-telomere-length-and-multiple-myeloma-risk-and-outcome": { url: "/publications", code:301},
        "/publications/geniac-automatic-configuration-generator-and-installer-nextflow-pipelines-0": { url: "/publications", code:301},
        "/publications/genome-wide-analysis-dna-methylation-buccal-cells-children-conceived-through-ivf-and": { url: "/publications", code:301},
        "/publications/genome-wide-mapping-human-dna-replication-optical-replication-mapping-supports": { url: "/publications", code:301},
        "/publications/genome-wide-measurement-dna-replication-fork-directionality-and-quantification-dna": { url: "/publications", code:301},
        "/publications/genuage-visualize-and-analyze-multidimensional-single-molecule-point-cloud-data": { url: "/publications", code:301},
        "/publications/germline-mutation-mdm4-major-p53-regulator-familial-syndrome-defective-telomere": { url: "/publications", code:301},
        "/publications/glial-signature-and-wnt7-signaling-regulate-glioma-vascular-interactions-and-tumor": { url: "/publications", code:301},
        "/publications/glutamate-spillover-c-elegans-triggers-repetitive-behavior-through-presynaptic": { url: "/publications", code:301},
        "/publications/glycosylation-dependent-ifn-gr-partitioning-lipid-and-actin-nanodomains-critical-jak": { url: "/publications", code:301},
        "/publications/gradient-developmental-and-injury-response-transcriptional-states-defines-functional": { url: "/publications", code:301},
        "/publications/gradient-glycolytic-activity-coordinates-fgf-and-wnt-signaling-during-elongation-body": { url: "/publications", code:301},
        "/publications/guardian-genome-revisited-p53-downregulates-genes-required-telomere-maintenance-dna": { url: "/publications", code:301},
        "/publications/guided-tour-selected-image-processing-and-analysis-methods-fluorescence-and-electron": { url: "/publications", code:301},
        "/publications/guidelines-use-flow-cytometry-and-cell-sorting-immunological-studies": { url: "/publications", code:301},
        "/publications/h-rubies-new-family-red-emitting-fluorescent-ph-sensors-living-cells": { url: "/publications", code:301},
        "/publications/h2az-facilitates-licensing-and-activation-early-replication-origins": { url: "/publications", code:301},
        "/publications/helping-patients-communicate-oncologists-when-cancer-treatment-resistance-occurs": { url: "/publications", code:301},
        "/publications/hhip-as1-lncrna-promotes-tumorigenicity-through-stabilization-dynein-complex-1-human": { url: "/publications", code:301},
        "/publications/hif-1a-and-rapamycin-act-gerosuppressant-multiple-myeloma-cells-upon-genotoxic-stress": { url: "/publications", code:301},
        "/publications/hif2a-direct-regulator-neutrophil-motility": { url: "/publications", code:301},
        "/publications/high-fidelity-3d-live-cell-nanoscopy-through-data-driven-enhanced-super-resolution": { url: "/publications", code:301},
        "/publications/high-grade-childhood-intra-parenchymal-brain-tumor-clustering-atrt-and-expanding": { url: "/publications", code:301},
        "/publications/high-resolution-visualization-h3-variants-during-replication-reveals-their-controlled": { url: "/publications", code:301},
        "/publications/high-risk-retinoblastoma-subtype-stemness-features-dedifferentiated-cone-states-and": { url: "/publications", code:301},
        "/publications/high-throughput-drug-screening-identifies-pazopanib-and-clofilium-tosylate-promising": { url: "/publications", code:301},
        "/publications/high-throughput-large-scale-microfluidic-assembly-iron-oxide-nanoflowersps-b-paa": { url: "/publications", code:301},
        "/publications/high-throughput-single-cell-chip-seq-identifies-heterogeneity-chromatin-states-breast": { url: "/publications", code:301},
        "/publications/highly-efficient-multicolor-multifocus-microscopy-optimal-design-diffraction-binary": { url: "/publications", code:301},
        "/publications/histone-variant-h33-residue-s31-essential-xenopus-gastrulation-regardless-deposition": { url: "/publications", code:301},
        "/publications/hlh-2e2a-expression-links-stochastic-and-deterministic-elements-cell-fate-decision": { url: "/publications", code:301},
        "/publications/homologous-recombination-deficiency-derived-whole-genome-sequencing-predicts-platinum": { url: "/publications", code:301},
        "/publications/homologous-recombination-deficiency-exploiting-fundamental-vulnerability-ovarian": { url: "/publications", code:301},
        "/publications/homosalate-boosts-release-tumour-derived-extracellular-vesicles-protection-against": { url: "/publications", code:301},
        "/publications/hormad1-overexpression-predicts-response-anthracycline-cyclophosphamide-and-survival": { url: "/publications", code:301},
        "/publications/hotair-lncrna-promotes-epithelial-mesenchymal-transition-redistributing-lsd1": { url: "/publications", code:301},
        "/publications/how-membrane-physics-rules-hiv-envelope": { url: "/publications", code:301},
        "/publications/how-myosin-vi-traps-its-state-activated-and-dimerizes-0": { url: "/publications", code:301},
        "/publications/hspcs-display-within-family-homogeneity-differentiation-and-proliferation-despite": { url: "/publications", code:301},
        "/publications/human-aortic-valve-interstitial-cells-display-proangiogenic-properties-during-calcific": { url: "/publications", code:301},
        "/publications/human-dominant-disease-genes-are-enriched-paralogs-originating-whole-genome": { url: "/publications", code:301},
        "/publications/human-escrt-iii-polymers-assemble-positively-curved-membranes-and-induce-helical": { url: "/publications", code:301},
        "/publications/human-lymphoid-organ-cdc2-and-macrophages-play-complementary-roles-t-follicular-helper": { url: "/publications", code:301},
        "/publications/human-papilloma-virus-hpv-integration-signature-cervical-cancer-identification-macrod2": { url: "/publications", code:301},
        "/publications/human-papilloma-virus-integration-sites-and-genomic-signatures-head-and-neck-squamous": { url: "/publications", code:301},
        "/publications/human-primary-immune-cells-exhibit-distinct-mechanical-properties-are-modified": { url: "/publications", code:301},
        "/publications/human-regulator-telomere-elongation-helicase-1-rtel1-required-nuclear-and-cytoplasmic": { url: "/publications", code:301},
        "/publications/human-septin-octamer-complex-sensitive-membrane-curvature-drives-membrane-deformation": { url: "/publications", code:301},
        "/publications/humanized-mice-are-precious-tools-preclinical-evaluation-car-t-and-car-nk-cell": { url: "/publications", code:301},
        "/publications/hydrogen-peroxide-h2o2-controls-axon-pathfinding-during-zebrafish-development": { url: "/publications", code:301},
        "/publications/hyperbolic-cosine-function-physics": { url: "/publications", code:301},
        "/publications/identification-new-driver-and-passenger-mutations-within-apobec-induced-hotspot": { url: "/publications", code:301},
        "/publications/identification-new-potent-acetylcholinesterase-inhibitors-using-virtual-screening-and": { url: "/publications", code:301},
        "/publications/identification-ohnolog-genes-originating-whole-genome-duplication-early-vertebrates": { url: "/publications", code:301},
        "/publications/identification-pyrrolopyrimidine-derivative-pp-13-novel-microtubule-destabilizing": { url: "/publications", code:301},
        "/publications/identification-tissue-origin-and-guided-therapeutic-applications-cancers-unknown": { url: "/publications", code:301},
        "/publications/il2anti-il2-complex-combined-ctla-4-not-pd-1-blockade-rescues-antitumor-nk-cell": { url: "/publications", code:301},
        "/publications/imaging-and-multi-omics-datasets-converge-define-different-neural-progenitor-origins": { url: "/publications", code:301},
        "/publications/immune-checkpoint-inhibitors-rechallenge-efficacy-non-small-cell-lung-cancer-patients": { url: "/publications", code:301},
        "/publications/immune-gene-expression-head-and-neck-squamous-cell-carcinoma-patients": { url: "/publications", code:301},
        "/publications/immune-response-profiling-patients-spondyloarthritis-reveals-signalling-networks": { url: "/publications", code:301},
        "/publications/impact-des-regimes-alimentaires-sur-la-mortalite-et-le-risque-de-recidive-de-cancer-du": { url: "/publications", code:301},
        "/publications/improved-strategy-easy-process-monitoring-and-advanced-purification-recombinant": { url: "/publications", code:301},
        "/publications/increased-circulating-cd62e-endothelial-extracellular-vesicles-predict-severity-and": { url: "/publications", code:301},
        "/publications/increasing-ergosterol-levels-delays-formin-dependent-assembly-f-actin-cables-and": { url: "/publications", code:301},
        "/publications/inducible-system-silencing-establishment-reveals-stepwise-mechanism-which-anchoring": { url: "/publications", code:301},
        "/publications/induction-acquired-resistance-towards-egfr-inhibitor-gefitinib-patient-derived": { url: "/publications", code:301},
        "/publications/induction-resident-memory-t-cells-enhances-efficacy-cancer-vaccine": { url: "/publications", code:301},
        "/publications/inflammatory-fibroblasts-make-rectal-cancer-resistant-radiation-therapy": { url: "/publications", code:301},
        "/publications/influence-external-forces-actin-dependent-t-cell-protrusions-during-immune-synapse": { url: "/publications", code:301},
        "/publications/inhibition-hiv-infection-structural-proteins-inner-nuclear-membrane-associated-reduced": { url: "/publications", code:301},
        "/publications/innate-lymphoid-cells-nk-and-cytotoxic-ilc3-subsets-infiltrate-metastatic-breast": { url: "/publications", code:301},
        "/publications/innate-t-cell-subset-commitment-murine-thymus-independent-tcr-characteristics-and": { url: "/publications", code:301},
        "/publications/ino80-chromatin-remodeler-facilitates-release-rna-polymerase-ii-chromatin-ubiquitin": { url: "/publications", code:301},
        "/publications/insights-cerebellar-development-and-medulloblastoma": { url: "/publications", code:301},
        "/publications/integrated-droplet-microfluidic-device-magnetic-particles-handling-application-dna": { url: "/publications", code:301},
        "/publications/integrated-genomic-analysis-identifies-driver-genes-and-cisplatin-resistant-progenitor": { url: "/publications", code:301},
        "/publications/integrated-high-throughput-screening-and-large-scale-isobolographic-analysis": { url: "/publications", code:301},
        "/publications/integrative-identification-non-coding-regulatory-regions-driving-metastatic-prostate": { url: "/publications", code:301},
        "/publications/interaction-between-molecular-subtypes-and-stromal-immune-infiltration-and-after": { url: "/publications", code:301},
        "/publications/interferon-l-and-interleukin-22-act-synergistically-induction-interferon-stimulated": { url: "/publications", code:301},
        "/publications/internal-rulers-assess-fluorescent-protein-photoactivation-efficiency": { url: "/publications", code:301},
        "/publications/interplay-between-calcium-and-sarcomeres-directs-cardiomyocyte-maturation-during": { url: "/publications", code:301},
        "/publications/interplay-between-cellular-uptake-intracellular-localization-and-cell-death-mechanism": { url: "/publications", code:301},
        "/publications/interplay-between-diabetes-and-pancreatic-ductal-adenocarcinoma-and-insulinoma-role": { url: "/publications", code:301},
        "/publications/intrinsic-antiproliferative-activity-innate-sensor-sting-t-lymphocytes": { url: "/publications", code:301},
        "/publications/intrinsically-disordered-region-osbp-acts-entropic-barrier-control-protein-dynamics": { url: "/publications", code:301},
        "/publications/intronic-polyadenylation-isoforms-5-part-genes-constitute-source-microproteins-and-are": { url: "/publications", code:301},
        "/publications/irm-du-sein-dans-le-carcinome-lobulaire-infiltrant-aspects-diagnostiques-bilan": { url: "/publications", code:301},
        "/publications/iron-sensitive-prodrugs-trigger-active-ferroptosis-drug-tolerant-pancreatic-cancer": { url: "/publications", code:301},
        "/publications/isotopic-tracing-reveals-single-cell-assimilation-macroalgal-polysaccharide-few-marine": { url: "/publications", code:301},
        "/publications/jarid2-methylation-prc2-complex-regulates-h3k27me3-deposition-during-cell": { url: "/publications", code:301},
        "/publications/journal-extracellular-vesicles-seven-year-itch": { url: "/publications", code:301},
        "/publications/kinesin-14s-and-microtubule-dynamics-define-fission-yeast-mitotic-and-meiotic-spindle": { url: "/publications", code:301},
        "/publications/kinesin-5-independent-mitotic-spindle-assembly-requires-antiparallel-microtubule": { url: "/publications", code:301},
        "/publications/kinesin-6-klp9-orchestrates-spindle-elongation-regulating-microtubule-sliding-and": { url: "/publications", code:301},
        "/publications/kinesin-6-regulates-cell-size-dependent-spindle-elongation-velocity-keep-mitosis": { url: "/publications", code:301},
        "/publications/la-chromatine-bivalente-preserve-la-plasticite-transcriptionnelle": { url: "/publications", code:301},
        "/publications/la-cryo-microscopie-electronique-revele-une-nouvelle-vision-de-la-cellule-et-de-ses": { url: "/publications", code:301},
        "/publications/la-migration-nucleaire-dans-les-progeniteurs-neuronaux": { url: "/publications", code:301},
        "/publications/la-radiotherapie-flash-pour-epargner-les-tissus-sains": { url: "/publications", code:301},
        "/publications/langevin-equations-reaction-diffusion-processes": { url: "/publications", code:301},
        "/publications/large-and-reversible-myosin-dependent-forces-rigidity-sensing": { url: "/publications", code:301},
        "/publications/large-collection-integrated-genomically-characterized-patient-derived-xenografts": { url: "/publications", code:301},
        "/publications/le-mot-des-coordinatrices": { url: "/publications", code:301},
        "/publications/learning-causal-networks-latent-variables-multivariate-information-genomic-data": { url: "/publications", code:301},
        "/publications/leukocyte-derived-ifn-ab-and-epithelial-ifn-l-constitute-compartmentalized-mucosal": { url: "/publications", code:301},
        "/publications/lin7a-major-determinant-cell-polarity-defects-breast-carcinomas": { url: "/publications", code:301},
        "/publications/linc-complex-lis1-interplay-controls-mt1-mmp-matrix-digest-demand-response-confined": { url: "/publications", code:301},
        "/publications/lineage-tracing-notch1-expressing-cells-intestinal-tumours-reveals-distinct-population": { url: "/publications", code:301},
        "/publications/live-cell-imaging-reveals-square-shape-spindles-and-long-mitosis-duration-silkworm": { url: "/publications", code:301},
        "/publications/live-cell-micromanipulation-genomic-locus-reveals-interphase-chromatin-mechanics-0": { url: "/publications", code:301},
        "/publications/lncrna-regulates-alternative-splicing-establishment-splicing-specific-chromatin": { url: "/publications", code:301},
        "/publications/lncrnas-development-and-differentiation-sequence-motifs-functional-characterization": { url: "/publications", code:301},
        "/publications/local-insulin-reservoir-drosophila-alpha-cell-homologs-ensures-developmental": { url: "/publications", code:301},
        "/publications/local-light-activation-src-oncoprotein-epithelial-monolayer-promotes-collective": { url: "/publications", code:301},
        "/publications/long-non-coding-rnas-control-gametogenesis-lessons-fission-yeast": { url: "/publications", code:301},
        "/publications/long-time-diffusion-and-energy-transfer-polydisperse-mixtures-particles-different": { url: "/publications", code:301},
        "/publications/longitudinal-mouse-pet-imaging-reliable-method-estimating-binding-parameters-without": { url: "/publications", code:301},
        "/publications/loss-deglutamylase-ccp5-perturbs-multiple-steps-spermatogenesis-and-leads-male": { url: "/publications", code:301},
        "/publications/loss-tubulin-deglutamylase-ccp-1-causes-infantile-onset-neurodegeneration": { url: "/publications", code:301},
        "/publications/low-fidelity-polymerases-alphaviruses-recombine-higher-rates-overproduce-defective": { url: "/publications", code:301},
        "/publications/low-level-fibrillarin-ribosome-biogenesis-factor-new-independent-marker-poor-outcome": { url: "/publications", code:301},
        "/publications/low-retinal-toxicity-intravitreal-carboplatin-associated-good-retinal-tumour-control": { url: "/publications", code:301},
        "/publications/luminal-progenitors-undergo-partial-epithelial-mesenchymal-transition-onset-basal": { url: "/publications", code:301},
        "/publications/lung-organotypic-slices-enable-rapid-quantification-acute-radiotherapy-induced": { url: "/publications", code:301},
        "/publications/lymph-node-metastases-can-invade-local-blood-vessels-exit-node-and-colonize-distant": { url: "/publications", code:301},
        "/publications/lysate-based-pipeline-characterize-microtubule-associated-proteins-uncovers-unique": { url: "/publications", code:301},
        "/publications/lysosomal-membrane-proteins-lamp1-and-limp2-are-segregated-golgi-apparatus": { url: "/publications", code:301},
        "/publications/macropinocytosis-overcomes-directional-bias-dendritic-cells-due-hydraulic-resistance": { url: "/publications", code:301},
        "/publications/magneto-immunocapture-bead-fluorescent-labeling-amyloid-b-peptides-towards": { url: "/publications", code:301},
        "/publications/mait-cell-development-mice-and-humans": { url: "/publications", code:301},
        "/publications/major-b-catenine-cadherin-junctional-binding-site-primary-molecular-mechano": { url: "/publications", code:301},
        "/publications/mapping-histone-variant-genomic-distribution-exploiting-snap-tag-labeling-follow": { url: "/publications", code:301},
        "/publications/mathematical-model-circadian-clock-and-drug-pharmacology-optimize-irinotecan": { url: "/publications", code:301},
        "/publications/matrix-architecture-defines-preferential-localization-and-migration-t-cells-stroma": { url: "/publications", code:301},
        "/publications/maximum-entropy-analysis-polarized-fluorescence-decay-egfp-aqueous-solution": { url: "/publications", code:301},
        "/publications/mdm4-dont-judge-isoform-its-mrna-levels": { url: "/publications", code:301},
        "/publications/mdm4-loss-mice-expressing-p53-hypomorph-alters-tumor-spectrum-without-improving": { url: "/publications", code:301},
        "/publications/mecanotransduction-par-les-caveoles": { url: "/publications", code:301},
        "/publications/mechanical-compartmentalization-intestinal-organoid-enables-crypt-folding-and": { url: "/publications", code:301},
        "/publications/mechanical-signals-trigger-myosin-ii-redistribution-and-mesoderm-invagination": { url: "/publications", code:301},
        "/publications/mechanics-blastocyst-morphogenesis": { url: "/publications", code:301},
        "/publications/mechanics-human-embryo-compaction-0": { url: "/publications", code:301},
        "/publications/mechanism-small-molecule-inhibition-plasmodium-falciparum-myosin-informs-antimalarial": { url: "/publications", code:301},
        "/publications/mechanisms-regulating-prc2-recruitment-and-enzymatic-activity": { url: "/publications", code:301},
        "/publications/mechanistic-and-structural-basis-activation-cardiac-myosin-force-production-omecamtiv": { url: "/publications", code:301},
        "/publications/mechano-biochemical-marine-stimulation-inversion-gastrulation-and-endomesoderm": { url: "/publications", code:301},
        "/publications/mechanosensitive-caveolae-invadosome-interplay-drives-matrix-remodelling-cancer-cell": { url: "/publications", code:301},
        "/publications/mechanotransduction-mechanically-coupled-pulsating-cells-transition-collective": { url: "/publications", code:301},
        "/publications/mechanotransductive-cascade-myo-ii-dependent-mesoderm-and-endoderm-invaginations": { url: "/publications", code:301},
        "/publications/meiotic-xpf-ercc1-complex-recognizes-joint-molecule-recombination-intermediates": { url: "/publications", code:301},
        "/publications/melanosome-distribution-keratinocytes-different-skin-types-melanosome-clusters-are-not": { url: "/publications", code:301},
        "/publications/metal-free-activation-csp3-h-bond-and-practical-and-rapid-synthesis-privileged-1": { url: "/publications", code:301},
        "/publications/metastatic-neuroblastoma-patient-rohhad-new-alert-regarding-risk-aggressive": { url: "/publications", code:301},
        "/publications/methods-map-meiotic-recombination-proteins-saccharomyces-cerevisiae": { url: "/publications", code:301},
        "/publications/mice-and-men": { url: "/publications", code:301},
        "/publications/mice-engineered-obligatory-mdm4-exon-skipping-express-higher-levels-mdm4-s-isoform": { url: "/publications", code:301},
        "/publications/microvilli-derived-extracellular-vesicles-carry-hedgehog-morphogenic-signals": { url: "/publications", code:301},
        "/publications/miic-online-web-server-reconstruct-causal-or-non-causal-networks-non-perturbative-data": { url: "/publications", code:301},
        "/publications/minimal-information-studies-extracellular-vesicles-2018-misev2018-position-statement": { url: "/publications", code:301},
        "/publications/minimal-self-organisation-model-golgi-apparatus": { url: "/publications", code:301},
        "/publications/mir200-regulated-cxcl12b-promotes-fibroblast-heterogeneity-and-immunosuppression": { url: "/publications", code:301},
        "/publications/missense-variants-uncertain-significance-powerful-genetic-tool-function-discovery": { url: "/publications", code:301},
        "/publications/modeling-signaling-pathways-biology-maboss-one-single-cell-dynamic-population": { url: "/publications", code:301},
        "/publications/models-including-pathological-and-radiomic-features-vs-clinical-models-predicting": { url: "/publications", code:301},
        "/publications/modular-microfluidic-system-chip-extraction-preconcentration-and-detection-cytokine": { url: "/publications", code:301},
        "/publications/modulation-cellular-fate-vinyl-triarylamines-through-structural-fine-tuning-stay-or": { url: "/publications", code:301},
        "/publications/molecular-atlas-developing-ectoderm-defines-neural-neural-crest-placode-and-nonneural": { url: "/publications", code:301},
        "/publications/molecular-characterization-circulating-tumor-dna-pediatric-rhabdomyosarcoma": { url: "/publications", code:301},
        "/publications/molecular-functions-wwox-potentially-involved-cancer-development": { url: "/publications", code:301},
        "/publications/molecular-insights-activation-mre11-rad50-endonuclease-activity-sae2ctip-0": { url: "/publications", code:301},
        "/publications/molecular-insights-pathways-underlying-naked-mole-rat-eusociality": { url: "/publications", code:301},
        "/publications/molecular-mechanisms-underlying-tgf-sshippo-signaling-crosstalks-role-baso-apical": { url: "/publications", code:301},
        "/publications/morphogenesis-human-preimplantation-embryo-bringing-mechanics-clinics": { url: "/publications", code:301},
        "/publications/mouse-multipotent-progenitor-5-cells-are-located-interphase-between-hematopoietic-stem": { url: "/publications", code:301},
        "/publications/mpapass-software-enables-stitched-multiplex-multidimensional-ev-repertoire-analysis": { url: "/publications", code:301},
        "/publications/mrna-processing-pathway-suppresses-metastasis-governing-translational-control-0": { url: "/publications", code:301},
        "/publications/mtert-induction-p21-positive-cells-counteracts-capillary-rarefaction-and-pulmonary": { url: "/publications", code:301},
        "/publications/mtor-repression-response-amino-acid-starvation-promotes-ecm-degradation-through-mt1": { url: "/publications", code:301},
        "/publications/multi-omics-comparison-malignant-and-normal-uveal-melanocytes-reveals-molecular": { url: "/publications", code:301},
        "/publications/multiethnic-genome-wide-association-study-differentiated-thyroid-cancer-epithyr": { url: "/publications", code:301},
        "/publications/multifaceted-functions-homologous-recombination-dealing-replication-associated-dna": { url: "/publications", code:301},
        "/publications/multifocus-microscopy-precise-color-multi-phase-diffractive-optics-applied-functional": { url: "/publications", code:301},
        "/publications/multimodal-analysis-cell-free-dna-whole-genome-sequencing-pediatric-cancers-low": { url: "/publications", code:301},
        "/publications/multimodal-liquid-biopsy-early-monitoring-and-outcome-prediction-chemotherapy": { url: "/publications", code:301},
        "/publications/multimodal-profiling-reveals-site-specific-adaptation-and-tissue-residency-hallmarks": { url: "/publications", code:301},
        "/publications/multiscale-analysis-single-and-double-maternal-zygotic-myh9-and-myh10-mutants-during": { url: "/publications", code:301},
        "/publications/multiscale-design-cell-type-specific-pharmacokineticpharmacodynamic-models": { url: "/publications", code:301},
        "/publications/multiscale-signalling-network-map-innate-immune-response-cancer-reveals-cell": { url: "/publications", code:301},
        "/publications/muscle-stem-cells-undergo-extensive-clonal-drift-during-tissue-growth-meox1-mediated": { url: "/publications", code:301},
        "/publications/mutant-mice-lacking-alternatively-spliced-p53-isoforms-unveil-ackr4-male-specific": { url: "/publications", code:301},
        "/publications/mutations-impairing-gsk3-mediated-maf-phosphorylation-cause-cataract-deafness": { url: "/publications", code:301},
        "/publications/mutations-most-divergent-tubulin-isotype-a8-tubulin-cause-defective-platelet": { url: "/publications", code:301},
        "/publications/myosin-1b-functions-effector-ephb-signaling-control-cell-repulsion": { url: "/publications", code:301},
        "/publications/myosin-vi-and-branched-actin-filaments-mediate-membrane-constriction-and-fission": { url: "/publications", code:301},
        "/publications/n-1-naphthyl-345-trimethoxybenzohydrazide-microtubule-destabilizer-synthesis": { url: "/publications", code:301},
        "/publications/n-terminal-domain-cgas-determines-preferential-association-centromeric-dna-and-innate": { url: "/publications", code:301},
        "/publications/nali-h1-universal-synthetic-library-humanized-nanobodies-providing-highly-functional": { url: "/publications", code:301},
        "/publications/nanobodies-against-surface-biomarkers-enable-analysis-tumor-genetic-heterogeneity": { url: "/publications", code:301},
        "/publications/national-recommendations-french-genetics-and-cancer-group-unicancer-modalities-multi": { url: "/publications", code:301},
        "/publications/neoadjuvant-everolimus-adult-giant-mesenteric-cystic-lymphangioma-mtor-pathway": { url: "/publications", code:301},
        "/publications/neural-border-induction-specification-and-maturation-territory-generates-neural-crest": { url: "/publications", code:301},
        "/publications/neuronal-growth-volume-perspective": { url: "/publications", code:301},
        "/publications/neuronal-nsun2-deficiency-produces-trna-epitranscriptomic-alterations-and-proteomic": { url: "/publications", code:301},
        "/publications/never-tear-us-apart-importance-centrosome-clustering": { url: "/publications", code:301},
        "/publications/new-biomimetic-assay-reveals-temporal-role-matrix-stiffening-cancer-cell-invasion": { url: "/publications", code:301},
        "/publications/new-general-features-based-superpixels-image-segmentation-learning": { url: "/publications", code:301},
        "/publications/new-method-quantification-intratumoral-heterogeneity-feasibility-study-ktrans-maps": { url: "/publications", code:301},
        "/publications/new-pipeline-pathophysiological-analysis-mammary-gland-based-organoid-transplantation": { url: "/publications", code:301},
        "/publications/nitazoxanide-analogs-require-nitroreduction-antimicrobial-activity-mycobacterium": { url: "/publications", code:301},
        "/publications/non-conventional-axonal-organelles-control-trpm8-ion-channel-trafficking-and": { url: "/publications", code:301},
        "/publications/noncanonical-splicing-junctions-between-exons-and-transposable-elements-represent": { url: "/publications", code:301},
        "/publications/notch-signalling-sensor-and-instructor-microenvironment-coordinate-cell-fate-and-organ": { url: "/publications", code:301},
        "/publications/novel-3d-lung-tumor-spheroids-oncoimmunological-assays": { url: "/publications", code:301},
        "/publications/novel-fgfr4-targeting-single-domain-antibodies-multiple-targeted-therapies-against": { url: "/publications", code:301},
        "/publications/novel-lncrna-proca11-regulates-cell-plasticity-response-androgen-deprivation-prostate": { url: "/publications", code:301},
        "/publications/nrl-and-crx-define-photoreceptor-identity-and-reveal-subgroup-specific-dependencies": { url: "/publications", code:301},
        "/publications/nuclear-pore-primes-recombination-dependent-dna-synthesis-arrested-forks-promoting": { url: "/publications", code:301},
        "/publications/nuclei-segmentation-histopathology-images-using-deep-neural-networks": { url: "/publications", code:301},
        "/publications/observing-nutrient-gradients-gene-expression-and-growth-variation-using-yeast-machine": { url: "/publications", code:301},
        "/publications/occurrence-intracranial-rhabdoid-tumours-mice-depends-temporal-control-smarcb1": { url: "/publications", code:301},
        "/publications/oligopaint-dna-fish-reveals-telomere-based-meiotic-pairing-dynamics-silkworm-bombyx": { url: "/publications", code:301},
        "/publications/oncogenic-chimeric-transcription-factors-drive-tumor-specific-transcription-processing": { url: "/publications", code:301},
        "/publications/oncogenic-signaling-alters-cell-shape-and-mechanics-facilitate-cell-division-under": { url: "/publications", code:301},
        "/publications/optical-genome-mapping-detecting-homologous-recombination-deficiency-hrd-human-breast": { url: "/publications", code:301},
        "/publications/optical-volume-and-mass-measurements-show-mammalian-cells-swell-during-mitosis": { url: "/publications", code:301},
        "/publications/optimisation-imaging-flow-cytometry-analysis-single-extracellular-vesicles-using": { url: "/publications", code:301},
        "/publications/optimization-g-quadruplex-ligands-through-sar-study-combining-parallel-synthesis-and": { url: "/publications", code:301},
        "/publications/optimizing-multiplexed-imaging-experimental-design-through-tissue-spatial-segregation": { url: "/publications", code:301},
        "/publications/oral-contraceptive-use-and-ovarian-cancer-risk-brca12-mutation-carriers-international": { url: "/publications", code:301},
        "/publications/organelles-under-light-control": { url: "/publications", code:301},
        "/publications/outlier-response-anti-pd1-uveal-melanoma-reveals-germline-mbd4-mutations-hypermutated": { url: "/publications", code:301},
        "/publications/ovarian-and-breast-cancer-risks-associated-pathogenic-variants-rad51c-and-rad51d": { url: "/publications", code:301},
        "/publications/overall-survival-circulating-tumor-cell-count-driven-choice-therapy-advanced-breast": { url: "/publications", code:301},
        "/publications/oxidative-phosphorylation-metabolic-vulnerability-endocrine-therapy-and-palbociclib": { url: "/publications", code:301},
        "/publications/p53-controls-plasticity-mammary-luminal-progenitor-cells-downstream-met-signaling": { url: "/publications", code:301},
        "/publications/par-ici-la-sortie-le-sars-cov-2-utilise-les-lysosomes-pour-sortir-de-la-cellule": { url: "/publications", code:301},
        "/publications/paracrine-signalling-between-intestinal-epithelial-and-tumour-cells-induces": { url: "/publications", code:301},
        "/publications/parametric-approach-relaxing-independence-assumption-relative-survival-analysis": { url: "/publications", code:301},
        "/publications/path-12-new-powerful-tool-simultaneously-deliver-dna-methylation-profile-and-driver": { url: "/publications", code:301},
        "/publications/pbrm1-deficiency-confers-synthetic-lethality-dna-repair-inhibitors-cancer": { url: "/publications", code:301},
        "/publications/pd-l1-expression-after-neoadjuvant-chemotherapy-triple-negative-breast-cancers": { url: "/publications", code:301},
        "/publications/pdx-ou-xenogreffe-derivee-de-la-tumeur-du-patient-la-renaissance-dun-modele-oublie-son": { url: "/publications", code:301},
        "/publications/persistent-cell-migration-emerges-coupling-between-protrusion-dynamics-and-polarized": { url: "/publications", code:301},
        "/publications/personalized-logical-models-investigate-cancer-response-braf-treatments-melanomas-and": { url: "/publications", code:301},
        "/publications/pfkfb4-control-akt-signaling-essential-premigratory-and-migratory-neural-crest": { url: "/publications", code:301},
        "/publications/pharmacokinetic-analysis-18ffaza-dynamic-pet-imaging-acquisitions-highlighting-sacrum": { url: "/publications", code:301},
        "/publications/pharmacologic-reduction-mitochondrial-iron-triggers-noncanonical-baxbak-dependent-cell": { url: "/publications", code:301},
        "/publications/phase-iii-study-european-organisation-research-and-treatment-cancer-satisfaction": { url: "/publications", code:301},
        "/publications/phendv-turn-fluorescent-quadruplex-dna-probe-improving-sensitivity-drug-screening": { url: "/publications", code:301},
        "/publications/phenotypic-characterisation-ofrab6aknockout-mouse-embryonic-fibroblasts": { url: "/publications", code:301},
        "/publications/phenotyping-polarization-dynamics-immune-cells-using-lipid-droplet-cell-pairing": { url: "/publications", code:301},
        "/publications/photoactivatable-v-shaped-bifunctional-quinone-methide-precursors-new-class-selective": { url: "/publications", code:301},
        "/publications/photochemical-synthesis-and-versatile-functionalization-method-robust-porous": { url: "/publications", code:301},
        "/publications/physiboss-20-sustainable-integration-stochastic-boolean-and-agent-based-modelling-0": { url: "/publications", code:301},
        "/publications/physiboss-multi-scale-agent-based-modelling-framework-integrating-physical-dimension": { url: "/publications", code:301},
        "/publications/piecewise-stationary-motion-modeling-and-iterative-smoothing-track-heterogeneous": { url: "/publications", code:301},
        "/publications/pif1-helicase-actively-inhibited-during-meiotic-recombination-which-restrains-gene": { url: "/publications", code:301},
        "/publications/pitfalls-assessing-stromal-tumor-infiltrating-lymphocytes-stils-breast-cancer": { url: "/publications", code:301},
        "/publications/plasticity-neuroblastoma-cell-identity-defines-noradrenergic-mesenchymal-transition": { url: "/publications", code:301},
        "/publications/plk1-inhibition-exhibits-strong-anti-tumoral-activity-ccnd1-driven-breast-cancer": { url: "/publications", code:301},
        "/publications/pold3blm-dependent-pathway-handles-dsbs-transcribed-chromatin-upon-excessive-rnadna": { url: "/publications", code:301},
        "/publications/polo-kinase-1-potential-therapeutic-option-combination-conventional-chemotherapy": { url: "/publications", code:301},
        "/publications/polyfunctional-klrg-1cd57-senescent-cd4-t-cells-infiltrate-tumors-and-are-expanded": { url: "/publications", code:301},
        "/publications/polyheteroaryl-oxazolepyridine-based-compounds-selected-vitro-g-quadruplex-ligands": { url: "/publications", code:301},
        "/publications/pomalidomide-cyclophosphamide-and-dexamethasone-relapsed-multiple-myeloma": { url: "/publications", code:301},
        "/publications/porous-polystyrene-co-divinylbenzene-neutral-monolith-design-and-characterization": { url: "/publications", code:301},
        "/publications/post-intensive-care-syndrome-patients-surviving-covid-19": { url: "/publications", code:301},
        "/publications/post-transcriptional-polyadenylation-site-cleavage-maintains-3-end-processing-upon-dna": { url: "/publications", code:301},
        "/publications/prc2-independent-chromatin-compaction-and-transcriptional-repression-cancer": { url: "/publications", code:301},
        "/publications/precise-and-fast-control-dissolved-oxygen-level-tumor-chip": { url: "/publications", code:301},
        "/publications/precise-base-editing-vivo-study-developmental-signaling-and-human-pathologies": { url: "/publications", code:301},
        "/publications/precision-rush-trade-offs-between-reproducibility-and-steepness-hunchback-expression": { url: "/publications", code:301},
        "/publications/preclinical-dosimetry-proton-minibeam-radiation-therapy-robustness-analysis-and": { url: "/publications", code:301},
        "/publications/primary-cns-lymphoma-patient-derived-orthotopic-xenograft-model-capture-biological-and": { url: "/publications", code:301},
        "/publications/primary-vitreoretinal-lymphomas-display-remarkably-restricted-immunoglobulin-gene": { url: "/publications", code:301},
        "/publications/prise-en-charge-du-cancer-du-sein-quel-prelevement-pour-quel-traitement": { url: "/publications", code:301},
        "/publications/prmt1-regulates-egfr-and-wnt-signaling-pathways-and-promising-target-combinatorial": { url: "/publications", code:301},
        "/publications/prmt5-inhibition-disrupts-splicing-and-stemness-glioblastoma": { url: "/publications", code:301},
        "/publications/prognostic-clinical-and-biologic-features-overall-survival-after-relapse-childhood": { url: "/publications", code:301},
        "/publications/prognostic-impact-vitamin-b6-metabolism-lung-cancer": { url: "/publications", code:301},
        "/publications/prognostic-value-intratumoral-fusobacterium-nucleatum-and-association-immune-related": { url: "/publications", code:301},
        "/publications/prognostic-value-lymph-node-involvement-after-neoadjuvant-chemotherapy-different-among": { url: "/publications", code:301},
        "/publications/prognostic-value-tumor-infiltrating-lymphocytes-patients-early-stage-triple-negative": { url: "/publications", code:301},
        "/publications/programmable-magnetic-tweezers-and-droplet-microfluidic-device-high-throughput": { url: "/publications", code:301},
        "/publications/progression-associated-molecular-changes-basalsquamous-and-sarcomatoid-bladder": { url: "/publications", code:301},
        "/publications/progression-rapide-dune-cataracte-au-contact-dun-melanome-ciliaire-traite-par": { url: "/publications", code:301},
        "/publications/prolif-quantitative-integrin-protein-protein-interactions-and-synergistic-membrane": { url: "/publications", code:301},
        "/publications/protection-against-de-novo-methylation-instrumental-maintaining-parent-origin": { url: "/publications", code:301},
        "/publications/protein-arginine-methyltransferase-5-novel-therapeutic-target-triple-negative-breast": { url: "/publications", code:301},
        "/publications/protein-tyrosine-phosphatase-4a3-ptp4a3prl-3-promotes-aggressiveness-human-uveal": { url: "/publications", code:301},
        "/publications/proteogenomic-characterization-bladder-cancer-reveals-sensitivity-apoptosis-induced": { url: "/publications", code:301},
        "/publications/proteomic-comparison-defines-novel-markers-characterize-heterogeneous-populations": { url: "/publications", code:301},
        "/publications/protocol-automated-multivariate-quantitative-image-based-cytometry-analysis": { url: "/publications", code:301},
        "/publications/proton-flash-radiation-therapy-and-immune-infiltration-evaluation-orthotopic-glioma": { url: "/publications", code:301},
        "/publications/proton-minibeam-radiation-therapy-and-arc-therapy-proof-concept-winning-alliance": { url: "/publications", code:301},
        "/publications/protrudin-mediated-er-endosome-contact-sites-promote-mt1-mmp-exocytosis-and-cell": { url: "/publications", code:301},
        "/publications/pt-ttpy-g-quadruplex-binding-platinum-complex-induces-telomere-dysfunction-and-g-rich": { url: "/publications", code:301},
        "/publications/pyrrolopyrimidine-colchicine-binding-site-agent-pp-13-reduces-metastatic-dissemination": { url: "/publications", code:301},
        "/publications/qualitative-differences-t-cell-activation-dendritic-cell-derived-extracellular-vesicle": { url: "/publications", code:301},
        "/publications/quality-life-after-mastectomy-or-without-immediate-breast-reconstruction": { url: "/publications", code:301},
        "/publications/quantifying-entropy-production-active-fluctuations-hair-cell-bundle-time": { url: "/publications", code:301},
        "/publications/quantifying-exosome-secretion-single-cells-reveals-modulatory-role-gpcr-signaling": { url: "/publications", code:301},
        "/publications/quantifying-nanotherapeutic-penetration-using-hydrogel-based-microsystem-new-3d-vitro": { url: "/publications", code:301},
        "/publications/quantifying-solid-stress-and-elastic-energy-excised-or-situ-tumors": { url: "/publications", code:301},
        "/publications/quantitative-approach-analyzing-spatio-temporal-distribution-3d-intracellular-events": { url: "/publications", code:301},
        "/publications/quantitative-characterization-extracellular-vesicle-uptake-and-content-delivery-within": { url: "/publications", code:301},
        "/publications/quantum-dot-loaded-monofunctionalized-dna-icosahedra-single-particle-tracking": { url: "/publications", code:301},
        "/publications/question-prompt-list-advanced-cancer-patients-promoting-advance-care-planning-french": { url: "/publications", code:301},
        "/publications/quinolizinium-new-fluorescent-lysosomotropic-probe": { url: "/publications", code:301},
        "/publications/rab2a-controls-mt1-mmp-endocytic-and-e-cadherin-polarized-golgi-trafficking-promote": { url: "/publications", code:301},
        "/publications/rab6-and-dynein-drive-post-golgi-apical-transport-prevent-neuronal-progenitor": { url: "/publications", code:301},
        "/publications/rab6-dependent-retrograde-traffic-lat-controls-immune-synapse-formation-and-t-cell": { url: "/publications", code:301},
        "/publications/radiogenomics-neuroblastomas-relationships-between-imaging-phenotypes-tumor-genomic": { url: "/publications", code:301},
        "/publications/radiomics-pipeline-dedicated-breast-mri-validation-multi-scanner-phantom-study": { url: "/publications", code:301},
        "/publications/radiotherapy-retroperitoneal-liposarcoma-report-transatlantic-retroperitoneal-sarcoma": { url: "/publications", code:301},
        "/publications/raf-proteins-exert-both-specific-and-compensatory-functions-during-tumour-progression": { url: "/publications", code:301},
        "/publications/randall-type-monoclonal-immunoglobulin-deposition-disease-new-insights-pathogenesis": { url: "/publications", code:301},
        "/publications/randomised-open-label-multicentric-phase-iii-trial-evaluate-safety-and-efficacy": { url: "/publications", code:301},
        "/publications/rare-germline-heterozygous-missense-variants-brca1-associated-protein-1-bap1-cause": { url: "/publications", code:301},
        "/publications/rassf1a-puppeteer-cellular-homeostasis-fights-tumorigenesis-and-metastasis-updated": { url: "/publications", code:301},
        "/publications/rassf1a-suppresses-invasion-and-metastatic-potential-human-non-small-cell-lung-cancer": { url: "/publications", code:301},
        "/publications/rbms1-suppresses-colon-cancer-metastasis-through-targeted-stabilization-its-mrna": { url: "/publications", code:301},
        "/publications/real-time-de-novo-deposition-centromeric-histone-associated-proteins-using-auxin": { url: "/publications", code:301},
        "/publications/recipient-mucosal-associated-invariant-t-cells-control-gvhd-within-colon": { url: "/publications", code:301},
        "/publications/recurrent-noncoding-u1-snrna-mutations-drive-cryptic-splicing-shh-medulloblastoma": { url: "/publications", code:301},
        "/publications/regardless-deposition-pathway-aminoacid-31-histone-variant-h3-essential-gastrulation": { url: "/publications", code:301},
        "/publications/regeneration-zebrafish-lateral-line-neuromasts-expression-neural-progenitor-cell": { url: "/publications", code:301},
        "/publications/regulation-replicative-histone-rna-metabolism-histone-chaperone-asf1-0": { url: "/publications", code:301},
        "/publications/regulation-replicative-histone-rna-metabolism-histone-chaperone-asf1-1": { url: "/publications", code:301},
        "/publications/release-hiv-1-particles-macrophages-promoted-anchored-cytoskeleton-and-driven": { url: "/publications", code:301},
        "/publications/repeated-peripheral-infusions-anti-egfrviii-car-t-cells-combination-pembrolizumab-show": { url: "/publications", code:301},
        "/publications/replicative-histone-chaperone-caf-1-essential-maintenance-identity-and-genome": { url: "/publications", code:301},
        "/publications/repurposing-rotavirus-vaccines-intratumoral-immunotherapy-can-overcome-resistance": { url: "/publications", code:301},
        "/publications/residual-antxr1-myofibroblasts-after-chemotherapy-inhibit-anti-tumor-immunity-yap1": { url: "/publications", code:301},
        "/publications/ret-kinase-mediated-mechanical-induction-colon-stem-cells-tumor-growth-pressure": { url: "/publications", code:301},
        "/publications/retinoblastoma-and-neuroblastoma-predisposition-and-surveillance": { url: "/publications", code:301},
        "/publications/retracted-article-tumor-necrosis-factor-overcomes-immune-evasion-p53-mutant": { url: "/publications", code:301},
        "/publications/retrograde-and-anterograde-transport-lat-vesicles-during-immunological-synapse": { url: "/publications", code:301},
        "/publications/reversible-transitions-between-noradrenergic-and-mesenchymal-tumor-identities-define": { url: "/publications", code:301},
        "/publications/review-use-time-varying-covariates-fine-gray-subdistribution-hazard-competing-risk": { url: "/publications", code:301},
        "/publications/revisiting-chemistry-and-photophysics-3-n-methylpyridinium-4-ylcoumarins-designing": { url: "/publications", code:301},
        "/publications/ribosome-biogenesis-dysfunction-leads-p53-mediated-apoptosis-and-goblet-cell": { url: "/publications", code:301},
        "/publications/risk-stratification-diffuse-large-b-cell-lymphoma-using-lesion-dissemination-and": { url: "/publications", code:301},
        "/publications/rna-processing-proteins-regulate-mec1-atr-activation-promoting-generation-rpa-coated": { url: "/publications", code:301},
        "/publications/rnadna-hybrids-okazaki-fragments-contribute-establish-ku-mediated-barrier-replication": { url: "/publications", code:301},
        "/publications/roadmap-human-developmental-cell-atlas": { url: "/publications", code:301},
        "/publications/robotic-surgery-pediatric-oncology-lessons-learned-first-100-tumors-nationwide": { url: "/publications", code:301},
        "/publications/role-cancer-associated-fibroblast-subpopulations-immune-infiltration-new-means": { url: "/publications", code:301},
        "/publications/role-microtubule-end-protein-bik1-clip170-and-rho1-gtpase-snc1-trafficking": { url: "/publications", code:301},
        "/publications/role-mr1-driven-signals-and-amphiregulin-recruitment-and-repair-function-mait-cells": { url: "/publications", code:301},
        "/publications/role-tau-protein-maintaining-ribosomal-dna-stability-and-cytidine-deaminase-deficient": { url: "/publications", code:301},
        "/publications/role-yap-1-transcriptional-target-ciap2-differential-susceptibility-chemotherapy-non": { url: "/publications", code:301},
        "/publications/rotavirus-susceptibility-antibiotic-treated-mice-ascribed-diminished-expression": { url: "/publications", code:301},
        "/publications/rrp1-translocase-and-ubiquitin-ligase-activities-restrict-genome-destabilising-effects": { url: "/publications", code:301},
        "/publications/sars-cov-2-nsp3-unique-domain-sud-interacts-guanine-quadruplexes-and-g4-ligands": { url: "/publications", code:301},
        "/publications/scaling-regimes-active-turbulence-external-dissipation": { url: "/publications", code:301},
        "/publications/schwann-cells-support-oncogenic-potential-pancreatic-cancer-cells-through-tgfb": { url: "/publications", code:301},
        "/publications/scrna-seq-medulloblastoma-shows-cellular-heterogeneity-and-lineage-expansion-support": { url: "/publications", code:301},
        "/publications/second-pulmonary-resection-second-primary-lung-cancer-analysis-morbidity-and-survival": { url: "/publications", code:301},
        "/publications/secreted-gelsolin-inhibits-dngr-1-dependent-cross-presentation-and-cancer-immunity": { url: "/publications", code:301},
        "/publications/segcorr-statistical-procedure-detection-genomic-regions-correlated-expression": { url: "/publications", code:301},
        "/publications/segmentation-nuclei-histopathology-images-deep-regression-distance-map": { url: "/publications", code:301},
        "/publications/selection-bis-indolyl-pyridines-and-triphenylamines-new-inhibitors-sars-cov-2-cellular": { url: "/publications", code:301},
        "/publications/selective-control-transposable-element-expression-during-t-cell-exhaustion-and-anti-pd": { url: "/publications", code:301},
        "/publications/sense-antisense-rna-interaction-promotes-breast-cancer-metastasis-regulation-nqo1": { url: "/publications", code:301},
        "/publications/severity-endothelial-dysfunction-associated-occurrence-hemorrhagic-complications-copd": { url: "/publications", code:301},
        "/publications/sex-dependent-least-toxic-timing-irinotecan-combined-chronomodulated-chemotherapy": { url: "/publications", code:301},
        "/publications/shallowhrd-detection-homologous-recombination-deficiency-shallow-whole-genome": { url: "/publications", code:301},
        "/publications/shh-signaling-protects-atoh1-degradation-mediated-e3-ubiquitin-ligase-huwe1-neural": { url: "/publications", code:301},
        "/publications/shiga-toxin-signals-atp-and-its-effect-blocked-purinergic-receptor-antagonism": { url: "/publications", code:301},
        "/publications/short-and-long-term-evaluation-impact-proton-minibeam-radiation-therapy-motor": { url: "/publications", code:301},
        "/publications/should-i-stay-or-should-i-go-lancrage-des-cellules-souches-neurales-au-cours-du": { url: "/publications", code:301},
        "/publications/silac-based-quantitative-proteomics-reveals-pleiotropic-phenotypic-modulation-primary": { url: "/publications", code:301},
        "/publications/single-cell-analysis-crohns-disease-lesions-identifies-pathogenic-cellular-module": { url: "/publications", code:301},
        "/publications/single-cell-analysis-human-non-small-cell-lung-cancer-lesions-refines-tumor": { url: "/publications", code:301},
        "/publications/single-cell-analysis-reveals-fibroblast-clusters-linked-immunotherapy-resistance": { url: "/publications", code:301},
        "/publications/single-cell-rna-seq-analysis-reveals-dual-sensing-hiv-1-blood-axl-positive-dendritic": { url: "/publications", code:301},
        "/publications/single-cell-rna-seq-based-proteogenomics-identifies-glioblastoma-specific-transposable": { url: "/publications", code:301},
        "/publications/single-molecule-imaging-reveals-switch-between-spurious-and-functional-ncrna": { url: "/publications", code:301},
        "/publications/single-residue-variation-skeletal-muscle-myosin-enables-direct-and-selective-drug": { url: "/publications", code:301},
        "/publications/size-control-mammalian-cells-involves-modulation-both-growth-rate-and-cell-cycle": { url: "/publications", code:301},
        "/publications/sizes-proportions-and-environment": { url: "/publications", code:301},
        "/publications/slicing-and-dicing-viruses-antiviral-rna-interference-mammals": { url: "/publications", code:301},
        "/publications/smurf-transition-new-insights-ageing-end-life-studies-animal-models": { url: "/publications", code:301},
        "/publications/solid-stress-brain-tumours-causes-neuronal-loss-and-neurological-dysfunction-and-can": { url: "/publications", code:301},
        "/publications/sorafenib-metastatic-uveal-melanoma-efficacy-toxicity-and-health-related-quality-life": { url: "/publications", code:301},
        "/publications/spatial-positioning-and-matrix-programs-cancer-associated-fibroblasts-promote-t-cell": { url: "/publications", code:301},
        "/publications/spatial-positioning-and-matrix-programs-cancer-associated-fibroblasts-promote-t-cell-0": { url: "/publications", code:301},
        "/publications/spatially-modulated-proton-minibeams-results-same-increase-lifespan-uniform-target": { url: "/publications", code:301},
        "/publications/spatiotemporal-control-interferon-induced-jakstat-signalling-and-gene-transcription": { url: "/publications", code:301},
        "/publications/specific-subfamilies-transposable-elements-contribute-different-domains-t-lymphocyte": { url: "/publications", code:301},
        "/publications/specificities-exosome-versus-small-ectosome-secretion-revealed-live-intracellular": { url: "/publications", code:301},
        "/publications/specificities-secretion-and-uptake-exosomes-and-other-extracellular-vesicles-cell-cell": { url: "/publications", code:301},
        "/publications/spitfire-supermaneuverable-algorithm-fast-denoising-and-deconvolution-3d-fluorescence": { url: "/publications", code:301},
        "/publications/splicing-associated-chromatin-signatures-combinatorial-and-position-dependent-role": { url: "/publications", code:301},
        "/publications/splicing-patterns-sf3b1-mutated-uveal-melanoma-generate-shared-immunogenic-tumor": { url: "/publications", code:301},
        "/publications/stag2-mutations-alter-ctcf-anchored-loop-extrusion-reduce-cis-regulatory-interactions": { url: "/publications", code:301},
        "/publications/stam-and-hrs-interact-sequentially-ifn-receptor-control-spatiotemporal-jak-stat": { url: "/publications", code:301},
        "/publications/stiffness-increases-myofibroblast-content-and-collagen-density-mesenchymal-high-grade": { url: "/publications", code:301},
        "/publications/stk38-xpo1-axis-new-actor-physiology-and-cancer": { url: "/publications", code:301},
        "/publications/stromal-lymphocyte-infiltration-after-neoadjuvant-chemotherapy-associated-aggressive": { url: "/publications", code:301},
        "/publications/structural-insight-how-human-helicase-subunit-mcm2-may-act-histone-chaperone-together": { url: "/publications", code:301},
        "/publications/study-chromatin-remodeling-genes-implicates-smarca4-putative-player-oncogenesis": { url: "/publications", code:301},
        "/publications/studying-fate-tumor-extracellular-vesicles-high-spatiotemporal-resolution-using": { url: "/publications", code:301},
        "/publications/subtelomeres-specialized-chromatin-domains": { url: "/publications", code:301},
        "/publications/sulfobetaine-phosphonate-block-copolymer-coated-iron-oxide-nanoparticles-genomic-locus": { url: "/publications", code:301},
        "/publications/sumo-based-regulation-nuclear-positioning-spatially-regulate-homologous-recombination": { url: "/publications", code:301},
        "/publications/surface-lsp-1-phenotypic-marker-distinguishing-human-classical-versus-monocyte-derived": { url: "/publications", code:301},
        "/publications/synchronization-secretory-protein-traffic-populations-cells": { url: "/publications", code:301},
        "/publications/synergy-radiotherapy-and-cancer-vaccine-treatment-hpv-associated-head-and-neck-cancer": { url: "/publications", code:301},
        "/publications/synthesis-and-antiproliferative-activity-6brcaq-tpp-conjugates-targeting-mitochondrial": { url: "/publications", code:301},
        "/publications/synthesis-and-evaluation-photophysical-properties-series-p-conjugated-oxazole-dyes": { url: "/publications", code:301},
        "/publications/synthetic-reconstruction-hunchback-promoter-specifies-role-bicoid-zelda-and-hunchback": { url: "/publications", code:301},
        "/publications/systematic-analysis-compounds-specifically-targeting-telomeres-and-telomerase-clinical": { url: "/publications", code:301},
        "/publications/systematic-analysis-rhogefgap-localizations-uncovers-regulators-mechanosensing-and": { url: "/publications", code:301},
        "/publications/systematic-approach-identifies-p53-dream-pathway-target-genes-associated-blood-or": { url: "/publications", code:301},
        "/publications/systematic-evaluation-benchmark-g4-probes-and-g4-clinical-drugs-using-three": { url: "/publications", code:301},
        "/publications/systematic-multi-omics-cell-line-profiling-uncovers-principles-ewing-sarcoma-fusion": { url: "/publications", code:301},
        "/publications/targeted-knockout-b-catenin-adult-melanocyte-stem-cells-using-mouse-line-dctcreert2": { url: "/publications", code:301},
        "/publications/targeted-long-read-sequencing-ewing-sarcoma-6p251-susceptibility-locus-identifies": { url: "/publications", code:301},
        "/publications/targeting-cancer-stem-cells-small-molecules": { url: "/publications", code:301},
        "/publications/targeting-gpcrs-and-their-signaling-therapeutic-option-melanoma": { url: "/publications", code:301},
        "/publications/targeting-nat10-enhances-healthspan-mouse-model-human-accelerated-aging-syndrome": { url: "/publications", code:301},
        "/publications/tbc1d8b-loss-function-mutations-lead-x-linked-nephrotic-syndrome-defective-trafficking": { url: "/publications", code:301},
        "/publications/tertiary-lymphoid-structure-b-cells-narrow-regulatory-t-cells-impact-lung-cancer": { url: "/publications", code:301},
        "/publications/tgfb-induced-long-non-coding-rna-linc00313-activates-wnt-signaling-and-promotes": { url: "/publications", code:301},
        "/publications/theoretical-understanding-mammalian-preimplantation-development": { url: "/publications", code:301},
        "/publications/therapeutic-advantage-targeting-prmt5-combination-chemotherapies-or-egfrher2": { url: "/publications", code:301},
        "/publications/therapeutically-targetable-positive-feedback-loop-between-lnc-hlx-2-7-hlx-and-myc": { url: "/publications", code:301},
        "/publications/thoracic-proton-minibeam-radiation-therapy-tissue-preservation-and-survival-advantage": { url: "/publications", code:301},
        "/publications/three-component-c-h-bond-sulfonylation-imidazoheterocycles-visible-light": { url: "/publications", code:301},
        "/publications/time-measure-positional-information-maternal-hunchback-required-synchrony-bicoid": { url: "/publications", code:301},
        "/publications/tissue-deformation-modulates-twist-expression-determine-anterior-midgut": { url: "/publications", code:301},
        "/publications/tissue-resident-folr2-macrophages-associate-cd8-t-cell-infiltration-human-breast": { url: "/publications", code:301},
        "/publications/tlr-or-nod-receptor-signaling-skews-monocyte-fate-decision-distinct-mechanisms-driven": { url: "/publications", code:301},
        "/publications/topoisomerase-1-prevents-replication-stress-r-loop-enriched-transcription-termination": { url: "/publications", code:301},
        "/publications/total-metabolic-tumor-volume-and-spleen-metabolism-baseline-18f-fdg-petct-independent": { url: "/publications", code:301},
        "/publications/toward-better-understanding-t-cells-cancer": { url: "/publications", code:301},
        "/publications/trans-scale-mechanotransductive-cascade-biochemical-and-biomechanical-patterning": { url: "/publications", code:301},
        "/publications/trans-synaptic-signaling-through-glutamate-receptor-delta-1-mediates-inhibitory": { url: "/publications", code:301},
        "/publications/transcription-mediated-organization-replication-initiation-program-across-large-genes": { url: "/publications", code:301},
        "/publications/transcriptional-and-functional-analysis-cd1c-human-dendritic-cells-identifies-cd163": { url: "/publications", code:301},
        "/publications/transcriptional-programs-define-intratumoral-heterogeneity-ewing-sarcoma-single-cell": { url: "/publications", code:301},
        "/publications/transient-microfluidic-compartmentalization-using-actionable-microfilaments": { url: "/publications", code:301},
        "/publications/transmorph-unifying-computational-framework-modular-single-cell-rna-seq-data": { url: "/publications", code:301},
        "/publications/treatment-sequence-first-and-second-generation-tyrosine-kinase-inhibitor-followed": { url: "/publications", code:301},
        "/publications/trem-1-orchestrates-angiotensin-ii-induced-monocyte-trafficking-and-promotes": { url: "/publications", code:301},
        "/publications/tricellular-junction-proteins-promote-disentanglement-daughter-and-neighbour-cells": { url: "/publications", code:301},
        "/publications/triggering-tcr-developmental-checkpoint-activates-therapeutically-targetable-tumor": { url: "/publications", code:301},
        "/publications/tubulin-glycylation-controls-axonemal-dynein-activity-flagellar-beat-and-male": { url: "/publications", code:301},
        "/publications/tumor-brca-testing-can-reveal-high-tumor-mutational-burden-related-pole-pathogenic": { url: "/publications", code:301},
        "/publications/tumor-cells-and-cancer-associated-fibroblasts-updated-metabolic-perspective": { url: "/publications", code:301},
        "/publications/tumor-control-rg2-glioma-bearing-rats-comparison-between-proton-minibeam-therapy-and": { url: "/publications", code:301},
        "/publications/tumor-invasion-draining-lymph-nodes-associated-treg-accumulation-breast-cancer": { url: "/publications", code:301},
        "/publications/tunable-dnmt1-degradation-reveals-dnmt1dnmt3b-synergy-dna-methylation-and-genome": { url: "/publications", code:301},
        "/publications/turbulent-dynamics-epithelial-cell-cultures": { url: "/publications", code:301},
        "/publications/two-classes-brc-repeats-brca2-promote-rad51-nucleoprotein-filament-function-distinct": { url: "/publications", code:301},
        "/publications/two-hira-dependent-pathways-mediate-h33-de-novo-deposition-and-recycling-during": { url: "/publications", code:301},
        "/publications/two-induced-pluripotent-stem-cell-ipsc-lines-derived-patients-affected-waardenburg": { url: "/publications", code:301},
        "/publications/type-2-diabetes-related-variants-influence-risk-developing-multiple-myeloma-results": { url: "/publications", code:301},
        "/publications/tyro3-targeting-radiosensitizing-strategy-bladder-cancer-through-cell-cycle": { url: "/publications", code:301},
        "/publications/ultrabright-two-photon-excitable-red-emissive-fluorogenic-probes-fast-and-wash-free-0": { url: "/publications", code:301},
        "/publications/ultrabright-two-photon-excitable-red-emissive-fluorogenic-probes-fast-and-wash-free-1": { url: "/publications", code:301},
        "/publications/unbiased-proteomic-profiling-host-cell-extracellular-vesicle-composition-and-dynamics": { url: "/publications", code:301},
        "/publications/uncoupling-dynamin-polymerization-and-gtpase-activity-revealed-conformation-specific": { url: "/publications", code:301},
        "/publications/unprecedented-reactivity-polyamines-aldehydic-dna-modifications-structural": { url: "/publications", code:301},
        "/publications/upregulation-mevalonate-pathway-through-ewsr1-fli1egr2-regulatory-axis-confers-ewing": { url: "/publications", code:301},
        "/publications/urinary-extracellular-vesicles-contain-mature-transcriptome-enriched-circular-and-long": { url: "/publications", code:301},
        "/publications/using-dose-finding-benchmark-quantify-loss-incurred-dichotomization-phase-ii-dose": { url: "/publications", code:301},
        "/publications/using-microfluidics-and-live-cell-reporters-dissect-dynamics-tgf-b-signaling-mouse": { url: "/publications", code:301},
        "/publications/validation-high-performance-functional-assay-individual-radiosensitivity-pediatric": { url: "/publications", code:301},
        "/publications/vamp7-controls-t-cell-activation-regulating-recruitment-and-phosphorylation-vesicular": { url: "/publications", code:301},
        "/publications/vasa-nervorum-angiogenesis-prostate-cancer-perineural-invasion": { url: "/publications", code:301},
        "/publications/vc-resist-glioblastoma-cell-state-vessel-co-option-key-driver-chemoradiation": { url: "/publications", code:301},
        "/publications/vimentin-epigenetic-deregulation-bladder-cancer-associates-acquisition-invasive-and": { url: "/publications", code:301},
        "/publications/viral-infection-disrupts-intestinal-homeostasis-sting-dependent-nf-kb-signaling": { url: "/publications", code:301},
        "/publications/virtual-metabolic-human-database-integrating-human-and-gut-microbiome-metabolism": { url: "/publications", code:301},
        "/publications/virus-population-dynamics-during-infection": { url: "/publications", code:301},
        "/publications/visible-light-induced-insertion-sulfur-dioxide-mediated-electron-donor-acceptor": { url: "/publications", code:301},
        "/publications/visualization-endogenous-mitochondrial-azoreductase-activity-under-normoxic-conditions": { url: "/publications", code:301},
        "/publications/vivo-genome-wide-crispr-screens-identify-socs1-intrinsic-checkpoint-cd4-t-h-1-cell": { url: "/publications", code:301},
        "/publications/vivo-imaging-mammary-epithelial-cell-dynamics-response-lineage-biased-wntb-catenin-0": { url: "/publications", code:301},
        "/publications/whole-cell-biopanning-synthetic-phage-display-library-nanobodies-enabled-recovery": { url: "/publications", code:301},
        "/publications/whole-exome-sequencing-cell-free-dna-reveals-temporo-spatial-heterogeneity-and": { url: "/publications", code:301},
        "/publications/whole-guv-patch-clamping": { url: "/publications", code:301},
        "/publications/wnt-dependent-interaction-between-inflammatory-fibroblasts-and-folr2-macrophages": { url: "/publications", code:301},
        "/publications/x-rays-minibeam-radiation-therapy-conventional-irradiator-pilot-evaluation-f98-glioma": { url: "/publications", code:301},
        "/publications/xact-noncoding-rna-competes-xist-control-x-chromosome-activity-during-human-early": { url: "/publications", code:301},
        "/publications/xist-dependent-imprinted-x-inactivation-and-early-developmental-consequences-its": { url: "/publications", code:301},
        "/publications/zip4-protein-directly-couples-meiotic-crossover-formation-synaptonemal-complex": { url: "/publications", code:301},
        "/publications/zranb2-and-syf2-mediated-splicing-programs-converging-ect2-are-involved-breast-cancer": { url: "/publications", code:301},
        "/quantification-dev": { url: "/", code:301},
        "/rapports-annuels/html-20200907075436-Institut-Curie-Rapport-financier-2019/1-Institut-Curie-Rapport-financier-2019.html": { url: "/", code:301},
        "/rapports-annuels/html-20200907075436-Institut-Curie-Rapport-financier-2019/19-Institut-Curie-Rapport-financier-2019.html": { url: "/", code:301},
        "/rapports-annuels/html-20200907075716-Institut-Curie-Annual-report-2019/7-Institut-Curie-Annual-report-2019.html": { url: "/", code:301},
        "/rapports-annuels/html-20200907075851-Institut-Curie-Rapport-annuel-2019/29-Institut-Curie-Rapport-annuel-2019.html": { url: "/", code:301},
        "/rapports-annuels/html-20200907075851-Institut-Curie-Rapport-annuel-2019/46-Institut-Curie-Rapport-annuel-2019.html": { url: "/", code:301},
        "/recherche-et-developpement": { url: "/plateforme/curiecoretech-criblage-genetique-crisprit", code:301},
        "/recherche-translationnelle": { url: "/", code:301},
        "/recherche/acces-aux-labos": { url: "/la-recherche-et-linnovation-linstitut-curie", code:301},
        "/recherche/centre-recherche-orsay": { url: "/le-centre-de-recherche", code:301},
        "/recherche/nos-equipes-recherche": { url: "/equipes-recherche", code:301},
        "/reconstruction_mammaire": { url: "/la-reconstruction-mammaire-apres-un-cancer-du-sein", code:301},
        "/reparation-des-fourches-de-replication-et-assemblage-de-la-chromatine": { url: "/", code:301},
        "/replication-dependante-de-la-recombinaison-homologue": { url: "/", code:301},
        "/revues-scientifiques-equipe-larue": { url: "/", code:301},
        "/role-des-fibroblastes-associes-au-cancer-dans-linvasion-du-cancer-et-la-resistance-aux-traitements": { url: "/", code:301},
        "/role-des-fibroblastes-dans-lhomeostasie-de-lintestin-epithelial-et-la-cicatrisation-des-plaies": { url: "/", code:301},
        "/role-des-gtpases-de-la-famille-rab-dans-la-regulation-du-transport-intracellulaire": { url: "/", code:301},
        "/role-des-macrophages-dans-lhomeostasie-du-colon": { url: "/", code:301},
        "/seminaires-equipe-larue": { url: "/", code:301},
        "/septines": { url: "/", code:301},
        "/service-metabolomique-et-lipidomique": { url: "/plateforme/curiecoretech-metabolomique-et-lipidomique", code:301},
        "/service-social@curie.fr": { url: "/", code:301},
        "/services-plateforme-anticorps-recombinants": { url: "/", code:301},
        "/services-reseaux-et-collaborations": { url: "/", code:301},
        "/sites/all/themes/custom/curie/images/flags/en.gif": { url: "/", code:301},
        "/sites/all/themes/custom/curie/images/icone-rss.png": { url: "/", code:301},
        "/sites/default/files/logo_fr.png": { url: "/", code:301},
        "/sites/default/files/medias/documents/2020-08/CPCNRS-CD44ferCancer-FR-emb.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2021-08/Livret%20informations%20patients%20APA.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2021-12/RHU%20CASSIOPEIA_0.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2022-04/CP%20AACR%202022.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2022-05/CP%20ASCO%2030052022.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2022-09/CP%20ESMO%202022.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2022-10/Rapport_annuel_2021.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2022-11/GEDIP_V1.0_July22f.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2022-12/Remerciements_partenaires_Octobre_Rose_2022.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2023-03/Index%20égalité%20professionnelle%20femmes-hommes%202022.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2023-04/Curie_134_web_0.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2023-09/2303_04292_INSTITUT%20CURIE_RA22_Exe_VF.pdf": { url: "/", code:301},
        "/sites/default/files/medias/documents/2024-01/COURSEdelaJONQUILLE2024-OffreEntreprises-SansTarifs.pdf": { url: "/", code:301},
        "/strategies": { url: "/", code:301},
        "/taxonomy/term/132": { url: "/", code:301},
        "/taxonomy/term/1348": { url: "/", code:301},
        "/taxonomy/term/136": { url: "/", code:301},
        "/taxonomy/term/137": { url: "/", code:301},
        "/taxonomy/term/138": { url: "/", code:301},
        "/taxonomy/term/143": { url: "/", code:301},
        "/taxonomy/term/361": { url: "/", code:301},
        "/taxonomy/term/371": { url: "/", code:301},
        "/teams-presentation": { url: "/", code:301},
        "/tipit-towards-integrative-approach-precision-immunotherapy-lung-cancer": { url: "/", code:301},
        "/tirer-des-nanotubes-membranaires-de-guvs": { url: "/", code:301},
        "/trafic-membranaire-trier-deformer-couper": { url: "/", code:301},
        "/traitement-neoadjuvant-et-immunite": { url: "/", code:301},
        "/tumor-initiation-and-progression": { url: "/", code:301},
        "/unejonquillecontrelecancer2023": { url: "/ujcc2023", code:301},
        "/user/login": { url: "/", code:301},
        "/user/password": { url: "/", code:301},
        "/variants-genetiques-predisposants-aux-neoantigenes-tumoraux-publics-dans-le-melanome-uveal": { url: "/melanome-uveal", code:301},
        "/webconferences-patients": { url: "/webconferences-pour-les-patients", code:301},
        "/webconferences-reconstruction-mammaire": { url: "/", code:301},
        "/webform/nous-contacter": { url: "/contact", code:301},
        "/webform/votre-avis-sur-le-site-internet-de-linstitut-curie": { url: "/", code:301},
      },
      mask:{
        /*"/toto/zzzzz/*": {url:"/test",code:301},
        "/toto/*": {url:"/test2",code:301}*/
      }
    },
    ENGLISH: {
      single: {
        "/actualite/aacr/aacr-2018-institut-curies-work-well-represented": { url: "/news", code:301},
        "/actualite/aacr/aacr-little-vesicles-full-hope": { url: "/news", code:301},
        "/actualite/aacr/circulating-tumor-dna-new-tumor-screening": { url: "/news", code:301},
        "/actualite/aacr/esope-study-new-revelations-metastatic-breast-cancers": { url: "/news", code:301},
        "/actualite/aacr/new-strategies-knowing-and-understanding-cancer-better": { url: "/news", code:301},
        "/actualite/ascb-embo/ascb-6-presentations-curies-collaborators": { url: "/news", code:301},
        "/actualite/ascb-embo/focus-sentinel-cells-immune-system": { url: "/news", code:301},
        "/actualite/ascb-embo/mitotic-spindle-reveals-unsuspected-variations": { url: "/news", code:301},
        "/actualite/ascb-embo/stretching-cells-patricia-bassereau": { url: "/news", code:301},
        "/actualite/ascb-embo/when-glutamate-accumulates": { url: "/news", code:301},
        "/actualite/asco/asco-2018-14-presentations-and-posters-institut-curie": { url: "/news", code:301},
        "/actualite/asco/benefits-receiving-sarcoma-treatment-reference-center": { url: "/news", code:301},
        "/actualite/asco/institut-curie-attending-meeting-asco-chicago": { url: "/news", code:301},
        "/actualite/asco/lymph-node-irradiation-breast-cancer-confirmed-useful": { url: "/news", code:301},
        "/actualite/asco/ophthalmic-artery-chemosurgery-interesting-retinoblastoma": { url: "/news", code:301},
        "/actualite/asco/retroperitoneal-sarcomas-benefits-radiotherapy": { url: "/news", code:301},
        "/actualite/asco/very-active-research-immunotherapy": { url: "/news", code:301},
        "/actualite/award/anr-2019-institut-curie-teams-remarkable-success": { url: "/news", code:301},
        "/actualite/award/antonin-morillon-winner-erc-proof-concept-scholarship": { url: "/news", code:301},
        "/actualite/award/celine-vallot-cnrs-2018-bronze-medalist": { url: "/news", code:301},
        "/actualite/award/challenge-alena-shkumatava-screening-non-coding-rna": { url: "/news", code:301},
        "/actualite/award/daniele-fachinetti-wins-emergences-2018-award": { url: "/news", code:301},
        "/actualite/award/institut-curie-now-one-largest-leading-european-cancer-research-centers": { url: "/news", code:301},
        "/actualite/award/loreal-unesco-women-science-fellowships-five-winners-institut-curie": { url: "/news", code:301},
        "/actualite/award/new-success-institut-curie-researchers-european-research-council-erc": { url: "/news", code:301},
        "/actualite/award/olivier-delattre-receives-erc-synergy-grant-kill-or-differentiate-project": { url: "/news", code:301},
        "/actualite/award/prof-philip-poortmans-receives-jens-overgaard-legacy-award-estro-congress": { url: "/news", code:301},
        "/actualite/award/raphael-rodriguez-receives-tetrahedron-young-investigator-award": { url: "/news", code:301},
        "/actualite/award/researcher-jean-leon-maitre-receives-claude-paoletti-prize": { url: "/news", code:301},
        "/actualite/award/rhabdoid-tumors-trigger-immune-reaction-children": { url: "/news", code:301},
        "/actualite/award/two-young-researchers-institut-curie-future-elite-european-molecular-biology": { url: "/news", code:301},
        "/actualite/breast-cancers/breast-cancer-how-fibroblasts-increase-metastasis-formation": { url: "/news", code:301},
        "/actualite/breast-cancers/breast-cancer-treatments-tomorrow": { url: "/news", code:301},
        "/actualite/breast-cancers/chemotherapy-resistance-breast-cancer-new-factors-identified": { url: "/news", code:301},
        "/actualite/breast-cancers/covid-19-and-breast-cancer": { url: "/news", code:301},
        "/actualite/breast-cancers/genetic-predisposition-and-cancer-new-factors-are-identified": { url: "/news", code:301},
        "/actualite/breast-cancers/philip-poortmans-co-author-prestigious-synthesis-breast-cancer": { url: "/news", code:301},
        "/actualite/breast-cancers/tn-breast-cancer-dangerous-links-between-stromal-immune-and-tumor-cells": { url: "/news", code:301},
        "/actualite/breast-cancers/triple-negative-breast-cancer-effective-therapeutic-innovation-game": { url: "/news", code:301},
        "/actualite/cancers-de-lenfant/molecular-diagnosis-valuable-tool-against-childhood-cancer": { url: "/news", code:301},
        "/actualite/cancers-du-sein/breast-cancers-similarities-between-sub-families": { url: "/news", code:301},
        "/actualite/clinical-research/cancer-biomarkers-are-revolutionizing-cancer-care": { url: "/news", code:301},
        "/actualite/clinical-research/cervical-cancer-nicol-new-therapeutic-trial": { url: "/news", code:301},
        "/actualite/corporate/2019-annual-report-another-step-towards-comprehensive-cancer-center-future": { url: "/news", code:301},
        "/actualite/corporate/appointment-alain-puisieux-new-director-institut-curie-research-center": { url: "/news", code:301},
        "/actualite/corporate/basic-research-institut-curie-inestimable-source-innovation": { url: "/news", code:301},
        "/actualite/corporate/best-wishes-2019": { url: "/news", code:301},
        "/actualite/corporate/four-new-teams-research-center": { url: "/news", code:301},
        "/actualite/derniere-minute/covid-19-and-cellular-biology-how-does-virus-enter-cells-and-what": { url: "/news", code:301},
        "/actualite/derniere-minute/covid-19-and-cellular-biology-new-screening-tests-and-antiviral": { url: "/news", code:301},
        "/actualite/derniere-minute/covid-19-and-cellular-biology-targeting-new-mechanisms-involved-viral": { url: "/news", code:301},
        "/actualite/derniere-minute/covid-19-and-immunology-ways-forward-towards-new-vaccination-strategies": { url: "/news", code:301},
        "/actualite/derniere-minute/covid-19-and-radiotherapy-how-continue-care": { url: "/news", code:301},
        "/actualite/derniere-minute/covid-19-what-are-consequences-institut-curie-patients-overseas": { url: "/news", code:301},
        "/actualite/epigenetics-and-genetics/warning-about-online-genetic-testing-legal-framework-urgently": { url: "/news", code:301},
        "/actualite/epigenetique-et-genetique/childhood-genetic-disease-one-sugar-too-many": { url: "/news", code:301},
        "/actualite/epigenetique-et-genetique/deborah-bourchis-art-methylation": { url: "/news", code:301},
        "/actualite/epigenetique-et-genetique/fact-quality-control-heart-chromosomes": { url: "/news", code:301},
        "/actualite/epigenetique-et-genetique/metronome-embryo": { url: "/news", code:301},
        "/actualite/esmo/esmo-2018-nearly-50-institut-curie-colleaguess-attendance": { url: "/news", code:301},
        "/actualite/esmo/institut-curie-attending-esmo-congress-barcelona": { url: "/news", code:301},
        "/actualite/event/institut-curie-attending-meeting-aacr-atlanta": { url: "/news", code:301},
        "/actualite/event/institut-curie-attends-ascb-embo-meeting-washington": { url: "/news", code:301},
        "/actualite/event/muse-ic-when-science-and-music-meet": { url: "/news", code:301},
        "/actualite/generosite/orsay-amphitheater-baptized-nelly-riguet": { url: "/news", code:301},
        "/actualite/immunotherapie/speed-dating-between-antigen-presenting-cells-and-lymphocytes": { url: "/news", code:301},
        "/actualite/immunotherapie/toward-immunotherapies-tailored-each-patient": { url: "/news", code:301},
        "/actualite/immunotherapy/childhood-cancer-hope-rhabdoid-tumors": { url: "/news", code:301},
        "/actualite/immunotherapy/eliane-piaggio-immunotherapy-all": { url: "/news", code:301},
        "/actualite/immunotherapy/how-are-t-lymphocytes-fates-sealed": { url: "/news", code:301},
        "/actualite/immunotherapy/nono-red-flag-system-detects-hiv": { url: "/news", code:301},
        "/actualite/immunotherapy/predicting-effectiveness-immunotherapy": { url: "/news", code:301},
        "/actualite/innovation/2016-annual-report-online": { url: "/news", code:301},
        "/actualite/innovation/2017-annual-report-out": { url: "/news", code:301},
        "/actualite/innovation/amaury-martin-becoming-reference-technology-transfer-oncology": { url: "/news", code:301},
        "/actualite/innovation/brca2-unexpected-role-tumor-suppressor-gene": { url: "/news", code:301},
        "/actualite/innovation/breast-cancer-alternative-neoadjuvant-chemotherapy-post-menopausal-women": { url: "/news", code:301},
        "/actualite/innovation/bruno-goud-appointed-director-research-center": { url: "/news", code:301},
        "/actualite/innovation/cervical-cancer-initial-results-european-study": { url: "/news", code:301},
        "/actualite/innovation/charlene-estrada-wins-prestigious-loreal-unesco-women-science-fellowship": { url: "/news", code:301},
        "/actualite/innovation/covid-19-early-results-large-scale-study-immune-response-against-sars-cov-2": { url: "/news", code:301},
        "/actualite/innovation/erc-europe-looking-jean-louis-viovy-weave-oncology-future": { url: "/news", code:301},
        "/actualite/innovation/erc-yohanns-bellaiche-awarded-holy-grail-european-research": { url: "/news", code:301},
        "/actualite/innovation/european-boost-danijela-vignjevic": { url: "/news", code:301},
        "/actualite/innovation/genuage-4-dimensional-visualization-and-analysis-super-resolution-microscopy": { url: "/news", code:301},
        "/actualite/innovation/giorgio-seano-top-european-science": { url: "/news", code:301},
        "/actualite/innovation/google-maps-immune-system": { url: "/news", code:301},
        "/actualite/innovation/hiv-key-functional-healing": { url: "/news", code:301},
        "/actualite/innovation/immune-cells-guided-our-microbiota": { url: "/news", code:301},
        "/actualite/innovation/immunotherapy-specialists-present-their-results-asco-2016": { url: "/news", code:301},
        "/actualite/innovation/institut-curie-now-equipped-11-room-operating-suite": { url: "/news", code:301},
        "/actualite/innovation/institut-curie-team-iron-throne": { url: "/news", code:301},
        "/actualite/innovation/isabell-fromantin-between-clinics-and-research": { url: "/news", code:301},
        "/actualite/innovation/leukaemia-discovery-interrupter-causes-death-tumorous-cells": { url: "/news", code:301},
        "/actualite/innovation/libra-gender-balance": { url: "/news", code:301},
        "/actualite/innovation/lifetime-visionary-proposal-eu-flagship": { url: "/news", code:301},
        "/actualite/innovation/nanoparticles-and-radiotherapy-winning-combination-fight-against-cancer": { url: "/news", code:301},
        "/actualite/innovation/new-3d-chromosome-structures-revealed-very-first-stages-embryo-development": { url: "/news", code:301},
        "/actualite/innovation/new-microfluidic-method-studying-single-cell-epigenetics": { url: "/news", code:301},
        "/actualite/innovation/new-therapeutic-target-medulloblastomas": { url: "/news", code:301},
        "/actualite/innovation/permedcoe-european-center-excellence-providing-ai-assist-personalized-medicine": { url: "/news", code:301},
        "/actualite/innovation/raising-awareness-head-and-neck-cancer": { url: "/news", code:301},
        "/actualite/innovation/resistance-immunotherapy-brand-new-cellular-type-involved-tumor": { url: "/news", code:301},
        "/actualite/innovation/single-cell-biology-changes-scale": { url: "/news", code:301},
        "/actualite/innovation/two-start-ups-run-cnrs-researchers-institut-curie-win-i-lab-2019-national": { url: "/news", code:301},
        "/actualite/innovation/understanding-formation-vesicles-close-membranes-possible": { url: "/news", code:301},
        "/actualite/innovation/uveal-melanoma-discovery-new-genetic-predisposition": { url: "/news", code:301},
        "/actualite/innovation/xose-fernandez-putting-data-work-help-patients": { url: "/news", code:301},
        "/actualite/interdisciplinarite/hiv-entry-keys-discovered-cell-nucleus": { url: "/news", code:301},
        "/actualite/interdisciplinarite/way-putting-together-centromere": { url: "/news", code:301},
        "/actualite/interdisciplinarity/french-israeli-research-summit-institut-curie": { url: "/news", code:301},
        "/actualite/interdisciplinarity/hiv-cure-and-cancer-strengthening-collaborations-benefit-patients-des": { url: "/news", code:301},
        "/actualite/interdisciplinarity/maxime-dahan-director-curie-physical-chemistry-unit-dies-age-46": { url: "/news", code:301},
        "/actualite/interdisciplinarity/mc21-project-accelerating-innovation-oncology": { url: "/news", code:301},
        "/actualite/interdisciplinarity/two-way-traffic-cell-migration": { url: "/news", code:301},
        "/actualite/journee-mondiale-contre-le-cancer/new-very-promising-combination-treatments": { url: "/news", code:301},
        "/actualite/lung-cancers/immunotherapy-game-changer-lung-cancer": { url: "/news", code:301},
        "/actualite/medecine-de-precision/scandare-one-step-further-precision-medicine": { url: "/news", code:301},
        "/actualite/melanomes-de-loeil/uveal-melanoma-promising-avenue-treating-metastatic-forms": { url: "/news", code:301},
        "/actualite/octobre-rose/fatima-mechta-grigoriou-her-battle-against-triple-negative-breast-cancer": { url: "/news", code:301},
        "/actualite/octobre-rose/triple-negative-breast-cancer-combining-radiotherapy-parp-inhibitors": { url: "/news", code:301},
        "/actualite/openness/chest-center-center-excellence-thoracic-cancers": { url: "/news", code:301},
        "/actualite/openness/improving-european-healthcare-through-cell-based-interceptive-medicine": { url: "/news", code:301},
        "/actualite/openness/institut-curie-international-stage-sharing-know-how": { url: "/news", code:301},
        "/actualite/openness/marie-curie-fight-goes": { url: "/news", code:301},
        "/actualite/openness/promoting-research-europe": { url: "/news", code:301},
        "/actualite/ovarian-cancer/new-plan-action-against-ovarian-cancer": { url: "/news", code:301},
        "/actualite/parcours-patient/improved-treatment-sarcomas-and-tumors-bone-and-soft-tissue": { url: "/news", code:301},
        "/actualite/partnership/historic-partnership-between-weizmann-institute-science-and-institut-curie": { url: "/news", code:301},
        "/actualite/partnership/institut-curie-names-intel-lead-partner": { url: "/news", code:301},
        "/actualite/partnership/institut-curies-carnot-label-renewed-4-years": { url: "/news", code:301},
        "/actualite/partnership/servier-and-institut-curie-extend-duration-and-scope-partnership": { url: "/news", code:301},
        "/actualite/pediatric-cancers/childhood-cancer-improving-characterization-medulloblastoma": { url: "/news", code:301},
        "/actualite/pediatric-cancers/childhood-cancers-innovation-above-all": { url: "/news", code:301},
        "/actualite/pediatric-cancers/childhood-cancers-providing-new-therapies-more-quickly": { url: "/news", code:301},
        "/actualite/pediatric-cancers/micchado-trial-can-change-outlook-high-risk-pediatric-cancers": { url: "/news", code:301},
        "/actualite/pediatric-cancers/neuroblastomas-extremely-diverse-type-pediatric-tumors": { url: "/news", code:301},
        "/actualite/pediatric-cancers/siredo-curing-more-children-cancer": { url: "/news", code:301},
        "/actualite/pediatric-cancers/siredo-pictures": { url: "/news", code:301},
        "/actualite/pink-october/fatima-mechta-grigoriou-grand-prix-ruban-rose": { url: "/news", code:301},
        "/actualite/pink-october/invadopods-how-tumor-cell-becomes-invasive": { url: "/news", code:301},
        "/actualite/pink-october/triple-negative-breast-cancers-expanding-frontiers-struggle": { url: "/news", code:301},
        "/actualite/precision-medicine/maud-kamal-art-coordination": { url: "/news", code:301},
        "/actualite/publication/celine-vallots-team-honored-prestigious-european-grant": { url: "/news", code:301},
        "/actualite/publication/deciphering-centromeres-understand-how-cancer-cells-develop": { url: "/news", code:301},
        "/actualite/publication/gaining-better-understanding-intratumoral-heterogeneity-ewing-sarcoma-through": { url: "/news", code:301},
        "/actualite/publication/genomic-instability-and-pre-cancerous-lesions-role-r-loops-gradually-revealed": { url: "/news", code:301},
        "/actualite/publication/immunotherapy-two-populations-t-cell-precursors-discovered-non-small-cell": { url: "/news", code:301},
        "/actualite/publication/two-discoveries-better-understand-metastatic-processes-ewing-sarcoma": { url: "/news", code:301},
        "/actualite/publication/uveal-melanoma-new-findings-therapeutic-targets": { url: "/news", code:301},
        "/actualite/publication/what-lies-behind-peaceful-union-between-genes-and-retrotransposons": { url: "/news", code:301},
        "/actualite/radiotherapy/dbait-efforts-continue": { url: "/news", code:301},
        "/actualite/radiotherapy/deployment-brand-new-proton-therapy-technology": { url: "/news", code:301},
        "/actualite/radiotherapy/experimental-radiobiology-building-new-venue-ambitious-research": { url: "/news", code:301},
        "/actualite/radiotherapy/flash-radiotherapy-excitement-promising-breakthrough": { url: "/news", code:301},
        "/actualite/radiotherapy/hopes-oncological-radiotherapist": { url: "/news", code:301},
        "/actualite/radiotherapy/philip-poortmans-continuing-marie-curies-battle": { url: "/news", code:301},
        "/actualite/radiotherapy/radiotherapy-pursuing-innovation": { url: "/news", code:301},
        "/actualite/sarcomes/sarcoma-nanoparticles-optimise-treatment": { url: "/news", code:301},
        "/actualite/sarcomes/what-do-sarcoma-cells-and-liquid-crystals-have-common": { url: "/news", code:301},
        "/actualite/training/12-phd-positions-available-institut-curie-apply-now": { url: "/news", code:301},
        "/actualite/training/join-institut-curie-phd-student": { url: "/news", code:301},
        "/actualite/transmission-des-savoirs/melanoma-success-international-course": { url: "/news", code:301},
        "/actualite/uveal-melanoma/portrait-samar-alsafadi-melanome-uveal-group-manager": { url: "/news", code:301},
        "/ajax/candidature-spontanee/1": { url: "/join-institut-curie", code:301},
        "/alain-puisieux": { url: "/prof-alain-puisieux-chairman-executive-board-institut-curie", code:301},
        "/atlas-cancer-signalling-network-and-navicell": { url: "/", code:301},
        "/avemap": { url: "/", code:301},
        "/biogenesis-and-decay-long-non-coding-rnas-yeast": { url: "/", code:301},
        "/biogenesis-and-fate-pigment-granules-human-skin": { url: "/", code:301},
        "/biogenesis-and-functions-extracellular-vesicles-intercellular-communication": { url: "/", code:301},
        "/breast-cancer-and-physical-activity": { url: "/breast-cancer", code:301},
        "/breast-cancer-young-women": { url: "/breast-cancer", code:301},
        "/bulk-ev-characterisation": { url: "/", code:301},
        "/cancer-and-genome-bioinformatics-biostatistics-and-epidemiology-complex-systems-u900": { url: "/unit/u900", code:301},
        "/cancer-genome-evolution": { url: "/", code:301},
        "/cancer-heterogeneity-instability-and-plasticity-chip-u830": { url: "/unit/u830", code:301},
        "/cellular-and-chemical-biology-umr3666-u1143": { url: "/unit/umr3666-u1143", code:301},
        "/centromere-aberrations-and-genome-instability-causes-and-consequences": { url: "/research-and-innovation-institut-curie", code:301},
        "/centromere-crisis-aneuploidy-response-and-aging": { url: "/research-and-innovation-institut-curie", code:301},
        "/centromeric-formation-and-function-role-centromeric-dna": { url: "/research-and-innovation-institut-curie", code:301},
        "/cervical-cancer": { url: "/cervical-cancer", code:301},
        "/characterizing-diversity-tumor-cells-cancer": { url: "/", code:301},
        "/chemistry-and-modelling-biology-cancer-umr9187-u1196": { url: "/unit/umr9187-u1196", code:301},
        "/clinical-relevance-extracellular-vesicles-rnas-non-invasive-prognostic-biomarkers-prostate-cancer": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=16": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=17": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=20": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=21": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=22": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=23": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=24": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=25": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=26": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=27": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=28": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=29": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=30": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=31": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=32": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=33": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=34": { url: "/clinical-trials", code:301},
        "/clinical-trials?page=35": { url: "/clinical-trials", code:301},
        "/comedications-and-cancer": { url: "/", code:301},
        "/coralie-chevallier": { url: "/coralie-chevallier-member-executive-board-institut-curie", code:301},
        "/curie-coretech-extracellular-vesicles-facility": { url: "/", code:301},
        "/deciphering-inter-and-intra-tumor-heterogeneity-breast-cancer": { url: "/breast-cancer", code:301},
        "/deciphering-intra-tumoral-and-temporal-heterogeneity-idh-mutant-gliomas-brain-tumors": { url: "/", code:301},
        "/deciphering-tumor-micro-environment-heterogeneity-and-plasticity": { url: "/", code:301},
        "/dispatch/curiecoretech-core-facilities": { url: "/platforms", code:301},
        "/dispatch/curiecoretech-core-facilities?profile=3": { url: "/platforms", code:301},
        "/dispatch/curiecoretech-core-facilities(.*)": { url: "/platforms", code:301},
        "/dispatch/joint-research-units": { url: "/research-units", code:301},
        "/dispatch/joint-research-units?profile=3": { url: "/research-units", code:301},
        "/dispatch/joint-research-units(.*)": { url: "/research-units", code:301},
        "/dispatch/research-teams-and-groups": { url: "/research-teams", code:301},
        "/dispatch/research-teams-and-groups?profile=3": { url: "/research-teams", code:301},
        "/dispatch/research-teams-and-groups(.*)": { url: "/research-teams", code:301},
        "/doctors-directory": { url: "/directory", code:301},
        "/doctors-directory?doctor=pneumologie&page=1": { url: "/directory", code:301},
        "/doctors-directory?doctor=pneumologie&page=2": { url: "/directory", code:301},
        "/doctors-directory?page=0": { url: "/directory", code:301},
        "/doctors-directory?page=1": { url: "/directory", code:301},
        "/doctors-directory?page=10": { url: "/directory", code:301},
        "/doctors-directory?page=11": { url: "/directory", code:301},
        "/doctors-directory?page=12": { url: "/directory", code:301},
        "/doctors-directory?page=13": { url: "/directory", code:301},
        "/doctors-directory?page=14": { url: "/directory", code:301},
        "/doctors-directory?page=15": { url: "/directory", code:301},
        "/doctors-directory?page=16": { url: "/directory", code:301},
        "/doctors-directory?page=17": { url: "/directory", code:301},
        "/doctors-directory?page=18": { url: "/directory", code:301},
        "/doctors-directory?page=19": { url: "/directory", code:301},
        "/doctors-directory?page=2": { url: "/directory", code:301},
        "/doctors-directory?page=20": { url: "/directory", code:301},
        "/doctors-directory?page=21": { url: "/directory", code:301},
        "/doctors-directory?page=22": { url: "/directory", code:301},
        "/doctors-directory?page=23": { url: "/directory", code:301},
        "/doctors-directory?page=24": { url: "/directory", code:301},
        "/doctors-directory?page=25": { url: "/directory", code:301},
        "/doctors-directory?page=26": { url: "/directory", code:301},
        "/doctors-directory?page=27": { url: "/directory", code:301},
        "/doctors-directory?page=28": { url: "/directory", code:301},
        "/doctors-directory?page=29": { url: "/directory", code:301},
        "/doctors-directory?page=3": { url: "/directory", code:301},
        "/doctors-directory?page=30": { url: "/directory", code:301},
        "/doctors-directory?page=31": { url: "/directory", code:301},
        "/doctors-directory?page=32": { url: "/directory", code:301},
        "/doctors-directory?page=33": { url: "/directory", code:301},
        "/doctors-directory?page=34": { url: "/directory", code:301},
        "/doctors-directory?page=35": { url: "/directory", code:301},
        "/doctors-directory?page=36": { url: "/directory", code:301},
        "/doctors-directory?page=37": { url: "/directory", code:301},
        "/doctors-directory?page=38": { url: "/directory", code:301},
        "/doctors-directory?page=39": { url: "/directory", code:301},
        "/doctors-directory?page=4": { url: "/directory", code:301},
        "/doctors-directory?page=40": { url: "/directory", code:301},
        "/doctors-directory?page=41": { url: "/directory", code:301},
        "/doctors-directory?page=42": { url: "/directory", code:301},
        "/doctors-directory?page=43": { url: "/directory", code:301},
        "/doctors-directory?page=44": { url: "/directory", code:301},
        "/doctors-directory?page=45": { url: "/directory", code:301},
        "/doctors-directory?page=46": { url: "/directory", code:301},
        "/doctors-directory?page=47": { url: "/directory", code:301},
        "/doctors-directory?page=48": { url: "/directory", code:301},
        "/doctors-directory?page=49": { url: "/directory", code:301},
        "/doctors-directory?page=5": { url: "/directory", code:301},
        "/doctors-directory?page=50": { url: "/directory", code:301},
        "/doctors-directory?page=51": { url: "/directory", code:301},
        "/doctors-directory?page=52": { url: "/directory", code:301},
        "/doctors-directory?page=53": { url: "/directory", code:301},
        "/doctors-directory?page=54": { url: "/directory", code:301},
        "/doctors-directory?page=55": { url: "/directory", code:301},
        "/doctors-directory?page=56": { url: "/directory", code:301},
        "/doctors-directory?page=57": { url: "/directory", code:301},
        "/doctors-directory?page=58": { url: "/directory", code:301},
        "/doctors-directory?page=59": { url: "/directory", code:301},
        "/doctors-directory?page=6": { url: "/directory", code:301},
        "/doctors-directory?page=60": { url: "/directory", code:301},
        "/doctors-directory?page=61": { url: "/directory", code:301},
        "/doctors-directory?page=62": { url: "/directory", code:301},
        "/doctors-directory?page=63": { url: "/directory", code:301},
        "/doctors-directory?page=64": { url: "/directory", code:301},
        "/doctors-directory?page=65": { url: "/directory", code:301},
        "/doctors-directory?page=66": { url: "/directory", code:301},
        "/doctors-directory?page=67": { url: "/directory", code:301},
        "/doctors-directory?page=68": { url: "/directory", code:301},
        "/doctors-directory?page=69": { url: "/directory", code:301},
        "/doctors-directory?page=7": { url: "/directory", code:301},
        "/doctors-directory?page=70": { url: "/directory", code:301},
        "/doctors-directory?page=71": { url: "/directory", code:301},
        "/doctors-directory?page=72": { url: "/directory", code:301},
        "/doctors-directory?page=8": { url: "/directory", code:301},
        "/doctors-directory?page=9": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=1": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=10": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=11": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=12": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=2": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=3": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=4": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=5": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=6": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=7": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=8": { url: "/directory", code:301},
        "/doctors-directory?search_api_fulltext=&page=9": { url: "/directory", code:301},
        "/dossier-pedagogique/and-then-european-research-program": { url: "/uveal-melanoma", code:301},
        "/dossier-pedagogique/breast-cancer-biopsies-confirm-diagnosis": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/breast-cancer-deciding-reconstructive-surgery": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/breast-cancer-everything-you-need-know": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/breast-cancer-france-screening-program-women-between-50-and-74-years-age": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/breast-cancer-hereditary-risk-factors": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/breast-cancer-local-treatments": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/breast-cancer-optimizing-care": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/breast-cancer-other-treatments": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/breast-cancer-research-recent-breakthroughs": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/breast-cancer-review-environmental-risk-factors": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/cancers-treated-are-chordoma-and-chondrosarcoma": { url: "/proton-therapy-institut-curie", code:301},
        "/dossier-pedagogique/cancers-treated-childhood-cancers": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/cancers-treated-melanoma-eye": { url: "/uveal-melanoma", code:301},
        "/dossier-pedagogique/chemotherapy-sarcomas": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/childhood-cancer-supporting-child-and-hisher-loved-ones": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/childhood-cancers": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/childhood-cancers-brain-cancer": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/childhood-cancers-ewing-sarcoma": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/childhood-cancers-importance-precise-diagnosis": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/childhood-cancers-leukemia": { url: "/acute-myeloid-leukemia-myelodysplasia", code:301},
        "/dossier-pedagogique/childhood-cancers-lymphoma": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/childhood-cancers-making-progress-thanks-clinical-research": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/childhood-cancers-nephroblastoma": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/childhood-cancers-neuroblastoma": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/childhood-cancers-osteosarcoma": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/childhood-cancers-retinoblastoma-tumor-retina": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/childhood-cancers-rhabdomyosarcoma": { url: "/childhood-and-adolescent-cancers", code:301},
        "/dossier-pedagogique/combining-approaches": { url: "/radiotherapy-institut-curie", code:301},
        "/dossier-pedagogique/diagnosing-sarcomas-importance-specialized-management": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/epidemiology-sarcomas-who-affected": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/how-does-radiotherapy-work": { url: "/radiotherapy-institut-curie", code:301},
        "/dossier-pedagogique/immunotherapy-against-cancer-expected-revolution": { url: "/immunotherapy", code:301},
        "/dossier-pedagogique/interview-dr-remi-dendale-manager-proton-therapy-center": { url: "/orsay-proton-therapy-center", code:301},
        "/dossier-pedagogique/interview-marie-dutreix": { url: "/", code:301},
        "/dossier-pedagogique/interview-professor-roman-rouzier-medical-director-senology-department-institut": { url: "/", code:301},
        "/dossier-pedagogique/lung-cancer": { url: "/lung-cancer", code:301},
        "/dossier-pedagogique/lung-cancer-diverse-clinical-signs-are-difficult-identify": { url: "/lung-cancer", code:301},
        "/dossier-pedagogique/lung-cancer-epidemiological-data": { url: "/lung-cancer", code:301},
        "/dossier-pedagogique/lung-cancer-few-key-points": { url: "/lung-cancer", code:301},
        "/dossier-pedagogique/lung-cancer-increasingly-personalized-treatments": { url: "/lung-cancer", code:301},
        "/dossier-pedagogique/lung-cancer-tools-diagnosis": { url: "/lung-cancer", code:301},
        "/dossier-pedagogique/monoclonal-antibodies-treating-cancer-immunotherapy": { url: "/immunotherapy", code:301},
        "/dossier-pedagogique/non-specific-immunotherapy-stimulating-immune-system": { url: "/immunotherapy", code:301},
        "/dossier-pedagogique/not-one-breast-cancer-many": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/progress-management-sarcomas-three-questions-dr-sylvie-bonvalot": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/proton-therapy": { url: "/proton-therapy-institut-curie", code:301},
        "/dossier-pedagogique/proton-therapy-center-high-tech-hub": { url: "/orsay-proton-therapy-center", code:301},
        "/dossier-pedagogique/proton-therapy-center-institut-curie-focus-future": { url: "/orsay-proton-therapy-center", code:301},
        "/dossier-pedagogique/proton-therapy-center-institut-curie-remarkable-story": { url: "/orsay-proton-therapy-center", code:301},
        "/dossier-pedagogique/radiotherapy-advanced-technical-platform": { url: "/radiotherapy-institut-curie", code:301},
        "/dossier-pedagogique/radiotherapy-finely-tuned-sessions": { url: "/radiotherapy-institut-curie", code:301},
        "/dossier-pedagogique/radiotherapy-ongoing-development-cutting-edge-technique": { url: "/radiotherapy-institut-curie", code:301},
        "/dossier-pedagogique/relief-pain-and-side-effects-role-supportive-care": { url: "/pain-management-institut-curie", code:301},
        "/dossier-pedagogique/research-lung-cancer": { url: "/lung-cancer", code:301},
        "/dossier-pedagogique/retinoblastoma": { url: "/cancer-types", code:301},
        "/dossier-pedagogique/retinoblastoma-genetic-information-consultation": { url: "/cancer-types", code:301},
        "/dossier-pedagogique/returning-work-after-cancer-navigating-experience": { url: "/", code:301},
        "/dossier-pedagogique/sarcomas": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/sarcomas-causes-symptoms-and-evolution": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/sarcomas-follow-after-treatment": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/sarcomas-review-research": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/sarcomas-surgery-and-additional-treatments": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/sarcomas-treatment-institut-curie": { url: "/sarcomas", code:301},
        "/dossier-pedagogique/sebastian-amigorena-immunotherapy-raising-hopes": { url: "/immunotherapy", code:301},
        "/dossier-pedagogique/several-types-breast-cancer": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/smoking-primary-risk-factor-lung-cancer": { url: "/lung-cancer", code:301},
        "/dossier-pedagogique/taking-exercise-after-breast-cancer": { url: "/breast-cancer", code:301},
        "/dossier-pedagogique/three-questions-dr-catherine-daniel": { url: "/", code:301},
        "/dossier-pedagogique/unlocking-immune-system-against-cancerous-cells": { url: "/immunotherapy", code:301},
        "/dossier-pedagogique/uveal-melanoma": { url: "/uveal-melanoma", code:301},
        "/dossier-pedagogique/uveal-melanoma-discovering-new-therapeutic-targets": { url: "/uveal-melanoma", code:301},
        "/dossier-pedagogique/uveal-melanoma-identifying-biomarkers-prognosis": { url: "/uveal-melanoma", code:301},
        "/dossier-pedagogique/uveal-melanoma-major-involvement-physicians-and-researchers-institut-curie": { url: "/uveal-melanoma", code:301},
        "/dossier-pedagogique/uveal-melanoma-refining-diagnosis": { url: "/uveal-melanoma", code:301},
        "/dossier-pedagogique/uveal-melanoma-risk-factors": { url: "/uveal-melanoma", code:301},
        "/dossier-pedagogique/uveal-melanoma-treatments-available": { url: "/uveal-melanoma", code:301},
        "/dossier-pedagogique/uveal-melanoma-what-are-symptoms": { url: "/uveal-melanoma", code:301},
        "/dossier-pedagogique/vaccine-therapy-educating-immune-system": { url: "/immunotherapy", code:301},
        "/dossier-pedagogique/what-are-side-effects-proton-therapy": { url: "/proton-therapy-institut-curie", code:301},
        "/dossier-pedagogique/what-happens-proton-therapy-session": { url: "/proton-therapy-institut-curie", code:301},
        "/dossier-pedagogique/what-immune-system-how-does-it-work": { url: "/immunotherapy", code:301},
        "/dossier-pedagogique/why-does-immune-system-not-work-fight-cancers": { url: "/immunotherapy", code:301},
        "/educational-files/radiotherapy": { url: "/radiotherapy-institut-curie", code:301},
        "/epithelial-cell-migration-gut-homeostasis": { url: "/", code:301},
        "/equipment-chemical-library": { url: "/plateforme/curiecoretech-chemical-library", code:301},
        "/equipment-metabolomics-and-lipidomics": { url: "/plateforme/curiecoretech-metabolomics-and-lipidomics", code:301},
        "/equipments-electron-cryo-microscopy-facility": { url: "/platform/curiecoretech-cell-and-tissue-imaging-pict", code:301},
        "/equipments-ionic-microscopy-nanosims-facility": { url: "/platform/curiecoretech-cell-and-tissue-imaging-pict", code:301},
        "/equipments-light-microscopy-facility": { url: "/platform/curiecoretech-cell-and-tissue-imaging-pict", code:301},
        "/equipments-mass-spectrometry": { url: "/platform/curiecoretech-cell-and-tissue-imaging-pict", code:301},
        "/eri": { url: "/patients-and-relatives-areas", code:301},
        "/eric-nicolas": { url: "/eric-nicolas-member-executive-board-institut-curie", code:301},
        "/essai-clinique/219885-219885-galaxies-hn-202": { url: "/clinical-trial/219885-219885-galaxies-hn-202", code:301},
        "/essai-clinique/aas-lynch": { url: "/clinical-trial/aas-lynch", code:301},
        "/essai-clinique/acceleret-blu-667-2303": { url: "/clinical-trial/acceleret-blu-667-2303", code:301},
        "/essai-clinique/acse-nivolumab": { url: "/clinical-trial/acse-nivolumab", code:301},
        "/essai-clinique/acse-pembrolizumab": { url: "/clinical-trial/acse-pembrolizumab", code:301},
        "/essai-clinique/acse-vemurafenib": { url: "/clinical-trial/acse-vemurafenib", code:301},
        "/essai-clinique/adage-prodige-34": { url: "/clinical-trial/adage-prodige-34", code:301},
        "/essai-clinique/ago-ovar-229": { url: "/clinical-trial/ago-ovar-229", code:301},
        "/essai-clinique/aipac": { url: "/clinical-trial/aipac", code:301},
        "/essai-clinique/ak112-301-harmoni": { url: "/clinical-trial/ak112-301-harmoni", code:301},
        "/essai-clinique/alcina-2": { url: "/clinical-trial/alcina-2", code:301},
        "/essai-clinique/alcina-4-ic-2020-11": { url: "/clinical-trial/alcina-4-ic-2020-11", code:301},
        "/essai-clinique/alcyta-ic-2020-12": { url: "/clinical-trial/alcyta-ic-2020-12", code:301},
        "/essai-clinique/alienor": { url: "/clinical-trial/alienor", code:301},
        "/essai-clinique/alphabet": { url: "/clinical-trial/alphabet", code:301},
        "/essai-clinique/ambre": { url: "/clinical-trial/ambre", code:301},
        "/essai-clinique/amebica-prodige-38": { url: "/clinical-trial/amebica-prodige-38", code:301},
        "/essai-clinique/amg-20110261": { url: "/clinical-trial/amg-20110261", code:301},
        "/essai-clinique/amg-2019000925004": { url: "/clinical-trial/amg-2019000925004", code:301},
        "/essai-clinique/amg20170543": { url: "/clinical-trial/amg20170543", code:301},
        "/essai-clinique/amg20190294": { url: "/clinical-trial/amg20190294", code:301},
        "/essai-clinique/amg20200491-dellphi-301": { url: "/clinical-trial/amg20200491-dellphi-301", code:301},
        "/essai-clinique/amgen20210004-amg-757": { url: "/clinical-trial/amgen20210004-amg-757", code:301},
        "/essai-clinique/aml-li-1": { url: "/clinical-trial/aml-li-1", code:301},
        "/essai-clinique/anita": { url: "/clinical-trial/anita", code:301},
        "/essai-clinique/apacap": { url: "/clinical-trial/apacap", code:301},
        "/essai-clinique/arasens": { url: "/clinical-trial/arasens", code:301},
        "/essai-clinique/arianes": { url: "/clinical-trial/arianes", code:301},
        "/essai-clinique/arrow-blu-667-1101": { url: "/clinical-trial/arrow-blu-667-1101", code:301},
        "/essai-clinique/ascent-immu-132-05": { url: "/clinical-trial/ascent-immu-132-05", code:301},
        "/essai-clinique/ascordia01": { url: "/clinical-trial/ascordia01", code:301},
        "/essai-clinique/astefania-wo42633": { url: "/clinical-trial/astefania-wo42633", code:301},
        "/essai-clinique/atalante": { url: "/clinical-trial/atalante", code:301},
        "/essai-clinique/atalk-ml39349": { url: "/clinical-trial/atalk-ml39349", code:301},
        "/essai-clinique/attain-nktr-15-102-14": { url: "/clinical-trial/attain-nktr-15-102-14", code:301},
        "/essai-clinique/backbone-inter-group-1-big-1": { url: "/clinical-trial/backbone-inter-group-1-big-1", code:301},
        "/essai-clinique/bet115521": { url: "/clinical-trial/bet115521", code:301},
        "/essai-clinique/bfr-ess-01": { url: "/clinical-trial/bfr-ess-01", code:301},
        "/essai-clinique/bgb-a317-a3055-101": { url: "/clinical-trial/bgb-a317-a3055-101", code:301},
        "/essai-clinique/bgb-hnscc-201": { url: "/clinical-trial/bgb-hnscc-201", code:301},
        "/essai-clinique/bi-1336-0011": { url: "/clinical-trial/bi-1336-0011", code:301},
        "/essai-clinique/bi-13361": { url: "/clinical-trial/bi-13361", code:301},
        "/essai-clinique/bi128018": { url: "/clinical-trial/bi128018", code:301},
        "/essai-clinique/blad-rad01-getug-afu-v07": { url: "/clinical-trial/blad-rad01-getug-afu-v07", code:301},
        "/essai-clinique/blocage-01": { url: "/clinical-trial/blocage-01", code:301},
        "/essai-clinique/bnt113-01": { url: "/clinical-trial/bnt113-01", code:301},
        "/essai-clinique/bo25430": { url: "/clinical-trial/bo25430", code:301},
        "/essai-clinique/bp40657": { url: "/clinical-trial/bp40657", code:301},
        "/essai-clinique/br31-ifct1401": { url: "/clinical-trial/br31-ifct1401", code:301},
        "/essai-clinique/brainstorm": { url: "/clinical-trial/brainstorm", code:301},
        "/essai-clinique/breastimmune03-ca209-9xd": { url: "/clinical-trial/breastimmune03-ca209-9xd", code:301},
        "/essai-clinique/brocade-3-m12-914": { url: "/clinical-trial/brocade-3-m12-914", code:301},
        "/essai-clinique/bylieve-cbyl719x402": { url: "/clinical-trial/bylieve-cbyl719x402", code:301},
        "/essai-clinique/ca-4948-101": { url: "/clinical-trial/ca-4948-101", code:301},
        "/essai-clinique/ca009-002-immuno": { url: "/clinical-trial/ca009-002-immuno", code:301},
        "/essai-clinique/ca017-003": { url: "/clinical-trial/ca017-003", code:301},
        "/essai-clinique/ca045-020-pivot-io-020": { url: "/clinical-trial/ca045-020-pivot-io-020", code:301},
        "/essai-clinique/ca209-73l": { url: "/clinical-trial/ca209-73l", code:301},
        "/essai-clinique/ca209-848": { url: "/clinical-trial/ca209-848", code:301},
        "/essai-clinique/ca209-9t9-pronihn": { url: "/clinical-trial/ca209-9t9-pronihn", code:301},
        "/essai-clinique/ca223-001": { url: "/clinical-trial/ca223-001", code:301},
        "/essai-clinique/ca224-104-relativity": { url: "/clinical-trial/ca224-104-relativity", code:301},
        "/essai-clinique/canopy-1-cacz885u2301": { url: "/clinical-trial/canopy-1-cacz885u2301", code:301},
        "/essai-clinique/canopy-cacz885t2301": { url: "/clinical-trial/canopy-cacz885t2301", code:301},
        "/essai-clinique/canto-paris": { url: "/clinical-trial/canto-paris", code:301},
        "/essai-clinique/canto-saint-cloud": { url: "/clinical-trial/canto-saint-cloud", code:301},
        "/essai-clinique/card-lps14201": { url: "/clinical-trial/card-lps14201", code:301},
        "/essai-clinique/carmen-lc03-efc15858": { url: "/clinical-trial/carmen-lc03-efc15858", code:301},
        "/essai-clinique/cc-4047-brn-001": { url: "/clinical-trial/cc-4047-brn-001", code:301},
        "/essai-clinique/cc-95775-st-001": { url: "/clinical-trial/cc-95775-st-001", code:301},
        "/essai-clinique/cdrb436g2201": { url: "/clinical-trial/cdrb436g2201", code:301},
        "/essai-clinique/cdrb436g2401": { url: "/clinical-trial/cdrb436g2401", code:301},
        "/essai-clinique/chacry": { url: "/clinical-trial/chacry", code:301},
        "/essai-clinique/checkmate-384-ca209-384": { url: "/clinical-trial/checkmate-384-ca209-384", code:301},
        "/essai-clinique/checkmate-592-ca209-592": { url: "/clinical-trial/checkmate-592-ca209-592", code:301},
        "/essai-clinique/checkmate-651-ca209-651": { url: "/clinical-trial/checkmate-651-ca209-651", code:301},
        "/essai-clinique/checkmate-722-ca209-722": { url: "/clinical-trial/checkmate-722-ca209-722", code:301},
        "/essai-clinique/checkmate-816-ca209-816": { url: "/clinical-trial/checkmate-816-ca209-816", code:301},
        "/essai-clinique/checkmate-908-ca209-908": { url: "/clinical-trial/checkmate-908-ca209-908", code:301},
        "/essai-clinique/checkup": { url: "/clinical-trial/checkup", code:301},
        "/essai-clinique/chrysalis-2-73841937nsc1001": { url: "/clinical-trial/chrysalis-2-73841937nsc1001", code:301},
        "/essai-clinique/cinnamon": { url: "/clinical-trial/cinnamon", code:301},
        "/essai-clinique/circa-01-ic-2014-05": { url: "/clinical-trial/circa-01-ic-2014-05", code:301},
        "/essai-clinique/circa-hpv-ic-2017-01": { url: "/clinical-trial/circa-hpv-ic-2017-01", code:301},
        "/essai-clinique/clag525b2101": { url: "/clinical-trial/clag525b2101", code:301},
        "/essai-clinique/clever-peptide-ic-2018-06": { url: "/clinical-trial/clever-peptide-ic-2018-06", code:301},
        "/essai-clinique/clxs196x2101": { url: "/clinical-trial/clxs196x2101", code:301},
        "/essai-clinique/co40151": { url: "/clinical-trial/co40151", code:301},
        "/essai-clinique/codebreak202": { url: "/clinical-trial/codebreak202", code:301},
        "/essai-clinique/combinair3": { url: "/clinical-trial/combinair3", code:301},
        "/essai-clinique/compleement-1": { url: "/clinical-trial/compleement-1", code:301},
        "/essai-clinique/contessa-odo-te-b301": { url: "/clinical-trial/contessa-odo-te-b301", code:301},
        "/essai-clinique/copan-orl-06": { url: "/clinical-trial/copan-orl-06", code:301},
        "/essai-clinique/cotesarc-pediatrie": { url: "/clinical-trial/cotesarc-pediatrie", code:301},
        "/essai-clinique/cpdr001x2103": { url: "/clinical-trial/cpdr001x2103", code:301},
        "/essai-clinique/cpzp034x2203-veg116731": { url: "/clinical-trial/cpzp034x2203-veg116731", code:301},
        "/essai-clinique/crisp-ic-2017-08-itcc-053": { url: "/clinical-trial/crisp-ic-2017-08-itcc-053", code:301},
        "/essai-clinique/ct7001003-sumit-ela": { url: "/clinical-trial/ct7001003-sumit-ela", code:301},
        "/essai-clinique/cupisco-mx39795": { url: "/clinical-trial/cupisco-mx39795", code:301},
        "/essai-clinique/d3610c00001": { url: "/clinical-trial/d3610c00001", code:301},
        "/essai-clinique/d419ec00001": { url: "/clinical-trial/d419ec00001", code:301},
        "/essai-clinique/debio-1143-201": { url: "/clinical-trial/debio-1143-201", code:301},
        "/essai-clinique/debio-1143-202": { url: "/clinical-trial/debio-1143-202", code:301},
        "/essai-clinique/dermaclic-ic-2019-12": { url: "/clinical-trial/dermaclic-ic-2019-12", code:301},
        "/essai-clinique/destiny-breast-09": { url: "/clinical-trial/destiny-breast-09", code:301},
        "/essai-clinique/destiny-breast06-d9670c00001": { url: "/clinical-trial/destiny-breast06-d9670c00001", code:301},
        "/essai-clinique/df1001-001": { url: "/clinical-trial/df1001-001", code:301},
        "/essai-clinique/diciple-ifct-1701": { url: "/clinical-trial/diciple-ifct-1701", code:301},
        "/essai-clinique/dolaf": { url: "/clinical-trial/dolaf", code:301},
        "/essai-clinique/driiv-1-ox2016-203-01": { url: "/clinical-trial/driiv-1-ox2016-203-01", code:301},
        "/essai-clinique/ds-73001-003": { url: "/clinical-trial/ds-73001-003", code:301},
        "/essai-clinique/ds8201-u207-destiny-crc02": { url: "/clinical-trial/ds8201-u207-destiny-crc02", code:301},
        "/essai-clinique/ds8201-u301-destiny-breast02": { url: "/clinical-trial/ds8201-u301-destiny-breast02", code:301},
        "/essai-clinique/ds8201-u302-destiny-breast03": { url: "/clinical-trial/ds8201-u302-destiny-breast03", code:301},
        "/essai-clinique/ds8201-u303-destiny-breast04": { url: "/clinical-trial/ds8201-u303-destiny-breast04", code:301},
        "/essai-clinique/e7080-g000-230-olie": { url: "/clinical-trial/e7080-g000-230-olie", code:301},
        "/essai-clinique/edi-1001": { url: "/clinical-trial/edi-1001", code:301},
        "/essai-clinique/elderly": { url: "/clinical-trial/elderly", code:301},
        "/essai-clinique/ember-2-j2j-mc-jzlb": { url: "/clinical-trial/ember-2-j2j-mc-jzlb", code:301},
        "/essai-clinique/ember-3-j2j-ox-jzlc": { url: "/clinical-trial/ember-3-j2j-ox-jzlc", code:301},
        "/essai-clinique/ember-j2j-mc-jzla": { url: "/clinical-trial/ember-j2j-mc-jzla", code:301},
        "/essai-clinique/emerald": { url: "/clinical-trial/emerald", code:301},
        "/essai-clinique/enco-braf-ifct-1904": { url: "/clinical-trial/enco-braf-ifct-1904", code:301},
        "/essai-clinique/endola": { url: "/clinical-trial/endola", code:301},
        "/essai-clinique/entree-lung-gsk-205801": { url: "/clinical-trial/entree-lung-gsk-205801", code:301},
        "/essai-clinique/eortc-1447-stbsg": { url: "/clinical-trial/eortc-1447-stbsg", code:301},
        "/essai-clinique/epi-r-chop": { url: "/clinical-trial/epi-r-chop", code:301},
        "/essai-clinique/epic-1511": { url: "/clinical-trial/epic-1511", code:301},
        "/essai-clinique/epik-b2-cbyl719g12301": { url: "/clinical-trial/epik-b2-cbyl719g12301", code:301},
        "/essai-clinique/epik-b3-cbyl719h12301": { url: "/clinical-trial/epik-b3-cbyl719h12301", code:301},
        "/essai-clinique/epitop-01": { url: "/clinical-trial/epitop-01", code:301},
        "/essai-clinique/er-one-ic-2020-04": { url: "/clinical-trial/er-one-ic-2020-04", code:301},
        "/essai-clinique/eremiss-1801": { url: "/clinical-trial/eremiss-1801", code:301},
        "/essai-clinique/es4-uic-ic-2020-18": { url: "/clinical-trial/es4-uic-ic-2020-18", code:301},
        "/essai-clinique/esmart": { url: "/clinical-trial/esmart", code:301},
        "/essai-clinique/estrotimp": { url: "/clinical-trial/estrotimp", code:301},
        "/essai-clinique/euronet-phl-c2": { url: "/clinical-trial/euronet-phl-c2", code:301},
        "/essai-clinique/eusa-pass-eusa-db-0001": { url: "/clinical-trial/eusa-pass-eusa-db-0001", code:301},
        "/essai-clinique/express": { url: "/clinical-trial/express", code:301},
        "/essai-clinique/fast": { url: "/clinical-trial/fast", code:301},
        "/essai-clinique/fdg-immun": { url: "/clinical-trial/fdg-immun", code:301},
        "/essai-clinique/figaro": { url: "/clinical-trial/figaro", code:301},
        "/essai-clinique/first-3000-03-005engot-ov44": { url: "/clinical-trial/first-3000-03-005engot-ov44", code:301},
        "/essai-clinique/forward-1": { url: "/clinical-trial/forward-1", code:301},
        "/essai-clinique/fraction-lung-ca018-001": { url: "/clinical-trial/fraction-lung-ca018-001", code:301},
        "/essai-clinique/ganea-3": { url: "/clinical-trial/ganea-3", code:301},
        "/essai-clinique/gata": { url: "/clinical-trial/gata", code:301},
        "/essai-clinique/gct1047-01": { url: "/clinical-trial/gct1047-01", code:301},
        "/essai-clinique/geometry-iii-cinc280a2301": { url: "/clinical-trial/geometry-iii-cinc280a2301", code:301},
        "/essai-clinique/gerico-14": { url: "/clinical-trial/gerico-14", code:301},
        "/essai-clinique/getug-storm-01": { url: "/clinical-trial/getug-storm-01", code:301},
        "/essai-clinique/gm102": { url: "/clinical-trial/gm102", code:301},
        "/essai-clinique/go30103-tigit": { url: "/clinical-trial/go30103-tigit", code:301},
        "/essai-clinique/go40871": { url: "/clinical-trial/go40871", code:301},
        "/essai-clinique/great-1550-c081": { url: "/clinical-trial/great-1550-c081", code:301},
        "/essai-clinique/gsk213406": { url: "/clinical-trial/gsk213406", code:301},
        "/essai-clinique/gynet": { url: "/clinical-trial/gynet", code:301},
        "/essai-clinique/hector-ic-2018-09": { url: "/clinical-trial/hector-ic-2018-09", code:301},
        "/essai-clinique/her2climb-ont-380-206": { url: "/clinical-trial/her2climb-ont-380-206", code:301},
        "/essai-clinique/her2climb02-sgntuc-016": { url: "/clinical-trial/her2climb02-sgntuc-016", code:301},
        "/essai-clinique/herseq-puma-ner-9501": { url: "/clinical-trial/herseq-puma-ner-9501", code:301},
        "/essai-clinique/herthena-lung01-u31402-u201": { url: "/clinical-trial/herthena-lung01-u31402-u201", code:301},
        "/essai-clinique/hpvdcvax": { url: "/clinical-trial/hpvdcvax", code:301},
        "/essai-clinique/hr-nbl2": { url: "/clinical-trial/hr-nbl2", code:301},
        "/essai-clinique/hypog-01": { url: "/clinical-trial/hypog-01", code:301},
        "/essai-clinique/i3y-mc-jpcs": { url: "/clinical-trial/i3y-mc-jpcs", code:301},
        "/essai-clinique/i4t-mc-jvdf": { url: "/clinical-trial/i4t-mc-jvdf", code:301},
        "/essai-clinique/icing-orl": { url: "/clinical-trial/icing-orl", code:301},
        "/essai-clinique/ifm-2017-03": { url: "/clinical-trial/ifm-2017-03", code:301},
        "/essai-clinique/imatrix-alectinib-go42286": { url: "/clinical-trial/imatrix-alectinib-go42286", code:301},
        "/essai-clinique/imcgp100-202": { url: "/clinical-trial/imcgp100-202", code:301},
        "/essai-clinique/immuno-addict-gfpc-06-2018": { url: "/clinical-trial/immuno-addict-gfpc-06-2018", code:301},
        "/essai-clinique/immuno-bil-d48-1-prodige-57": { url: "/clinical-trial/immuno-bil-d48-1-prodige-57", code:301},
        "/essai-clinique/impassion-030-big16-05wo39391": { url: "/clinical-trial/impassion-030-big16-05wo39391", code:301},
        "/essai-clinique/impassion-131-mo39196": { url: "/clinical-trial/impassion-131-mo39196", code:301},
        "/essai-clinique/impassion-132-mo39193": { url: "/clinical-trial/impassion-132-mo39193", code:301},
        "/essai-clinique/incb-86550-102": { url: "/clinical-trial/incb-86550-102", code:301},
        "/essai-clinique/induce-1-gsk-204691": { url: "/clinical-trial/induce-1-gsk-204691", code:301},
        "/essai-clinique/induce-3-gsk-209229": { url: "/clinical-trial/induce-3-gsk-209229", code:301},
        "/essai-clinique/induce-4-gsk-209227": { url: "/clinical-trial/induce-4-gsk-209227", code:301},
        "/essai-clinique/inform2-nivent-ic-2018-07": { url: "/clinical-trial/inform2-nivent-ic-2018-07", code:301},
        "/essai-clinique/intermediate-01": { url: "/clinical-trial/intermediate-01", code:301},
        "/essai-clinique/ionesco-ifct-1601": { url: "/clinical-trial/ionesco-ifct-1601", code:301},
        "/essai-clinique/irocas-prodige-52": { url: "/clinical-trial/irocas-prodige-52", code:301},
        "/essai-clinique/j10-mc-jzhb": { url: "/clinical-trial/j10-mc-jzhb", code:301},
        "/essai-clinique/j1o-mc-jzhd": { url: "/clinical-trial/j1o-mc-jzhd", code:301},
        "/essai-clinique/j1s-mc-jv01": { url: "/clinical-trial/j1s-mc-jv01", code:301},
        "/essai-clinique/j1s-mc-jv02": { url: "/clinical-trial/j1s-mc-jv02", code:301},
        "/essai-clinique/javelin-hn100-b9991016": { url: "/clinical-trial/javelin-hn100-b9991016", code:301},
        "/essai-clinique/lcb-1801-001": { url: "/clinical-trial/lcb-1801-001", code:301},
        "/essai-clinique/le-declic-epri-ic-2019-06": { url: "/clinical-trial/le-declic-epri-ic-2019-06", code:301},
        "/essai-clinique/list-ca209-7hx": { url: "/clinical-trial/list-ca209-7hx", code:301},
        "/essai-clinique/loxo-bcl-20001": { url: "/clinical-trial/loxo-bcl-20001", code:301},
        "/essai-clinique/loxo-ext-17005": { url: "/clinical-trial/loxo-ext-17005", code:301},
        "/essai-clinique/luminosity-m14-239": { url: "/clinical-trial/luminosity-m14-239", code:301},
        "/essai-clinique/lung-art": { url: "/clinical-trial/lung-art", code:301},
        "/essai-clinique/lym1001-67856633lym1001": { url: "/clinical-trial/lym1001-67856633lym1001", code:301},
        "/essai-clinique/lynparza-d0816c000025": { url: "/clinical-trial/lynparza-d0816c000025", code:301},
        "/essai-clinique/m15-819": { url: "/clinical-trial/m15-819", code:301},
        "/essai-clinique/m16-074": { url: "/clinical-trial/m16-074", code:301},
        "/essai-clinique/m19-037": { url: "/clinical-trial/m19-037", code:301},
        "/essai-clinique/m19-894": { url: "/clinical-trial/m19-894", code:301},
        "/essai-clinique/m20-111": { url: "/clinical-trial/m20-111", code:301},
        "/essai-clinique/m20-621": { url: "/clinical-trial/m20-621", code:301},
        "/essai-clinique/magtrivacsein-projet-magtn3": { url: "/clinical-trial/magtrivacsein-projet-magtn3", code:301},
        "/essai-clinique/mapam-01": { url: "/clinical-trial/mapam-01", code:301},
        "/essai-clinique/mariposa-2-61186372nsc3002": { url: "/clinical-trial/mariposa-2-61186372nsc3002", code:301},
        "/essai-clinique/mariposa-73841937nsc3003": { url: "/clinical-trial/mariposa-73841937nsc3003", code:301},
        "/essai-clinique/mcla-128-cl01": { url: "/clinical-trial/mcla-128-cl01", code:301},
        "/essai-clinique/mcla-128-cl02": { url: "/clinical-trial/mcla-128-cl02", code:301},
        "/essai-clinique/mcla-158-cl01": { url: "/clinical-trial/mcla-158-cl01", code:301},
        "/essai-clinique/medea": { url: "/clinical-trial/medea", code:301},
        "/essai-clinique/melrose": { url: "/clinical-trial/melrose", code:301},
        "/essai-clinique/meru-m16-298": { url: "/clinical-trial/meru-m16-298", code:301},
        "/essai-clinique/metro-pd1-1708": { url: "/clinical-trial/metro-pd1-1708", code:301},
        "/essai-clinique/mibi-thyr": { url: "/clinical-trial/mibi-thyr", code:301},
        "/essai-clinique/micchado-ic-2017-02": { url: "/clinical-trial/micchado-ic-2017-02", code:301},
        "/essai-clinique/midas-ifm-202-02": { url: "/clinical-trial/midas-ifm-202-02", code:301},
        "/essai-clinique/mirasol": { url: "/clinical-trial/mirasol", code:301},
        "/essai-clinique/mk-1454-001-sting": { url: "/clinical-trial/mk-1454-001-sting", code:301},
        "/essai-clinique/mk-7902-013": { url: "/clinical-trial/mk-7902-013", code:301},
        "/essai-clinique/mk3475-365": { url: "/clinical-trial/mk3475-365", code:301},
        "/essai-clinique/mk3475-522": { url: "/clinical-trial/mk3475-522", code:301},
        "/essai-clinique/mk3475-689-keynote-689": { url: "/clinical-trial/mk3475-689-keynote-689", code:301},
        "/essai-clinique/mk3475-756-keynote-756": { url: "/clinical-trial/mk3475-756-keynote-756", code:301},
        "/essai-clinique/mk3475-826-keynote-826": { url: "/clinical-trial/mk3475-826-keynote-826", code:301},
        "/essai-clinique/mk3475-992": { url: "/clinical-trial/mk3475-992", code:301},
        "/essai-clinique/mk3475-b96-engot-ov65": { url: "/clinical-trial/mk3475-b96-engot-ov65", code:301},
        "/essai-clinique/mk4621-002": { url: "/clinical-trial/mk4621-002", code:301},
        "/essai-clinique/mk7684a-005": { url: "/clinical-trial/mk7684a-005", code:301},
        "/essai-clinique/mk7902-007-leap-007": { url: "/clinical-trial/mk7902-007-leap-007", code:301},
        "/essai-clinique/mk7902-008-leap-008": { url: "/clinical-trial/mk7902-008-leap-008", code:301},
        "/essai-clinique/mk7902-009-leap-009": { url: "/clinical-trial/mk7902-009-leap-009", code:301},
        "/essai-clinique/mk7902-011-leap-011": { url: "/clinical-trial/mk7902-011-leap-011", code:301},
        "/essai-clinique/monarche-i3y-mc-jpcf": { url: "/clinical-trial/monarche-i3y-mc-jpcf", code:301},
        "/essai-clinique/mondrian": { url: "/clinical-trial/mondrian", code:301},
        "/essai-clinique/most-plus": { url: "/clinical-trial/most-plus", code:301},
        "/essai-clinique/movie-m17-gep-15": { url: "/clinical-trial/movie-m17-gep-15", code:301},
        "/essai-clinique/ms1000070-176": { url: "/clinical-trial/ms1000070-176", code:301},
        "/essai-clinique/ms100070-0087": { url: "/clinical-trial/ms100070-0087", code:301},
        "/essai-clinique/ms200647-0005": { url: "/clinical-trial/ms200647-0005", code:301},
        "/essai-clinique/ms200647-0020breast020": { url: "/clinical-trial/ms200647-0020breast020", code:301},
        "/essai-clinique/mucila": { url: "/clinical-trial/mucila", code:301},
        "/essai-clinique/multisarc-c16-40": { url: "/clinical-trial/multisarc-c16-40", code:301},
        "/essai-clinique/nanorad2": { url: "/clinical-trial/nanorad2", code:301},
        "/essai-clinique/nanoray-312": { url: "/clinical-trial/nanoray-312", code:301},
        "/essai-clinique/nant-2015-02-ic-2018-05": { url: "/clinical-trial/nant-2015-02-ic-2018-05", code:301},
        "/essai-clinique/natalee-clee011o12301c": { url: "/clinical-trial/natalee-clee011o12301c", code:301},
        "/essai-clinique/nbtxr3-102": { url: "/clinical-trial/nbtxr3-102", code:301},
        "/essai-clinique/neo-checkray": { url: "/clinical-trial/neo-checkray", code:301},
        "/essai-clinique/neovab": { url: "/clinical-trial/neovab", code:301},
        "/essai-clinique/nicol-ic-2016-08": { url: "/clinical-trial/nicol-ic-2016-08", code:301},
        "/essai-clinique/nipinec-gco-001": { url: "/clinical-trial/nipinec-gco-001", code:301},
        "/essai-clinique/nivoglio": { url: "/clinical-trial/nivoglio", code:301},
        "/essai-clinique/nivopost-op-gortec-2018-01": { url: "/clinical-trial/nivopost-op-gortec-2018-01", code:301},
        "/essai-clinique/nivothym-eortc-1525": { url: "/clinical-trial/nivothym-eortc-1525", code:301},
        "/essai-clinique/oreo": { url: "/clinical-trial/oreo", code:301},
        "/essai-clinique/pacha-01": { url: "/clinical-trial/pacha-01", code:301},
        "/essai-clinique/pact-01": { url: "/clinical-trial/pact-01", code:301},
        "/essai-clinique/pada-1": { url: "/clinical-trial/pada-1", code:301},
        "/essai-clinique/pairs-aja-ic2017-10": { url: "/clinical-trial/pairs-aja-ic2017-10", code:301},
        "/essai-clinique/palatine-uc-01401814": { url: "/clinical-trial/palatine-uc-01401814", code:301},
        "/essai-clinique/papillon-61186372nsc3001": { url: "/clinical-trial/papillon-61186372nsc3001", code:301},
        "/essai-clinique/patina": { url: "/clinical-trial/patina", code:301},
        "/essai-clinique/peace-1-getug-afu-21": { url: "/clinical-trial/peace-1-getug-afu-21", code:301},
        "/essai-clinique/peace-2-getug-afu-23": { url: "/clinical-trial/peace-2-getug-afu-23", code:301},
        "/essai-clinique/pecati-medopp341": { url: "/clinical-trial/pecati-medopp341", code:301},
        "/essai-clinique/penelope-b": { url: "/clinical-trial/penelope-b", code:301},
        "/essai-clinique/perspective-ms202202-0002": { url: "/clinical-trial/perspective-ms202202-0002", code:301},
        "/essai-clinique/pevosqp17": { url: "/clinical-trial/pevosqp17", code:301},
        "/essai-clinique/phitt-rg15-114": { url: "/clinical-trial/phitt-rg15-114", code:301},
        "/essai-clinique/pisarro": { url: "/clinical-trial/pisarro", code:301},
        "/essai-clinique/plume": { url: "/clinical-trial/plume", code:301},
        "/essai-clinique/pravaprev-01": { url: "/clinical-trial/pravaprev-01", code:301},
        "/essai-clinique/presto-getug-afu-36": { url: "/clinical-trial/presto-getug-afu-36", code:301},
        "/essai-clinique/proactif-01": { url: "/clinical-trial/proactif-01", code:301},
        "/essai-clinique/provin": { url: "/clinical-trial/provin", code:301},
        "/essai-clinique/prt2527-02": { url: "/clinical-trial/prt2527-02", code:301},
        "/essai-clinique/qpl-mds": { url: "/clinical-trial/qpl-mds", code:301},
        "/essai-clinique/radio-rythmic-01-ic-2020-09": { url: "/clinical-trial/radio-rythmic-01-ic-2020-09", code:301},
        "/essai-clinique/radioparp": { url: "/clinical-trial/radioparp", code:301},
        "/essai-clinique/ragnar-42756493can2002": { url: "/clinical-trial/ragnar-42756493can2002", code:301},
        "/essai-clinique/ramp-201-vs-6766-201-engot-ov60": { url: "/clinical-trial/ramp-201-vs-6766-201-engot-ov60", code:301},
        "/essai-clinique/reach-gortec-2017-01": { url: "/clinical-trial/reach-gortec-2017-01", code:301},
        "/essai-clinique/real-moov-lung": { url: "/clinical-trial/real-moov-lung", code:301},
        "/essai-clinique/realysa": { url: "/clinical-trial/realysa", code:301},
        "/essai-clinique/regiri-prodige-58": { url: "/clinical-trial/regiri-prodige-58", code:301},
        "/essai-clinique/regomune": { url: "/clinical-trial/regomune", code:301},
        "/essai-clinique/retino-2018-ic-2019-05": { url: "/clinical-trial/retino-2018-ic-2019-05", code:301},
        "/essai-clinique/revcci": { url: "/clinical-trial/revcci", code:301},
        "/essai-clinique/rocsan": { url: "/clinical-trial/rocsan", code:301},
        "/essai-clinique/romance-rad05-uc-01071803": { url: "/clinical-trial/romance-rad05-uc-01071803", code:301},
        "/essai-clinique/rosaline": { url: "/clinical-trial/rosaline", code:301},
        "/essai-clinique/rtep7-ifct-1402": { url: "/clinical-trial/rtep7-ifct-1402", code:301},
        "/essai-clinique/safir-02-lung": { url: "/clinical-trial/safir-02-lung", code:301},
        "/essai-clinique/safir-pi3k": { url: "/clinical-trial/safir-pi3k", code:301},
        "/essai-clinique/safir-tor": { url: "/clinical-trial/safir-tor", code:301},
        "/essai-clinique/safir02-breast": { url: "/clinical-trial/safir02-breast", code:301},
        "/essai-clinique/salome-ic-2019-13": { url: "/clinical-trial/salome-ic-2019-13", code:301},
        "/essai-clinique/samco-ffcd1603-prodige-54": { url: "/clinical-trial/samco-ffcd1603-prodige-54", code:301},
        "/essai-clinique/santal-gortec-2016-02": { url: "/clinical-trial/santal-gortec-2016-02", code:301},
        "/essai-clinique/sarcome-13": { url: "/clinical-trial/sarcome-13", code:301},
        "/essai-clinique/sarcome-13-pediatrie": { url: "/clinical-trial/sarcome-13-pediatrie", code:301},
        "/essai-clinique/savannah-d5084c00007": { url: "/clinical-trial/savannah-d5084c00007", code:301},
        "/essai-clinique/scandare-ic-2016-03": { url: "/clinical-trial/scandare-ic-2016-03", code:301},
        "/essai-clinique/scarce-c17-02-prodige-60": { url: "/clinical-trial/scarce-c17-02-prodige-60", code:301},
        "/essai-clinique/seal-kcp-330-020": { url: "/clinical-trial/seal-kcp-330-020", code:301},
        "/essai-clinique/senticol-iii": { url: "/clinical-trial/senticol-iii", code:301},
        "/essai-clinique/serena-2-d8530c00002": { url: "/clinical-trial/serena-2-d8530c00002", code:301},
        "/essai-clinique/serena-4": { url: "/clinical-trial/serena-4", code:301},
        "/essai-clinique/serena-6-d8534c00001": { url: "/clinical-trial/serena-6-d8534c00001", code:301},
        "/essai-clinique/shiva02-ic-2016-06": { url: "/clinical-trial/shiva02-ic-2016-06", code:301},
        "/essai-clinique/siop-ependymoma-ii": { url: "/clinical-trial/siop-ependymoma-ii", code:301},
        "/essai-clinique/skyline": { url: "/clinical-trial/skyline", code:301},
        "/essai-clinique/skyscraper-06-bo42592": { url: "/clinical-trial/skyscraper-06-bo42592", code:301},
        "/essai-clinique/skyscraper-09-bo42533": { url: "/clinical-trial/skyscraper-09-bo42533", code:301},
        "/essai-clinique/sophia-cp-mgah22-04": { url: "/clinical-trial/sophia-cp-mgah22-04", code:301},
        "/essai-clinique/start": { url: "/clinical-trial/start", code:301},
        "/essai-clinique/startrk-2-go40782": { url: "/clinical-trial/startrk-2-go40782", code:301},
        "/essai-clinique/sterimgli": { url: "/clinical-trial/sterimgli", code:301},
        "/essai-clinique/strass-2-etude-1809-stbsg": { url: "/clinical-trial/strass-2-etude-1809-stbsg", code:301},
        "/essai-clinique/stream-eortc-1559-hncg": { url: "/clinical-trial/stream-eortc-1559-hncg", code:301},
        "/essai-clinique/sudoped": { url: "/clinical-trial/sudoped", code:301},
        "/essai-clinique/summit-puma-ner-0502": { url: "/clinical-trial/summit-puma-ner-0502", code:301},
        "/essai-clinique/synergy": { url: "/clinical-trial/synergy", code:301},
        "/essai-clinique/tas6417-201": { url: "/clinical-trial/tas6417-201", code:301},
        "/essai-clinique/tedopam-prodige-63": { url: "/clinical-trial/tedopam-prodige-63", code:301},
        "/essai-clinique/temokids": { url: "/clinical-trial/temokids", code:301},
        "/essai-clinique/tempos-getug-p14": { url: "/clinical-trial/tempos-getug-p14", code:301},
        "/essai-clinique/tet-sel": { url: "/clinical-trial/tet-sel", code:301},
        "/essai-clinique/tg400112": { url: "/clinical-trial/tg400112", code:301},
        "/essai-clinique/tg405001": { url: "/clinical-trial/tg405001", code:301},
        "/essai-clinique/tg405002": { url: "/clinical-trial/tg405002", code:301},
        "/essai-clinique/topnivo-orl09": { url: "/clinical-trial/topnivo-orl09", code:301},
        "/essai-clinique/topology": { url: "/clinical-trial/topology", code:301},
        "/essai-clinique/trans-rosalee": { url: "/clinical-trial/trans-rosalee", code:301},
        "/essai-clinique/trilynx-debio-1143-scchn-301": { url: "/clinical-trial/trilynx-debio-1143-scchn-301", code:301},
        "/essai-clinique/triton2-co-338-052": { url: "/clinical-trial/triton2-co-338-052", code:301},
        "/essai-clinique/triton3-co-338-063": { url: "/clinical-trial/triton3-co-338-063", code:301},
        "/essai-clinique/trizell-rad-ifn-mm-301": { url: "/clinical-trial/trizell-rad-ifn-mm-301", code:301},
        "/essai-clinique/tropics-02-immu132-09": { url: "/clinical-trial/tropics-02-immu132-09", code:301},
        "/essai-clinique/tropion-lung01-ds1062-u301": { url: "/clinical-trial/tropion-lung01-ds1062-u301", code:301},
        "/essai-clinique/tropion-lung04-ds1062-u104": { url: "/clinical-trial/tropion-lung04-ds1062-u104", code:301},
        "/essai-clinique/tropion-lung05-ds1062-u202": { url: "/clinical-trial/tropion-lung05-ds1062-u202", code:301},
        "/essai-clinique/tumospec-onco04-paris": { url: "/clinical-trial/tumospec-onco04-paris", code:301},
        "/essai-clinique/u31287-u203-patritumab": { url: "/clinical-trial/u31287-u203-patritumab", code:301},
        "/essai-clinique/ultimate": { url: "/clinical-trial/ultimate", code:301},
        "/essai-clinique/unirad": { url: "/clinical-trial/unirad", code:301},
        "/essai-clinique/utola": { url: "/clinical-trial/utola", code:301},
        "/essai-clinique/vraiment": { url: "/clinical-trial/vraiment", code:301},
        "/essai-clinique/xenera-1-bi-1280-0022": { url: "/clinical-trial/xenera-1-bi-1280-0022", code:301},
        "/essai-clinique/xl-184021": { url: "/clinical-trial/xl-184021", code:301},
        "/essai-clinique/yo39523": { url: "/clinical-trial/yo39523", code:301},
        "/essai-clinique/zn-c3-003": { url: "/clinical-trial/zn-c3-003", code:301},
        "/ev-isolation": { url: "/", code:301},
        "/ev-quantification": { url: "/", code:301},
        "/evenements-scientifiques": { url: "/scientific-events", code:301},
        "/evenements-scientifiques?date_from_month=1&date_from_year=2024&date_to_month=8&date_to_year=2024&page=0": { url: "/scientific-events", code:301},
        "/evenements-scientifiques?date_from_month=1&date_from_year=2024&date_to_month=8&date_to_year=2024&page=1": { url: "/scientific-events", code:301},
        "/evenements-scientifiques?page=0": { url: "/scientific-events", code:301},
        "/evenements-scientifiques?page=1": { url: "/scientific-events", code:301},
        "/evenements-scientifiques?page=2": { url: "/scientific-events", code:301},
        "/evenements-scientifiques?page=3": { url: "/scientific-events", code:301},
        "/evenements-scientifiques?profile=3": { url: "/scientific-events", code:301},
        "/evenements-scientifiques?profile=3&page=0": { url: "/scientific-events", code:301},
        "/evenements-scientifiques?profile=3&page=1": { url: "/scientific-events", code:301},
        "/evenements-scientifiques?profile=3&page=2": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/1er-seminaire-institut-curie-cooperation-ville-hopital-femmes-haut-risque": { url: "/scientific-event/1er-seminaire-institut-curie-cooperation-ville-hopital-femmes-haut-risque", code:301},
        "/evenements-scientifiques/1st-ferroptosis-france-symposium": { url: "/scientific-event/1st-ferroptosis-france-symposium", code:301},
        "/evenements-scientifiques/3rd-psl-chemical-biology-symposium": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/6th-international-breast-cancer-course-clinics-biology": { url: "/scientific-event/6th-international-breast-cancer-course-clinics-biology", code:301},
        "/evenements-scientifiques/active-nematic-order-morphogenesis-hydra": { url: "/scientific-event/active-nematic-order-morphogenesis-hydra", code:301},
        "/evenements-scientifiques/acute-and-chronic-endothelial-cell-responses-modulate-radiation-therapy": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/advanced-course-immunity-and-cancer-immunotherapy-2024": { url: "/scientific-event/advanced-course-immunity-and-cancer-immunotherapy-2024", code:301},
        "/evenements-scientifiques/artificial-intelligence-radiomics-assessment-response-immune-therapies": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/bioengineering-human-embryo-and-organ-models": { url: "/scientific-event/bioengineering-human-embryo-and-organ-models", code:301},
        "/evenements-scientifiques/bioinfo-singlecell-seminars": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/biophotonics-robots-ghosts": { url: "/scientific-event/biophotonics-robots-ghosts", code:301},
        "/evenements-scientifiques/building-multimodal-atlas-vertebrate-development": { url: "/scientific-event/building-multimodal-atlas-vertebrate-development", code:301},
        "/evenements-scientifiques/cell-biological-mechanisms-mediating-optimal-immunosurveillance": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/cell-size-control": { url: "/scientific-event/cell-size-control", code:301},
        "/evenements-scientifiques/chatgpt-scientific-writing": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/chemical-biology-tools-unravel-biological-functions-nucleic-acids": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/circuit-approach-understand-aging": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/common-threads-regeneration-mechano-chemical-insights-jellyfish-model": { url: "/scientific-event/common-threads-regeneration-mechano-chemical-insights-jellyfish-model", code:301},
        "/evenements-scientifiques/coping-mechanical-stress-tissue-dynamics-during-development-homeostasis": { url: "/scientific-event/coping-mechanical-stress-tissue-dynamics-during-development-homeostasis", code:301},
        "/evenements-scientifiques/countoscope-measuring-dynamics-counting-particles-boxes": { url: "/scientific-event/countoscope-measuring-dynamics-counting-particles-boxes", code:301},
        "/evenements-scientifiques/cutting-and-pasting-dna-create-our-adaptive-immune-system": { url: "/scientific-event/cutting-and-pasting-dna-create-our-adaptive-immune-system", code:301},
        "/evenements-scientifiques/dangerous-combination-dna-structures-and-chromosome-gaps-implications": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/deconstruct-reconstruct-decode-cancer-immune-crosstalk-probe-organoids": { url: "/scientific-event/deconstruct-reconstruct-decode-cancer-immune-crosstalk-probe-organoids", code:301},
        "/evenements-scientifiques/defining-roles-genetic-drivers-uveal-melanoma-cell-based-and-drosophila": { url: "/scientific-event/defining-roles-genetic-drivers-uveal-melanoma-cell-based-and-drosophila", code:301},
        "/evenements-scientifiques/demystifying-machine-and-deep-learning-and-overview-their-applications": { url: "/scientific-event/demystifying-machine-and-deep-learning-and-overview-their-applications", code:301},
        "/evenements-scientifiques/developmental-biology-stem-cells-morphogenesis-15th-edition": { url: "/scientific-event/developmental-biology-stem-cells-morphogenesis-15th-edition", code:301},
        "/evenements-scientifiques/double-raphael-double-trouble-double-impact": { url: "/scientific-event/double-raphael-double-trouble-double-impact", code:301},
        "/evenements-scientifiques/endocytic-control-cell-plasticity-cancer": { url: "/scientific-event/endocytic-control-cell-plasticity-cancer", code:301},
        "/evenements-scientifiques/engineering-fluorescent-biosensors-spy-cell-signaling-and-metabolism": { url: "/scientific-event/engineering-fluorescent-biosensors-spy-cell-signaling-and-metabolism", code:301},
        "/evenements-scientifiques/enhance-software-zoo-exploring-human-functional-connectome-using-whole": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/establishing-paternal-chromosome-identity-drosophila-male-germline": { url: "/scientific-event/establishing-paternal-chromosome-identity-drosophila-male-germline", code:301},
        "/evenements-scientifiques/eukaryotic-gephyrin-and-cognate-membrane-receptor-coordinate": { url: "/scientific-event/eukaryotic-gephyrin-and-cognate-membrane-receptor-coordinate", code:301},
        "/evenements-scientifiques/exploiting-self-inflicted-dna-breaks-evade-growth-limits-imposed-genotoxic": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/foldscript-new-webserver-interpreting-artificial-intelligence-structural": { url: "/scientific-event/foldscript-new-webserver-interpreting-artificial-intelligence-structural", code:301},
        "/evenements-scientifiques/functional-and-molecular-characterization-cancer-derived-extracellular": { url: "/scientific-event/functional-and-molecular-characterization-cancer-derived-extracellular", code:301},
        "/evenements-scientifiques/garden-forking-paths-branching-switching-and-reversal-meiotic": { url: "/scientific-event/garden-forking-paths-branching-switching-and-reversal-meiotic", code:301},
        "/evenements-scientifiques/garden-forking-paths-branching-switching-and-reversal-meiotic-0": { url: "/scientific-event/garden-forking-paths-branching-switching-and-reversal-meiotic-0", code:301},
        "/evenements-scientifiques/generation-neuronal-diversity-and-its-evolution": { url: "/scientific-event/generation-neuronal-diversity-and-its-evolution", code:301},
        "/evenements-scientifiques/generation-neuronal-diversity-and-its-evolution-0": { url: "/scientific-event/generation-neuronal-diversity-and-its-evolution-0", code:301},
        "/evenements-scientifiques/genetics-together-next-generation-symposium-celebrate-career-alain-nicolas": { url: "/scientific-event/genetics-together-next-generation-symposium-celebrate-career-alain-nicolas", code:301},
        "/evenements-scientifiques/high-speed-imaging-droplet-and-vesicle-formation-during-emulsion-transfer": { url: "/scientific-event/high-speed-imaging-droplet-and-vesicle-formation-during-emulsion-transfer", code:301},
        "/evenements-scientifiques/hydra-morphogenesis-phase-transition-dynamics": { url: "/scientific-event/hydra-morphogenesis-phase-transition-dynamics", code:301},
        "/evenements-scientifiques/illuminating-path-chembio-toolbox-biological-processes": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/initiation-dna-replication-visualised-cryo-em": { url: "/scientific-event/initiation-dna-replication-visualised-cryo-em", code:301},
        "/evenements-scientifiques/institut-curie-optical-genome-mapping-ogm-meeting": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/journee-criblage-crispr-dans-la-recherche-contre-le-cancer": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/kinase-misshapenmap4k4-coordinates-collective-cell-migrations": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/learning-dynamical-models-stochastic-trajectories": { url: "/scientific-event/learning-dynamical-models-stochastic-trajectories", code:301},
        "/evenements-scientifiques/lineage-and-species-specific-sensitivity-or-resistance-cancer-insights-rb": { url: "/scientific-event/lineage-and-species-specific-sensitivity-or-resistance-cancer-insights-rb", code:301},
        "/evenements-scientifiques/live-single-cell-transcriptomics": { url: "/scientific-event/live-single-cell-transcriptomics", code:301},
        "/evenements-scientifiques/mechanics-neural-crest-development-induction-migration-and-differentiation": { url: "/scientific-event/mechanics-neural-crest-development-induction-migration-and-differentiation", code:301},
        "/evenements-scientifiques/mechanism-receptor-choice-olfactory-system-ants": { url: "/scientific-event/mechanism-receptor-choice-olfactory-system-ants", code:301},
        "/evenements-scientifiques/mechanisms-organelle-remodeling-cellular-function": { url: "/scientific-event/mechanisms-organelle-remodeling-cellular-function", code:301},
        "/evenements-scientifiques/mechanisms-transcriptional-regulation-drosophila-mammalian-cells": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/metabolism-neural-stem-cell-niche-interactions": { url: "/scientific-event/metabolism-neural-stem-cell-niche-interactions", code:301},
        "/evenements-scientifiques/microenvironmental-coordination-control-human-cancers": { url: "/scientific-event/microenvironmental-coordination-control-human-cancers", code:301},
        "/evenements-scientifiques/minibar-dual-rac-and-rab-effector-controls-cilia-length-and-left-right": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/minibar-dual-rac-and-rab-effector-controls-cilia-length-and-left-right-0": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/modeling-myc-driven-malignant-pediatric-brain-tumors-and-novel-treatment": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/modern-fluorophores-and-hypoxia-targeting": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/molecular-assembly-and-mechanics-single-vimentin-intermediate-filaments": { url: "/scientific-event/molecular-assembly-and-mechanics-single-vimentin-intermediate-filaments", code:301},
        "/evenements-scientifiques/molecular-engineering-light-activated-probes-precision-diagnostics-therapy": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/molecular-imaging-and-microphysiological-culture-systems-towards-improved": { url: "/scientific-event/molecular-imaging-and-microphysiological-culture-systems-towards-improved", code:301},
        "/evenements-scientifiques/multiple-roads-cell-fate-decisions-integrating-transcription-factors-rna": { url: "/scientific-event/multiple-roads-cell-fate-decisions-integrating-transcription-factors-rna", code:301},
        "/evenements-scientifiques/next-generation-molecular-tumor-boards-systems-biology-and-functional": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/non-coding-genome": { url: "/scientific-event/non-coding-genome", code:301},
        "/evenements-scientifiques/nucleosomes-and-dna-methylation-implications-immunodeficiency-centromeric": { url: "/scientific-event/nucleosomes-and-dna-methylation-implications-immunodeficiency-centromeric", code:301},
        "/evenements-scientifiques/paired-multiplex-imaging-and-spatial-transcriptomics-decipher-tumor-cells": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/personalising-cancer-risk-prediction-prevention-and-early-detection": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/plant-derived-chemical-probes-and-leads-target-osbp-protein-key-player": { url: "/scientific-event/plant-derived-chemical-probes-and-leads-target-osbp-protein-key-player", code:301},
        "/evenements-scientifiques/prevention-des-violences-sexistes-et-sexuelles-0": { url: "/scientific-event/prevention-des-violences-sexistes-et-sexuelles-0", code:301},
        "/evenements-scientifiques/prevention-des-violences-sexistes-et-sexuelles-1": { url: "/scientific-event/prevention-des-violences-sexistes-et-sexuelles-1", code:301},
        "/evenements-scientifiques/prevention-des-violences-sexistes-et-sexuelles-2": { url: "/scientific-event/prevention-des-violences-sexistes-et-sexuelles-2", code:301},
        "/evenements-scientifiques/prevention-des-violences-sexistes-et-sexuelles-3": { url: "/scientific-event/prevention-des-violences-sexistes-et-sexuelles-3", code:301},
        "/evenements-scientifiques/prevention-des-violences-sexistes-et-sexuelles-4": { url: "/scientific-event/prevention-des-violences-sexistes-et-sexuelles-4", code:301},
        "/evenements-scientifiques/protect-and-recycle-how-cells-regulate-dormancy": { url: "/scientific-event/protect-and-recycle-how-cells-regulate-dormancy", code:301},
        "/evenements-scientifiques/proximity-induced-targeted-rna-degradation-mapping-rna-modifications-and": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/regulation-and-immunotherapy-responses-nk-cells-and-t-cells-against-tumors": { url: "/scientific-event/regulation-and-immunotherapy-responses-nk-cells-and-t-cells-against-tumors", code:301},
        "/evenements-scientifiques/role-innate-immune-response-and-micro-environment-regenerative-potential": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/sexist-and-sexual-violence-prevention-0": { url: "/scientific-event/sexist-and-sexual-violence-prevention-0", code:301},
        "/evenements-scientifiques/sexist-and-sexual-violence-prevention-1": { url: "/scientific-event/sexist-and-sexual-violence-prevention-1", code:301},
        "/evenements-scientifiques/single-cell-isoforms-time-and-space": { url: "/scientific-event/single-cell-isoforms-time-and-space", code:301},
        "/evenements-scientifiques/single-cell-multi-omics-solid-tumor-and-haematological-malignancies": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/situ-structures-muscle-sarcomere-and-sarcomeric-proteins": { url: "/scientific-event/situ-structures-muscle-sarcomere-and-sarcomeric-proteins", code:301},
        "/evenements-scientifiques/slide-tags-enables-single-nucleus-barcoding-multimodal-spatial-genomics": { url: "/scientific-event/slide-tags-enables-single-nucleus-barcoding-multimodal-spatial-genomics", code:301},
        "/evenements-scientifiques/solar-radiation-and-pollution-mitochondrial-perspective": { url: "/scientific-event/solar-radiation-and-pollution-mitochondrial-perspective", code:301},
        "/evenements-scientifiques/spatial-multimodal-data-analysis-when-omics-meet-images-computational": { url: "/scientific-event/spatial-multimodal-data-analysis-when-omics-meet-images-computational", code:301},
        "/evenements-scientifiques/spatiotemporal-models-mammalian-development-epigeneticists-dream-or": { url: "/scientific-event/spatiotemporal-models-mammalian-development-epigeneticists-dream-or", code:301},
        "/evenements-scientifiques/spliceosome-mutations-myeloid-malignancy": { url: "/scientific-event/spliceosome-mutations-myeloid-malignancy", code:301},
        "/evenements-scientifiques/structural-neutrophils": { url: "/scientific-event/structural-neutrophils", code:301},
        "/evenements-scientifiques/tango1-builds-secretory-route-collagen": { url: "/scientific-event/tango1-builds-secretory-route-collagen", code:301},
        "/evenements-scientifiques/targeting-chromatin-regulators-cancer": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/tissue-mechanics-memory-and-metabolism-6th-edition-cell-biology-and-cancer": { url: "/scientific-event/tissue-mechanics-memory-and-metabolism-6th-edition-cell-biology-and-cancer", code:301},
        "/evenements-scientifiques/understanding-and-modeling-aging": { url: "/scientific-event/understanding-and-modeling-aging", code:301},
        "/evenements-scientifiques/user-day-fixed-rna-profiling-and-chromium-ix-presentation": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/using-genomics-invesigate-radiaion-related-thyroid-cancer-following": { url: "/scientific-events", code:301},
        "/evenements-scientifiques/vimentin-intermediate-filaments-network-architecture-and-crosstalk": { url: "/scientific-event/vimentin-intermediate-filaments-network-architecture-and-crosstalk", code:301},
        "/evenements-scientifiques/visualisation-genomic-data-using-ucsc-genome-browser": { url: "/scientific-events", code:301},
        "/exploiting-state-art-imaging": { url: "/", code:301},
        "/fabien-reyal-group": { url: "/", code:301},
        "/fachinetti-lab": { url: "/team/fachinetti", code:301},
        "/genetic-and-genomic-characterization-uveal-melanoma-um": { url: "/", code:301},
        "/genetic-screen-cell-line": { url: "/", code:301},
        "/genetics-and-developmental-biology-umr3215u934": { url: "/unit/umr3215-u934", code:301},
        "/genomic-and-transcriptomic-instabilities-human-diseases": { url: "/", code:301},
        "/histology-materials": { url: "/plateforme/curiecoretech-histology", code:301},
        "/histology-methods": { url: "/plateforme/curiecoretech-histology", code:301},
        "/histology-strategies": { url: "/plateforme/curiecoretech-histology", code:301},
        "/hub-acute-myeloid-leukemia-myelodysplasia": { url: "/acute-myeloid-leukemia-myelodysplasia", code:301},
        "/hub-breast-cancer": { url: "/breast-cancer", code:301},
        "/hub-colorectal-cancer": { url: "/colorectal-cancer", code:301},
        "/hub-digestive-cancers": { url: "/digestive-cancers", code:301},
        "/hub-endometrial-cancer": { url: "/endometrial-cancer", code:301},
        "/hub-ent-head-neck-cancers": { url: "/ent-head-neck-cancers", code:301},
        "/hub-eye-tumors": { url: "/eye-tumors", code:301},
        "/hub-gynecological-cancers": { url: "/gynecological-cancers", code:301},
        "/hub-hematological-cancers": { url: "/hematological-cancers", code:301},
        "/hub-liver-cancer": { url: "/liver-cancer", code:301},
        "/hub-lung-cancer": { url: "/lung-cancer", code:301},
        "/hub-lymphoma": { url: "/lymphoma", code:301},
        "/hub-myeloma": { url: "/myeloma", code:301},
        "/hub-oncogenetics": { url: "/oncogenetics", code:301},
        "/hub-ovarian-cancer": { url: "/ovarian-cancer", code:301},
        "/hub-pancreatic-cancer": { url: "/pancreatic-cancer", code:301},
        "/hub-prostate-cancer": { url: "/prostate-cancer", code:301},
        "/hub-sarcomas": { url: "/sarcomas", code:301},
        "/hub-uveal-melanoma": { url: "/uveal-melanoma", code:301},
        "/immucan-integrated-european-immuno-oncology-profiling-platform": { url: "/", code:301},
        "/immunity-and-cancer-u932": { url: "/unit/u932", code:301},
        "/instabilities-tissues-and-cell-aggregates": { url: "/", code:301},
        "/integrative-omics-profiling-all-mb-subgroups": { url: "/", code:301},
        "/ipaediatriccure": { url: "/", code:301},
        "/job-offers?page=0": { url: "/job-offers", code:301},
        "/job-offers?page=1": { url: "/job-offers", code:301},
        "/lab-pictures": { url: "/", code:301},
        "/lab-retreat-2021": { url: "/", code:301},
        "/lab-retreats-2016-2017": { url: "/", code:301},
        "/lab-retreats-2018-2019": { url: "/", code:301},
        "/landing/lifetime": { url: "/", code:301},
        "/linking-gene-expression-cell-dynamics-and-mechanical-forces": { url: "/", code:301},
        "/lipocanpredict-multi-omics-biomarker-signatures-identification-cancer-associated-adipocytes": { url: "/research-teams", code:301},
        "/list/adult-care": { url: "/cancer-types", code:301},
        "/list/adult-care?profile=2": { url: "/cancer-types", code:301},
        "/list/collaborative-programs-promote-innovation": { url: "/", code:301},
        "/list/different-types-cancer": { url: "/cancer-types", code:301},
        "/list/get-involved": { url: "/", code:301},
        "/list/our-departments": { url: "/", code:301},
        "/list/our-departments-0": { url: "/", code:301},
        "/list/our-expertise": { url: "/", code:301},
        "/list/our-expertise?profile=5": { url: "/", code:301},
        "/list/our-strategic-priorities-2015-2020": { url: "/", code:301},
        "/list/research-news": { url: "/news?fieldDomainNews[]=270&page=1", code:301},
        "/list/research-programs": { url: "/research-and-innovation-institut-curie", code:301},
        "/list/supportive-care": { url: "/supportive-care", code:301},
        "/list/treatments": { url: "/cancer-treatments", code:301},
        "/list/understand-cancer": { url: "/oncogenetics", code:301},
        "/list/understand-cancer?profile=1": { url: "/oncogenetics", code:301},
        "/list/understand-cancer?profile=5": { url: "/oncogenetics", code:301},
        "/list/who-we-are": { url: "/institut-curie", code:301},
        "/list/who-we-are?profile=1": { url: "/institut-curie", code:301},
        "/liste_actualites/3": { url: "/news", code:301},
        "/liste/attentive-ear": { url: "/supportive-care", code:301},
        "/liste/health-well-being": { url: "/supportive-care", code:301},
        "/liste/our-hospitals": { url: "/institut-curie-hospital-group", code:301},
        "/liste/our-hospitals?profile=2": { url: "/institut-curie-hospital-group", code:301},
        "/liste/our-hospitals?profile=5": { url: "/institut-curie-hospital-group", code:301},
        "/liste/our-organization": { url: "/governance-and-management-organization", code:301},
        "/liste/proton-therapy-center": { url: "/orsay-proton-therapy-center", code:301},
        "/liste/rights-and-duties": { url: "/patient-rights", code:301},
        "/liste/support-us": { url: "/support-us", code:301},
        "/liste/support-us?profile=1": { url: "/", code:301},
        "/long-noncoding-rnas-cancer-discovery-functional-characterization-and-clinical-relevance": { url: "/clinical-trials", code:301},
        "/macromolecules-and-microsystems-biology-and-medicine-mmbm": { url: "/", code:301},
        "/mdm4-telomeres": { url: "/", code:301},
        "/medical-and-scientific-community-tools": { url: "/", code:301},
        "/medulloblastome": { url: "/", code:301},
        "/meeting-participation-team-larue": { url: "/", code:301},
        "/melanome": { url: "/uveal-melanoma", code:301},
        "/membrane-trafficking-sorting-bending-cutting": { url: "/", code:301},
        "/modelling-immunogenic-cell-death-modiced": { url: "/", code:301},
        "/modelling-membrane-bound-cellular-organelles-non-equilibrium-dynamics": { url: "/", code:301},
        "/neoadjuvant-treatment-and-immunity": { url: "/", code:301},
        "/news?page=17": { url: "/news", code:301},
        "/news?page=18": { url: "/news", code:301},
        "/news?page=19": { url: "/news", code:301},
        "/news?page=20": { url: "/news", code:301},
        "/news?page=21": { url: "/news", code:301},
        "/news?page=22": { url: "/news", code:301},
        "/news?page=23": { url: "/news", code:301},
        "/news/award/drs-allison-bardin-and-valerie-borde-elected-members-european-embo-organization": { url: "/news", code:301},
        "/news/corporate/dna-recombination-alphafold2-breaks-lock": { url: "/news", code:301},
        "/news/corporate/dr-claire-rougeulle-new-director-research-center-institut-curie": { url: "/news", code:301},
        "/news/corporate/prairie-institute-specializing-ai-obtains-endowment-eu75-million-and-becomes-prairie": { url: "/news", code:301},
        "/news/innovation/cancer-vaccine-institut-curie-announces-creation-start-cereus-biosciences": { url: "/news", code:301},
        "/news/innovation/new-results-better-treatment-t-cell-acute-lymphoblastic-leukemia": { url: "/news", code:301},
        "/news/innovation/two-institut-curie-researchers-secure-human-frontier-science-program-2020-grants": { url: "/news", code:301},
        "/news/pediatric-cancers/pediatric-cancer-origin-medulloblastoma-detected-renewed-hope": { url: "/childhood-and-adolescent-cancers", code:301},
        "/node/11242": { url: "/", code:301},
        "/node/11244": { url: "/", code:301},
        "/node/12629": { url: "/", code:301},
        "/node/12769": { url: "/", code:301},
        "/node/12934": { url: "/", code:301},
        "/node/13170": { url: "/", code:301},
        "/node/13228": { url: "/", code:301},
        "/node/13445": { url: "/", code:301},
        "/node/13631": { url: "/", code:301},
        "/node/13704": { url: "/", code:301},
        "/node/1394": { url: "/", code:301},
        "/node/13943": { url: "/childhood-and-adolescent-cancers", code:301},
        "/node/1397": { url: "/cancer-types", code:301},
        "/node/1400": { url: "/cancer-types", code:301},
        "/node/1436": { url: "/", code:301},
        "/node/1442": { url: "/", code:301},
        "/node/16093": { url: "/", code:301},
        "/node/1651": { url: "/", code:301},
        "/node/16781": { url: "/", code:301},
        "/node/17299": { url: "/", code:301},
        "/node/17649": { url: "/", code:301},
        "/node/17883": { url: "/", code:301},
        "/node/17968": { url: "/", code:301},
        "/node/18482": { url: "/", code:301},
        "/node/1872": { url: "/institut-curie", code:301},
        "/node/1874": { url: "/institut-curie", code:301},
        "/node/1876": { url: "/institut-curie", code:301},
        "/node/18922": { url: "/", code:301},
        "/node/18933": { url: "/", code:301},
        "/node/18961": { url: "/", code:301},
        "/node/193": { url: "/", code:301},
        "/node/1968": { url: "/", code:301},
        "/node/20252": { url: "/", code:301},
        "/node/20253": { url: "/", code:301},
        "/node/20291": { url: "/", code:301},
        "/node/2068": { url: "/", code:301},
        "/node/20765": { url: "/", code:301},
        "/node/20839": { url: "/", code:301},
        "/node/20905": { url: "/", code:301},
        "/node/2092": { url: "/", code:301},
        "/node/21139": { url: "/", code:301},
        "/node/21322": { url: "/", code:301},
        "/node/21332": { url: "/", code:301},
        "/node/21390": { url: "/", code:301},
        "/node/21525": { url: "/", code:301},
        "/node/224": { url: "/", code:301},
        "/node/232": { url: "/", code:301},
        "/node/235": { url: "/", code:301},
        "/node/2473": { url: "/", code:301},
        "/node/2688": { url: "/", code:301},
        "/node/2733": { url: "/", code:301},
        "/node/280": { url: "/", code:301},
        "/node/292": { url: "/", code:301},
        "/node/2952": { url: "/", code:301},
        "/node/310": { url: "/", code:301},
        "/node/3125": { url: "/", code:301},
        "/node/3183": { url: "/", code:301},
        "/node/3405": { url: "/what-are-urogenital-cancers", code:301},
        "/node/3410": { url: "/what-are-urogenital-cancers", code:301},
        "/node/3736": { url: "/", code:301},
        "/node/4027": { url: "/", code:301},
        "/node/407": { url: "/ent-head-neck-cancers", code:301},
        "/node/428": { url: "/uveal-melanoma", code:301},
        "/node/439": { url: "/uveal-melanoma", code:301},
        "/node/57": { url: "/", code:301},
        "/node/6927": { url: "/", code:301},
        "/notch-signaling-stem-cells-and-tumors": { url: "/", code:301},
        "/nous_rejoindre/3": { url: "/join-institut-curie", code:301},
        "/nous_rejoindre/3?profile=3": { url: "/join-institut-curie", code:301},
        "/nuclear-positioning-and-replication-fork-repair-pathways-choice": { url: "/", code:301},
        "/offre-emploi/bioinformatician-cancer-research-mf": { url: "/job-offers", code:301},
        "/offre-emploi/bioinformatics-engineer-cell-therapy-mf": { url: "/job-offers", code:301},
        "/offre-emploi/engineer-ie-biology-mf": { url: "/job-offers", code:301},
        "/offre-emploi/engineer-ie-molecular-and-cell-biology-mf": { url: "/job-offers", code:301},
        "/offre-emploi/engineer-immunology-and-cell-biology-mf-1": { url: "/job-offers", code:301},
        "/offre-emploi/engineer-oncoimmunology-mf": { url: "/job/engineer-oncoimmunology-mf", code:301},
        "/offre-emploi/engineer-position-ie-single-cell-project-mf": { url: "/job-offers", code:301},
        "/offre-emploi/ie-ir-research-engineer-mf": { url: "/job-offers", code:301},
        "/offre-emploi/microscopy-image-analysis-engineer-inserm-unit-u932-immunity-and-cancer-mf": { url: "/job-offers", code:301},
        "/offre-emploi/phd-program-manager-mf-0": { url: "/job-offers", code:301},
        "/offre-emploi/phd-thesis-project-hf": { url: "/job/phd-thesis-project-hf", code:301},
        "/offre-emploi/phd-thesis-project-mf-0": { url: "/job-offers", code:301},
        "/offre-emploi/phenotypic-drug-screening-data-analyst-mf": { url: "/job-offers", code:301},
        "/offre-emploi/post-doc-position-dna-repair-and-uveal-melanoma-team-fm-0": { url: "/job-offers", code:301},
        "/offre-emploi/post-doctoral-position-immunity-and-cancer-fm": { url: "/job-offers", code:301},
        "/offre-emploi/post-doctoral-super-resolution-imaging-mf-0": { url: "/job-offers", code:301},
        "/offre-emploi/postdoctoral-computational-biology-mf-cancer-genomics-pediatric-brain-tumors": { url: "/job/postdoctoral-computational-biology-mf-cancer-genomics-pediatric-brain-tumors", code:301},
        "/offre-emploi/postdoctoral-mf-systems-biology-cancer-group": { url: "/job-offers", code:301},
        "/offre-emploi/postdoctoral-position-computational-biology-mf-0": { url: "/job-offers", code:301},
        "/offre-emploi/postdoctoral-position-genomics-bio-informatics-mf": { url: "/job-offers", code:301},
        "/offre-emploi/postdoctoral-position-genomics-mf-0": { url: "/job-offers", code:301},
        "/offre-emploi/postdoctoral-position-immuno-oncology-mf-0": { url: "/job-offers", code:301},
        "/offre-emploi/postdoctoral-position-medical-physics-mf-2": { url: "/job-offers", code:301},
        "/offre-emploi/postdoctoral-position-molecular-cellular-genetics-mf": { url: "/job-offers", code:301},
        "/offre-emploi/postdoctoral-researcher-computational-translational-paediatric-oncology-mf-0": { url: "/job-offers", code:301},
        "/offre-emploi/postdoctoral-vitro-reconstitution-cellular-protrusions-mf": { url: "/job-offers", code:301},
        "/offre-emploi/postdoctoralir-position-genomics-mf-0": { url: "/job-offers", code:301},
        "/offre-emploi/project-manager-labex-deep-mf-1": { url: "/job-offers", code:301},
        "/offre-emploi/project-manager-mf-international-research-projects-siredo-pediatric-oncology-center": { url: "/job-offers", code:301},
        "/offre-emploi/research-engineer-mf-0": { url: "/job-offers", code:301},
        "/offre-emploi/research-engineer-position-oncology-mf": { url: "/job-offers", code:301},
        "/organismes-scientifiques": { url: "/support-us", code:301},
        "/organization-meetings-and-conferences": { url: "/", code:301},
        "/our-links": { url: "/", code:301},
        "/our-patents": { url: "/", code:301},
        "/our-pdx-models-en": { url: "/", code:301},
        "/our-videos": { url: "/", code:301},
        "/our-videos-0": { url: "/", code:301},
        "/out-equilibrium-control-internal-organization-bacteria": { url: "/", code:301},
        "/p53-fanconi-genes": { url: "/", code:301},
        "/page/2016-annual-report-strengthening-partnerships-and-openness": { url: "/", code:301},
        "/page/2016-annual-report-strengthening-partnerships-and-openness?profile=1": { url: "/", code:301},
        "/page/3d-printing-oncology": { url: "/", code:301},
        "/page/adic": { url: "/", code:301},
        "/page/adic?profile=4": { url: "/", code:301},
        "/page/anesthesia-icu-and-pain-management": { url: "/anesthesia-icu-and-pain-management", code:301},
        "/page/annual-report-2017-transitional-year": { url: "/", code:301},
        "/page/annual-report-2018-springboard-year": { url: "/", code:301},
        "/page/answers-your-most-frequently-asked-questions": { url: "/", code:301},
        "/page/auriculotherapy": { url: "/complementary-approaches-institut-curie", code:301},
        "/page/better-use-radiotherapy-children": { url: "/radiotherapy-institut-curie", code:301},
        "/page/bilateral-breast-cancer-first-full-study-reveals-tumors-are-independent-one-another": { url: "/breast-cancer", code:301},
        "/page/blood-and-bone-marrow-cancers": { url: "/hematological-cancers", code:301},
        "/page/bone-metastases-precision-rays-thanks-stereotaxy": { url: "/", code:301},
        "/page/brain-tumors-children": { url: "/", code:301},
        "/page/brca-genes-new-applications-and-precautions": { url: "/", code:301},
        "/page/breast-and-ovarian-cancer-healthy-cells-prevent-action-certain-medications": { url: "/breast-cancer", code:301},
        "/page/breast-cancer": { url: "/breast-cancer", code:301},
        "/page/breast-cancer-0": { url: "/breast-cancer", code:301},
        "/page/breast-cancer-blocking-first-steps-tumour-invasion": { url: "/breast-cancer", code:301},
        "/page/cancer-elderly": { url: "/cancer-types", code:301},
        "/page/center-cancer-immunotherapy": { url: "/immunotherapy", code:301},
        "/page/center-proton-therapy-high-tech-hub": { url: "/orsay-proton-therapy-center", code:301},
        "/page/central-nervous-system-and-skull-base-tumors": { url: "/", code:301},
        "/page/centromeres-centrosomes-and-cell-division": { url: "/", code:301},
        "/page/chemotherapy-and-hormone-therapy": { url: "/chemotherapy-and-hormone-therapy", code:301},
        "/page/chest-center": { url: "/chest-center", code:301},
        "/page/choroidal-melanoma-how-do-metastases-form": { url: "/", code:301},
        "/page/choroidal-uveal-melanoma": { url: "/uveal-melanoma", code:301},
        "/page/clinical-trial-promising-results-boosting-effects-radiotherapy": { url: "/radiotherapy-institut-curie", code:301},
        "/page/combating-chronic-pain": { url: "/", code:301},
        "/page/commission-relations-users": { url: "/", code:301},
        "/page/composers-muse-ic-project": { url: "/", code:301},
        "/page/covid-19-disease-map-explorable-computational-repository-sars-cov-2-virus-host-interactions": { url: "/", code:301},
        "/page/crucial-hours-shape-you": { url: "/", code:301},
        "/page/diagnostic-and-theranostic-medicine-division": { url: "/diagnostic-and-theranostic-medicine-division", code:301},
        "/page/diet-and-nutrition": { url: "/dietetics-and-nutrition-oncology", code:301},
        "/page/digestive-cancers": { url: "/digestive-cancers", code:301},
        "/page/dna-break-multi-scale-dance": { url: "/", code:301},
        "/page/early-trials": { url: "/", code:301},
        "/page/emerald-international-phd-program-medical-doctors-call-applications-now-open": { url: "/", code:301},
        "/page/ent-cancers": { url: "/ent-head-neck-cancers", code:301},
        "/page/ewings-sarcoma-epigenetics-route": { url: "/sarcomas", code:301},
        "/page/first-vibrations-universe-and-quest-first-galaxy-clusters": { url: "/", code:301},
        "/page/four-areas-research-address-all-facets-biology-cancer": { url: "/research-and-innovation-institut-curie", code:301},
        "/page/functional-rehabilitation": { url: "/functional-rehabilitation-better-life-cancer", code:301},
        "/page/genetics-and-epigenetics": { url: "/epigenetics-rna-and-genome-dynamics", code:301},
        "/page/genetics-consultations": { url: "/", code:301},
        "/page/group-relaxation": { url: "/complementary-approaches-institut-curie", code:301},
        "/page/gynaecological-cancer": { url: "/gynecological-cancers", code:301},
        "/page/help-innovation-and-research-against-cancer": { url: "/research-and-innovation-institut-curie", code:301},
        "/page/hereditary-predispositions-breast-ovarian-cancer-focus-interpretation": { url: "/example-predispositions-breast-and-ovarian-cancers", code:301},
        "/page/hopes-new-therapeutic-strategy-triple-negative-breast-cancer": { url: "/breast-cancer", code:301},
        "/page/hospital-patients-bill-rights": { url: "/charter-hospitalized-patients", code:301},
        "/page/how-get-paris-hospital": { url: "/paris-hospital", code:301},
        "/page/how-use-international-patient-platform": { url: "/international-patients", code:301},
        "/page/hpv-viral-dna-integration-anal-cancer": { url: "/", code:301},
        "/page/hr-excellence-research-label": { url: "/hr-excellence-research-label", code:301},
        "/page/identifying-friends-and-foes-among-vesicles": { url: "/", code:301},
        "/page/immunotherapy": { url: "/immunotherapy", code:301},
        "/page/immunotherapy-0": { url: "/immunotherapy", code:301},
        "/page/inflammation-and-cancer-identifying-role-copper-paves-way-new-therapeutic-applications": { url: "/news/publication/inflammation-and-cancer-identifying-role-copper-paves-way-new-therapeutic", code:301},
        "/page/information-and-consent": { url: "/information-and-consent", code:301},
        "/page/innovation/hydraulic-fracturing-embryos": { url: "/", code:301},
        "/page/institut-curie-research-center": { url: "/research-and-innovation-institut-curie", code:301},
        "/page/interactions-hospital-group": { url: "/institut-curie-hospital-group", code:301},
        "/page/international-patient": { url: "/international-patients", code:301},
        "/page/international-patient-steps-your-care": { url: "/international-patient-steps-your-care", code:301},
        "/page/international-patient?profile=2": { url: "/international-patients", code:301},
        "/page/international-patients-dedicated-team": { url: "/international-patients-dedicated-team", code:301},
        "/page/international-relations": { url: "/international-patients", code:301},
        "/page/intestinal-health-macrophages-lead-conversation-between-microbiota-and-colon": { url: "/", code:301},
        "/page/iron-mediated-cancer-cell-activity-new-regulation-mechanism": { url: "/", code:301},
        "/page/lebanon-institut-curie-steps-its-support-oncology": { url: "/", code:301},
        "/page/legal-notice": { url: "/", code:301},
        "/page/loop-extrusion-model": { url: "/", code:301},
        "/page/lung-cancer": { url: "/lung-cancer", code:301},
        "/page/lung-cancer-patient-profiling-tailored-treatment": { url: "/lung-cancer", code:301},
        "/page/maison-des-patients-et-des-proches-patients-and-families": { url: "/patients-and-relatives-areas", code:301},
        "/page/make-donation": { url: "/support-us", code:301},
        "/page/making-first-appointment-institut-curie": { url: "/", code:301},
        "/page/malignant-eye-tumors": { url: "/treatment-eye-tumors", code:301},
        "/page/managing-side-effects": { url: "/", code:301},
        "/page/managing-treatment-related-pain": { url: "/", code:301},
        "/page/mechanics-working-cellular-world": { url: "/", code:301},
        "/page/medical-hypnosis-and-hypnosedation": { url: "/complementary-approaches-institut-curie", code:301},
        "/page/medical-imaging": { url: "/", code:301},
        "/page/medical-record": { url: "/medical-record", code:301},
        "/page/mitochondrial-dna-another-target-radiotherapy": { url: "/radiotherapy-institut-curie", code:301},
        "/page/mnemo-therapeutics-and-institut-curie-announce-two-key-publications-science-immunology": { url: "/", code:301},
        "/page/modes-hospitalization": { url: "/methods-hospitalization", code:301},
        "/page/music-and-science-when-two-worlds-meet": { url: "/", code:301},
        "/page/new-international-phd-program-ready-steady-apply-now": { url: "/international-patients", code:301},
        "/page/new-therapeutic-strategy-against-triple-negative-breast-cancer": { url: "/breast-cancer", code:301},
        "/page/once-upon-time": { url: "/", code:301},
        "/page/oncological-medicine": { url: "/", code:301},
        "/page/oncological-radiotherapy": { url: "/radiotherapy-institut-curie", code:301},
        "/page/organization-research-center": { url: "/research-and-innovation-institut-curie", code:301},
        "/page/osteosarcomas-and-ewing-tumors": { url: "/sarcomas", code:301},
        "/page/our-economic-model": { url: "/", code:301},
        "/page/our-governance": { url: "/", code:301},
        "/page/our-history": { url: "/", code:301},
        "/page/our-missions": { url: "/", code:301},
        "/page/our-research-teams": { url: "/research-and-innovation-institut-curie", code:301},
        "/page/our-scientific-advisory-board": { url: "/", code:301},
        "/page/our-supporters": { url: "/", code:301},
        "/page/paediatric-teenage-and-young-adult-oncology": { url: "/", code:301},
        "/page/page-403": { url: "/", code:301},
        "/page/page-404": { url: "/", code:301},
        "/page/palliative-care": { url: "/palliative-care-cancer", code:301},
        "/page/patients-committee": { url: "/patient-experience-committee", code:301},
        "/page/pediatric-cancer": { url: "/childhood-and-adolescent-cancers", code:301},
        "/page/pediatric-cancers-working-towards-precision-medicine": { url: "/childhood-and-adolescent-cancers", code:301},
        "/page/personal-data-protection-charter": { url: "/", code:301},
        "/page/pharmacy": { url: "/pharmacy", code:301},
        "/page/portrait-amandine-trouchet-manager-future-single-cell-platform": { url: "/", code:301},
        "/page/portrait-amel-yahou-data-developer": { url: "/", code:301},
        "/page/portrait-laetitia-chanas-manager-health-data-consulting-and-services-team": { url: "/", code:301},
        "/page/portrait-linda-haroun-clinical-data-manager": { url: "/", code:301},
        "/page/portrait-sophie-houzard-public-health-physician": { url: "/", code:301},
        "/page/portrait-thomas-balezeau-data-engineer": { url: "/", code:301},
        "/page/prof-steven-le-gouill-new-director-hospital-group-institut-curie-0": { url: "/prof-steven-le-gouill-director-institut-curie-hospital-group", code:301},
        "/page/projects-funded-through-public-generosity": { url: "/", code:301},
        "/page/protein-scrutinized-better-understand-and-treat-telomeres": { url: "/", code:301},
        "/page/proton-therapy-center-practical-information": { url: "/orsay-proton-therapy-center", code:301},
        "/page/proton-therapy-treating-eye-melanoma": { url: "/treatments-uveal-melanoma", code:301},
        "/page/protontherapy": { url: "/proton-therapy-institut-curie", code:301},
        "/page/quality-and-safety-healthcare-our-priority": { url: "/supportive-care", code:301},
        "/page/radio-pharmacology-department": { url: "/", code:301},
        "/page/radiotherapy": { url: "/radiotherapy-institut-curie", code:301},
        "/page/radiotherapy-and-radiation-biology": { url: "/radiotherapy-institut-curie", code:301},
        "/page/radiotherapy-institut-curie-boost-state-art-innovation": { url: "/radiotherapy-institut-curie", code:301},
        "/page/reconstructive-surgery-and-cancer": { url: "/breast-cancer", code:301},
        "/page/research-and-development-proton-therapy-center": { url: "/orsay-proton-therapy-center", code:301},
        "/page/research-treatment": { url: "/research-and-innovation-institut-curie", code:301},
        "/page/research-without-borders": { url: "/research-and-innovation-institut-curie", code:301},
        "/page/retinoblastoma": { url: "/cancer-types", code:301},
        "/page/revolutionary-research-radiotherapy": { url: "/radiotherapy-institut-curie", code:301},
        "/page/ruban-rose-elisabetta-marangoni": { url: "/", code:301},
        "/page/saint-cloud-hospital": { url: "/saint-cloud-hospital", code:301},
        "/page/sarcomas": { url: "/sarcomas", code:301},
        "/page/school-support-children-and-adolescents-hospitalized-institut-curie": { url: "/", code:301},
        "/page/seeing-entire-nucleus-cell": { url: "/", code:301},
        "/page/services-offered-international-patients": { url: "/international-patients", code:301},
        "/page/single-cell-disruptive-technology": { url: "/", code:301},
        "/page/skin-cancers": { url: "/cancer-types", code:301},
        "/page/social-support": { url: "/supportive-care", code:301},
        "/page/soft-tissue-cancer-sarcoma": { url: "/sarcomas", code:301},
        "/page/soft-tissue-sarcomas": { url: "/sarcomas", code:301},
        "/page/sophrology": { url: "/complementary-approaches-institut-curie", code:301},
        "/page/spirit-collaboration": { url: "/", code:301},
        "/page/spirit-collaboration?profile=4": { url: "/", code:301},
        "/page/supportive-care-available-institut-curie": { url: "/", code:301},
        "/page/surgery": { url: "/surgery-institut-curie", code:301},
        "/page/surgery-0": { url: "/surgery-institut-curie", code:301},
        "/page/tailored-social-support-young-patients-institut-curie": { url: "/", code:301},
        "/page/targeted-therapies": { url: "/", code:301},
        "/page/tax-provisions": { url: "/", code:301},
        "/page/teaching-paving-way-tomorrows-research-and-medicine": { url: "/", code:301},
        "/page/teaching-paving-way-tomorrows-research-and-medicine?profile=4": { url: "/", code:301},
        "/page/team-proton-therapy-center": { url: "/orsay-proton-therapy-center", code:301},
        "/page/therapeutic-prospects-g-quadruplexes-against-cancer": { url: "/", code:301},
        "/page/tumor-sequencing-access-new-medication": { url: "/", code:301},
        "/page/understanding-how-tumour-cell-diverts-its-environment": { url: "/", code:301},
        "/page/upcoming-events": { url: "/", code:301},
        "/page/urogenital-cancers": { url: "/what-are-urogenital-cancers", code:301},
        "/page/uveal-melanoma": { url: "/uveal-melanoma", code:301},
        "/page/uveal-melanoma-and-risk-metastasis": { url: "/uveal-melanoma", code:301},
        "/page/what-clinical-trial": { url: "/clinical-trials", code:301},
        "/page/what-clinical-trial?profile=2": { url: "/", code:301},
        "/page/what-going": { url: "/", code:301},
        "/page/what-our-patients-are-saying-about-us": { url: "/", code:301},
        "/page/when-our-cells-sleep-quiescence-and-renaissance": { url: "/", code:301},
        "/page/where-does-your-donation-go": { url: "/where-do-your-donations-go", code:301},
        "/page/who-who": { url: "/", code:301},
        "/page/workplace-gender-equality-index": { url: "/", code:301},
        "/partnerships-collaborations-and-supports": { url: "/", code:301},
        "/permedcoe-exascale-ready-cell-level-simulations-european-personalised-medicine": { url: "/", code:301},
        "/personne/aaliya-khan": { url: "/directory", code:301},
        "/personne/abdelmalek-ghimouz": { url: "/person/abdelmalek-ghimouz", code:301},
        "/personne/abdenour-abbas": { url: "/person/abdenour-abbas", code:301},
        "/personne/achille-joliot": { url: "/person/achille-joliot", code:301},
        "/personne/adele-berlioz": { url: "/person/adele-berlioz", code:301},
        "/personne/adeline-lablanche": { url: "/person/adeline-lablanche", code:301},
        "/personne/adil-midoun": { url: "/person/adil-midoun", code:301},
        "/personne/adriana-langer": { url: "/person/adriana-langer", code:301},
        "/personne/adrien-leroy": { url: "/person/adrien-leroy", code:301},
        "/personne/adrien-thomas": { url: "/person/adrien-thomas", code:301},
        "/personne/agathe-peltier": { url: "/person/agathe-peltier", code:301},
        "/personne/agnes-lemoine": { url: "/person/agnes-lemoine", code:301},
        "/personne/ahmad-maswadeh": { url: "/person/ahmad-maswadeh", code:301},
        "/personne/ahmed-el-marjou": { url: "/person/ahmed-el-marjou", code:301},
        "/personne/aikaterini-vasileiou": { url: "/person/aikaterini-vasileiou", code:301},
        "/personne/akhil-ananthan": { url: "/person/akhil-ananthan", code:301},
        "/personne/akshay-chaturvedi": { url: "/person/akshay-chaturvedi", code:301},
        "/personne/ala-eddine-boudemia": { url: "/person/ala-eddine-boudemia", code:301},
        "/personne/alain-eychene": { url: "/person/alain-eychene", code:301},
        "/personne/alain-fourquet": { url: "/directory", code:301},
        "/personne/alain-joliot": { url: "/person/alain-joliot", code:301},
        "/personne/alain-labib": { url: "/directory", code:301},
        "/personne/alain-mauviel": { url: "/person/alain-mauviel", code:301},
        "/personne/albertas-navickas": { url: "/person/albertas-navickas", code:301},
        "/personne/alberto-ballestin-rodriguez": { url: "/person/alberto-ballestin-rodriguez", code:301},
        "/personne/alessandro-donada": { url: "/person/alessandro-donada", code:301},
        "/personne/alexander-chervov": { url: "/person/alexander-chervov", code:301},
        "/personne/alexandre-baffet": { url: "/person/alexandre-baffet", code:301},
        "/personne/alexandre-baffet?page=0": { url: "/person/alexandre-baffet", code:301},
        "/personne/alexandre-baffet?page=1": { url: "/person/alexandre-baffet", code:301},
        "/personne/alexandre-houy": { url: "/person/alexandre-houy", code:301},
        "/personne/alexandre-matet": { url: "/person/alexandre-matet", code:301},
        "/personne/alexia-le-barch": { url: "/person/alexia-le-barch", code:301},
        "/personne/alexia-savignoni": { url: "/person/alexia-savignoni", code:301},
        "/personne/alexis-burnod": { url: "/person/alexis-burnod", code:301},
        "/personne/alexis-cornec": { url: "/person/alexis-cornec", code:301},
        "/personne/alexis-haan": { url: "/person/alexis-haan", code:301},
        "/personne/alexis-hardy": { url: "/person/alexis-hardy", code:301},
        "/personne/alice-blondel": { url: "/person/alice-blondel", code:301},
        "/personne/alice-williart": { url: "/person/alice-williart", code:301},
        "/personne/aline-albi-feldzer": { url: "/person/aline-albi-feldzer", code:301},
        "/personne/aline-yatim": { url: "/person/aline-yatim", code:301},
        "/personne/alix-zhou": { url: "/person/alix-zhou", code:301},
        "/personne/allison-bardin": { url: "/person/allison-bardin", code:301},
        "/personne/allison-bardin?page=1": { url: "/person/allison-bardin", code:301},
        "/personne/alodie-hervo": { url: "/person/alodie-hervo", code:301},
        "/personne/alvaro-banderas": { url: "/person/alvaro-banderas", code:301},
        "/personne/amandine-di-cicco": { url: "/person/amandine-di-cicco", code:301},
        "/personne/amandine-saint-martin": { url: "/directory", code:301},
        "/personne/amandine-servy": { url: "/person/amandine-servy", code:301},
        "/personne/amani-asnacios-lecerf": { url: "/person/amani-asnacios-lecerf", code:301},
        "/personne/amartya-chakraborty": { url: "/directory", code:301},
        "/personne/amaury-martin-0": { url: "/person/amaury-martin", code:301},
        "/personne/ambre-petitalot": { url: "/person/ambre-petitalot", code:301},
        "/personne/amelie-gantzer": { url: "/person/amelie-gantzer", code:301},
        "/personne/amelie-heneman": { url: "/person/amelie-heneman", code:301},
        "/personne/amine-naimi": { url: "/person/amine-naimi", code:301},
        "/personne/amira-kramdi": { url: "/person/amira-kramdi", code:301},
        "/personne/ana-joaquina-jimenez-salazar": { url: "/directory", code:301},
        "/personne/ana-luisa-dian": { url: "/person/ana-luisa-dian", code:301},
        "/personne/ana-maria-lennon-dumenil": { url: "/person/ana-maria-lennon-dumenil", code:301},
        "/personne/anael-hanouna": { url: "/person/anael-hanouna", code:301},
        "/personne/anais-le-ven": { url: "/person/anais-le-ven", code:301},
        "/personne/anastasia-lanzi": { url: "/person/anastasia-lanzi", code:301},
        "/personne/andre-bortolini-silveira": { url: "/person/andre-bortolini-silveira", code:301},
        "/personne/andrea-avila-avila": { url: "/directory", code:301},
        "/personne/andrea-iglesias-ramas": { url: "/person/andrea-iglesias-ramas", code:301},
        "/personne/andrea-scelfo": { url: "/person/andrea-scelfo", code:301},
        "/personne/andreu-pujolar-ramos": { url: "/person/andreu-pujolar-ramos", code:301},
        "/personne/angela-corvino": { url: "/person/angela-corvino", code:301},
        "/personne/angela-taddei": { url: "/person/angela-taddei", code:301},
        "/personne/anissia-ait-saada": { url: "/person/anissia-ait-saada", code:301},
        "/personne/anna-almeida": { url: "/person/anna-almeida", code:301},
        "/personne/anna-chipont": { url: "/person/anna-chipont", code:301},
        "/personne/anna-shmakova": { url: "/person/anna-shmakova", code:301},
        "/personne/annabelle-ballesta": { url: "/person/annabelle-ballesta", code:301},
        "/personne/annaig-bertho": { url: "/person/annaig-bertho", code:301},
        "/personne/annapaola-angrisani": { url: "/person/annapaola-angrisani", code:301},
        "/personne/anne-bethune": { url: "/person/anne-bethune", code:301},
        "/personne/anne-bredart": { url: "/person/anne-bredart", code:301},
        "/personne/anne-chilles-wang": { url: "/person/anne-chilles-wang", code:301},
        "/personne/anne-christine-brunet": { url: "/person/anne-christine-brunet", code:301},
        "/personne/anne-claire-kroger": { url: "/person/anne-claire-kroger", code:301},
        "/personne/anne-donnadieu": { url: "/person/anne-donnadieu", code:301},
        "/personne/anne-fajac": { url: "/person/anne-fajac", code:301},
        "/personne/anne-gaelle-lafont": { url: "/person/anne-gaelle-lafont", code:301},
        "/personne/anne-helene-monsoro-burq": { url: "/person/anne-helene-monsoro-burq", code:301},
        "/personne/anne-houdusse-juille": { url: "/person/anne-houdusse-juille", code:301},
        "/personne/anne-houdusse-juille?page=1": { url: "/person/anne-houdusse-juille", code:301},
        "/personne/anne-laure-begue": { url: "/person/anne-laure-begue", code:301},
        "/personne/anne-laure-le-gac": { url: "/person/anne-laure-le-gac", code:301},
        "/personne/anne-mangnan": { url: "/person/anne-mangnan", code:301},
        "/personne/anne-marie-lyne": { url: "/person/anne-marie-lyne", code:301},
        "/personne/anne-salomon": { url: "/person/anne-salomon", code:301},
        "/personne/anne-sophie-hamy-petit": { url: "/person/anne-sophie-hamy-petit", code:301},
        "/personne/anne-sophie-mace": { url: "/person/anne-sophie-mace", code:301},
        "/personne/anne-tardivon": { url: "/person/anne-tardivon", code:301},
        "/personne/annick-viguier": { url: "/person/annick-viguier", code:301},
        "/personne/anthony-rabate": { url: "/directory", code:301},
        "/personne/anthony-simon": { url: "/person/anthony-simon", code:301},
        "/personne/antoine-coulon": { url: "/person/antoine-coulon", code:301},
        "/personne/antoine-dubray-vautrin": { url: "/person/antoine-dubray-vautrin", code:301},
        "/personne/antoine-even": { url: "/person/antoine-even", code:301},
        "/personne/anton-granzhan": { url: "/person/anton-granzhan", code:301},
        "/personne/anton-granzhan?page=1": { url: "/person/anton-granzhan", code:301},
        "/personne/anton-granzhan?page=2": { url: "/person/anton-granzhan", code:301},
        "/personne/anton-granzhan?page=3": { url: "/person/anton-granzhan", code:301},
        "/personne/anton-granzhan?page=4": { url: "/person/anton-granzhan", code:301},
        "/personne/anton-granzhan?page=5": { url: "/person/anton-granzhan", code:301},
        "/personne/antonia-terrizzano": { url: "/person/antonia-terrizzano", code:301},
        "/personne/antonin-morillon": { url: "/person/antonin-morillon", code:301},
        "/personne/antony-lee": { url: "/person/antony-lee", code:301},
        "/personne/apolline-de-testas-de-folmont": { url: "/person/apolline-de-testas-de-folmont", code:301},
        "/personne/apolline-gallois": { url: "/person/apolline-gallois", code:301},
        "/personne/ariane-ramaekers": { url: "/person/ariane-ramaekers", code:301},
        "/personne/arjeta-kluna": { url: "/person/arjeta-kluna", code:301},
        "/personne/artem-fokin": { url: "/person/artem-fokin", code:301},
        "/personne/arthur-meslin": { url: "/person/arthur-meslin", code:301},
        "/personne/arthur-pereira": { url: "/person/arthur-pereira", code:301},
        "/personne/arturo-londono": { url: "/person/arturo-londono", code:301},
        "/personne/arturo-londono?page=1": { url: "/person/arturo-londono", code:301},
        "/personne/aruni-senaratne": { url: "/directory", code:301},
        "/personne/arya-krishnan": { url: "/person/arya-krishnan", code:301},
        "/personne/audrey-forest": { url: "/person/audrey-forest", code:301},
        "/personne/audrey-gravelat": { url: "/person/audrey-gravelat", code:301},
        "/personne/audrey-rapinat": { url: "/person/audrey-rapinat", code:301},
        "/personne/audrey-stalla-bourdillon": { url: "/person/audrey-stalla-bourdillon", code:301},
        "/personne/aurelie-bertin": { url: "/person/aurelie-bertin", code:301},
        "/personne/aurelie-darbois-delahousse": { url: "/person/aurelie-darbois-delahousse", code:301},
        "/personne/aurelie-descamps": { url: "/person/aurelie-descamps", code:301},
        "/personne/aurelie-di-cicco": { url: "/person/aurelie-di-cicco", code:301},
        "/personne/aurelie-le-guen": { url: "/person/aurelie-le-guen", code:301},
        "/personne/aurelie-rodriguez": { url: "/person/aurelie-rodriguez", code:301},
        "/personne/aurelie-teissandier": { url: "/person/aurelie-teissandier", code:301},
        "/personne/aurelien-bore": { url: "/person/aurelien-bore", code:301},
        "/personne/aurelien-dauphin": { url: "/person/aurelien-dauphin", code:301},
        "/personne/aurelien-latouche": { url: "/person/aurelien-latouche", code:301},
        "/personne/aurelien-maillot": { url: "/person/aurelien-maillot", code:301},
        "/personne/aurore-aguado": { url: "/person/aurore-aguado", code:301},
        "/personne/aurore-sanchez": { url: "/person/aurore-sanchez", code:301},
        "/personne/aurore-van-de-walle": { url: "/person/aurore-van-de-walle", code:301},
        "/personne/axel-buguin": { url: "/person/axel-buguin", code:301},
        "/personne/aya-alami": { url: "/person/aya-alami", code:301},
        "/personne/ayan-mallick": { url: "/person/ayan-mallick", code:301},
        "/personne/bakhos-jneid": { url: "/person/bakhos-jneid", code:301},
        "/personne/baptiste-tesson": { url: "/person/baptiste-tesson", code:301},
        "/personne/bassam-hajj": { url: "/person/bassam-hajj", code:301},
        "/personne/batuhan-altay": { url: "/person/batuhan-altay", code:301},
        "/personne/beatrice-martin": { url: "/person/beatrice-martin", code:301},
        "/personne/beatriz-farah-noroes-goncalves": { url: "/person/beatriz-farah-noroes-goncalves", code:301},
        "/personne/beatriz-grandal-rejo": { url: "/person/beatriz-grandal-rejo", code:301},
        "/personne/benedicte-lefevre": { url: "/person/benedicte-lefevre", code:301},
        "/personne/benjamin-bonneau": { url: "/person/benjamin-bonneau", code:301},
        "/personne/benjamin-bonsang": { url: "/directory", code:301},
        "/personne/benoit-albaud": { url: "/person/benoit-albaud", code:301},
        "/personne/benoit-couturaud": { url: "/person/benoit-couturaud", code:301},
        "/personne/benoit-sorre": { url: "/person/benoit-sorre", code:301},
        "/personne/berangere-lombard": { url: "/person/berangere-lombard", code:301},
        "/personne/berengere-ouine": { url: "/person/berengere-ouine", code:301},
        "/personne/bernardin-akagah": { url: "/person/bernardin-akagah", code:301},
        "/personne/bertrand-duvillie": { url: "/person/bertrand-duvillie", code:301},
        "/personne/blandine-baudon": { url: "/person/blandine-baudon", code:301},
        "/personne/boris-bardot": { url: "/person/boris-bardot", code:301},
        "/personne/boris-bardot?page=1": { url: "/person/boris-bardot", code:301},
        "/personne/boris-guirao": { url: "/person/boris-guirao", code:301},
        "/personne/brenda-montana-chaparro": { url: "/person/brenda-montana-chaparro", code:301},
        "/personne/brice-lapin": { url: "/person/brice-lapin", code:301},
        "/personne/brigitte-blaszka-jaulerry": { url: "/person/brigitte-blaszka-jaulerry", code:301},
        "/personne/bruna-pages": { url: "/directory", code:301},
        "/personne/bruno-buecher": { url: "/person/bruno-buecher", code:301},
        "/personne/bruno-goud": { url: "/person/bruno-goud", code:301},
        "/personne/caio-vaz-rimoli": { url: "/person/caio-vaz-rimoli", code:301},
        "/personne/calvin-steve-rodrigues": { url: "/person/calvin-steve-rodrigues", code:301},
        "/personne/camellia-chakraborty": { url: "/person/camellia-chakraborty", code:301},
        "/personne/camila-garcia-baudino": { url: "/person/camila-garcia-baudino", code:301},
        "/personne/camila-leite-nascimento": { url: "/person/camila-leite-nascimento", code:301},
        "/personne/camila-veludo-ramos": { url: "/person/camila-veludo-ramos", code:301},
        "/personne/camilla-giaccaglia": { url: "/person/camilla-giaccaglia", code:301},
        "/personne/camille-coulibaly": { url: "/directory", code:301},
        "/personne/camille-glaser": { url: "/person/camille-glaser", code:301},
        "/personne/camille-plancke": { url: "/person/camille-plancke", code:301},
        "/personne/camille-richagneux": { url: "/person/camille-richagneux", code:301},
        "/personne/camille-schmitt": { url: "/person/camille-schmitt", code:301},
        "/personne/candice-merle": { url: "/person/candice-merle", code:301},
        "/personne/carine-rosse": { url: "/person/carine-rosse", code:301},
        "/personne/carla-martins-da-costa": { url: "/person/carla-martins-da-costa", code:301},
        "/personne/carla-matta": { url: "/directory", code:301},
        "/personne/carles-blanch-mercader": { url: "/person/carles-blanch-mercader", code:301},
        "/personne/carlos-balinas-gavira": { url: "/person/carlos-balinas-gavira", code:301},
        "/personne/carlos-kikuti": { url: "/person/carlos-kikuti", code:301},
        "/personne/carlos-perez-gonzalez": { url: "/person/carlos-perez-gonzalez", code:301},
        "/personne/carole-bouleuc-parrot": { url: "/person/carole-bouleuc-parrot", code:301},
        "/personne/carole-gonzalez": { url: "/person/carole-gonzalez", code:301},
        "/personne/carole-kalogeropoulos": { url: "/directory", code:301},
        "/personne/carole-rohrbacher": { url: "/person/carole-rohrbacher", code:301},
        "/personne/carole-soussain": { url: "/person/carole-soussain", code:301},
        "/personne/carole-thomas": { url: "/person/carole-thomas", code:301},
        "/personne/caroline-adams": { url: "/person/caroline-adams", code:301},
        "/personne/caroline-hego-garnier": { url: "/person/caroline-hego-garnier", code:301},
        "/personne/caroline-louis": { url: "/person/caroline-louis", code:301},
        "/personne/caroline-malhaire": { url: "/person/caroline-malhaire", code:301},
        "/personne/caroline-mencarelli": { url: "/directory", code:301},
        "/personne/caroline-parent": { url: "/person/caroline-parent", code:301},
        "/personne/carsten-janke": { url: "/person/carsten-janke", code:301},
        "/personne/carsten-janke?page=1": { url: "/person/carsten-janke", code:301},
        "/personne/carsten-janke?page=2": { url: "/person/carsten-janke", code:301},
        "/personne/carsten-janke?page=3": { url: "/person/carsten-janke", code:301},
        "/personne/carsten-janke?page=4": { url: "/person/carsten-janke", code:301},
        "/personne/cassandra-mamotte": { url: "/person/cassandra-mamotte", code:301},
        "/personne/catalina-francisca-salinas-luypaert": { url: "/person/catalina-francisca-salinas-luypaert", code:301},
        "/personne/catherine-ala-eddine": { url: "/person/catherine-ala-eddine", code:301},
        "/personne/catherine-daniel": { url: "/person/catherine-daniel", code:301},
        "/personne/catherine-robley": { url: "/person/catherine-robley", code:301},
        "/personne/cathy-pichol-thievend": { url: "/person/cathy-pichol-thievend", code:301},
        "/personne/cecile-alanio": { url: "/person/cecile-alanio", code:301},
        "/personne/cecile-campagne": { url: "/person/cecile-campagne", code:301},
        "/personne/cecile-conrad": { url: "/person/cecile-conrad", code:301},
        "/personne/cecile-pierre-eugene": { url: "/person/cecile-pierre-eugene", code:301},
        "/personne/cecile-reyes": { url: "/person/cecile-reyes", code:301},
        "/personne/cecile-thirant": { url: "/person/cecile-thirant", code:301},
        "/personne/cedric-blouin": { url: "/person/cedric-blouin", code:301},
        "/personne/cedric-messaoudi": { url: "/person/cedric-messaoudi", code:301},
        "/personne/celine-adam": { url: "/person/celine-adam", code:301},
        "/personne/celine-chauvin": { url: "/person/celine-chauvin", code:301},
        "/personne/celine-gracia": { url: "/person/celine-gracia", code:301},
        "/personne/celine-jacquet": { url: "/person/celine-jacquet", code:301},
        "/personne/celine-labbe": { url: "/person/celine-labbe", code:301},
        "/personne/celine-laouisset": { url: "/person/celine-laouisset", code:301},
        "/personne/celine-roulle": { url: "/person/celine-roulle", code:301},
        "/personne/celine-vallot": { url: "/person/celine-vallot", code:301},
        "/personne/celine-vallot?page=0": { url: "/person/celine-vallot", code:301},
        "/personne/celine-vallot?page=1": { url: "/person/celine-vallot", code:301},
        "/personne/celine-vallot?page=2": { url: "/person/celine-vallot", code:301},
        "/personne/celio-pouponnot": { url: "/person/celio-pouponnot", code:301},
        "/personne/chafia-chassaing": { url: "/directory", code:301},
        "/personne/chafiaa-bouguechtouli": { url: "/person/chafiaa-bouguechtouli", code:301},
        "/personne/chandini-bhaskar-naidu": { url: "/person/chandini-bhaskar-naidu", code:301},
        "/personne/chandramouli-natarajan": { url: "/person/chandramouli-natarajan", code:301},
        "/personne/chantal-alkhoury": { url: "/person/chantal-alkhoury", code:301},
        "/personne/charlene-lasgi": { url: "/person/charlene-lasgi", code:301},
        "/personne/charlene-renaud-pageot": { url: "/person/charlene-renaud-pageot", code:301},
        "/personne/charles-fouillade": { url: "/person/charles-fouillade", code:301},
        "/personne/charles-fouillade?page=1": { url: "/person/charles-fouillade", code:301},
        "/personne/charles-fouillade?page=2": { url: "/person/charles-fouillade", code:301},
        "/personne/charles-kervrann": { url: "/person/charles-kervrann", code:301},
        "/personne/charlie-duclut": { url: "/person/charlie-duclut", code:301},
        "/personne/charlotte-butterworth": { url: "/person/charlotte-butterworth", code:301},
        "/personne/charlotte-canet-jourdan": { url: "/person/charlotte-canet-jourdan", code:301},
        "/personne/charlotte-lamirault": { url: "/person/charlotte-lamirault", code:301},
        "/personne/charlotte-lozach": { url: "/person/charlotte-lozach", code:301},
        "/personne/charlotte-martin": { url: "/person/charlotte-martin", code:301},
        "/personne/chieh-yu-chang": { url: "/person/chieh-yu-chang", code:301},
        "/personne/chloe-agathe-azencott": { url: "/person/chloe-agathe-azencott", code:301},
        "/personne/chloe-barbier": { url: "/person/chloe-barbier", code:301},
        "/personne/chloe-guedj": { url: "/person/chloe-guedj", code:301},
        "/personne/chloe-sybillin": { url: "/directory", code:301},
        "/personne/choumouss-kamoun": { url: "/person/choumouss-kamoun", code:301},
        "/personne/christel-goudot": { url: "/person/christel-goudot", code:301},
        "/personne/christele-maison": { url: "/person/christele-maison", code:301},
        "/personne/christelle-masdeu": { url: "/directory", code:301},
        "/personne/christian-chappey": { url: "/person/christian-chappey", code:301},
        "/personne/christian-wunder": { url: "/person/christian-wunder", code:301},
        "/personne/christian-wunder?page=2": { url: "/person/christian-wunder", code:301},
        "/personne/christine-canbezdi": { url: "/person/christine-canbezdi", code:301},
        "/personne/christine-gaillet": { url: "/person/christine-gaillet", code:301},
        "/personne/christine-karagoz": { url: "/person/christine-karagoz", code:301},
        "/personne/christine-lonjou": { url: "/person/christine-lonjou", code:301},
        "/personne/christine-sedlik": { url: "/person/christine-sedlik", code:301},
        "/personne/christine-tran-quang": { url: "/person/christine-tran-quang", code:301},
        "/personne/christine-viaris-de-lesegno": { url: "/person/christine-viaris-de-lesegno", code:301},
        "/personne/christine-walczak": { url: "/person/christine-walczak", code:301},
        "/personne/christophe-lamaze": { url: "/person/christophe-lamaze", code:301},
        "/personne/christophe-lamaze?page=0": { url: "/person/christophe-lamaze", code:301},
        "/personne/christophe-lamaze?page=1": { url: "/person/christophe-lamaze", code:301},
        "/personne/christophe-lamaze?page=2": { url: "/person/christophe-lamaze", code:301},
        "/personne/christophe-le-tourneau": { url: "/person/christophe-le-tourneau", code:301},
        "/personne/christophe-nioche": { url: "/person/christophe-nioche", code:301},
        "/personne/christophe-roulin": { url: "/person/christophe-roulin", code:301},
        "/personne/chrystelle-colas": { url: "/person/chrystelle-colas", code:301},
        "/personne/chunlong-chen": { url: "/person/chunlong-chen", code:301},
        "/personne/chunlong-chen?page=0": { url: "/person/chunlong-chen", code:301},
        "/personne/chunlong-chen?page=1": { url: "/person/chunlong-chen", code:301},
        "/personne/chunlong-chen?page=2": { url: "/person/chunlong-chen", code:301},
        "/personne/chunlong-chen?page=3": { url: "/person/chunlong-chen", code:301},
        "/personne/chunlong-chen?page=4": { url: "/person/chunlong-chen", code:301},
        "/personne/chunlong-chen?page=5": { url: "/person/chunlong-chen", code:301},
        "/personne/chunlong-chen?page=6": { url: "/person/chunlong-chen", code:301},
        "/personne/cindy-assi": { url: "/person/cindy-assi", code:301},
        "/personne/claire-alapetite": { url: "/person/claire-alapetite", code:301},
        "/personne/claire-beauvineau": { url: "/person/claire-beauvineau", code:301},
        "/personne/claire-bonneau": { url: "/person/claire-bonneau", code:301},
        "/personne/claire-hivroz": { url: "/person/claire-hivroz", code:301},
        "/personne/claire-hivroz?page=1": { url: "/person/claire-hivroz", code:301},
        "/personne/claire-hivroz?page=2": { url: "/person/claire-hivroz", code:301},
        "/personne/claire-morel": { url: "/person/claire-morel", code:301},
        "/personne/claire-saule": { url: "/person/claire-saule", code:301},
        "/personne/claire-soudais": { url: "/person/claire-soudais", code:301},
        "/personne/claire-wilhelm": { url: "/person/claire-wilhelm", code:301},
        "/personne/claire-wilhelm?page=1": { url: "/person/claire-wilhelm", code:301},
        "/personne/clara-phan": { url: "/person/clara-phan", code:301},
        "/personne/clarisse-brunet": { url: "/person/clarisse-brunet", code:301},
        "/personne/clemence-metayer": { url: "/person/clemence-metayer", code:301},
        "/personne/clement-bonnet": { url: "/person/clement-bonnet", code:301},
        "/personne/clement-hua": { url: "/person/clement-hua", code:301},
        "/personne/clement-martet": { url: "/directory", code:301},
        "/personne/clementine-krucker": { url: "/person/clementine-krucker", code:301},
        "/personne/clothilde-roy": { url: "/directory", code:301},
        "/personne/clotilde-thery": { url: "/person/clotilde-thery", code:301},
        "/personne/clotilde-thery?page=0": { url: "/person/clotilde-thery", code:301},
        "/personne/clotilde-thery?page=1": { url: "/person/clotilde-thery", code:301},
        "/personne/clotilde-thery?page=2": { url: "/person/clotilde-thery", code:301},
        "/personne/colin-morand": { url: "/person/colin-morand", code:301},
        "/personne/coline-hugot": { url: "/person/coline-hugot", code:301},
        "/personne/coralie-guerin": { url: "/person/coralie-guerin", code:301},
        "/personne/corentin-melisse": { url: "/directory", code:301},
        "/personne/corinne-cordier": { url: "/person/corinne-cordier", code:301},
        "/personne/corinne-guetta": { url: "/person/corinne-guetta", code:301},
        "/personne/cristele-gilbert": { url: "/person/cristele-gilbert", code:301},
        "/personne/cristian-ignacio-saquel-escobedo": { url: "/person/cristian-ignacio-saquel-escobedo", code:301},
        "/personne/cristina-bartocci": { url: "/person/cristina-bartocci", code:301},
        "/personne/cynthia-occhipinti": { url: "/directory", code:301},
        "/personne/daad-mikhail": { url: "/person/daad-mikhail", code:301},
        "/personne/dalila-saulebekova": { url: "/person/dalila-saulebekova", code:301},
        "/personne/dalmiro-blanco-obregon": { url: "/person/dalmiro-blanco-obregon", code:301},
        "/personne/damarys-loew": { url: "/person/damarys-loew", code:301},
        "/personne/damien-cuvelier": { url: "/person/damien-cuvelier", code:301},
        "/personne/damien-maclard": { url: "/person/damien-maclard", code:301},
        "/personne/daniel-henri-orbach": { url: "/person/daniel-henri-orbach", code:301},
        "/personne/daniel-herrero-saboya": { url: "/person/daniel-herrero-saboya", code:301},
        "/personne/daniel-holoch": { url: "/person/daniel-holoch", code:301},
        "/personne/daniel-levy": { url: "/person/daniel-levy", code:301},
        "/personne/daniel-louvard": { url: "/person/daniel-louvard", code:301},
        "/personne/daniel-szwarc": { url: "/person/daniel-szwarc", code:301},
        "/personne/daniela-verga": { url: "/person/daniela-verga", code:301},
        "/personne/daniela-verga?page=1": { url: "/person/daniela-verga", code:301},
        "/personne/daniela-verga?page=2": { url: "/person/daniela-verga", code:301},
        "/personne/daniele-fachinetti": { url: "/person/daniele-fachinetti", code:301},
        "/personne/daniele-fachinetti?page=1": { url: "/person/daniele-fachinetti", code:301},
        "/personne/daniele-fachinetti?page=2": { url: "/person/daniele-fachinetti", code:301},
        "/personne/daniele-fachinetti?page=3": { url: "/person/daniele-fachinetti", code:301},
        "/personne/danijela-matic-vignjevic": { url: "/person/danijela-matic-vignjevic", code:301},
        "/personne/danijela-matic-vignjevic?page=1": { url: "/person/danijela-matic-vignjevic", code:301},
        "/personne/danijela-matic-vignjevic?page=2": { url: "/person/danijela-matic-vignjevic", code:301},
        "/personne/danilo-da-silva-lopes": { url: "/person/danilo-da-silva-lopes", code:301},
        "/personne/darawan-tabtim": { url: "/person/darawan-tabtim", code:301},
        "/personne/dario-pasquale-anobile": { url: "/person/dario-pasquale-anobile", code:301},
        "/personne/dario-rocha": { url: "/person/dario-rocha", code:301},
        "/personne/dave-ng-yong-xiang": { url: "/person/dave-ng-yong-xiang", code:301},
        "/personne/david-mazaud": { url: "/person/david-mazaud", code:301},
        "/personne/david-remy": { url: "/person/david-remy", code:301},
        "/personne/david-rozema": { url: "/person/david-rozema", code:301},
        "/personne/david-sitbon": { url: "/person/david-sitbon", code:301},
        "/personne/debarpan-ghosh": { url: "/person/debarpan-ghosh", code:301},
        "/personne/deborah-bourchis": { url: "/person/deborah-bourchis", code:301},
        "/personne/delia-dupre": { url: "/person/delia-dupre", code:301},
        "/personne/delphine-bailly": { url: "/person/delphine-bailly", code:301},
        "/personne/delphine-bonhomme": { url: "/person/delphine-bonhomme", code:301},
        "/personne/delphine-javelaud": { url: "/person/delphine-javelaud", code:301},
        "/personne/delphine-loirat": { url: "/person/delphine-loirat", code:301},
        "/personne/delphine-naud-martin": { url: "/person/delphine-naud-martin", code:301},
        "/personne/denis-malaise": { url: "/person/denis-malaise", code:301},
        "/personne/didier-decaudin": { url: "/person/didier-decaudin", code:301},
        "/personne/didier-decaudin-0": { url: "/person/didier-decaudin", code:301},
        "/personne/dimitri-tzanis": { url: "/person/dimitri-tzanis", code:301},
        "/personne/dominika-foretek": { url: "/directory", code:301},
        "/personne/dominique-ray-gallet": { url: "/person/dominique-ray-gallet", code:301},
        "/personne/dominique-stoppa-lyonnet": { url: "/person/dominique-stoppa-lyonnet", code:301},
        "/personne/dondu-kirmizi": { url: "/person/dondu-kirmizi", code:301},
        "/personne/dorothe-haouy": { url: "/person/dorothe-haouy", code:301},
        "/personne/dorothee-baille": { url: "/person/dorothee-baille", code:301},
        "/personne/dorra-limam": { url: "/directory", code:301},
        "/personne/dorthe-nickel": { url: "/person/dorthe-nickel", code:301},
        "/personne/edgar-daniel-calderon-mora": { url: "/person/edgar-daniel-calderon-mora", code:301},
        "/personne/edith-borcoman": { url: "/person/edith-borcoman", code:301},
        "/personne/edith-somveille": { url: "/person/edith-somveille", code:301},
        "/personne/eka-putra-gusti-ngurah-putu": { url: "/person/eka-putra-gusti-ngurah-putu", code:301},
        "/personne/ekaterina-antonenko": { url: "/person/ekaterina-antonenko", code:301},
        "/personne/elaine-del-nery-santos": { url: "/person/elaine-del-nery-santos", code:301},
        "/personne/elaine-del-nery-santos?page=0": { url: "/person/elaine-del-nery-santos", code:301},
        "/personne/elaine-del-nery-santos?page=1": { url: "/person/elaine-del-nery-santos", code:301},
        "/personne/elaine-del-nery-santos?page=2": { url: "/person/elaine-del-nery-santos", code:301},
        "/personne/elena-de-la-mata-santaella": { url: "/person/elena-de-la-mata-santaella", code:301},
        "/personne/elena-musat": { url: "/person/elena-musat", code:301},
        "/personne/eliane-piaggio": { url: "/person/eliane-piaggio", code:301},
        "/personne/eliane-piaggio?page=0": { url: "/person/eliane-piaggio", code:301},
        "/personne/eliane-piaggio?page=1": { url: "/person/eliane-piaggio", code:301},
        "/personne/eliane-piaggio?page=2": { url: "/person/eliane-piaggio", code:301},
        "/personne/eliane-piaggio?page=3": { url: "/person/eliane-piaggio", code:301},
        "/personne/elias-assaf": { url: "/person/elias-assaf", code:301},
        "/personne/elif-lale-alpar": { url: "/person/elif-lale-alpar", code:301},
        "/personne/elina-zueva": { url: "/person/elina-zueva", code:301},
        "/personne/eliot-schlang": { url: "/person/eliot-schlang", code:301},
        "/personne/elisabeth-cazals": { url: "/person/elisabeth-cazals", code:301},
        "/personne/elisabeth-lucchi": { url: "/person/elisabeth-lucchi", code:301},
        "/personne/elisabetta-marangoni": { url: "/person/elisabetta-marangoni", code:301},
        "/personne/elise-dumas": { url: "/person/elise-dumas", code:301},
        "/personne/elise-vacher": { url: "/person/elise-vacher", code:301},
        "/personne/elliot-lopez": { url: "/person/elliot-lopez", code:301},
        "/personne/elliot-thouvenot": { url: "/person/elliot-thouvenot", code:301},
        "/personne/ellora-chua-hui-zhen": { url: "/person/ellora-chua-hui-zhen", code:301},
        "/personne/elodie-anthony": { url: "/person/elodie-anthony", code:301},
        "/personne/elodie-girard": { url: "/person/elodie-girard", code:301},
        "/personne/elodie-segura": { url: "/person/elodie-segura", code:301},
        "/personne/elsy-el-alam": { url: "/person/elsy-el-alam", code:301},
        "/personne/emanuela-romano": { url: "/person/emanuela-romano", code:301},
        "/personne/emeline-ravalli": { url: "/directory", code:301},
        "/personne/emile-maucotel": { url: "/person/emile-maucotel", code:301},
        "/personne/emilie-henry": { url: "/person/emilie-henry", code:301},
        "/personne/emilie-mylne": { url: "/person/emilie-mylne", code:301},
        "/personne/emma-armand": { url: "/person/emma-armand", code:301},
        "/personne/emma-bonerandi": { url: "/person/emma-bonerandi", code:301},
        "/personne/emma-kaufmann": { url: "/person/emma-kaufmann", code:301},
        "/personne/emma-moliere": { url: "/person/emma-moliere", code:301},
        "/personne/emma-torun": { url: "/person/emma-torun", code:301},
        "/personne/emmanuel-barillot": { url: "/person/emmanuel-barillot", code:301},
        "/personne/emmanuel-farge": { url: "/person/emmanuel-farge", code:301},
        "/personne/emmanuelle-menet": { url: "/person/emmanuelle-menet", code:301},
        "/personne/emmanuelle-mouret-fourme": { url: "/person/emmanuelle-mouret-fourme", code:301},
        "/personne/enora-laas-faron": { url: "/person/enora-laas-faron", code:301},
        "/personne/enzo-poirier": { url: "/person/enzo-poirier", code:301},
        "/personne/enzo-poirier?page=0": { url: "/person/enzo-poirier", code:301},
        "/personne/enzo-poirier?page=1": { url: "/person/enzo-poirier", code:301},
        "/personne/eric-allyre": { url: "/person/eric-allyre", code:301},
        "/personne/eric-quiniou": { url: "/person/eric-quiniou", code:301},
        "/personne/erkan-narmanli": { url: "/person/erkan-narmanli", code:301},
        "/personne/estelle-dransart": { url: "/person/estelle-dransart", code:301},
        "/personne/etienne-brain": { url: "/person/etienne-brain", code:301},
        "/personne/etienne-poidomani": { url: "/person/etienne-poidomani", code:301},
        "/personne/ettore-de-giorgio": { url: "/person/ettore-de-giorgio", code:301},
        "/personne/eugenie-guillot": { url: "/person/eugenie-guillot", code:301},
        "/personne/eva-desvigne-hansch": { url: "/person/eva-desvigne-hansch", code:301},
        "/personne/eva-galateau": { url: "/person/eva-galateau", code:301},
        "/personne/eva-guerin": { url: "/person/eva-guerin", code:301},
        "/personne/eva-safar": { url: "/person/eva-safar", code:301},
        "/personne/eve-moutaux": { url: "/person/eve-moutaux", code:301},
        "/personne/evelyne-coudrier": { url: "/person/evelyne-coudrier", code:301},
        "/personne/evelyne-renault-tessier": { url: "/person/evelyne-renault-tessier", code:301},
        "/personne/evelyne-ruff": { url: "/directory", code:301},
        "/personne/evelyne-sage": { url: "/person/evelyne-sage", code:301},
        "/personne/fabien-reyal": { url: "/person/fabien-reyal", code:301},
        "/personne/fabien-reyal-0": { url: "/person/fabien-reyal", code:301},
        "/personne/fabien-romano": { url: "/directory", code:301},
        "/personne/fabien-sindikubwabo": { url: "/person/fabien-sindikubwabo", code:301},
        "/personne/fabien-sindikubwabo?page=0": { url: "/person/fabien-sindikubwabo", code:301},
        "/personne/fabien-sindikubwabo?page=1": { url: "/person/fabien-sindikubwabo", code:301},
        "/personne/fabienne-lesueur": { url: "/person/fabienne-lesueur", code:301},
        "/personne/fabrice-demarthon": { url: "/person/fabrice-demarthon", code:301},
        "/personne/fadma-lakhal": { url: "/person/fadma-lakhal", code:301},
        "/personne/faina-kuperstein": { url: "/directory", code:301},
        "/personne/fanny-coffin": { url: "/person/fanny-coffin", code:301},
        "/personne/fanny-orlhac": { url: "/person/fanny-orlhac", code:301},
        "/personne/fanny-tabarin-cayrac": { url: "/person/fanny-tabarin-cayrac", code:301},
        "/personne/fatima-laki": { url: "/person/fatima-laki", code:301},
        "/personne/fatima-mechta-grigoriou": { url: "/person/fatima-mechta-grigoriou", code:301},
        "/personne/fatima-mechta-grigoriou?page=0": { url: "/person/fatima-mechta-grigoriou", code:301},
        "/personne/fatima-mechta-grigoriou?page=1": { url: "/person/fatima-mechta-grigoriou", code:301},
        "/personne/fatima-mechta-grigoriou?page=2": { url: "/person/fatima-mechta-grigoriou", code:301},
        "/personne/fatima-mechta-grigoriou?page=3": { url: "/person/fatima-mechta-grigoriou", code:301},
        "/personne/federica-scotto-di-carlo": { url: "/person/federica-scotto-di-carlo", code:301},
        "/personne/federico-marziali": { url: "/person/federico-marziali", code:301},
        "/personne/feng-ching-tsai": { url: "/person/feng-ching-tsai", code:301},
        "/personne/feryal-ali-zadeh": { url: "/person/feryal-ali-zadeh", code:301},
        "/personne/flavia-bernardi": { url: "/person/flavia-bernardi", code:301},
        "/personne/flavia-marsigliesi": { url: "/person/flavia-marsigliesi", code:301},
        "/personne/florence-bachelot": { url: "/person/florence-bachelot", code:301},
        "/personne/florence-cavalli": { url: "/person/florence-cavalli", code:301},
        "/personne/florence-cavalli?page=1": { url: "/person/florence-cavalli", code:301},
        "/personne/florence-cornebise-drouhet": { url: "/person/florence-cornebise-drouhet", code:301},
        "/personne/florence-coussy": { url: "/person/florence-coussy", code:301},
        "/personne/florence-faure": { url: "/person/florence-faure", code:301},
        "/personne/florence-lerebours": { url: "/person/florence-lerebours", code:301},
        "/personne/florence-mahuteau-betzer": { url: "/person/florence-mahuteau-betzer", code:301},
        "/personne/florence-piastra-facon": { url: "/person/florence-piastra-facon", code:301},
        "/personne/florence-rollot-trad": { url: "/person/florence-rollot-trad", code:301},
        "/personne/florent-dingli": { url: "/person/florent-dingli", code:301},
        "/personne/florian-massip": { url: "/person/florian-massip", code:301},
        "/personne/floriane-petit": { url: "/person/floriane-petit", code:301},
        "/personne/florine-verny": { url: "/person/florine-verny", code:301},
        "/personne/floris-bosveld": { url: "/person/floris-bosveld", code:301},
        "/personne/frances-edwards": { url: "/directory", code:301},
        "/personne/francesca-lucibello": { url: "/person/francesca-lucibello", code:301},
        "/personne/franck-bourdeaut": { url: "/person/franck-bourdeaut", code:301},
        "/personne/franck-bourdeaut-0": { url: "/person/franck-bourdeaut", code:301},
        "/personne/franck-perez": { url: "/person/franck-perez", code:301},
        "/personne/franck-perez?page=1": { url: "/person/franck-perez", code:301},
        "/personne/franck-perez?page=2": { url: "/person/franck-perez", code:301},
        "/personne/franck-perez?page=3": { url: "/person/franck-perez", code:301},
        "/personne/franck-perez?page=4": { url: "/person/franck-perez", code:301},
        "/personne/franck-perez?page=5": { url: "/person/franck-perez", code:301},
        "/personne/franck-perez?page=6": { url: "/person/franck-perez", code:301},
        "/personne/franck-toledo": { url: "/person/franck-toledo", code:301},
        "/personne/franck-toledo?page=1": { url: "/person/franck-toledo", code:301},
        "/personne/francois-clement-bidard": { url: "/person/francois-clement-bidard", code:301},
        "/personne/francois-doz": { url: "/person/francois-doz", code:301},
        "/personne/francois-legoux": { url: "/person/francois-legoux", code:301},
        "/personne/francois-legoux?page=0": { url: "/person/francois-legoux", code:301},
        "/personne/francois-legoux?page=1": { url: "/person/francois-legoux", code:301},
        "/personne/francois-radvanyi": { url: "/person/francois-radvanyi", code:301},
        "/personne/francois-radvanyi?page=1": { url: "/person/francois-radvanyi", code:301},
        "/personne/francois-radvanyi?page=2": { url: "/person/francois-radvanyi", code:301},
        "/personne/francois-xavier-gobert": { url: "/person/francois-xavier-gobert", code:301},
        "/personne/francoise-brochard": { url: "/person/francoise-brochard", code:301},
        "/personne/frederic-coquelle": { url: "/person/frederic-coquelle", code:301},
        "/personne/frederic-jarlier": { url: "/person/frederic-jarlier", code:301},
        "/personne/frederic-pouzoulet": { url: "/person/frederic-pouzoulet", code:301},
        "/personne/frederique-boutte": { url: "/directory", code:301},
        "/personne/frederique-frouin": { url: "/person/frederique-frouin", code:301},
        "/personne/frederique-grynkorn": { url: "/person/frederique-grynkorn", code:301},
        "/personne/frederique-kuhnowski": { url: "/person/frederique-kuhnowski", code:301},
        "/personne/frederique-megnin-chanet": { url: "/person/frederique-megnin-chanet", code:301},
        "/personne/frederique-quignon": { url: "/person/frederique-quignon", code:301},
        "/personne/gabriel-ramasamy": { url: "/person/gabriel-ramasamy", code:301},
        "/personne/gabriele-cancila": { url: "/directory", code:301},
        "/personne/gaelle-fontaine": { url: "/person/gaelle-fontaine", code:301},
        "/personne/gaetan-cornilleau": { url: "/person/gaetan-cornilleau", code:301},
        "/personne/genevieve-almouzni": { url: "/person/genevieve-almouzni", code:301},
        "/personne/genevieve-almouzni?page=0": { url: "/person/genevieve-almouzni", code:301},
        "/personne/genevieve-almouzni?page=1": { url: "/person/genevieve-almouzni", code:301},
        "/personne/geoffroy-aubouin": { url: "/person/geoffroy-aubouin", code:301},
        "/personne/georgia-maria-kefala": { url: "/person/georgia-maria-kefala", code:301},
        "/personne/geraldine-buhagiar": { url: "/person/geraldine-buhagiar", code:301},
        "/personne/geraldine-gentric": { url: "/person/geraldine-gentric", code:301},
        "/personne/geraldine-gubitta-wane": { url: "/person/geraldine-gubitta-wane", code:301},
        "/personne/geraldine-gubitta-wane-0": { url: "/person/geraldine-gubitta-wane", code:301},
        "/personne/gertjan-laenen": { url: "/person/gertjan-laenen", code:301},
        "/personne/giacomo-gropplero": { url: "/person/giacomo-gropplero", code:301},
        "/personne/giorgio-seano": { url: "/person/giorgio-seano", code:301},
        "/personne/giorgio-seano?page=1": { url: "/person/giorgio-seano", code:301},
        "/personne/gisela-dangelo": { url: "/person/gisela-dangelo", code:301},
        "/personne/godelieve-rochette-de-lempdes": { url: "/person/godelieve-rochette-de-lempdes", code:301},
        "/personne/gregoire-jouault": { url: "/person/gregoire-jouault", code:301},
        "/personne/gregory-thomson": { url: "/person/gregory-thomson", code:301},
        "/personne/guadalupe-suarez": { url: "/directory", code:301},
        "/personne/gudrun-schleiermacher": { url: "/person/gudrun-schleiermacher", code:301},
        "/personne/gudrun-schleiermacher-0": { url: "/person/gudrun-schleiermacher", code:301},
        "/personne/guilhem-cure": { url: "/person/guilhem-cure", code:301},
        "/personne/guillaume-bort": { url: "/person/guillaume-bort", code:301},
        "/personne/guillaume-bourmeau": { url: "/person/guillaume-bourmeau", code:301},
        "/personne/guillaume-dutertre": { url: "/person/guillaume-dutertre", code:301},
        "/personne/guy-kienda": { url: "/person/guy-kienda", code:301},
        "/personne/gwendal-guerin": { url: "/person/gwendal-guerin", code:301},
        "/personne/gwendoline-gros": { url: "/person/gwendoline-gros", code:301},
        "/personne/gwenn-guichaoua": { url: "/person/gwenn-guichaoua", code:301},
        "/personne/gwenn-le-meur": { url: "/person/gwenn-le-meur", code:301},
        "/personne/hamid-mammar": { url: "/person/hamid-mammar", code:301},
        "/personne/harry-rathbone": { url: "/person/harry-rathbone", code:301},
        "/personne/hedvika-martin": { url: "/person/hedvika-martin", code:301},
        "/personne/helene-moreau": { url: "/person/helene-moreau", code:301},
        "/personne/helene-neyret-kahn": { url: "/person/helene-neyret-kahn", code:301},
        "/personne/helene-pacquement": { url: "/directory", code:301},
        "/personne/helene-salmon": { url: "/person/helene-salmon", code:301},
        "/personne/helene-salmon?page=1": { url: "/person/helene-salmon", code:301},
        "/personne/heloise-desazars-de-montgailhard": { url: "/person/heloise-desazars-de-montgailhard", code:301},
        "/personne/heloise-muller": { url: "/person/heloise-muller", code:301},
        "/personne/henri-de-soyres": { url: "/person/henri-de-soyres", code:301},
        "/personne/henri-popard": { url: "/person/henri-popard", code:301},
        "/personne/henri-ver-hulst": { url: "/person/henri-ver-hulst", code:301},
        "/personne/hermine-ferran": { url: "/person/hermine-ferran", code:301},
        "/personne/herve-brisse": { url: "/person/herve-brisse", code:301},
        "/personne/herve-isambert": { url: "/person/herve-isambert", code:301},
        "/personne/herve-isambert?page=1": { url: "/person/herve-isambert", code:301},
        "/personne/hornella-fokem-fosso": { url: "/person/hornella-fokem-fosso", code:301},
        "/personne/hua-yu": { url: "/person/hua-yu", code:301},
        "/personne/hugo-croizer": { url: "/person/hugo-croizer", code:301},
        "/personne/hugo-laporte": { url: "/person/hugo-laporte", code:301},
        "/personne/hugo-roux": { url: "/person/hugo-roux", code:301},
        "/personne/hugo-saavedra": { url: "/person/hugo-saavedra", code:301},
        "/personne/ibtissam-ashwak-nekka": { url: "/person/ibtissam-ashwak-nekka", code:301},
        "/personne/ignacio-medina-yanez": { url: "/person/ignacio-medina-yanez", code:301},
        "/personne/ilham-aboulfath-ladid": { url: "/person/ilham-aboulfath-ladid", code:301},
        "/personne/ilyes-hamitouche": { url: "/directory", code:301},
        "/personne/inacio-gomes-medeiros": { url: "/person/inacio-gomes-medeiros", code:301},
        "/personne/ines-anna-drinnenberg": { url: "/person/ines-anna-drinnenberg", code:301},
        "/personne/ines-pires-santos-ramos-pinheiro": { url: "/person/ines-pires-santos-ramos-pinheiro", code:301},
        "/personne/irena-draskovic": { url: "/person/irena-draskovic", code:301},
        "/personne/irene-basili": { url: "/person/irene-basili", code:301},
        "/personne/irene-buvat": { url: "/person/irene-buvat", code:301},
        "/personne/irene-buvat?page=1": { url: "/person/irene-buvat", code:301},
        "/personne/irina-soldatova": { url: "/person/irina-soldatova", code:301},
        "/personne/isabella-hofer": { url: "/person/isabella-hofer", code:301},
        "/personne/isabelle-aerts": { url: "/person/isabelle-aerts", code:301},
        "/personne/isabelle-bernard-pierrot": { url: "/person/isabelle-bernard-pierrot", code:301},
        "/personne/isabelle-bonnet": { url: "/person/isabelle-bonnet", code:301},
        "/personne/isabelle-janoueix": { url: "/person/isabelle-janoueix", code:301},
        "/personne/isabelle-loiodice": { url: "/person/isabelle-loiodice", code:301},
        "/personne/isabelle-puisieux": { url: "/person/isabelle-puisieux", code:301},
        "/personne/ismail-jamail": { url: "/person/ismail-jamail", code:301},
        "/personne/iva-simeonova": { url: "/person/iva-simeonova", code:301},
        "/personne/ivan-bieche": { url: "/person/ivan-bieche", code:301},
        "/personne/jacob-torrejon-diaz": { url: "/person/jacob-torrejon-diaz", code:301},
        "/personne/jacques-camonis": { url: "/person/jacques-camonis", code:301},
        "/personne/jacques-ghysdael": { url: "/person/jacques-ghysdael", code:301},
        "/personne/jacques-prost": { url: "/person/jacques-prost", code:301},
        "/personne/jacques-vargaftig": { url: "/person/jacques-vargaftig", code:301},
        "/personne/jakub-sumbal": { url: "/person/jakub-sumbal", code:301},
        "/personne/jamal-hoche": { url: "/person/jamal-hoche", code:301},
        "/personne/jan-timon-werle": { url: "/person/jan-timon-werle", code:301},
        "/personne/jane-merlevede": { url: "/person/jane-merlevede", code:301},
        "/personne/jean-baptiste-lecanu": { url: "/person/jean-baptiste-lecanu", code:301},
        "/personne/jean-chatain": { url: "/person/jean-chatain", code:301},
        "/personne/jean-christophe-mino": { url: "/person/jean-christophe-mino", code:301},
        "/personne/jean-claude-florent": { url: "/person/jean-claude-florent", code:301},
        "/personne/jean-francois-joanny": { url: "/person/jean-francois-joanny", code:301},
        "/personne/jean-guillaume-feron": { url: "/person/jean-guillaume-feron", code:301},
        "/personne/jean-leon-maitre": { url: "/person/jean-leon-maitre", code:301},
        "/personne/jean-leon-maitre?page=0": { url: "/person/jean-leon-maitre", code:301},
        "/personne/jean-leon-maitre?page=1": { url: "/person/jean-leon-maitre", code:301},
        "/personne/jean-louis-viovy": { url: "/person/jean-louis-viovy", code:301},
        "/personne/jean-marc-guinebretiere": { url: "/person/jean-marc-guinebretiere", code:301},
        "/personne/jean-pierre-quivy": { url: "/person/jean-pierre-quivy", code:301},
        "/personne/jean-pierre-quivy?page=1": { url: "/person/jean-pierre-quivy", code:301},
        "/personne/jean-yves-pierga": { url: "/person/jean-yves-pierga", code:301},
        "/personne/jeanne-contal": { url: "/directory", code:301},
        "/personne/jeanne-rakotopare": { url: "/person/jeanne-rakotopare", code:301},
        "/personne/jeremie-goldstein": { url: "/person/jeremie-goldstein", code:301},
        "/personne/jeremie-tournay": { url: "/directory", code:301},
        "/personne/jeremy-mesple": { url: "/person/jeremy-mesple", code:301},
        "/personne/jerzy-klijanienko": { url: "/person/jerzy-klijanienko", code:301},
        "/personne/jesus-lopez-gay": { url: "/person/jesus-lopez-gay", code:301},
        "/personne/jill-pilet": { url: "/person/jill-pilet", code:301},
        "/personne/jimena-tosello": { url: "/person/jimena-tosello", code:301},
        "/personne/jimmy-mullaert": { url: "/person/jimmy-mullaert", code:301},
        "/personne/jimmy-mullaert-0": { url: "/person/jimmy-mullaert", code:301},
        "/personne/jing-xie": { url: "/person/jing-xie", code:301},
        "/personne/joao-miguel-franco-da-cruz-cardoso-vilhena": { url: "/person/joao-miguel-franco-da-cruz-cardoso-vilhena", code:301},
        "/personne/joffrey-alves-gasnier": { url: "/person/joffrey-alves-gasnier", code:301},
        "/personne/johanna-gerstenecker": { url: "/person/johanna-gerstenecker", code:301},
        "/personne/jonathan-ulmer": { url: "/person/jonathan-ulmer", code:301},
        "/personne/jorge-bretones-santamarina": { url: "/person/jorge-bretones-santamarina", code:301},
        "/personne/joseph-boissieras": { url: "/person/joseph-boissieras", code:301},
        "/personne/joseph-vermeil": { url: "/person/joseph-vermeil", code:301},
        "/personne/joshua-waterfall": { url: "/person/joshua-waterfall", code:301},
        "/personne/joshua-waterfall?page=1": { url: "/person/joshua-waterfall", code:301},
        "/personne/jovan-nikolic": { url: "/person/jovan-nikolic", code:301},
        "/personne/joy-bacrie": { url: "/person/joy-bacrie", code:301},
        "/personne/juan-jose-saez-pons": { url: "/person/juan-jose-saez-pons", code:301},
        "/personne/judith-pineau": { url: "/person/judith-pineau", code:301},
        "/personne/judith-souphron": { url: "/person/judith-souphron", code:301},
        "/personne/jules-ceccaldi": { url: "/person/jules-ceccaldi", code:301},
        "/personne/julia-ronsch": { url: "/person/julia-ronsch", code:301},
        "/personne/julian-iranzo": { url: "/person/julian-iranzo", code:301},
        "/personne/julian-ostermaier": { url: "/person/julian-ostermaier", code:301},
        "/personne/julie-auriac": { url: "/person/julie-auriac", code:301},
        "/personne/julie-cartier": { url: "/person/julie-cartier", code:301},
        "/personne/julie-espenon": { url: "/person/julie-espenon", code:301},
        "/personne/julie-perlbarg-samson": { url: "/person/julie-perlbarg-samson", code:301},
        "/personne/julie-stoufflet": { url: "/person/julie-stoufflet", code:301},
        "/personne/julie-takissian": { url: "/person/julie-takissian", code:301},
        "/personne/julie-talbot": { url: "/person/julie-talbot", code:301},
        "/personne/julien-audouin": { url: "/person/julien-audouin", code:301},
        "/personne/julien-delpierre": { url: "/person/julien-delpierre", code:301},
        "/personne/julien-masliah-planchon": { url: "/person/julien-masliah-planchon", code:301},
        "/personne/julien-maufront": { url: "/person/julien-maufront", code:301},
        "/personne/julien-robert-paganin": { url: "/person/julien-robert-paganin", code:301},
        "/personne/julien-romejon": { url: "/person/julien-romejon", code:301},
        "/personne/juliette-dindart": { url: "/person/juliette-dindart", code:301},
        "/personne/juliette-reveilles": { url: "/person/juliette-reveilles", code:301},
        "/personne/juliette-soulier": { url: "/person/juliette-soulier", code:301},
        "/personne/julio-lopes-sampaio": { url: "/person/julio-lopes-sampaio", code:301},
        "/personne/justine-marsolier": { url: "/person/justine-marsolier", code:301},
        "/personne/kanokporn-nuantang": { url: "/person/kanokporn-nuantang", code:301},
        "/personne/kanza-el-hachadi": { url: "/person/kanza-el-hachadi", code:301},
        "/personne/karine-freon": { url: "/person/karine-freon", code:301},
        "/personne/karine-guevorkian": { url: "/person/karine-guevorkian", code:301},
        "/personne/karine-laud-duval": { url: "/person/karine-laud-duval", code:301},
        "/personne/katia-ancelin": { url: "/person/katia-ancelin", code:301},
        "/personne/kazi-faizul-azim": { url: "/person/kazi-faizul-azim", code:301},
        "/personne/kelly-ferreira-pinto": { url: "/person/kelly-ferreira-pinto", code:301},
        "/personne/kemil-belhadji": { url: "/person/kemil-belhadji", code:301},
        "/personne/kevin-moreau": { url: "/person/kevin-moreau", code:301},
        "/personne/kevin-renault": { url: "/person/kevin-renault", code:301},
        "/personne/khaoula-el-marzkioui": { url: "/person/khaoula-el-marzkioui", code:301},
        "/personne/kibrom-girum": { url: "/person/kibrom-girum", code:301},
        "/personne/kim-cao": { url: "/person/kim-cao", code:301},
        "/personne/konstantina-antoniadou": { url: "/person/konstantina-antoniadou", code:301},
        "/personne/kossi-agbetiafa": { url: "/directory", code:301},
        "/personne/kotryna-vaidziulyte": { url: "/person/kotryna-vaidziulyte", code:301},
        "/personne/koyomi-nakazawa": { url: "/person/koyomi-nakazawa", code:301},
        "/personne/krassen-kirov": { url: "/person/krassen-kirov", code:301},
        "/personne/kyra-borgman": { url: "/person/kyra-borgman", code:301},
        "/personne/laetitia-besse": { url: "/person/laetitia-besse", code:301},
        "/personne/laetitia-gaujal": { url: "/directory", code:301},
        "/personne/lamia-lamrani": { url: "/person/lamia-lamrani", code:301},
        "/personne/laura-bernard": { url: "/person/laura-bernard", code:301},
        "/personne/laura-boulan": { url: "/directory", code:301},
        "/personne/laura-lebrun": { url: "/person/laura-lebrun", code:301},
        "/personne/laura-loriente-gulias": { url: "/directory", code:301},
        "/personne/laura-sourd": { url: "/person/laura-sourd", code:301},
        "/personne/laura-tanguy": { url: "/person/laura-tanguy", code:301},
        "/personne/laure-de-chancel": { url: "/person/laure-de-chancel", code:301},
        "/personne/laure-lagaye": { url: "/person/laure-lagaye", code:301},
        "/personne/laure-stickel": { url: "/person/laure-stickel", code:301},
        "/personne/laurence-bataille": { url: "/person/laurence-bataille", code:301},
        "/personne/laurence-bozec": { url: "/person/laurence-bozec", code:301},
        "/personne/laurence-calzone": { url: "/person/laurence-calzone", code:301},
        "/personne/laurence-calzone?page=0": { url: "/person/laurence-calzone", code:301},
        "/personne/laurence-calzone?page=1": { url: "/person/laurence-calzone", code:301},
        "/personne/laurence-calzone?page=2": { url: "/person/laurence-calzone", code:301},
        "/personne/laurence-calzone?page=3": { url: "/person/laurence-calzone", code:301},
        "/personne/laurence-champion": { url: "/person/laurence-champion", code:301},
        "/personne/laurence-escalup": { url: "/person/laurence-escalup", code:301},
        "/personne/laurence-jagot": { url: "/person/laurence-jagot", code:301},
        "/personne/laurence-mery": { url: "/person/laurence-mery", code:301},
        "/personne/laurene-lacouque": { url: "/person/laurene-lacouque", code:301},
        "/personne/lea-baudre": { url: "/person/lea-baudre", code:301},
        "/personne/lea-guyonnet": { url: "/person/lea-guyonnet", code:301},
        "/personne/lea-huguet": { url: "/person/lea-huguet", code:301},
        "/personne/lea-surcouf": { url: "/person/lea-surcouf", code:301},
        "/personne/lea-wurges": { url: "/person/lea-wurges", code:301},
        "/personne/leanne-de-koning": { url: "/person/leanne-de-koning", code:301},
        "/personne/leeroy-baron": { url: "/person/leeroy-baron", code:301},
        "/personne/leila-heidsieck": { url: "/directory", code:301},
        "/personne/leila-perie": { url: "/person/leila-perie", code:301},
        "/personne/leila-perie?page=0": { url: "/person/leila-perie", code:301},
        "/personne/leila-perie?page=1": { url: "/person/leila-perie", code:301},
        "/personne/leila-perie?page=2": { url: "/person/leila-perie", code:301},
        "/personne/lena-atlan": { url: "/person/lena-atlan", code:301},
        "/personne/leonid-velikovsky": { url: "/person/leonid-velikovsky", code:301},
        "/personne/lexane-louis": { url: "/person/lexane-louis", code:301},
        "/personne/li-wang": { url: "/person/li-wang", code:301},
        "/personne/liane-fernandes": { url: "/person/liane-fernandes", code:301},
        "/personne/lilia-estrada-virrueta": { url: "/person/lilia-estrada-virrueta", code:301},
        "/personne/liliane-mouawad": { url: "/person/liliane-mouawad", code:301},
        "/personne/liliane-mouawad?page=1": { url: "/person/liliane-mouawad", code:301},
        "/personne/lionel-chiron": { url: "/person/lionel-chiron", code:301},
        "/personne/lionel-larue": { url: "/person/lionel-larue", code:301},
        "/personne/livia-lacerda-mariano": { url: "/person/livia-lacerda-mariano", code:301},
        "/personne/livia-lumbroso-le-rouic": { url: "/person/livia-lumbroso-le-rouic", code:301},
        "/personne/loic-chadoutaud": { url: "/person/loic-chadoutaud", code:301},
        "/personne/loic-lecomte": { url: "/person/loic-lecomte", code:301},
        "/personne/lolita-lecompte": { url: "/person/lolita-lecompte", code:301},
        "/personne/lorea-iturri": { url: "/person/lorea-iturri", code:301},
        "/personne/loredana-martignetti": { url: "/person/loredana-martignetti", code:301},
        "/personne/lorena-kolar-znika": { url: "/person/lorena-kolar-znika", code:301},
        "/personne/lorena-martin-jaular": { url: "/person/lorena-martin-jaular", code:301},
        "/personne/lorette-noiret": { url: "/person/lorette-noiret", code:301},
        "/personne/lorraine-bonneville": { url: "/person/lorraine-bonneville", code:301},
        "/personne/louis-gervais": { url: "/person/louis-gervais", code:301},
        "/personne/louisa-hadj-abed": { url: "/person/louisa-hadj-abed", code:301},
        "/personne/louise-dagher": { url: "/person/louise-dagher", code:301},
        "/personne/louise-dupuis": { url: "/person/louise-dupuis", code:301},
        "/personne/louise-massing": { url: "/directory", code:301},
        "/personne/louise-merle": { url: "/person/louise-merle", code:301},
        "/personne/louise-musikas": { url: "/person/louise-musikas", code:301},
        "/personne/louise-nassor": { url: "/person/louise-nassor", code:301},
        "/personne/louise-regnier": { url: "/person/louise-regnier", code:301},
        "/personne/louisiane-perrin": { url: "/person/louisiane-perrin", code:301},
        "/personne/lounes-djerroudi": { url: "/person/lounes-djerroudi", code:301},
        "/personne/luc-cabel": { url: "/person/luc-cabel", code:301},
        "/personne/luca-valzania": { url: "/person/luca-valzania", code:301},
        "/personne/lucas-di-pietro-neves-figueiredo-pinto": { url: "/person/lucas-di-pietro-neves-figueiredo-pinto", code:301},
        "/personne/lucie-filipe": { url: "/person/lucie-filipe", code:301},
        "/personne/lucie-gaspard-boulinc": { url: "/person/lucie-gaspard-boulinc", code:301},
        "/personne/lucie-gentilhomme": { url: "/person/lucie-gentilhomme", code:301},
        "/personne/lucie-lagadec": { url: "/person/lucie-lagadec", code:301},
        "/personne/lucie-marpaux": { url: "/person/lucie-marpaux", code:301},
        "/personne/lucie-maurin": { url: "/person/lucie-maurin", code:301},
        "/personne/lucie-pierau": { url: "/person/lucie-pierau", code:301},
        "/personne/lucilla-fabbri": { url: "/person/lucilla-fabbri", code:301},
        "/personne/ludger-johannes": { url: "/person/ludger-johannes", code:301},
        "/personne/ludger-johannes?page=1": { url: "/person/ludger-johannes", code:301},
        "/personne/ludger-johannes?page=2": { url: "/person/ludger-johannes", code:301},
        "/personne/ludger-johannes?page=3": { url: "/person/ludger-johannes", code:301},
        "/personne/ludivine-chaix": { url: "/person/ludivine-chaix", code:301},
        "/personne/ludmilla-marie-emma-de-plater": { url: "/person/ludmilla-marie-emma-de-plater", code:301},
        "/personne/ludovic-colombeau": { url: "/person/ludovic-colombeau", code:301},
        "/personne/madai-keila-risal": { url: "/person/madai-keila-risal", code:301},
        "/personne/maeva-fischer-hunsinger": { url: "/person/maeva-fischer-hunsinger", code:301},
        "/personne/maeva-veyssiere": { url: "/person/maeva-veyssiere", code:301},
        "/personne/magali-frah": { url: "/person/magali-frah", code:301},
        "/personne/magalie-larcher": { url: "/person/magalie-larcher", code:301},
        "/personne/magda-cannata-serio": { url: "/person/magda-cannata-serio", code:301},
        "/personne/magda-magiera": { url: "/person/magda-magiera", code:301},
        "/personne/magda-magiera?page=1": { url: "/person/magda-magiera", code:301},
        "/personne/magda-magiera?page=2": { url: "/person/magda-magiera", code:301},
        "/personne/magda-magiera?page=3": { url: "/person/magda-magiera", code:301},
        "/personne/malcy-tarin": { url: "/person/malcy-tarin", code:301},
        "/personne/mamy-jean-de-dieu-andrianteranagna": { url: "/person/mamy-jean-de-dieu-andrianteranagna", code:301},
        "/personne/manel-bedoui": { url: "/person/manel-bedoui", code:301},
        "/personne/manon-budzyk": { url: "/person/manon-budzyk", code:301},
        "/personne/manon-chevalier": { url: "/person/manon-chevalier", code:301},
        "/personne/manon-lesage": { url: "/person/manon-lesage", code:301},
        "/personne/manuel-erminy": { url: "/person/manuel-erminy", code:301},
        "/personne/manuel-guthmann": { url: "/person/manuel-guthmann", code:301},
        "/personne/manuel-rodrigues": { url: "/person/manuel-rodrigues", code:301},
        "/personne/manuela-baquero-perez": { url: "/person/manuela-baquero-perez", code:301},
        "/personne/manuela-dezi": { url: "/person/manuela-dezi", code:301},
        "/personne/manuela-spagnuolo": { url: "/person/manuela-spagnuolo", code:301},
        "/personne/manuela-ye": { url: "/person/manuela-ye", code:301},
        "/personne/marc-gabriel": { url: "/person/marc-gabriel", code:301},
        "/personne/marc-henri-stern": { url: "/person/marc-henri-stern", code:301},
        "/personne/marcin-pietrzak": { url: "/person/marcin-pietrzak", code:301},
        "/personne/marco-grillo": { url: "/person/marco-grillo", code:301},
        "/personne/marco-ruscone": { url: "/person/marco-ruscone", code:301},
        "/personne/margot-budzyk": { url: "/person/margot-budzyk", code:301},
        "/personne/margot-gautier": { url: "/person/margot-gautier", code:301},
        "/personne/margot-le-bot": { url: "/person/margot-le-bot", code:301},
        "/personne/margot-revel": { url: "/person/margot-revel", code:301},
        "/personne/mari-yoshida": { url: "/person/mari-yoshida", code:301},
        "/personne/maria-carla-parrini": { url: "/person/maria-carla-parrini", code:301},
        "/personne/maria-carla-parrini?page=0": { url: "/person/maria-carla-parrini", code:301},
        "/personne/maria-carla-parrini?page=2": { url: "/person/maria-carla-parrini", code:301},
        "/personne/maria-da-graca-benedetti-raposo": { url: "/person/maria-da-graca-benedetti-raposo", code:301},
        "/personne/maria-elena-fernandez-sanchez": { url: "/person/maria-elena-fernandez-sanchez", code:301},
        "/personne/maria-elena-sirkia": { url: "/person/maria-elena-sirkia", code:301},
        "/personne/maria-florencia-di-pietro": { url: "/person/maria-florencia-di-pietro", code:301},
        "/personne/maria-graciela-delgado": { url: "/person/maria-graciela-delgado", code:301},
        "/personne/maria-lesnik": { url: "/person/maria-lesnik", code:301},
        "/personne/maria-luisa-martin-faraldo": { url: "/person/maria-luisa-martin-faraldo", code:301},
        "/personne/mariana-lobato-de-oliveira": { url: "/person/mariana-lobato-de-oliveira", code:301},
        "/personne/mariane-shouky": { url: "/person/mariane-shouky", code:301},
        "/personne/marianne-burbage": { url: "/person/marianne-burbage", code:301},
        "/personne/marie-alice-minassian": { url: "/person/marie-alice-minassian", code:301},
        "/personne/marie-ange-massiani": { url: "/person/marie-ange-massiani", code:301},
        "/personne/marie-catherine-queinnec": { url: "/directory", code:301},
        "/personne/marie-dumont": { url: "/person/marie-dumont", code:301},
        "/personne/marie-dutreix-1": { url: "/person/marie-dutreix", code:301},
        "/personne/marie-le-page": { url: "/person/marie-le-page", code:301},
        "/personne/marie-luporsi": { url: "/person/marie-luporsi", code:301},
        "/personne/marie-noelle-guilhaume": { url: "/person/marie-noelle-guilhaume", code:301},
        "/personne/marie-noelle-soler": { url: "/person/marie-noelle-soler", code:301},
        "/personne/marie-paule-sablin": { url: "/person/marie-paule-sablin", code:301},
        "/personne/marie-paule-teulade-fichou": { url: "/person/marie-paule-teulade-fichou", code:301},
        "/personne/marie-paule-teulade-fichou?page=1": { url: "/person/marie-paule-teulade-fichou", code:301},
        "/personne/marie-paule-teulade-fichou?page=2": { url: "/person/marie-paule-teulade-fichou", code:301},
        "/personne/marie-pechard": { url: "/person/marie-pechard", code:301},
        "/personne/marie-schonau": { url: "/person/marie-schonau", code:301},
        "/personne/marie-vallette": { url: "/person/marie-vallette", code:301},
        "/personne/marieke-vromman": { url: "/directory", code:301},
        "/personne/marina-bischerour": { url: "/person/marina-bischerour", code:301},
        "/personne/marina-plays": { url: "/person/marina-plays", code:301},
        "/personne/marine-louarn": { url: "/person/marine-louarn", code:301},
        "/personne/marine-poupon-landreau": { url: "/person/marine-poupon-landreau", code:301},
        "/personne/marine-stefanutti": { url: "/person/marine-stefanutti", code:301},
        "/personne/marion-espenel": { url: "/person/marion-espenel", code:301},
        "/personne/marion-gauthier-villars": { url: "/person/marion-gauthier-villars", code:301},
        "/personne/marion-salou": { url: "/person/marion-salou", code:301},
        "/personne/marius-bichot": { url: "/person/marius-bichot", code:301},
        "/personne/marius-muresan": { url: "/person/marius-muresan", code:301},
        "/personne/marjorie-juchaux": { url: "/person/marjorie-juchaux", code:301},
        "/personne/markus-schliffka": { url: "/directory", code:301},
        "/personne/marthe-laisne": { url: "/person/marthe-laisne", code:301},
        "/personne/martin-dutertre": { url: "/person/martin-dutertre", code:301},
        "/personne/martin-nurmik": { url: "/person/martin-nurmik", code:301},
        "/personne/martina-serafini": { url: "/person/martina-serafini", code:301},
        "/personne/marvin-lerousseau": { url: "/person/marvin-lerousseau", code:301},
        "/personne/mary-saad": { url: "/person/mary-saad", code:301},
        "/personne/maryse-romao": { url: "/person/maryse-romao", code:301},
        "/personne/massiullah-shafaq-zadah": { url: "/person/massiullah-shafaq-zadah", code:301},
        "/personne/mathias-schwartz": { url: "/person/mathias-schwartz", code:301},
        "/personne/mathieu-coppey": { url: "/person/mathieu-coppey", code:301},
        "/personne/mathieu-coppey?page=1": { url: "/person/mathieu-coppey", code:301},
        "/personne/mathieu-coppey?page=2": { url: "/person/mathieu-coppey", code:301},
        "/personne/mathieu-coppey?page=3": { url: "/person/mathieu-coppey", code:301},
        "/personne/mathieu-minsat": { url: "/person/mathieu-minsat", code:301},
        "/personne/mathilde-dimarco": { url: "/person/mathilde-dimarco", code:301},
        "/personne/mathilde-huyghe": { url: "/person/mathilde-huyghe", code:301},
        "/personne/matthieu-carton": { url: "/person/matthieu-carton", code:301},
        "/personne/matthieu-cortes": { url: "/person/matthieu-cortes", code:301},
        "/personne/matthieu-haudiquet": { url: "/person/matthieu-haudiquet", code:301},
        "/personne/matthieu-petitjean": { url: "/person/matthieu-petitjean", code:301},
        "/personne/matthieu-piel": { url: "/person/matthieu-piel", code:301},
        "/personne/matthieu-piel?page=0": { url: "/person/matthieu-piel", code:301},
        "/personne/matthieu-piel?page=1": { url: "/person/matthieu-piel", code:301},
        "/personne/matthieu-piel?page=2": { url: "/person/matthieu-piel", code:301},
        "/personne/matthieu-piel?page=3": { url: "/person/matthieu-piel", code:301},
        "/personne/matthieu-piel?page=4": { url: "/person/matthieu-piel", code:301},
        "/personne/maud-bongaerts": { url: "/person/maud-bongaerts", code:301},
        "/personne/maude-delost": { url: "/person/maude-delost", code:301},
        "/personne/maxime-corbe": { url: "/person/maxime-corbe", code:301},
        "/personne/maxime-dubail": { url: "/person/maxime-dubail", code:301},
        "/personne/maxime-simon": { url: "/person/maxime-simon", code:301},
        "/personne/maxime-wery": { url: "/person/maxime-wery", code:301},
        "/personne/maylis-dugert": { url: "/directory", code:301},
        "/personne/megane-lacaud": { url: "/person/megane-lacaud", code:301},
        "/personne/melanie-armand": { url: "/person/melanie-armand", code:301},
        "/personne/melanie-gracia": { url: "/person/melanie-gracia", code:301},
        "/personne/melanie-miranda": { url: "/person/melanie-miranda", code:301},
        "/personne/melissa-quintanilla": { url: "/person/melissa-quintanilla", code:301},
        "/personne/meryem-baghdadi": { url: "/person/meryem-baghdadi", code:301},
        "/personne/michael-benhamou": { url: "/person/michael-benhamou", code:301},
        "/personne/michael-bringuier": { url: "/person/michael-bringuier", code:301},
        "/personne/michael-richard": { url: "/person/michael-richard", code:301},
        "/personne/michael-schertzer": { url: "/person/michael-schertzer", code:301},
        "/personne/michel-wassef": { url: "/person/michel-wassef", code:301},
        "/personne/michel-wassef?page=0": { url: "/person/michel-wassef", code:301},
        "/personne/michel-wassef?page=1": { url: "/person/michel-wassef", code:301},
        "/personne/michele-castellana": { url: "/person/michele-castellana", code:301},
        "/personne/mickael-garnier": { url: "/person/mickael-garnier", code:301},
        "/personne/miguel-eduardo-sambrano-lopez": { url: "/person/miguel-eduardo-sambrano-lopez", code:301},
        "/personne/miguel-francisco": { url: "/person/miguel-francisco", code:301},
        "/personne/minh-anh-vu": { url: "/person/minh-anh-vu", code:301},
        "/personne/mireia-gomez-duro": { url: "/person/mireia-gomez-duro", code:301},
        "/personne/mirella-miredin": { url: "/person/mirella-miredin", code:301},
        "/personne/mirna-kramar": { url: "/person/mirna-kramar", code:301},
        "/personne/moencopi-bernheim-dennery": { url: "/directory", code:301},
        "/personne/mohammed-toufik-bouhadiba": { url: "/person/mohammed-toufik-bouhadiba", code:301},
        "/personne/morgane-lannoy": { url: "/person/morgane-lannoy", code:301},
        "/personne/mouna-laadhari": { url: "/person/mouna-laadhari", code:301},
        "/personne/mounir-ichalalen": { url: "/person/mounir-ichalalen", code:301},
        "/personne/mounira-amor-gueret": { url: "/person/mounira-amor-gueret", code:301},
        "/personne/moustapha-ka": { url: "/person/moustapha-ka", code:301},
        "/personne/mustapha-zoubir": { url: "/person/mustapha-zoubir", code:301},
        "/personne/mylene-bohec": { url: "/person/mylene-bohec", code:301},
        "/personne/myriam-ruault": { url: "/person/myriam-ruault", code:301},
        "/personne/n-deo-kati-ba-pierozzi": { url: "/person/n-deo-kati-ba-pierozzi", code:301},
        "/personne/nadege-gruel": { url: "/person/nadege-gruel", code:301},
        "/personne/nadine-andrieu": { url: "/person/nadine-andrieu", code:301},
        "/personne/nagesh-yadavrao-kadam": { url: "/person/nagesh-yadavrao-kadam", code:301},
        "/personne/naoji-yubuki": { url: "/person/naoji-yubuki", code:301},
        "/personne/narayani-subramanian": { url: "/directory", code:301},
        "/personne/narinee-hovhannisyan": { url: "/person/narinee-hovhannisyan", code:301},
        "/personne/nasrine-callet": { url: "/person/nasrine-callet", code:301},
        "/personne/natayme-rocha-tartaglia": { url: "/person/natayme-rocha-tartaglia", code:301},
        "/personne/nathalie-amzallag": { url: "/person/nathalie-amzallag", code:301},
        "/personne/nathalie-badois": { url: "/person/nathalie-badois", code:301},
        "/personne/nathalie-berthault": { url: "/person/nathalie-berthault", code:301},
        "/personne/nathalie-brandon": { url: "/person/nathalie-brandon", code:301},
        "/personne/nathalie-cassoux": { url: "/person/nathalie-cassoux", code:301},
        "/personne/nathalie-dostatni": { url: "/person/nathalie-dostatni", code:301},
        "/personne/nathalie-franck": { url: "/person/nathalie-franck", code:301},
        "/personne/nathalie-marie": { url: "/person/nathalie-marie", code:301},
        "/personne/nathalie-nevo": { url: "/person/nathalie-nevo", code:301},
        "/personne/nathalie-planque": { url: "/person/nathalie-planque", code:301},
        "/personne/neda-glisovic": { url: "/person/neda-glisovic", code:301},
        "/personne/nesrine-ben-nasr": { url: "/person/nesrine-ben-nasr", code:301},
        "/personne/neta-felsenthal": { url: "/person/neta-felsenthal", code:301},
        "/personne/ngoc-minh-nguyen": { url: "/directory", code:301},
        "/personne/niccolo-schintu": { url: "/directory", code:301},
        "/personne/nico-zaghet": { url: "/person/nico-zaghet", code:301},
        "/personne/nicolas-ansart": { url: "/person/nicolas-ansart", code:301},
        "/personne/nicolas-captier": { url: "/directory", code:301},
        "/personne/nicolas-girard": { url: "/person/nicolas-girard", code:301},
        "/personne/nicolas-girard-0": { url: "/person/nicolas-girard", code:301},
        "/personne/nicolas-guenezan": { url: "/person/nicolas-guenezan", code:301},
        "/personne/nicolas-manel": { url: "/person/nicolas-manel", code:301},
        "/personne/nicolas-manel?page=1": { url: "/person/nicolas-manel", code:301},
        "/personne/nicolas-pouget": { url: "/person/nicolas-pouget", code:301},
        "/personne/nicolas-servant": { url: "/person/nicolas-servant", code:301},
        "/personne/nicolas-viart": { url: "/person/nicolas-viart", code:301},
        "/personne/nicolas-vogt": { url: "/person/nicolas-vogt", code:301},
        "/personne/nicole-weiss": { url: "/person/nicole-weiss", code:301},
        "/personne/nikita-lagrange": { url: "/person/nikita-lagrange", code:301},
        "/personne/nina-jehanno": { url: "/person/nina-jehanno", code:301},
        "/personne/nolan-burban": { url: "/person/nolan-burban", code:301},
        "/personne/nour-el-houda-djerir": { url: "/person/nour-el-houda-djerir", code:301},
        "/personne/oceane-anezo": { url: "/person/oceane-anezo", code:301},
        "/personne/oceane-saibou": { url: "/directory", code:301},
        "/personne/octave-joliot": { url: "/directory", code:301},
        "/personne/okba-ibn-nafaa-bensaoula": { url: "/person/okba-ibn-nafaa-bensaoula", code:301},
        "/personne/oleg-mikhajlov": { url: "/directory", code:301},
        "/personne/olena-pylypenko": { url: "/person/olena-pylypenko", code:301},
        "/personne/olivier-ayrault": { url: "/person/olivier-ayrault", code:301},
        "/personne/olivier-ayrault?page=1": { url: "/person/olivier-ayrault", code:301},
        "/personne/olivier-brenet": { url: "/person/olivier-brenet", code:301},
        "/personne/olivier-choussy": { url: "/person/olivier-choussy", code:301},
        "/personne/olivier-delattre": { url: "/person/olivier-delattre", code:301},
        "/personne/olivier-ganier": { url: "/person/olivier-ganier", code:301},
        "/personne/olivier-lantz": { url: "/person/olivier-lantz", code:301},
        "/personne/olivier-lantz-0": { url: "/person/olivier-lantz", code:301},
        "/personne/olivier-lantz-0?page=7": { url: "/person/olivier-lantz", code:301},
        "/personne/olivier-leroy": { url: "/person/olivier-leroy", code:301},
        "/personne/olivier-saulnier": { url: "/person/olivier-saulnier", code:301},
        "/personne/olivier-saulnier?page=0": { url: "/person/olivier-saulnier", code:301},
        "/personne/olivier-saulnier?page=1": { url: "/person/olivier-saulnier", code:301},
        "/personne/olivier-zajac": { url: "/person/olivier-zajac", code:301},
        "/personne/ophelie-soulie": { url: "/person/ophelie-soulie", code:301},
        "/personne/orson-jay": { url: "/person/orson-jay", code:301},
        "/personne/ozan-kastas": { url: "/person/ozan-kastas", code:301},
        "/personne/pamela-gonzalez-troncoso": { url: "/person/pamela-gonzalez-troncoso", code:301},
        "/personne/pankhuri-sinha": { url: "/person/pankhuri-sinha", code:301},
        "/personne/pascal-hersen": { url: "/person/pascal-hersen", code:301},
        "/personne/pascal-martin": { url: "/person/pascal-martin", code:301},
        "/personne/pascal-rigolet": { url: "/person/pascal-rigolet", code:301},
        "/personne/pascal-silberzan": { url: "/person/pascal-silberzan", code:301},
        "/personne/pascale-leon": { url: "/person/pascale-leon", code:301},
        "/personne/pascale-mariani": { url: "/person/pascale-mariani", code:301},
        "/personne/patricia-bassereau": { url: "/person/patricia-bassereau", code:301},
        "/personne/patricia-duchambon": { url: "/person/patricia-duchambon", code:301},
        "/personne/patricia-le-baccon": { url: "/person/patricia-le-baccon", code:301},
        "/personne/patricia-legoix": { url: "/person/patricia-legoix", code:301},
        "/personne/patricia-noguiez-hellin": { url: "/person/patricia-noguiez-hellin", code:301},
        "/personne/patricia-uguen": { url: "/person/patricia-uguen", code:301},
        "/personne/patrick-keller": { url: "/person/patrick-keller", code:301},
        "/personne/patrick-poullet": { url: "/person/patrick-poullet", code:301},
        "/personne/paul-etheimer": { url: "/person/paul-etheimer", code:301},
        "/personne/paul-freneaux": { url: "/person/paul-freneaux", code:301},
        "/personne/paul-henri-cottu": { url: "/person/paul-henri-cottu", code:301},
        "/personne/paul-meria": { url: "/person/paul-meria", code:301},
        "/personne/paul-steinmetz": { url: "/person/paul-steinmetz", code:301},
        "/personne/paula-cambronera-ghiglione": { url: "/person/paula-cambronera-ghiglione", code:301},
        "/personne/pauline-du-rusquec": { url: "/person/pauline-du-rusquec", code:301},
        "/personne/pedro-hernandez-cerda": { url: "/person/pedro-hernandez-cerda", code:301},
        "/personne/pedro-monteiro": { url: "/person/pedro-monteiro", code:301},
        "/personne/pedro-moreira-goncalves": { url: "/person/pedro-moreira-goncalves", code:301},
        "/personne/peta-bradbury": { url: "/directory", code:301},
        "/personne/peter-petrow": { url: "/person/peter-petrow", code:301},
        "/personne/philemon-sirven": { url: "/person/philemon-sirven", code:301},
        "/personne/philippe-benaroch": { url: "/person/philippe-benaroch", code:301},
        "/personne/philippe-benaroch?page=1": { url: "/person/philippe-benaroch", code:301},
        "/personne/philippe-benaroch?page=2": { url: "/person/philippe-benaroch", code:301},
        "/personne/philippe-brault": { url: "/person/philippe-brault", code:301},
        "/personne/philippe-chavrier": { url: "/person/philippe-chavrier", code:301},
        "/personne/philippe-chavrier?page=1": { url: "/person/philippe-chavrier", code:301},
        "/personne/philippe-chavrier?page=2": { url: "/person/philippe-chavrier", code:301},
        "/personne/philippe-goater": { url: "/person/philippe-goater", code:301},
        "/personne/philippe-hupe": { url: "/person/philippe-hupe", code:301},
        "/personne/philippe-nodiot": { url: "/person/philippe-nodiot", code:301},
        "/personne/phong-nguyen": { url: "/person/phong-nguyen", code:301},
        "/personne/phong-thanh-tran": { url: "/person/phong-thanh-tran", code:301},
        "/personne/phong-thanh-tran?page=1": { url: "/person/phong-thanh-tran", code:301},
        "/personne/phong-thanh-tran?page=2": { url: "/person/phong-thanh-tran", code:301},
        "/personne/pierre-bost": { url: "/person/pierre-bost", code:301},
        "/personne/pierre-bove": { url: "/person/pierre-bove", code:301},
        "/personne/pierre-gestraud": { url: "/person/pierre-gestraud", code:301},
        "/personne/pierre-gregoire-coulon": { url: "/person/pierre-gregoire-coulon", code:301},
        "/personne/pierre-leopold": { url: "/person/pierre-leopold", code:301},
        "/personne/pierre-marie-girard": { url: "/person/pierre-marie-girard", code:301},
        "/personne/pierre-sens": { url: "/person/pierre-sens", code:301},
        "/personne/pierre-sens?page=1": { url: "/person/pierre-sens", code:301},
        "/personne/pierre-simonin": { url: "/person/pierre-simonin", code:301},
        "/personne/pierre-ucla": { url: "/person/pierre-ucla", code:301},
        "/personne/prisca-laure-moubhio-loubouhi": { url: "/person/prisca-laure-moubhio-loubouhi", code:301},
        "/personne/priscillia-pierre-elies": { url: "/person/priscillia-pierre-elies", code:301},
        "/personne/priyanka-sasmal": { url: "/person/priyanka-sasmal", code:301},
        "/personne/ptissam-bergam": { url: "/person/ptissam-bergam", code:301},
        "/personne/pulkit-aditya": { url: "/person/pulkit-aditya", code:301},
        "/personne/qianwen-zhu": { url: "/person/qianwen-zhu", code:301},
        "/personne/quentin-chaboche": { url: "/person/quentin-chaboche", code:301},
        "/personne/quentin-cosson": { url: "/person/quentin-cosson", code:301},
        "/personne/rabah-taouachi": { url: "/person/rabah-taouachi", code:301},
        "/personne/rady-chaaban": { url: "/person/rady-chaaban", code:301},
        "/personne/rafael-de-almeida-paiva": { url: "/person/rafael-de-almeida-paiva", code:301},
        "/personne/rafael-mena-osuna": { url: "/person/rafael-mena-osuna", code:301},
        "/personne/raju-regmi": { url: "/person/raju-regmi", code:301},
        "/personne/rana-mhaidly": { url: "/directory", code:301},
        "/personne/rania-assad": { url: "/person/rania-assad", code:301},
        "/personne/ranjit-shiva-gulvady": { url: "/person/ranjit-shiva-gulvady", code:301},
        "/personne/raphael-ceccaldi": { url: "/person/raphael-ceccaldi", code:301},
        "/personne/raphael-margueron": { url: "/person/raphael-margueron", code:301},
        "/personne/raphael-margueron?page=1": { url: "/person/raphael-margueron", code:301},
        "/personne/raphael-margueron?page=2": { url: "/person/raphael-margueron", code:301},
        "/personne/raphael-rodriguez": { url: "/person/raphael-rodriguez", code:301},
        "/personne/raphael-rodriguez?page=1": { url: "/person/raphael-rodriguez", code:301},
        "/personne/raphael-rodriguez?page=2": { url: "/person/raphael-rodriguez", code:301},
        "/personne/raphael-rodriguez?page=3": { url: "/person/raphael-rodriguez", code:301},
        "/personne/raphael-rodriguez?page=4": { url: "/person/raphael-rodriguez", code:301},
        "/personne/raphael-rodriguez?page=5": { url: "/person/raphael-rodriguez", code:301},
        "/personne/raphael-rodriguez?page=6": { url: "/person/raphael-rodriguez", code:301},
        "/personne/reda-bouras": { url: "/person/reda-bouras", code:301},
        "/personne/reini-fernandez-de-luco": { url: "/person/reini-fernandez-de-luco", code:301},
        "/personne/remi-dendale": { url: "/person/remi-dendale", code:301},
        "/personne/remi-montagne": { url: "/person/remi-montagne", code:301},
        "/personne/remi-pigache": { url: "/person/remi-pigache", code:301},
        "/personne/renata-basto": { url: "/person/renata-basto", code:301},
        "/personne/renata-basto?page=0": { url: "/person/renata-basto", code:301},
        "/personne/renata-basto?page=1": { url: "/person/renata-basto", code:301},
        "/personne/renata-basto?page=2": { url: "/person/renata-basto", code:301},
        "/personne/riham-salame": { url: "/person/riham-salame", code:301},
        "/personne/robin-journot": { url: "/person/robin-journot", code:301},
        "/personne/rodrigo-salinas": { url: "/directory", code:301},
        "/personne/romain-bucci": { url: "/person/romain-bucci", code:301},
        "/personne/romain-geiss": { url: "/person/romain-geiss", code:301},
        "/personne/roman-rouzier": { url: "/person/roman-rouzier", code:301},
        "/personne/ronan-thibaut": { url: "/person/ronan-thibaut", code:301},
        "/personne/rosine-onclercq-delic": { url: "/person/rosine-onclercq-delic", code:301},
        "/personne/rwaida-elias-shamieh": { url: "/person/rwaida-elias-shamieh", code:301},
        "/personne/ryszard-wimmer": { url: "/person/ryszard-wimmer", code:301},
        "/personne/sabine-rodiere": { url: "/person/sabine-rodiere", code:301},
        "/personne/sacha-delabrousse": { url: "/person/sacha-delabrousse", code:301},
        "/personne/saida-ibntaleb": { url: "/person/saida-ibntaleb", code:301},
        "/personne/sakina-zaidi": { url: "/person/sakina-zaidi", code:301},
        "/personne/salma-fantar": { url: "/directory", code:301},
        "/personne/samia-melaabi": { url: "/person/samia-melaabi", code:301},
        "/personne/sandra-antoine": { url: "/person/sandra-antoine", code:301},
        "/personne/sandra-majo": { url: "/person/sandra-majo", code:301},
        "/personne/sandra-malak": { url: "/person/sandra-malak", code:301},
        "/personne/sandrie-moquet": { url: "/person/sandrie-moquet", code:301},
        "/personne/sandrine-grossetete": { url: "/person/sandrine-grossetete", code:301},
        "/personne/sandrine-moutel": { url: "/person/sandrine-moutel", code:301},
        "/personne/sara-chabi": { url: "/person/sara-chabi", code:301},
        "/personne/sarah-keary": { url: "/person/sarah-keary", code:301},
        "/personne/sarah-lambert": { url: "/person/sarah-lambert", code:301},
        "/personne/sarah-lambert?page=1": { url: "/person/sarah-lambert", code:301},
        "/personne/sarah-lambert?page=2": { url: "/person/sarah-lambert", code:301},
        "/personne/sarah-lambert?page=3": { url: "/person/sarah-lambert", code:301},
        "/personne/sarah-lambert?page=4": { url: "/person/sarah-lambert", code:301},
        "/personne/sarah-manoury": { url: "/person/sarah-manoury-battais", code:301},
        "/personne/sarah-manoury-battais": { url: "/person/sarah-manoury-battais", code:301},
        "/personne/sarah-potiron": { url: "/person/sarah-potiron", code:301},
        "/personne/sarah-sadik": { url: "/person/sarah-sadik", code:301},
        "/personne/saran-pankaew": { url: "/person/saran-pankaew", code:301},
        "/personne/sebastian-amigorena": { url: "/person/sebastian-amigorena", code:301},
        "/personne/sebastian-amigorena?page=0": { url: "/person/sebastian-amigorena", code:301},
        "/personne/sebastian-amigorena?page=1": { url: "/person/sebastian-amigorena", code:301},
        "/personne/sebastian-amigorena?page=2": { url: "/person/sebastian-amigorena", code:301},
        "/personne/sebastian-amigorena?page=3": { url: "/person/sebastian-amigorena", code:301},
        "/personne/sebastian-amigorena?page=7": { url: "/person/sebastian-amigorena", code:301},
        "/personne/sebastian-muller": { url: "/person/sebastian-muller", code:301},
        "/personne/sebastian-muller?page=1": { url: "/person/sebastian-muller", code:301},
        "/personne/sebastian-muller?page=2": { url: "/person/sebastian-muller", code:301},
        "/personne/sebastian-muller?page=3": { url: "/person/sebastian-muller", code:301},
        "/personne/sebastien-lemaire": { url: "/person/sebastien-lemaire", code:301},
        "/personne/segolene-hescot": { url: "/person/segolene-hescot", code:301},
        "/personne/segolene-ladaigue": { url: "/person/segolene-ladaigue", code:301},
        "/personne/selma-djender": { url: "/directory", code:301},
        "/personne/serena-janho-dit-hreich": { url: "/person/serena-janho-dit-hreich", code:301},
        "/personne/sergio-roman-roman": { url: "/person/sergio-roman-roman", code:301},
        "/personne/severine-cessac": { url: "/directory", code:301},
        "/personne/severine-divoux": { url: "/person/severine-divoux", code:301},
        "/personne/shima-ghoroghi": { url: "/person/shima-ghoroghi", code:301},
        "/personne/shivani-dharmadhikari": { url: "/person/shivani-dharmadhikari", code:301},
        "/personne/shrena-chakraborty": { url: "/person/shrena-chakraborty", code:301},
        "/personne/shreyangi-chakraborty": { url: "/person/shreyangi-chakraborty", code:301},
        "/personne/shubhamay-das": { url: "/person/shubhamay-das", code:301},
        "/personne/shufang-renault": { url: "/person/shufang-renault", code:301},
        "/personne/siau-kun-robert-de-rancher": { url: "/person/siau-kun-robert-de-rancher", code:301},
        "/personne/sidhant-kalia": { url: "/person/sidhant-kalia", code:301},
        "/personne/silvia-fre": { url: "/person/silvia-fre", code:301},
        "/personne/silvia-fre?page=1": { url: "/person/silvia-fre", code:301},
        "/personne/silvia-torchio": { url: "/person/silvia-torchio", code:301},
        "/personne/simli-dey": { url: "/directory", code:301},
        "/personne/simon-barral": { url: "/person/simon-barral", code:301},
        "/personne/simon-besson-girard": { url: "/person/simon-besson-girard", code:301},
        "/personne/simon-gemble": { url: "/person/simon-gemble", code:301},
        "/personne/sinda-khanfir": { url: "/person/sinda-khanfir", code:301},
        "/personne/sloane-paulcan": { url: "/person/sloane-paulcan", code:301},
        "/personne/solene-gregoire": { url: "/directory", code:301},
        "/personne/solene-huard": { url: "/person/solene-huard", code:301},
        "/personne/sonia-lameiras": { url: "/person/sonia-lameiras", code:301},
        "/personne/sonia-ruggiero": { url: "/person/sonia-ruggiero", code:301},
        "/personne/sophia-orozco-ruiz": { url: "/person/sophia-orozco-ruiz", code:301},
        "/personne/sophie-beigbeder": { url: "/person/sophie-beigbeder", code:301},
        "/personne/sophie-bombard": { url: "/person/sophie-bombard", code:301},
        "/personne/sophie-bombard?page=3": { url: "/person/sophie-bombard", code:301},
        "/personne/sophie-frank": { url: "/person/sophie-frank", code:301},
        "/personne/sophie-heinrich": { url: "/person/sophie-heinrich", code:301},
        "/personne/sophie-leboucher": { url: "/directory", code:301},
        "/personne/sophie-loeillet": { url: "/person/sophie-loeillet", code:301},
        "/personne/sophie-piperno-neumann": { url: "/person/sophie-piperno-neumann", code:301},
        "/personne/sophie-richon": { url: "/person/sophie-richon", code:301},
        "/personne/soraya-rabahi": { url: "/person/soraya-rabahi", code:301},
        "/personne/spyridon-roumeliotis": { url: "/person/spyridon-roumeliotis", code:301},
        "/personne/stacy-cyrille": { url: "/person/stacy-cyrille", code:301},
        "/personne/stephan-vagner": { url: "/person/stephan-vagner", code:301},
        "/personne/stephan-vagner?page=1": { url: "/person/stephan-vagner", code:301},
        "/personne/stephane-liva": { url: "/person/stephane-liva", code:301},
        "/personne/stephanie-descroix": { url: "/person/stephanie-descroix", code:301},
        "/personne/stephanie-descroix?page=0": { url: "/person/stephanie-descroix", code:301},
        "/personne/stephanie-descroix?page=1": { url: "/person/stephanie-descroix", code:301},
        "/personne/stephanie-descroix?page=2": { url: "/person/stephanie-descroix", code:301},
        "/personne/stephanie-dogniaux": { url: "/person/stephanie-dogniaux", code:301},
        "/personne/stephanie-fitte-duval": { url: "/person/stephanie-fitte-duval", code:301},
        "/personne/stephanie-miserey": { url: "/person/stephanie-miserey", code:301},
        "/personne/stephanie-solier": { url: "/person/stephanie-solier", code:301},
        "/personne/stephanie-solier?page=1": { url: "/person/stephanie-solier", code:301},
        "/personne/subhadarsini-sahoo": { url: "/person/subhadarsini-sahoo", code:301},
        "/personne/subham-seal": { url: "/person/subham-seal", code:301},
        "/personne/suzette-lust": { url: "/person/suzette-lust", code:301},
        "/personne/sylvain-baulande": { url: "/person/sylvain-baulande", code:301},
        "/personne/sylvain-baulande?page=1": { url: "/person/sylvain-baulande", code:301},
        "/personne/sylvain-baulande?page=2": { url: "/person/sylvain-baulande", code:301},
        "/personne/sylvain-baulande?page=3": { url: "/person/sylvain-baulande", code:301},
        "/personne/sylvain-baulande?page=4": { url: "/person/sylvain-baulande", code:301},
        "/personne/sylvain-martineau": { url: "/person/sylvain-martineau", code:301},
        "/personne/sylvia-trival": { url: "/person/sylvia-trival", code:301},
        "/personne/sylvie-bonvalot": { url: "/person/sylvie-bonvalot", code:301},
        "/personne/sylvie-coscoy": { url: "/person/sylvie-coscoy", code:301},
        "/personne/sylvie-coscoy?page=1": { url: "/person/sylvie-coscoy", code:301},
        "/personne/sylvie-dolbeault": { url: "/person/sylvie-dolbeault", code:301},
        "/personne/sylvie-helfre": { url: "/person/sylvie-helfre", code:301},
        "/personne/sylvie-wascheul": { url: "/directory", code:301},
        "/personne/tapas-singha": { url: "/person/tapas-singha", code:301},
        "/personne/tarek-gharsalli": { url: "/person/tarek-gharsalli", code:301},
        "/personne/tatiana-caneque-cobo": { url: "/person/tatiana-caneque-cobo", code:301},
        "/personne/tatiana-malherbe": { url: "/person/tatiana-malherbe", code:301},
        "/personne/tatiana-popova": { url: "/person/tatiana-popova", code:301},
        "/personne/theo-lassale": { url: "/person/theo-lassale", code:301},
        "/personne/theresa-jakuszeit": { url: "/person/theresa-jakuszeit", code:301},
        "/personne/therese-wilhelm": { url: "/person/therese-wilhelm", code:301},
        "/personne/thibaud-pivron": { url: "/person/thibaud-pivron", code:301},
        "/personne/thibault-delobel": { url: "/person/thibault-delobel", code:301},
        "/personne/thibault-verrier": { url: "/person/thibault-verrier", code:301},
        "/personne/thierry-dubois": { url: "/person/thierry-dubois", code:301},
        "/personne/thierry-guihard": { url: "/person/thierry-guihard", code:301},
        "/personne/thomas-blanc": { url: "/person/thomas-blanc", code:301},
        "/personne/thomas-bonte": { url: "/person/thomas-bonte", code:301},
        "/personne/thomas-risler": { url: "/person/thomas-risler", code:301},
        "/personne/thomas-walter": { url: "/person/thomas-walter", code:301},
        "/personne/tina-karagyozova": { url: "/person/tina-karagyozova", code:301},
        "/personne/ting-di-wu": { url: "/person/ting-di-wu", code:301},
        "/personne/ting-di-wu?page=1": { url: "/person/ting-di-wu", code:301},
        "/personne/tiphaine-hery": { url: "/person/tiphaine-hery", code:301},
        "/personne/tiziana-tocci": { url: "/person/tiziana-tocci", code:301},
        "/personne/tristan-espie-caullet": { url: "/person/tristan-espie-caullet", code:301},
        "/personne/trung-kien-bui": { url: "/person/trung-kien-bui", code:301},
        "/personne/ugo-szachnowski": { url: "/person/ugo-szachnowski", code:301},
        "/personne/vadim-karnaukhov": { url: "/person/vadim-karnaukhov", code:301},
        "/personne/valentin-calugaru": { url: "/person/valentin-calugaru", code:301},
        "/personne/valentina-lo-re": { url: "/person/valentina-lo-re", code:301},
        "/personne/valeria-manriquez": { url: "/person/valeria-manriquez", code:301},
        "/personne/valerie-borde": { url: "/person/valerie-borde", code:301},
        "/personne/valerie-borde?page=0": { url: "/person/valerie-borde", code:301},
        "/personne/valerie-borde?page=1": { url: "/person/valerie-borde", code:301},
        "/personne/valerie-borde?page=2": { url: "/person/valerie-borde", code:301},
        "/personne/valerie-chambon": { url: "/person/valerie-chambon", code:301},
        "/personne/valerie-laurence": { url: "/person/valerie-laurence", code:301},
        "/personne/valerie-petit": { url: "/person/valerie-petit", code:301},
        "/personne/valerie-royer-garabige": { url: "/directory", code:301},
        "/personne/vanessa-catarina-ribeiro-nunes": { url: "/person/vanessa-catarina-ribeiro-nunes", code:301},
        "/personne/vanessa-masson": { url: "/person/vanessa-masson", code:301},
        "/personne/vasco-manuel-teixeira-afonso-rodrigues": { url: "/person/vasco-manuel-teixeira-afonso-rodrigues", code:301},
        "/personne/veronica-hernandez-trejo": { url: "/person/veronica-hernandez-trejo", code:301},
        "/personne/veronique-becette": { url: "/directory", code:301},
        "/personne/veronique-delmas": { url: "/person/veronique-delmas", code:301},
        "/personne/veronique-fabreges": { url: "/person/veronique-fabreges", code:301},
        "/personne/veronique-henriot": { url: "/person/veronique-henriot", code:301},
        "/personne/veronique-marsaud": { url: "/person/veronique-marsaud", code:301},
        "/personne/veronique-marthiens": { url: "/person/veronique-marthiens", code:301},
        "/personne/veronique-mosseri-longinovic": { url: "/person/veronique-mosseri-longinovic", code:301},
        "/personne/veronique-stoven": { url: "/person/veronique-stoven", code:301},
        "/personne/viacheslav-shcherbakov": { url: "/person/viacheslav-shcherbakov", code:301},
        "/personne/vibha-singh": { url: "/person/vibha-singh", code:301},
        "/personne/victoire-gueroult": { url: "/directory", code:301},
        "/personne/victoire-ruffino": { url: "/person/victoire-ruffino", code:301},
        "/personne/victor-laigle": { url: "/person/victor-laigle", code:301},
        "/personne/victoria-remy": { url: "/person/victoria-remy", code:301},
        "/personne/vincent-favaudon": { url: "/person/vincent-favaudon", code:301},
        "/personne/vincent-favaudon?page=1": { url: "/person/vincent-favaudon", code:301},
        "/personne/vincent-favaudon?page=2": { url: "/person/vincent-favaudon", code:301},
        "/personne/vincent-fraisier": { url: "/person/vincent-fraisier", code:301},
        "/personne/vincent-kappes": { url: "/person/vincent-kappes", code:301},
        "/personne/vincent-lejour": { url: "/person/vincent-lejour", code:301},
        "/personne/vincent-noel": { url: "/person/vincent-noel", code:301},
        "/personne/vincent-pennaneach": { url: "/person/vincent-pennaneach", code:301},
        "/personne/vincent-servois": { url: "/person/vincent-servois", code:301},
        "/personne/virginie-bel": { url: "/person/virginie-bel", code:301},
        "/personne/virginie-fourchotte": { url: "/person/virginie-fourchotte", code:301},
        "/personne/virginie-huchet": { url: "/person/virginie-huchet", code:301},
        "/personne/virginie-maire": { url: "/person/virginie-maire", code:301},
        "/personne/virginie-raynal": { url: "/person/virginie-raynal", code:301},
        "/personne/vittore-scolari": { url: "/person/vittore-scolari", code:301},
        "/personne/wahib-ghanem": { url: "/person/wahib-ghanem", code:301},
        "/personne/weitao-wang": { url: "/person/weitao-wang", code:301},
        "/personne/wenjie-sun": { url: "/person/wenjie-sun", code:301},
        "/personne/wilfrid-richer": { url: "/person/wilfrid-richer", code:301},
        "/personne/win-yan-liu": { url: "/person/win-yan-liu", code:301},
        "/personne/wolfgang-keil": { url: "/person/wolfgang-keil", code:301},
        "/personne/xavier-lahaye": { url: "/person/xavier-lahaye", code:301},
        "/personne/xavier-lahaye?page=1": { url: "/person/xavier-lahaye", code:301},
        "/personne/xavier-paoletti": { url: "/person/xavier-paoletti", code:301},
        "/personne/xingyi-cheng": { url: "/person/xingyi-cheng", code:301},
        "/personne/yang-yu": { url: "/person/yang-yu", code:301},
        "/personne/yanis-mouhali": { url: "/person/yanis-mouhali", code:301},
        "/personne/yann-kieffer": { url: "/person/yann-kieffer", code:301},
        "/personne/yann-neuzillet": { url: "/directory", code:301},
        "/personne/yannick-bono": { url: "/person/yannick-bono", code:301},
        "/personne/yasmine-iddir": { url: "/person/yasmine-iddir", code:301},
        "/personne/yazan-salloum": { url: "/person/yazan-salloum", code:301},
        "/personne/yoann-missolo-koussou": { url: "/person/yoann-missolo-koussou", code:301},
        "/personne/yoann-nicolas": { url: "/person/yoann-nicolas", code:301},
        "/personne/yohanns-bellaiche": { url: "/person/yohanns-bellaiche", code:301},
        "/personne/yolanda-prezado": { url: "/person/yolanda-prezado", code:301},
        "/personne/yolanda-prezado?page=0": { url: "/person/yolanda-prezado", code:301},
        "/personne/yolanda-prezado?page=1": { url: "/person/yolanda-prezado", code:301},
        "/personne/youlia-kirova": { url: "/person/youlia-kirova", code:301},
        "/personne/yu-luan": { url: "/directory", code:301},
        "/personne/yulia-gryaznova": { url: "/person/yulia-gryaznova", code:301},
        "/personne/yuna-landais": { url: "/person/yuna-landais", code:301},
        "/personne/yuying-wang": { url: "/person/yuying-wang", code:301},
        "/personne/yves-allory": { url: "/person/yves-allory", code:301},
        "/personne/zhi-qian-wu": { url: "/directory", code:301},
        "/personne/zhijun-xin": { url: "/person/zhijun-xin", code:301},
        "/personne/zhimin-wang-bauduin": { url: "/person/zhimin-wang-bauduin", code:301},
        "/personne/ziad-fadel": { url: "/person/ziad-fadel", code:301},
        "/personne/zoe-fusilier": { url: "/person/zoe-fusilier", code:301},
        "/physical-chemistry-curie-umr168": { url: "/unit/umr168", code:301},
        "/plateforme/single-cell-initiative": { url: "/platform/single-cell-initiative", code:301},
        "/popin/access": { url: "/research-teams", code:301},
        "/popin/access-equipment-booking-and-services-pricing-light-microscopy": { url: "/research-teams", code:301},
        "/popin/access-prices-light-microscopy-pict": { url: "/research-teams", code:301},
        "/popin/advanced-analysis-and-data-visualization": { url: "/research-teams", code:301},
        "/popin/advanced-optical-microscopy-single-molecule-imaging": { url: "/research-teams", code:301},
        "/popin/alumni": { url: "/research-teams", code:301},
        "/popin/alumni-0": { url: "/research-teams", code:301},
        "/popin/alumni-1": { url: "/research-teams", code:301},
        "/popin/alumni-2": { url: "/research-teams", code:301},
        "/popin/amandine-verguet": { url: "/research-teams", code:301},
        "/popin/analysis-macrophage-regulation-and-function-solid-tumors": { url: "/research-teams", code:301},
        "/popin/analysis-mechanisms-involved-inhibitory-effects-pd-1": { url: "/research-teams", code:301},
        "/popin/analysis-transport-flows": { url: "/research-teams", code:301},
        "/popin/antoine-bergamaschi-2017": { url: "/research-teams", code:301},
        "/popin/axis-11-role-intronic-polyadenylation-response-dna-damage-and-anticancer-drugs-axis-led-m": { url: "/research-teams", code:301},
        "/popin/ayrault-lab": { url: "/research-teams", code:301},
        "/popin/benaroch-team-going-projects": { url: "/research-teams", code:301},
        "/popin/biochemistry-and-biology-anticancer-drugs": { url: "/research-teams", code:301},
        "/popin/biogenic-photo-responsive-vectors-nanomedicine": { url: "/research-teams", code:301},
        "/popin/biophysics-membrane-proteins-single-molecule-resolution": { url: "/research-teams", code:301},
        "/popin/blaise-dumat-2012": { url: "/research-teams", code:301},
        "/popin/brevet-wo2022189618": { url: "/research-teams", code:301},
        "/popin/cell-sorting": { url: "/research-teams", code:301},
        "/popin/centrosome-number-alterations-cancer": { url: "/research-teams", code:301},
        "/popin/characterisation-t-cell-response-against-tumor-neo-antigens-three-tumor-types": { url: "/research-teams", code:301},
        "/popin/chloe-breton-patient-2022": { url: "/research-teams", code:301},
        "/popin/collaborations": { url: "/research-teams", code:301},
        "/popin/collaborations-team-anton-granzhan": { url: "/research-teams", code:301},
        "/popin/contacts-pict": { url: "/research-teams", code:301},
        "/popin/contributions": { url: "/research-teams", code:301},
        "/popin/conventional-cytometry": { url: "/research-teams", code:301},
        "/popin/coralie-caron-2019": { url: "/research-teams", code:301},
        "/popin/culture-information-and-expertise-transfer-between-research-and-care": { url: "/research-teams", code:301},
        "/popin/cytometry-platform-cytpic": { url: "/research-teams", code:301},
        "/popin/deepanjan-ghosh-2020": { url: "/research-teams", code:301},
        "/popin/developing-vitro-models-tumor-tumor-microenvironment-evaluate-and-improve-therapeutic": { url: "/research-teams", code:301},
        "/popin/development-and-functional-characterization-molecules-radio-sensitize-tumors-andor-protect": { url: "/research-teams", code:301},
        "/popin/dna-repair": { url: "/research-teams", code:301},
        "/popin/dynamics-genetic-information-fundamental-bases-and-cancer-dig-cancer-umr3244": { url: "/research-teams", code:301},
        "/popin/electron-cryo-microscopy-facility": { url: "/research-teams", code:301},
        "/popin/electron-microscopy-and-cryo-em": { url: "/research-teams", code:301},
        "/popin/elodie-morel-2015": { url: "/research-teams", code:301},
        "/popin/elucidating-role-mark2-t-cell-activation": { url: "/research-teams", code:301},
        "/popin/equipment-and-reservation": { url: "/research-teams", code:301},
        "/popin/european-neucrest-itn-network": { url: "/research-teams", code:301},
        "/popin/extracellular-matrix-and-immune-cell-migration": { url: "/research-teams", code:301},
        "/popin/fabien-hammerer-2012": { url: "/research-teams", code:301},
        "/popin/fluorescent-probes-detection-dna": { url: "/research-teams", code:301},
        "/popin/functional-characterization-tumor-tregs-specific-molecules-translational-perspective": { url: "/research-teams", code:301},
        "/popin/genetically-engineered-variants-cytokine-il-2-selective-immunomodulation": { url: "/research-teams", code:301},
        "/popin/genome-integrity-rna-and-cancer-umr3348": { url: "/research-teams", code:301},
        "/popin/governance": { url: "/research-teams", code:301},
        "/popin/group-pictures-taddei-2022": { url: "/research-teams", code:301},
        "/popin/high-content-cellular-screening": { url: "/research-teams", code:301},
        "/popin/horizon-2020-innovative-training-network-neucrest": { url: "/research-teams", code:301},
        "/popin/how-cells-interact-their-environment": { url: "/research-teams", code:301},
        "/popin/human-and-social-sciences-and-outcome-research": { url: "/research-teams", code:301},
        "/popin/image-processing": { url: "/research-teams", code:301},
        "/popin/images-processing-and-analysing": { url: "/research-teams", code:301},
        "/popin/imaging-cytometry": { url: "/research-teams", code:301},
        "/popin/indrop-rna-seq": { url: "/research-teams", code:301},
        "/popin/integrated-service": { url: "/research-teams", code:301},
        "/popin/ionic-microscopy-nanosims-facility": { url: "/research-teams", code:301},
        "/popin/isolating-single-cells": { url: "/research-teams", code:301},
        "/popin/jaime-franco-pinto-2021": { url: "/research-teams", code:301},
        "/popin/joel-lefebvre-2017": { url: "/research-teams", code:301},
        "/popin/join-us": { url: "/research-teams", code:301},
        "/popin/julie-le-bescont-2020": { url: "/research-teams", code:301},
        "/popin/lab-retreat-savoie": { url: "/research-teams", code:301},
        "/popin/light-microscopy": { url: "/research-teams", code:301},
        "/popin/light-microscopy-facility": { url: "/research-teams", code:301},
        "/popin/linda-silina-2020": { url: "/research-teams", code:301},
        "/popin/links-team-piel": { url: "/research-teams", code:301},
        "/popin/macrophages-and-viruses-complex-and-instructive-relationship": { url: "/research-teams", code:301},
        "/popin/magnetic-tissue-bioengineering-0": { url: "/research-teams", code:301},
        "/popin/magnetic-tissue-stretcher": { url: "/research-teams", code:301},
        "/popin/marie-auvray-2021": { url: "/research-teams", code:301},
        "/popin/marlene-rasschaert": { url: "/research-teams", code:301},
        "/popin/mechanical-response-myeloid-cells": { url: "/research-teams", code:301},
        "/popin/mechanisms-heart-regeneration": { url: "/research-teams", code:301},
        "/popin/microfluidic-based-bioanalysis": { url: "/research-teams", code:301},
        "/popin/microfluidics": { url: "/research-teams", code:301},
        "/popin/mitotic-spindle-morphogenesis-and-function": { url: "/research-teams", code:301},
        "/popin/more-information": { url: "/research-teams", code:301},
        "/popin/myeloid-cells-gut-sentinels": { url: "/research-teams", code:301},
        "/popin/myochip-project": { url: "/research-teams", code:301},
        "/popin/nano-therapies": { url: "/research-teams", code:301},
        "/popin/nanobiomag": { url: "/research-teams", code:301},
        "/popin/nanobodies": { url: "/research-teams", code:301},
        "/popin/nanoparticles-fate-and-transport-extracellular-vesicles": { url: "/research-teams", code:301},
        "/popin/newsletter": { url: "/research-teams", code:301},
        "/popin/ngs-platform-terms-and-conditions": { url: "/research-teams", code:301},
        "/popin/nuclear-dynamics-umr3664": { url: "/research-teams", code:301},
        "/popin/nuclear-organization-and-dynamics": { url: "/research-teams", code:301},
        "/popin/nuclear-organization-quiescence": { url: "/research-teams", code:301},
        "/popin/oksana-reznichenko-2021": { url: "/research-teams", code:301},
        "/popin/online-now-everything-you-want-know-about-h3-variants": { url: "/research-teams", code:301},
        "/popin/open-positions": { url: "/research-teams", code:301},
        "/popin/optimisation-anti-tumoral-immune-responses-rhabdoid-tumors-translational-approach": { url: "/research-teams", code:301},
        "/popin/organization-and-objectives": { url: "/research-teams", code:301},
        "/popin/our-areas-expertise": { url: "/research-teams", code:301},
        "/popin/our-equipment-and-booking": { url: "/research-teams", code:301},
        "/popin/our-patents": { url: "/research-teams", code:301},
        "/popin/our-platform": { url: "/research-teams", code:301},
        "/popin/outreach-henri-and-laure-gave-public-lecture-entitled-perception-sound-ear": { url: "/research-teams", code:301},
        "/popin/patent-wo2021224186": { url: "/research-teams", code:301},
        "/popin/patterning-cancer-weird-journey-around-metabolism-regulator": { url: "/research-teams", code:301},
        "/popin/patterning-multipotency-cell-migration-cancer": { url: "/research-teams", code:301},
        "/popin/photosensitizer-agents": { url: "/research-teams", code:301},
        "/popin/physiological-role-rab6-using-mouse-models": { url: "/research-teams", code:301},
        "/popin/polyploidy-paradox": { url: "/research-teams", code:301},
        "/popin/postdoctoral-position-developmental-genetics-and-physiology": { url: "/research-teams", code:301},
        "/popin/presentation-group-samar-alsafadi": { url: "/platforms", code:301},
        "/popin/presentation-lsmp": { url: "/platforms", code:301},
        "/popin/presentation-our-platforme": { url: "/platforms", code:301},
        "/popin/presentation-our-platforme-0": { url: "/platforms", code:301},
        "/popin/presentation-our-platforme-1": { url: "/platforms", code:301},
        "/popin/presentation-our-platforme-2": { url: "/platforms", code:301},
        "/popin/presentation-our-platforme-3": { url: "/platforms", code:301},
        "/popin/presentation-platform": { url: "/platforms", code:301},
        "/popin/presentation-platforme": { url: "/platforms", code:301},
        "/popin/presentation-platforme-0": { url: "/platforms", code:301},
        "/popin/presentation-platforme-1": { url: "/platforms", code:301},
        "/popin/presentation-platforme-2": { url: "/platforms", code:301},
        "/popin/presentation-platforme-3": { url: "/platforms", code:301},
        "/popin/presentation-team-celine-vallot": { url: "/research-teams", code:301},
        "/popin/presentation-team-chunlong-chen": { url: "/research-teams", code:301},
        "/popin/presentation-team-emmanuel-farge": { url: "/research-teams", code:301},
        "/popin/presentation-team-florence-mahuteau-betzer": { url: "/research-teams", code:301},
        "/popin/presentation-team-ines-anna-drinnenberg": { url: "/research-teams", code:301},
        "/popin/presentation-team-quantitative-developmental-biology": { url: "/research-teams", code:301},
        "/popin/presentation-team-silberzan-buguin": { url: "/research-teams", code:301},
        "/popin/presentation-thierry-dubois-group": { url: "/research-teams", code:301},
        "/popin/pricing-electron-cryo-microscopy-facility": { url: "/research-teams", code:301},
        "/popin/pricing-images-processing-and-analysing": { url: "/research-teams", code:301},
        "/popin/pricing-ionic-microscopy-nanosims-facility": { url: "/research-teams", code:301},
        "/popin/probes-targeting-g-quadruplex-nucleic-acids": { url: "/research-teams", code:301},
        "/popin/protocols-methods-and-ressources-books-team-piel": { url: "/research-teams", code:301},
        "/popin/publications-gallery-team-piel": { url: "/research-teams", code:301},
        "/popin/regulation-and-function-transcription-factor-atoh1": { url: "/research-teams", code:301},
        "/popin/revealing-essential-players-driving-embryonal-brain-tumors-bcor-alteration": { url: "/research-teams", code:301},
        "/popin/role-tumor-heterogeneity-treatment-resistance": { url: "/research-teams", code:301},
        "/popin/rt-superes-imaging-join-us": { url: "/research-teams", code:301},
        "/popin/samar-ali-2015": { url: "/research-teams", code:301},
        "/popin/scchip-seq": { url: "/platform/curiecoretech-custom-single-cell-omics", code:301},
        "/popin/scientific-policy-and-quality-ngs-platform": { url: "/research-teams", code:301},
        "/popin/scientific-retreat-2022": { url: "/research-teams", code:301},
        "/popin/shaping-and-controlling-organ-chips-models": { url: "/research-teams", code:301},
        "/popin/signaling-radiobiology-and-cancer-umr3347-u1021": { url: "/research-teams", code:301},
        "/popin/single-cell-analysis-viral-transcriptomes": { url: "/research-teams", code:301},
        "/popin/single-cell-data-analysis": { url: "/research-teams", code:301},
        "/popin/single-cell-data-analysis-0": { url: "/research-teams", code:301},
        "/popin/single-cell-epigenomics": { url: "/research-teams", code:301},
        "/popin/single-cell-genomics": { url: "/research-teams", code:301},
        "/popin/single-cell-organism": { url: "/research-teams", code:301},
        "/popin/single-cell-phenotyping": { url: "/research-teams", code:301},
        "/popin/single-cell-plates": { url: "/research-teams", code:301},
        "/popin/single-cell-rna-sequencing": { url: "/research-teams", code:301},
        "/popin/smart-seq3": { url: "/platform/curiecoretech-custom-single-cell-omics", code:301},
        "/popin/software-diva": { url: "/team/coppey", code:301},
        "/popin/software-genuage": { url: "/team/coppey", code:301},
        "/popin/spatial-analysis-tissue-raw-data-biology": { url: "/research-teams", code:301},
        "/popin/spatial-heterogeneity-high-grade-gliomas": { url: "/research-teams", code:301},
        "/popin/spatial-omics": { url: "/research-teams", code:301},
        "/popin/spatial-regulation-gene-silencing": { url: "/research-teams", code:301},
        "/popin/spectral-cytometry": { url: "/research-teams", code:301},
        "/popin/starting-material": { url: "/research-teams", code:301},
        "/popin/stephanie-lemaitre-2017": { url: "/research-teams", code:301},
        "/popin/study-interaction-between-dna-pk-complex-and-gli2-transcription-factor": { url: "/research-teams", code:301},
        "/popin/study-intracellular-trafficking-signaling-molecules": { url: "/research-teams", code:301},
        "/popin/su-chen-2016": { url: "/research-teams", code:301},
        "/popin/team-angela-taddei": { url: "/research-teams", code:301},
        "/popin/team-anne-helene-monsoro-burq": { url: "/research-teams", code:301},
        "/popin/team-anne-houdusse": { url: "/research-teams", code:301},
        "/popin/team-arturo-londono": { url: "/research-teams", code:301},
        "/popin/team-enzo-poirier": { url: "/research-teams", code:301},
        "/popin/team-joshua-waterfall": { url: "/research-teams", code:301},
        "/popin/team-olivier-delattre": { url: "/research-teams", code:301},
        "/popin/team-pedro-hernandez": { url: "/research-teams", code:301},
        "/popin/team-perie": { url: "/research-teams", code:301},
        "/popin/team-photo-july-2021": { url: "/research-teams", code:301},
        "/popin/team-piel": { url: "/research-teams", code:301},
        "/popin/team-stephan-vagner": { url: "/research-teams", code:301},
        "/popin/team-thomas-walter": { url: "/research-teams", code:301},
        "/popin/team-work-0": { url: "/research-teams", code:301},
        "/popin/team-yohanns-bellaiche": { url: "/research-teams", code:301},
        "/popin/thibaut-masson-2021": { url: "/research-teams", code:301},
        "/popin/thibaut-masson-2021-0": { url: "/research-teams", code:301},
        "/popin/tom-baladi-2016": { url: "/research-teams", code:301},
        "/popin/translational-applications": { url: "/research-teams", code:301},
        "/popin/translational-research-pediatric-oncology-rtop": { url: "/research-teams", code:301},
        "/popin/understanding-neural-crest-path-towardsmultipotency-and-patterning": { url: "/research-teams", code:301},
        "/popin/unit-presentation": { url: "/research-teams", code:301},
        "/popin/unraveling-mechanisms-tumor-treg-accumulation-lung-cancer-patients": { url: "/research-teams", code:301},
        "/popin/vascos-work-front-page-jcs": { url: "/research-teams", code:301},
        "/popin/xiao-xie-2015": { url: "/research-teams", code:301},
        "/pre-clinical-investigation-laboratory-lip": { url: "/clinical-trials", code:301},
        "/predisposing-genetic-variants-public-shared-tumor-specific-neo-antigens-uveal-melanoma": { url: "/", code:301},
        "/presentation-group-shufang-renault": { url: "/", code:301},
        "/presentation-ionic-microscopy-nanosims-facility": { url: "/", code:301},
        "/presentation-locco-team": { url: "/research-teams", code:301},
        "/presentation-team-allison-bardin": { url: "/research-teams", code:301},
        "/presentation-team-anton-granzhan": { url: "/research-teams", code:301},
        "/presentation-team-aura-carreira": { url: "/research-teams", code:301},
        "/presentation-team-ballesta": { url: "/research-teams", code:301},
        "/presentation-team-carsten-janke": { url: "/research-teams", code:301},
        "/presentation-team-clotilde-thery": { url: "/research-teams", code:301},
        "/presentation-team-helene-salmon": { url: "/research-teams", code:301},
        "/presentation-team-mounira-amor-gueret": { url: "/research-teams", code:301},
        "/presentation-team-nadine-andrieu": { url: "/research-teams", code:301},
        "/presentation-team-phong-tran": { url: "/research-teams", code:301},
        "/presentation-team-seano": { url: "/research-teams", code:301},
        "/prize-and-distinctions": { url: "/", code:301},
        "/profil/caregivers": { url: "/", code:301},
        "/profil/donors": { url: "/", code:301},
        "/profil/patients-and-relatives": { url: "/patients-and-relatives-areas", code:301},
        "/profil/patients-and-relatives?profile=4": { url: "/patients-and-relatives-areas", code:301},
        "/profil/researchers": { url: "/", code:301},
        "/profil/researchers?profile=3": { url: "/", code:301},
        "/profil/researchers?profile=4": { url: "/", code:301},
        "/profil/students": { url: "/", code:301},
        "/programs-and-partnerships": { url: "/institut-curies-major-programs", code:301},
        "/programs-and-partnerships?profile=3": { url: "/institut-curies-major-programs", code:301},
        "/projects-data": { url: "/", code:301},
        "/proteo-guv-preparation": { url: "/", code:301},
        "/publications/1531p-multi-omic-characterization-dedifferentiated-liposarcoma-towards-new-model": { url: "/publications", code:301},
        "/publications/18f-fdg-pet-dissemination-features-diffuse-large-b-cell-lymphoma-are-predictive": { url: "/publications", code:301},
        "/publications/18f-fdg-pet-maximum-intensity-projections-and-artificial-intelligence-win-win": { url: "/publications", code:301},
        "/publications/18f-fdg-petct-predict-response-neoadjuvant-chemotherapy-and-prognosis-inflammatory": { url: "/publications", code:301},
        "/publications/18f-fluorocholine-petct-patients-occult-biochemical-recurrence-prostate-cancer": { url: "/publications", code:301},
        "/publications/18ffdg-petct-deep-learning-method-fully-automated-detection-pathological-mediastinal": { url: "/publications", code:301},
        "/publications/2022-ra-1480-esgo-translacol-project-digital-pcr-human-papilloma-virus-hpv-detection": { url: "/publications", code:301},
        "/publications/2d-and-3d-radial-multi-gradient-echo-dce-mri-murine-tumor-models-dynamic-r2-corrected": { url: "/publications", code:301},
        "/publications/3d-bioprinted-vascularized-glioblastoma-chip-studying-impact-simulated-microgravity": { url: "/publications", code:301},
        "/publications/3d-magnetic-tissue-stretcher-remote-mechanical-control-embryonic-stem-cell": { url: "/publications", code:301},
        "/publications/3d-printed-microfluidic-device-production-functionalized-hydrogel-microcapsules": { url: "/publications", code:301},
        "/publications/53bp1-interacts-rna-primer-okazaki-fragments-support-their-processing-during": { url: "/publications", code:301},
        "/publications/68ga-psma-11-petct-restaging-castration-resistant-nonmetastatic-prostate-cancer": { url: "/publications", code:301},
        "/publications/abcb6-resides-melanosomes-and-regulates-early-steps-melanogenesis-required-pmel": { url: "/publications", code:301},
        "/publications/aberrant-erbb4-src-signaling-hallmark-group-4-medulloblastoma-revealed-integrative": { url: "/publications", code:301},
        "/publications/aberrant-fucosylation-enables-breast-cancer-clusterin-interact-dendritic-cell-specific": { url: "/publications", code:301},
        "/publications/abl-fbp17-mechanosensing-system-couples-local-plasma-membrane-curvature-and-stress": { url: "/publications", code:301},
        "/publications/absence-chromosome-axis-protein-recruitment-prevents-meiotic-recombination-chromosome": { url: "/publications", code:301},
        "/publications/absolute-quantification-drug-vector-delivery-cytosol-0": { url: "/publications", code:301},
        "/publications/abstract-1237-randomized-phase-ii-trial-pre-operative-afatinib-non-metastatic-head-and": { url: "/publications", code:301},
        "/publications/abstract-3390-predictive-and-prognostic-value-circulating-tumor-dna-ctdna-compared": { url: "/publications", code:301},
        "/publications/abstract-3418-twist1-new-determinant-epithelial-mesenchymal-transition-egfr-mutated": { url: "/publications", code:301},
        "/publications/abstract-4813-emt-inducers-catalyze-malignant-transformation-mammary-epithelial-cells": { url: "/publications", code:301},
        "/publications/accumulation-lncrnas-cytoplasm-upon-dis3-depletion-leads-production-cryptic-peptides": { url: "/publications", code:301},
        "/publications/achieving-high-mass-resolution-nanosims-50-while-preserving-signal-transmission": { url: "/publications", code:301},
        "/publications/acridine-o6-benzylguanine-hybrids-synthesis-dna-binding-mgmt-inhibition-and": { url: "/publications", code:301},
        "/publications/actin-dynamics-drive-membrane-reorganization-and-scission-clathrin-independent": { url: "/publications", code:301},
        "/publications/activated-i-bar-irsp53-clustering-controls-formation-vasp-actin-based-membrane-0": { url: "/publications", code:301},
        "/publications/activation-par-mecano-transduction-de-la-gastrulation-en-reponse-des-fluctuations-de": { url: "/publications", code:301},
        "/publications/actomyosin-interface-contains-evolutionary-conserved-core-and-ancillary-interface": { url: "/publications", code:301},
        "/publications/acute-and-late-toxicities-patients-infected-sars-cov-2-and-treated-cancer-radiation": { url: "/publications", code:301},
        "/publications/addressing-barriers-comprehensiveness-accessibility-reusability-interoperability-and": { url: "/publications", code:301},
        "/publications/adherence-french-and-esgo-quality-indicators-ovarian-cancer-surgery-ad-hoc-analysis": { url: "/publications", code:301},
        "/publications/adipocyte-extracellular-vesicles-decrease-p16ink4a-melanoma-additional-link-between": { url: "/publications", code:301},
        "/publications/adn-tumoral-circulant-principes-applications-actuelles-en-radiotherapie-et": { url: "/publications", code:301},
        "/publications/adolescents-and-young-adults-aya-cancer-position-paper-aya-working-group-european": { url: "/publications", code:301},
        "/publications/allocation-carbon-arbuscular-mycorrhizal-fungus-gigaspora-margarita-its-gram-negative": { url: "/publications", code:301},
        "/publications/altering-temporal-regulation-one-transcription-factor-drives-evolutionary-trade-offs": { url: "/publications", code:301},
        "/publications/amuse-bouche-stem-cell-regulation-underlying-principles-and-mechanisms-adult": { url: "/publications", code:301},
        "/publications/analysis-gene-expression-dynamics-revealed-delayed-and-abnormal-epidermal-repair": { url: "/publications", code:301},
        "/publications/analysis-genomic-and-non-genomic-signaling-estrogen-receptor-pdx-models-breast-cancer": { url: "/publications", code:301},
        "/publications/analysis-liver-cancer-cell-lines-identifies-agents-likely-efficacy-against": { url: "/publications", code:301},
        "/publications/analysis-recombination-dependent-processing-blocked-replication-forks-bidimensional": { url: "/publications", code:301},
        "/publications/analytical-platforms-1-use-cultured-cells-and-fluorescent-read-out-coupled-normacurve": { url: "/publications", code:301},
        "/publications/androgen-signaling-regulates-sars-cov-2-receptor-levels-and-associated-severe-covid-19": { url: "/publications", code:301},
        "/publications/angiopoietin-2-marker-endothelial-activation-good-predictor-factor-intensive-care-unit": { url: "/publications", code:301},
        "/publications/angular-orientation-between-cores-iron-oxide-nanoclusters-controls-their-magneto": { url: "/publications", code:301},
        "/publications/anti-cancer-and-radio-sensitizing-properties-new-bimetallic-n-heterocyclic-carbene": { url: "/publications", code:301},
        "/publications/anti-cancer-drug-5-fluorouracil-affects-cell-cycle-regulators-and-potential-regulatory": { url: "/publications", code:301},
        "/publications/anti-inflammatory-activity-superoxide-dismutase-mimics-functionalized-cell-penetrating": { url: "/publications", code:301},
        "/publications/antigen-presentation-mouse-monocyte-derived-cells-re-evaluating-concept-monocyte": { url: "/publications", code:301},
        "/publications/apalutamide-darolutamide-and-enzalutamide-nonmetastatic-castration-resistant-prostate": { url: "/publications", code:301},
        "/publications/apical-size-and-deltaa-expression-predict-adult-neural-stem-cell-decisions-along": { url: "/publications", code:301},
        "/publications/apical-stress-fibers-enable-scaling-between-cell-mechanical-response-and-area": { url: "/publications", code:301},
        "/publications/apkci-triggers-basal-extrusion-luminal-mammary-epithelial-cells-tuning-contractility": { url: "/publications", code:301},
        "/publications/apoptosis-mapping-space-and-time-3d-tumor-ecosystems-reveals-transmissibility": { url: "/publications", code:301},
        "/publications/arginine-finger-bloom-syndrome-protein-its-structural-organization-and-its-role-energy": { url: "/publications", code:301},
        "/publications/array-comparative-genomic-hybridization-identifies-high-level-pi3kaktmtor-pathway": { url: "/publications", code:301},
        "/publications/art-deco-easy-tool-detection-and-characterization-cross-contamination-dna-samples": { url: "/publications", code:301},
        "/publications/artificial-intelligence-predicts-immune-and-inflammatory-gene-signatures-directly": { url: "/publications", code:301},
        "/publications/asidna-treatment-induces-cumulative-antitumor-efficacy-low-probability-acquired": { url: "/publications", code:301},
        "/publications/asidnatm-decoy-mimicking-dsbs-protects-normal-tissue-radiation-toxicity-through-dna": { url: "/publications", code:301},
        "/publications/aso-visual-abstract-robotic-surgery-pediatric-oncology-lessons-learned-first-100": { url: "/publications", code:301},
        "/publications/assessing-associations-between-aurka-hmmr-tpx2-tubg1-functional-module-and-breast": { url: "/publications", code:301},
        "/publications/assessing-personalized-responses-anti-pd-1-treatment-using-patient-derived-lung-tumor": { url: "/publications", code:301},
        "/publications/assessment-heterogeneity-collective-endothelial-cell-behavior-multicolor-clonal-cell": { url: "/publications", code:301},
        "/publications/assessment-psychosocial-difficulties-genetic-clinicians-and-distress-women-high-risk": { url: "/publications", code:301},
        "/publications/association-between-ezh2-expression-silencing-tumor-suppressors-and-disease-outcome": { url: "/publications", code:301},
        "/publications/association-between-preoperative-medication-lists-and-postoperative-hospital-length": { url: "/publications", code:301},
        "/publications/association-tp53-mutations-response-and-longer-survival-under-immune-checkpoint": { url: "/publications", code:301},
        "/publications/associations-between-sex-age-and-spiritual-well-being-scores-eortc-qlq-swb32-patients": { url: "/publications", code:301},
        "/publications/associations-between-untargeted-plasma-metabolomic-signatures-and-gut-microbiota": { url: "/publications", code:301},
        "/publications/asta-signaling-functions-evolutionary-conserved-mechanism-timing-juvenile-adult": { url: "/publications", code:301},
        "/publications/asymmetric-division-contractile-domains-couples-cell-positioning-and-fate": { url: "/publications", code:301},
        "/publications/atlas-cancer-signalling-network-systems-biology-resource-integrative-analysis-cancer": { url: "/publications", code:301},
        "/publications/atm-has-major-role-double-strand-break-repair-pathway-dysregulation-sporadic-breast": { url: "/publications", code:301},
        "/publications/atoh1-controls-primary-cilia-formation-allow-shh-triggered-granule-neuron-progenitor": { url: "/publications", code:301},
        "/publications/atypical-teratoidrhabdoid-tumors-atrts-smarca4-mutation-are-molecularly-distinct": { url: "/publications", code:301},
        "/publications/author-correction-cancer-associated-fibroblasts-induce-metalloprotease-independent": { url: "/publications", code:301},
        "/publications/author-correction-cd8t-cell-responsiveness-anti-pd-1-epigenetically-regulated-suv39h1": { url: "/publications", code:301},
        "/publications/author-correction-neuronal-nsun2-deficiency-produces-trna-epitranscriptomic": { url: "/publications", code:301},
        "/publications/autoantibodies-neutralizing-type-i-ifns-are-present-4-uninfected-individuals-over-70": { url: "/publications", code:301},
        "/publications/autophagy-polarized-toward-cell-front-during-migration-and-spatially-perturbed": { url: "/publications", code:301},
        "/publications/axillary-lymph-node-micrometastases-decrease-triple-negative-early-breast-cancer": { url: "/publications", code:301},
        "/publications/axl-inhibition-macrophages-stimulates-host-versus-leukemia-immunity-and-eradicates": { url: "/publications", code:301},
        "/publications/back-future-very-high-energy-electrons-vhees-and-their-potential-application-radiation": { url: "/publications", code:301},
        "/publications/balanced-pyrimidine-pool-required-optimal-chk1-activation-prevent-ultrafine-anaphase": { url: "/publications", code:301},
        "/publications/barhl2-maintains-t-cell-factors-repressors-and-thereby-switches-wntb-catenin-response": { url: "/publications", code:301},
        "/publications/bayesian-predictive-model-assess-brca2-mutational-status-according-clinical-history": { url: "/publications", code:301},
        "/publications/bendamustine-based-conditioning-prior-autologous-stem-cell-transplantation-asct": { url: "/publications", code:301},
        "/publications/bendamustine-eam-versus-beam-regimen-patients-mantle-cell-lymphoma-undergoing": { url: "/publications", code:301},
        "/publications/berberine-impairs-survival-triple-negative-breast-cancer-cells-cellular-and-molecular": { url: "/publications", code:301},
        "/publications/bet-and-cdk-inhibition-reveal-differences-proliferation-control-sympathetic-ganglion": { url: "/publications", code:301},
        "/publications/biallelic-inactivation-rev7-associated-fanconi-anemia-0": { url: "/publications", code:301},
        "/publications/bin1m-amphiphysin2-induces-clustering-phosphoinositides-recruit-its-downstream-partner": { url: "/publications", code:301},
        "/publications/biochemical-perturbations-mitotic-spindle-xenopus-extracts-using-diffusion-based": { url: "/publications", code:301},
        "/publications/biogitflow-development-workflow-protocols-bioinformatics-pipelines-git-and-gitlab-0": { url: "/publications", code:301},
        "/publications/bioinformatics-precision-medicine-oncology-principles-and-application-shiva-clinical": { url: "/publications", code:301},
        "/publications/biological-effects-scattered-versus-scanned-proton-beams-normal-tissues-total-body": { url: "/publications", code:301},
        "/publications/biopathological-significance-piwi-pirna-pathway-deregulation-invasive-breast": { url: "/publications", code:301},
        "/publications/biosynthesis-magnetic-nanoparticles-nano-degradation-products-revealed-human-stem": { url: "/publications", code:301},
        "/publications/bipartite-tad-organization-x-inactivation-center-ensures-opposing-developmental": { url: "/publications", code:301},
        "/publications/bloc-1-and-bloc-3-regulate-vamp7-cycling-and-melanosomes-distinct-tubular-transport": { url: "/publications", code:301},
        "/publications/bloc-1-brings-together-actin-and-microtubule-cytoskeletons-generate-recycling": { url: "/publications", code:301},
        "/publications/blockade-stat3-oncogene-addiction-induces-cellular-senescence-and-reveals-cell": { url: "/publications", code:301},
        "/publications/blood-monocytes-sample-melanamart1-antigen-long-lasting-cross-presentation-cd8-t-cells": { url: "/publications", code:301},
        "/publications/bmal1-knockdown-leans-epithelial-mesenchymal-balance-toward-epithelial-properties-and": { url: "/publications", code:301},
        "/publications/bmal1-knockdown-triggers-different-colon-carcinoma-cell-fates-altering-delicate": { url: "/publications", code:301},
        "/publications/bml-265-and-tyrphostin-ag1478-disperse-golgi-apparatus-and-abolish-protein-transport": { url: "/publications", code:301},
        "/publications/bmp-signaling-enhanced-intracellularly-fhl3-controlling-wnt-dependent-spatiotemporal": { url: "/publications", code:301},
        "/publications/bottom-vitro-methods-assay-ultrastructural-organization-membrane-reshaping-and": { url: "/publications", code:301},
        "/publications/bouffees-de-chaleur-et-cancer-du-sein-avec-recepteurs-hormonaux-positifs-mecanismes-et": { url: "/publications", code:301},
        "/publications/brachytherapy-boost-bt-boost-or-stereotactic-body-radiation-therapy-boost-sbrt-boost": { url: "/publications", code:301},
        "/publications/brca2-promotes-dna-rna-hybrid-resolution-ddx5-helicase-dna-breaks-facilitate-their": { url: "/publications", code:301},
        "/publications/brcaness-slfn11-and-rb1-loss-predict-response-topoisomerase-i-inhibitors-triple": { url: "/publications", code:301},
        "/publications/brdu-immuno-tagged-g-quadruplex-ligands-new-ligand-guided-immunofluorescence-approach": { url: "/publications", code:301},
        "/publications/breakdown-effective-temperature-power-law-interactions-and-self-propulsion-momentum": { url: "/publications", code:301},
        "/publications/breaking-photoswitch-activation-depth-limit-using-ionising-radiation-stimuli-adapted": { url: "/publications", code:301},
        "/publications/breast-carcinomas-osteoclast-giant-cells-comprehensive-clinico-pathological-and": { url: "/publications", code:301},
        "/publications/brn2-suppresses-apoptosis-reprograms-dna-damage-repair-and-associated-high-somatic": { url: "/publications", code:301},
        "/publications/bystander-ifn-g-activity-promotes-widespread-and-sustained-cytokine-signaling-altering": { url: "/publications", code:301},
        "/publications/c-terminal-cleavage-human-foxp3-proprotein-convertase-motif-abrogates-its-suppressive": { url: "/publications", code:301},
        "/publications/c2c12-myoblastosteoblast-transdifferentiation-steps-enhanced-epigenetic-inhibition": { url: "/publications", code:301},
        "/publications/c57bl6-congenic-mouse-nras-q61k-melanoma-cell-lines-are-highly-sensitive-combination": { url: "/publications", code:301},
        "/publications/ca-125-early-dynamics-predict-overall-survival-women-newly-diagnosed-advanced-ovarian": { url: "/publications", code:301},
        "/publications/caf-1-and-hir-histone-chaperones-associate-sites-meiotic-double-strand-breaks-budding": { url: "/publications", code:301},
        "/publications/calamitic-mesogenic-near-infrared-absorbing-croconaine-dyeliquid-crystalline-elastomer": { url: "/publications", code:301},
        "/publications/calcineurin-protein-phosphatase-dispensable-bcr-abl-induced-b-all-maintenance": { url: "/publications", code:301},
        "/publications/calculation-force-field-required-nucleus-deformation-during-cell-migration-through": { url: "/publications", code:301},
        "/publications/calpastatin-prevents-angiotensin-ii-mediated-podocyte-injury-through-maintenance": { url: "/publications", code:301},
        "/publications/camsaps-organize-acentrosomal-microtubule-network-basal-varicosities-radial-glial": { url: "/publications", code:301},
        "/publications/cancer-associated-fibroblast-heterogeneity-axillary-lymph-nodes-drives-metastases": { url: "/publications", code:301},
        "/publications/cancer-associated-fibroblasts-actively-compress-cancer-cells-and-modulate": { url: "/publications", code:301},
        "/publications/cancer-associated-fibroblasts-induce-metalloprotease-independent-cancer-cell-invasion": { url: "/publications", code:301},
        "/publications/cancer-associated-fibroblasts-lead-tumor-invasion-through-integrin-b3-dependent": { url: "/publications", code:301},
        "/publications/cancer-associated-sf3b1-mutations-affect-alternative-splicing-promoting-alternative": { url: "/publications", code:301},
        "/publications/cancer-chez-un-enfant-porteur-de-handicap-intellectuel-questionnement-et-enjeux": { url: "/publications", code:301},
        "/publications/cancer-patient-satisfaction-health-care-professional-communication-international-eortc": { url: "/publications", code:301},
        "/publications/capecitabine-efficacy-correlated-tymp-and-rb1-expression-pdx-established-triple": { url: "/publications", code:301},
        "/publications/capsids-hiv-1-and-hiv-2-determine-immune-detection-viral-cdna-innate-sensor-cgas": { url: "/publications", code:301},
        "/publications/cardio-pulmonary-renal-interactions-icu-patients-role-mechanical-ventilation-venous": { url: "/publications", code:301},
        "/publications/cardiotoxicity-associated-radiotherapy-breast-cancer-question-based-review-current": { url: "/publications", code:301},
        "/publications/case-only-study-identify-genetic-modifiers-breast-cancer-risk-brca1brca2-mutation": { url: "/publications", code:301},
        "/publications/catabolism-lysosome-related-organelles-color-changing-spiders-supports-intracellular": { url: "/publications", code:301},
        "/publications/caveolin-1-protects-endothelial-cells-extensive-expansion-transcellular-tunnel-0": { url: "/publications", code:301},
        "/publications/caveolin-dependent-and-pi3kakt-independent-role-pten-b-catenin-transcriptional": { url: "/publications", code:301},
        "/publications/ccr6ccr10-mediated-plasmacytoid-dendritic-cell-recruitment-inflamed-epithelia-after": { url: "/publications", code:301},
        "/publications/cd163-tumor-associated-macrophage-accumulation-breast-cancer-patients-reflects-both": { url: "/publications", code:301},
        "/publications/cd36-specific-antibodies-block-release-hiv-1-infected-primary-macrophages-and-its": { url: "/publications", code:301},
        "/publications/cd44-regulates-epigenetic-plasticity-mediating-iron-endocytosis-0": { url: "/publications", code:301},
        "/publications/cd73-mediated-immunosuppression-linked-specific-fibroblast-population-paves-way-new": { url: "/publications", code:301},
        "/publications/cdk5-promotes-dna-replication-stress-checkpoint-activation-through-rpa-32": { url: "/publications", code:301},
        "/publications/cdkn1a-regulates-langerhans-cell-survival-and-promotes-treg-cell-generation-upon": { url: "/publications", code:301},
        "/publications/celecoxib-neoadjuvant-chemotherapy-breast-cancer-might-worsen-outcomes-differentially": { url: "/publications", code:301},
        "/publications/cell-cell-heterogeneity-ewsr1-fli1-activity-determines-proliferationmigration-choices": { url: "/publications", code:301},
        "/publications/cell-clusters-adopt-collective-amoeboid-mode-migration-confined-nonadhesive": { url: "/publications", code:301},
        "/publications/cell-density-sensing-alters-tgf-b-signaling-cell-type-specific-manner-independent": { url: "/publications", code:301},
        "/publications/cell-fate-decision-map-reveals-abundant-direct-neurogenesis-bypassing-intermediate": { url: "/publications", code:301},
        "/publications/cell-fragmentation-mouse-preimplantation-embryos-induced-ectopic-activation-polar-0": { url: "/publications", code:301},
        "/publications/cell-fragmentation-mouse-preimplantation-embryos-induced-ectopic-activation-polar-body": { url: "/publications", code:301},
        "/publications/cell-patient-machine-learning-transfer-approach-uncovers-novel-basal-breast-cancer": { url: "/publications", code:301},
        "/publications/cell-size-scaling-laws-unified-theory": { url: "/publications", code:301},
        "/publications/cellular-and-chemical-biology-endocytic-trafficking-and-intracellular-delivery-gl-lect": { url: "/publications", code:301},
        "/publications/cellular-blebs-and-membrane-invaginations-are-coupled-through-membrane-tension": { url: "/publications", code:301},
        "/publications/cellular-detection-mitochondria-targeted-brominated-vinyl-triphenylamine-optical-probe": { url: "/publications", code:301},
        "/publications/cenp-subnuclear-localization-pattern-marker-predicting-curability-chemoradiation": { url: "/publications", code:301},
        "/publications/centrosome-amplification-mediates-small-extracellular-vesicle-secretion-lysosome": { url: "/publications", code:301},
        "/publications/centrosome-nuclear-axis-repositioning-drives-assembly-bipolar-spindle-scaffold-ensure": { url: "/publications", code:301},
        "/publications/cetuximab-combined-paclitaxel-or-paclitaxel-alone-patients-recurrent-or-metastatic": { url: "/publications", code:301},
        "/publications/challenge-cardiac-dose-constraint-adaptation-hypofractionated-breast-radiotherapy": { url: "/publications", code:301},
        "/publications/characterization-signalling-pathways-link-apoptosis-and-autophagy-cell-death-induced": { url: "/publications", code:301},
        "/publications/characterizing-meiotic-chromosomes-structure-and-pairing-using-designer-sequence": { url: "/publications", code:301},
        "/publications/chikungunya-virus-superinfection-exclusion-mediated-block-viral-replication-and-does": { url: "/publications", code:301},
        "/publications/choreography-centrosomal-mrnas-reveals-conserved-localization-mechanism-involving": { url: "/publications", code:301},
        "/publications/chromatin-assembly-factor-1-promotes-rad51-dependent-template-switches-replication": { url: "/publications", code:301},
        "/publications/chromosome-segregation-fidelity-requires-microtubule-polyglutamylation-cancer": { url: "/publications", code:301},
        "/publications/chromosome-structural-anomalies-due-aberrant-spindle-forces-exerted-gene-editing-sites": { url: "/publications", code:301},
        "/publications/cigarette-smoke-induced-extracellular-vesicles-dendritic-cells-alter-t-cell-activation": { url: "/publications", code:301},
        "/publications/circular-rna-profiling-distinguishes-medulloblastoma-groups-and-shows-aberrant-rmst": { url: "/publications", code:301},
        "/publications/circulating-and-tumor-infiltrating-nk-cells-clear-cell-renal-cell-carcinoma-patients": { url: "/publications", code:301},
        "/publications/circulating-hpv-dna-marker-early-detection-relapse-patients-cervical-cancer": { url: "/publications", code:301},
        "/publications/circulating-tumor-dna-prognostic-factor-patients-resectable-hepatic-metastases-uveal": { url: "/publications", code:301},
        "/publications/classification-101-brca1-and-brca2-variants-uncertain-significance-cosegregation-study": { url: "/publications", code:301},
        "/publications/clec12b-decreases-melanoma-proliferation-repressing-signal-transducer-and-activator": { url: "/publications", code:301},
        "/publications/clinical-and-genetic-landscape-treatment-naive-cervical-cancer-alterations-pik3ca-and": { url: "/publications", code:301},
        "/publications/clinical-interest-combining-transcriptomic-and-genomic-signatures-high-grade-serous": { url: "/publications", code:301},
        "/publications/clinical-transfer-aguixr-based-radiation-treatments-locally-advanced-cervical-cancer": { url: "/publications", code:301},
        "/publications/clonal-analysis-notch1-expressing-cells-reveals-existence-unipotent-stem-cells-retain": { url: "/publications", code:301},
        "/publications/clonesig-can-jointly-infer-intra-tumor-heterogeneity-and-mutational-signature-activity": { url: "/publications", code:301},
        "/publications/clustering-heterochromatin-sir3-promotes-telomere-clustering-independently-silencing": { url: "/publications", code:301},
        "/publications/co-translational-assembly-and-localized-translation-nucleoporins-nuclear-pore-complex": { url: "/publications", code:301},
        "/publications/cocaine-conditioned-place-preference-unexpected-suppression-preference-due-testing": { url: "/publications", code:301},
        "/publications/coexistence-transient-liquid-droplets-and-amorphous-solid-particles-nonclassical": { url: "/publications", code:301},
        "/publications/collective-stresses-drive-competition-between-monolayers-normal-and-ras-transformed": { url: "/publications", code:301},
        "/publications/colomoto-interactive-notebook-accessible-and-reproducible-computational-analyses": { url: "/publications", code:301},
        "/publications/combination-olaparib-and-radiation-therapy-triple-negative-breast-cancer-preliminary": { url: "/publications", code:301},
        "/publications/combination-pi3k-and-mek-inhibitors-yields-durable-remission-pdx-models-pik3ca-mutated": { url: "/publications", code:301},
        "/publications/combinatorial-expression-microtubule-associated-eb1-and-atip3-biomarkers-improves": { url: "/publications", code:301},
        "/publications/combined-mek-and-jakstat3-pathway-inhibition-effectively-decreases-shh-medulloblastoma": { url: "/publications", code:301},
        "/publications/combined-transplantation-human-mscs-and-ecfcs-improves-cardiac-function-and-decrease": { url: "/publications", code:301},
        "/publications/combining-dna-repair-inhibitor-dbait-radiotherapy-treatment-high-grade-glioma-efficacy": { url: "/publications", code:301},
        "/publications/comedications-influence-immune-infiltration-and-pathological-response-neoadjuvant": { url: "/publications", code:301},
        "/publications/comment-ibrahim-et-al-effects-plane-spatial-resolution-ct-based-radiomic-features": { url: "/publications", code:301},
        "/publications/committed-tissue-resident-memory-t-cell-precursor-within-circulating-cd8-effector-t": { url: "/publications", code:301},
        "/publications/common-gene-variants-within-3-untranslated-regions-modulators-multiple-myeloma-risk": { url: "/publications", code:301},
        "/publications/common-transcriptomic-program-acquired-thymus-defines-tissue-residency-mait-and-nkt": { url: "/publications", code:301},
        "/publications/community-assessment-advance-computational-prediction-cancer-drug-combinations": { url: "/publications", code:301},
        "/publications/comparative-analysis-between-flaviviruses-reveals-specific-neural-stem-cell-tropism": { url: "/publications", code:301},
        "/publications/comparative-analysis-durable-responses-immune-checkpoint-inhibitors-versus-other": { url: "/publications", code:301},
        "/publications/comparing-phylogenetic-approaches-reconstructing-cell-lineage-microsatellites-missing": { url: "/publications", code:301},
        "/publications/compartment-specific-and-elavl1-coordinated-regulation-intronic-polyadenylation": { url: "/publications", code:301},
        "/publications/competition-ligands-and-18-mer-binding-domain-rhau-helicase-g-quadruplexes-orthosteric": { url: "/publications", code:301},
        "/publications/comprehensive-analysis-intramolecular-g-quadruplex-structures-furthering-understanding": { url: "/publications", code:301},
        "/publications/comprehensive-analysis-relationship-between-dose-rate-and-biological-effects": { url: "/publications", code:301},
        "/publications/comprehensive-evaluation-normalization-methods-illumina-high-throughput-rna-sequencing": { url: "/publications", code:301},
        "/publications/comprehensive-geriatric-assessment-and-quality-life-after-localized-prostate-cancer": { url: "/publications", code:301},
        "/publications/comprehensive-library-fluorescent-constructs-sars-cov-2-proteins-and-their-initial": { url: "/publications", code:301},
        "/publications/comprehensive-map-monocyte-derived-dendritic-cell-transcriptional-network-engaged-upon": { url: "/publications", code:301},
        "/publications/compromised-nuclear-envelope-integrity-drives-trex1-dependent-dna-damage-and-tumor": { url: "/publications", code:301},
        "/publications/computational-method-prioritizing-targeted-therapies-precision-oncology-performance": { url: "/publications", code:301},
        "/publications/conceptual-and-computational-framework-logical-modelling-biological-networks": { url: "/publications", code:301},
        "/publications/concerted-action-mutlb-heterodimer-and-mer3-helicase-regulates-global-extent-meiotic": { url: "/publications", code:301},
        "/publications/concurrent-use-palbociclib-and-radiation-therapy-single-centre-experience-and-review": { url: "/publications", code:301},
        "/publications/connexin43-intercellular-communication-drives-early-differentiation-human-bone-marrow": { url: "/publications", code:301},
        "/publications/conservation-and-topology-protein-interaction-networks-under-duplication-divergence": { url: "/publications", code:301},
        "/publications/conservation-antiviral-systems-across-domains-life-reveals-novel-immune-mechanisms": { url: "/publications", code:301},
        "/publications/conservation-copy-number-profiles-during-engraftment-and-passaging-patient-derived": { url: "/publications", code:301},
        "/publications/conserved-noncoding-locus-regulates-random-monoallelic-xist-expression-across": { url: "/publications", code:301},
        "/publications/constitutional-mismatch-repair-deficiency-differential-diagnosis-neurofibromatosis": { url: "/publications", code:301},
        "/publications/constitutive-siglec-1-expression-confers-susceptibility-hiv-1-infection-human": { url: "/publications", code:301},
        "/publications/constraints-and-limitations-transcriptional-response-downstream-bicoid-morphogen": { url: "/publications", code:301},
        "/publications/continuum-model-magnetic-particle-flows-microfluidics-applicable-dilute-packed": { url: "/publications", code:301},
        "/publications/contraception-breast-cancer-survivors-feeric-case-control-study-performed-behalf": { url: "/publications", code:301},
        "/publications/contribution-epigenetic-landscapes-and-transcription-factors-x-chromosome-reactivation": { url: "/publications", code:301},
        "/publications/contribution-resident-and-circulating-precursors-tumor-infiltrating-cd8-t-cell": { url: "/publications", code:301},
        "/publications/control-src-molecular-dynamics-encodes-distinct-cytoskeletal-responses-specifying": { url: "/publications", code:301},
        "/publications/convergent-network-effects-along-axis-gene-expression-during-prostate-cancer": { url: "/publications", code:301},
        "/publications/cooperation-between-non-essential-dna-polymerases-contributes-genome-stability": { url: "/publications", code:301},
        "/publications/cooperative-pro-tumorigenic-adaptation-oncogenic-ras-through-epithelial-mesenchymal": { url: "/publications", code:301},
        "/publications/coordinated-recruitment-spir-actin-nucleators-and-myosin-v-motors-rab11-vesicle": { url: "/publications", code:301},
        "/publications/copper-alkyne-complexation-responsible-nucleolar-localization-quadruplex-nucleic-acid": { url: "/publications", code:301},
        "/publications/coronin-1c-promotes-triple-negative-breast-cancer-invasiveness-through-regulation-mt1": { url: "/publications", code:301},
        "/publications/correction-developing-advanced-gut-chip-model-enabling-study-epithelial-cellfibroblast": { url: "/publications", code:301},
        "/publications/correction-magnetic-field-inhomogeneities-and-normalization-voxel-values-are-needed": { url: "/publications", code:301},
        "/publications/correction-robotic-surgery-pediatric-oncology-lessons-learned-first-100-tumors": { url: "/publications", code:301},
        "/publications/correlative-afm-and-fluorescence-imaging-demonstrate-nanoscale-membrane-remodeling-and": { url: "/publications", code:301},
        "/publications/correlative-infrared-nanospectroscopy-and-transmission-electron-microscopy-investigate": { url: "/publications", code:301},
        "/publications/cortical-softening-elicits-zygotic-contractility-during-mouse-preimplantation": { url: "/publications", code:301},
        "/publications/coupling-dna-damage-and-repair-essential-safeguard-during-programmed-dna-double-strand": { url: "/publications", code:301},
        "/publications/coupling-fission-and-exit-rab6-vesicles-golgi-hotspots-through-kinesin-myosin": { url: "/publications", code:301},
        "/publications/covariate-specific-time-dependent-receiver-operating-characteristic-curve-correlated": { url: "/publications", code:301},
        "/publications/covid-19-disease-map-building-computational-repository-sars-cov-2-virus-host": { url: "/publications", code:301},
        "/publications/covid19-disease-map-computational-knowledge-repository-virus-host-interaction": { url: "/publications", code:301},
        "/publications/critical-evaluation-cna-estimators-dna-data-using-matching-confidence-masks-and-wgs": { url: "/publications", code:301},
        "/publications/critical-role-toxoplasma-gondii-vacuolar-protein-sorting-vps9-secretory-organelle": { url: "/publications", code:301},
        "/publications/critical-role-trim28-and-hp1bg-epigenetic-control-t-cell-metabolic-reprograming-and": { url: "/publications", code:301},
        "/publications/crohns-disease-related-bacterial-strain-lf82-assembles-biofilm-communities-protect": { url: "/publications", code:301},
        "/publications/crowdsourced-assessment-common-genetic-contribution-predicting-anti-tnf-treatment": { url: "/publications", code:301},
        "/publications/ctip-mediated-fork-protection-synergizes-brca1-suppress-genomic-instability-upon-dna": { url: "/publications", code:301},
        "/publications/current-perspectives-external-control-arms-oncology-clinical-trials-analysis-ema": { url: "/publications", code:301},
        "/publications/current-recommendations-clinical-surveillance-and-genetic-testing-rhabdoid-tumor": { url: "/publications", code:301},
        "/publications/curving-cells-inside-and-out-roles-bar-domain-proteins-membrane-shaping-and-its": { url: "/publications", code:301},
        "/publications/cxcr6-deficiency-impairs-cancer-vaccine-efficacy-and-cd8-resident-memory-t-cell": { url: "/publications", code:301},
        "/publications/cytidine-deaminase-deficiency-impairs-sister-chromatid-disjunction-decreasing-parp-1": { url: "/publications", code:301},
        "/publications/cytidine-deaminase-deficiency-mice-enhances-genetic-instability-limits-number": { url: "/publications", code:301},
        "/publications/cytidine-deaminase-deficiency-tumor-cells-associated-sensitivity-naphthol-derivative": { url: "/publications", code:301},
        "/publications/cytoreductive-surgery-and-hyperthermic-intraperitoneal-chemotherapy-pseudomyxoma": { url: "/publications", code:301},
        "/publications/cytosolic-ezh1-isoform-modulates-prc2-ezh1-epigenetic-adaptive-response-postmitotic": { url: "/publications", code:301},
        "/publications/de-novo-scamp5-mutation-causes-neurodevelopmental-disorder-autistic-features-and": { url: "/publications", code:301},
        "/publications/deciphering-spatial-landscape-and-plasticity-immunosuppressive-fibroblasts-breast": { url: "/publications", code:301},
        "/publications/declic-patient-education-program-assessment-through-population-health-intervention": { url: "/publications", code:301},
        "/publications/decouverte-dune-grossesse-pendant-le-traitement-dun-jeune-enfant-atteint-de-cancer": { url: "/publications", code:301},
        "/publications/decrease-nampt-activity-impairs-basal-parp-1-activity-cytidine-deaminase-deficient": { url: "/publications", code:301},
        "/publications/deep-learning-18f-fdg-uptake-classification-enables-total-metabolic-tumor-volume": { url: "/publications", code:301},
        "/publications/deep-learning-identifies-morphological-patterns-homologous-recombination-deficiency": { url: "/publications", code:301},
        "/publications/deep-learning-improves-macromolecule-identification-3d-cellular-cryo-electron": { url: "/publications", code:301},
        "/publications/definition-biologically-distinct-groups-conjunctival-melanomas-according-etiological": { url: "/publications", code:301},
        "/publications/deletion-myeloid-endothelin-b-receptor-confers-long-term-protection-angiotensin-ii": { url: "/publications", code:301},
        "/publications/des-mecanismes-moleculaires-aux-applications-cliniques-lessentiel-du-colloque": { url: "/publications", code:301},
        "/publications/design-realization-and-characterization-novel-diamond-detector-prototype-flash": { url: "/publications", code:301},
        "/publications/design-synthesis-and-antiprotozoal-evaluation-new-24-bissubstituted": { url: "/publications", code:301},
        "/publications/design-synthesis-biological-evaluation-and-cellular-imaging-imidazo45-bpyridine": { url: "/publications", code:301},
        "/publications/detection-de-spots-avec-selection-dechelle-automatique-et-seuillage-adaptatif-en": { url: "/publications", code:301},
        "/publications/detection-interactions-tumour-derived-extracellular-vesicles-immune-cells-dependent-ev": { url: "/publications", code:301},
        "/publications/determinants-manageriaux-et-organisationnels-sante-psychologique-au-travail-des": { url: "/publications", code:301},
        "/publications/determination-ion-collection-efficiency-razor-nano-chamber-ultra-high-dose-rate": { url: "/publications", code:301},
        "/publications/determination-microtubule-lattice-parameters-cryo-electron-microscope-images-using": { url: "/publications", code:301},
        "/publications/developing-advanced-gut-chip-model-enabling-study-epithelial-cellfibroblast": { url: "/publications", code:301},
        "/publications/development-and-validation-radiomic-model-diagnosis-dopaminergic-denervation-18ffdopa": { url: "/publications", code:301},
        "/publications/development-droplet-microfluidics-device-based-integrated-soft-magnets-and-fluidic": { url: "/publications", code:301},
        "/publications/development-optimized-and-scalable-method-isolation-umbilical-cord-blood-derived-small": { url: "/publications", code:301},
        "/publications/dewar-valence-isomers-third-type-environmentally-relevant-dna-photoproducts-induced": { url: "/publications", code:301},
        "/publications/diacylglycerol-kinase-z-promotes-actin-cytoskeleton-remodeling-and-mechanical-forces-b": { url: "/publications", code:301},
        "/publications/diagnostic-accuracy-reduced-immunohistochemical-panel-medulloblastoma-molecular": { url: "/publications", code:301},
        "/publications/diagnostic-chest-x-rays-and-breast-cancer-risk-among-women-hereditary-predisposition": { url: "/publications", code:301},
        "/publications/diagnostic-performance-ultrasound-guided-fine-needle-aspiration-nonpalpable-breast": { url: "/publications", code:301},
        "/publications/dicer-2-dependent-generation-viral-dna-defective-genomes-rna-viruses-modulates": { url: "/publications", code:301},
        "/publications/dicer1-and-associated-conditions-identification-risk-individuals-and-recommended-0": { url: "/publications", code:301},
        "/publications/differences-mitotic-spindle-architecture-mammalian-neural-stem-cells-influence-mitotic": { url: "/publications", code:301},
        "/publications/different-pigmentation-risk-loci-high-risk-monosomy-3-and-low-risk-disomy-3-uveal": { url: "/publications", code:301},
        "/publications/different-tcr-induced-t-lymphocyte-responses-are-potentiated-stiffness-variable": { url: "/publications", code:301},
        "/publications/differential-cftr-interactome-proximity-labeling-procedures-identify-enrichment": { url: "/publications", code:301},
        "/publications/differential-correlations-between-changes-glutathione-redox-state-protein": { url: "/publications", code:301},
        "/publications/differential-effects-translation-immune-related-alternatively-polyadenylated-mrnas": { url: "/publications", code:301},
        "/publications/differential-normal-skin-transcriptomic-response-total-body-irradiated-mice-exposed": { url: "/publications", code:301},
        "/publications/differential-remodeling-oxylipin-pool-after-flash-versus-conventional-dose-rate": { url: "/publications", code:301},
        "/publications/digital-multiplexed-gene-expression-analysis-mrna-and-mirna-routinely-processed-and": { url: "/publications", code:301},
        "/publications/digital-phenotyping-young-breast-cancer-patients-treated-neoadjuvant-chemotherapy": { url: "/publications", code:301},
        "/publications/direct-imaging-and-automatic-analysis-tumor-chip-reveal-cooperative-antitumoral": { url: "/publications", code:301},
        "/publications/direct-observation-conformational-states-formin-mdia1-actin-filament-barbed-ends-and": { url: "/publications", code:301},
        "/publications/directional-control-neurite-outgrowth-emerging-technologies-parkinsons-disease-using": { url: "/publications", code:301},
        "/publications/dis3-ribonuclease-prevents-cytoplasmic-accumulation-lncrnas-carrying-non-canonical": { url: "/publications", code:301},
        "/publications/discovering-hidden-messages-within-cell-trajectories-using-deep-learning-approach": { url: "/publications", code:301},
        "/publications/discovery-druggable-copper-signaling-pathway-drives-cell-plasticity-and-inflammation": { url: "/publications", code:301},
        "/publications/discovery-novel-drug-sensitivities-t-pll-high-throughput-ex-vivo-drug-testing-and": { url: "/publications", code:301},
        "/publications/disordered-regions-and-folded-modules-caf-1-promote-histone-deposition": { url: "/publications", code:301},
        "/publications/disordered-regions-and-folded-modules-caf-1-promote-histone-deposition-s-pombe-0": { url: "/publications", code:301},
        "/publications/dissect-tool-segment-and-explore-cell-and-tissue-mechanics-highly-deformed-3d": { url: "/publications", code:301},
        "/publications/dissecting-effects-anti-cancer-drugs-and-cancer-associated-fibroblasts-chip": { url: "/publications", code:301},
        "/publications/dissecting-highly-mutagenic-processing-complex-clustered-dna-damage-yeast": { url: "/publications", code:301},
        "/publications/dissecting-signaling-hierarchies-patterning-mouse-primitive-streak-using": { url: "/publications", code:301},
        "/publications/distinct-molecular-cues-ensure-robust-microtubule-dependent-nuclear-positioning": { url: "/publications", code:301},
        "/publications/distinct-roles-and-b-tubulin-polyglutamylation-controlling-axonal-transport-and": { url: "/publications", code:301},
        "/publications/distinction-between-2-and-3-phosphate-isomers-fluorescent-nadph-analogue-led-strong": { url: "/publications", code:301},
        "/publications/distinctive-roles-age-sex-and-genetics-shaping-transcriptional-variation-human-immune": { url: "/publications", code:301},
        "/publications/distribution-mediastinal-lesions-across-multi-institutional-international-radiology": { url: "/publications", code:301},
        "/publications/dna-damage-induces-nuclear-envelope-rupture-through-atr-mediated-phosphorylation-lamin": { url: "/publications", code:301},
        "/publications/dna-damage-response-rna-binding-proteins-ddrbps-perspectives-new-class-proteins-and": { url: "/publications", code:301},
        "/publications/dna-methylation-restrains-transposons-adopting-chromatin-signature-permissive-meiotic": { url: "/publications", code:301},
        "/publications/dna-pkcs-plays-role-cancer-metastasis-through-regulation-secreted-proteins-involved": { url: "/publications", code:301},
        "/publications/dna-polymerase-d-single-pol31-polymorphism-suppresses-strain-background-specific": { url: "/publications", code:301},
        "/publications/dna-transposons-mediate-duplications-transposition-independent-and-dependent": { url: "/publications", code:301},
        "/publications/dnmt3a-dependent-dna-methylation-required-spermatogonial-stem-cells-commit": { url: "/publications", code:301},
        "/publications/dnmt3l-knockout-donor-cells-improve-somatic-cell-nuclear-transfer-reprogramming": { url: "/publications", code:301},
        "/publications/does-aggressive-local-treatment-have-impact-survival-children-metastatic": { url: "/publications", code:301},
        "/publications/dose-constraints-moderate-hypofractionated-radiotherapy-prostate-cancer-french-genito": { url: "/publications", code:301},
        "/publications/dramatic-vivo-efficacy-ezh2-inhibitor-tazemetostat-pbrm1-mutated-human-chordoma": { url: "/publications", code:301},
        "/publications/drug-driven-synthetic-lethality-bypassing-tumor-cell-genetics-combination-asidna-and": { url: "/publications", code:301},
        "/publications/drug-target-identification-covid-19-disease-mechanisms-using-computational-systems": { url: "/publications", code:301},
        "/publications/dual-protein-mrna-localization-screen-reveals-compartmentalized-translation-and": { url: "/publications", code:301},
        "/publications/dual-role-integrin-alpha-6-glioblastoma-supporting-stemness-proneural-stem-cells-while": { url: "/publications", code:301},
        "/publications/dual-targeting-higher-order-dna-structures-azacryptands-induces-dna-junction-mediated": { url: "/publications", code:301},
        "/publications/dye-functionalized-phosphate-binding-macrocycles-nucleotide-g-quadruplex-recognition": { url: "/publications", code:301},
        "/publications/dynamic-boolean-modeling-molecular-and-cellular-interactions-psoriasis-predicts-drug": { url: "/publications", code:301},
        "/publications/dynamic-enhancer-partitioning-instructs-activation-growth-related-gene-during-exit": { url: "/publications", code:301},
        "/publications/dynamics-chromatin-accessibility-and-long-range-interactions-response-glucocorticoid": { url: "/publications", code:301},
        "/publications/dynamics-hiv-containing-compartments-macrophages-reveal-sequestration-virions-and": { url: "/publications", code:301},
        "/publications/dystrophy-associated-caveolin-3-mutations-reveal-caveolae-couple-il6stat3-signaling": { url: "/publications", code:301},
        "/publications/e2f1-binds-peptide-binding-groove-within-bir3-domain-ciap1-and-requires-ciap1": { url: "/publications", code:301},
        "/publications/eb1-contributes-microtubule-bundling-and-organization-along-root-growth-arabidopsis": { url: "/publications", code:301},
        "/publications/editorial-crossroads-between-immunological-disorders-and-neuropsychiatric-diseases": { url: "/publications", code:301},
        "/publications/editorial-reciprocal-links-between-rna-metabolism-and-dna-damage-special-issue-july": { url: "/publications", code:301},
        "/publications/effect-perceived-control-and-psychological-distress-genetic-knowledge-women-breast": { url: "/publications", code:301},
        "/publications/effective-antitumor-therapy-based-novel-antibody-drug-conjugate-targeting-tn": { url: "/publications", code:301},
        "/publications/effectiveness-low-dose-radiation-therapy-symptoms-patients-knee-osteoarthritis": { url: "/publications", code:301},
        "/publications/effects-assisted-reproductive-technologies-transposon-regulation-mouse-pre-implanted": { url: "/publications", code:301},
        "/publications/effects-iron-modulation-mesenchymal-stem-cell-induced-drug-resistance-estrogen": { url: "/publications", code:301},
        "/publications/efficacy-targeted-radionuclide-therapy-using-131iicf01012-3d-pigmented-braf-and-nras": { url: "/publications", code:301},
        "/publications/efficient-conformational-sampling-and-weak-scoring-docking-programs-strategy-wisdom": { url: "/publications", code:301},
        "/publications/efficient-inhibition-infectious-prions-multiplication-and-release-targeting-exosomal": { url: "/publications", code:301},
        "/publications/eif4f-translation-initiation-complex-predictive-marker-response-immunotherapy-mucosal": { url: "/publications", code:301},
        "/publications/elevated-circulating-stem-cells-level-observed-one-month-after-implantation-carmat": { url: "/publications", code:301},
        "/publications/embryonic-signature-distinguishes-pediatric-and-adult-rhabdoid-tumors-other-smarcb1": { url: "/publications", code:301},
        "/publications/emt-inducers-catalyze-malignant-transformation-mammary-epithelial-cells-and-drive": { url: "/publications", code:301},
        "/publications/end-joining-factor-ku-acts-end-resection-double-strand-break-free-arrested-replication": { url: "/publications", code:301},
        "/publications/endocytic-membrane-repair-escrt-iii-controls-antigen-export-cytosol-during-antigen": { url: "/publications", code:301},
        "/publications/endocytosis-switch-controlled-transmembrane-osmotic-pressure-and-phospholipid-number": { url: "/publications", code:301},
        "/publications/endosomal-trafficking-defects-alter-neural-progenitor-proliferation-and-cause": { url: "/publications", code:301},
        "/publications/endosomal-trafficking-defects-alter-neural-progenitor-proliferation-and-cause-0": { url: "/publications", code:301},
        "/publications/eortc-qlq-comu26-questionnaire-assessment-communication-between-patients-and": { url: "/publications", code:301},
        "/publications/epigenetic-analysis-patients-t-all-identifies-poor-outcomes-and-hypomethylating-agent": { url: "/publications", code:301},
        "/publications/epigenetic-priming-mechanism-mediated-nutrient-sensing-regulates-transcriptional": { url: "/publications", code:301},
        "/publications/epigenetic-switch-ensures-transposon-repression-upon-dynamic-loss-dna-methylation": { url: "/publications", code:301},
        "/publications/epigenetically-controlled-tumor-antigens-derived-splice-junctions-between-exons-and": { url: "/publications", code:301},
        "/publications/epigenomic-mapping-identifies-enhancer-repertoire-regulates-cell-identity-bladder": { url: "/publications", code:301},
        "/publications/episomal-hpv16-responsible-aggressive-and-deadly-metastatic-anal-squamous-cell": { url: "/publications", code:301},
        "/publications/epithelial-disruption-drives-mesendoderm-differentiation-human-pluripotent-stem-cells": { url: "/publications", code:301},
        "/publications/epitranscriptomic-mechanism-underlies-selective-mrna-translation-remodelling-melanoma": { url: "/publications", code:301},
        "/publications/erap1-promotes-hedgehog-dependent-tumorigenesis-controlling-usp47-mediated-degradation": { url: "/publications", code:301},
        "/publications/erector-spinae-plane-block-versus-thoracic-paravertebral-block-prevention-acute": { url: "/publications", code:301},
        "/publications/erg-transcription-factors-have-splicing-regulatory-function-involving-rbfox2-altered": { url: "/publications", code:301},
        "/publications/erythropoietin-directly-remodels-clonal-composition-murine-hematopoietic-multipotent": { url: "/publications", code:301},
        "/publications/escrt-iii-isoforms-chmp2a-and-chmp2b-display-different-effects-membranes-upon": { url: "/publications", code:301},
        "/publications/escrt-iii-repairs-nuclear-envelope-ruptures-during-cell-migration-limit-dna-damage-and": { url: "/publications", code:301},
        "/publications/esp-eortc-and-euracan-expert-opinion-practical-recommendations-pathological-diagnosis": { url: "/publications", code:301},
        "/publications/european-joint-research-project-uhdpulse-metrology-advanced-radiotherapy-using": { url: "/publications", code:301},
        "/publications/european-mappyacts-trial-precision-medicine-program-pediatric-and-adolescent-patients": { url: "/publications", code:301},
        "/publications/ev-track-transparent-reporting-and-centralizing-knowledge-extracellular-vesicle": { url: "/publications", code:301},
        "/publications/evaluation-association-heterozygous-germline-variants-nthl1-breast-cancer": { url: "/publications", code:301},
        "/publications/evaluation-deep-and-shallow-learning-methods-chemogenomics-prediction-drugs": { url: "/publications", code:301},
        "/publications/ever-evolving-identity-magnetic-nanoparticles-within-human-cells-interplay-endosomal": { url: "/publications", code:301},
        "/publications/evidence-inter-and-intra-keloid-heterogeneity-through-analysis-dermal-fibroblasts-new": { url: "/publications", code:301},
        "/publications/evidence-sars-cov-2-induces-lung-cell-senescence-potential-impact-covid-19-lung": { url: "/publications", code:301},
        "/publications/evolution-and-genomic-signatures-spontaneous-somatic-mutation-drosophila-intestinal": { url: "/publications", code:301},
        "/publications/evolution-tumor-cells-during-asidna-treatment-results-energy-exhaustion-decrease": { url: "/publications", code:301},
        "/publications/evolutionary-emergence-first-animal-organisms-triggered-environmental-mechano": { url: "/publications", code:301},
        "/publications/evolutionary-emergence-first-animal-organisms-triggered-environmental-mechano-0": { url: "/publications", code:301},
        "/publications/excellent-translational-research-oncology-journey-towards-novel-and-more-effective": { url: "/publications", code:301},
        "/publications/excessive-tubulin-polyglutamylation-causes-neurodegeneration-and-perturbs-neuronal": { url: "/publications", code:301},
        "/publications/excitatory-granule-neuron-precursors-orchestrate-laminar-localization-and": { url: "/publications", code:301},
        "/publications/exome-sequencing-reveals-aberrant-signalling-pathways-hallmark-treatment-naive-anal": { url: "/publications", code:301},
        "/publications/expanding-heterochromatin-reveals-discrete-subtelomeric-domains-delimited-chromatin": { url: "/publications", code:301},
        "/publications/expansion-and-activation-cd103-dendritic-cell-progenitors-tumor-site-enhances-tumor": { url: "/publications", code:301},
        "/publications/expeditive-synthesis-potent-c20-epi-amino-derivatives-salinomycin-against-cancer-stem": { url: "/publications", code:301},
        "/publications/experience-francaise-de-lirradiation-corporelle-totale-en-pediatrie-etude-du-comite-de": { url: "/publications", code:301},
        "/publications/experimentally-generated-ground-truth-detecting-cell-types-image-based-immunotherapy": { url: "/publications", code:301},
        "/publications/exploring-isofunctional-molecules-design-benchmark-and-evaluation-prediction": { url: "/publications", code:301},
        "/publications/expression-anti-mullerian-hormone-type-ii-receptor-amhrii-non-gynecological-solid": { url: "/publications", code:301},
        "/publications/expression-level-alpha-synuclein-different-neuronal-populations-primary-determinant": { url: "/publications", code:301},
        "/publications/extracellular-lgals3bp-regulates-neural-progenitor-position-and-relates-human-cortical": { url: "/publications", code:301},
        "/publications/extracellular-vesicles-adipose-stromal-cells-combined-thermoresponsive-hydrogel": { url: "/publications", code:301},
        "/publications/extracellular-vesicles-and-co-isolated-endogenous-retroviruses-differently-affect": { url: "/publications", code:301},
        "/publications/extracellular-vesicles-containing-ace2-efficiently-prevent-infection-sars-cov-2-spike": { url: "/publications", code:301},
        "/publications/extracellular-vesicles-released-allogeneic-human-cardiac-stemprogenitor-cells-part": { url: "/publications", code:301},
        "/publications/extracellular-vesicles-shed-follicular-lymphoma-b-cells-promote-polarization-bone": { url: "/publications", code:301},
        "/publications/extracellular-vesicles-triple-negative-breast-cancer-promote-pro-inflammatory": { url: "/publications", code:301},
        "/publications/extrachromosomal-amplification-mechanisms-glioma-amplified-sequences-multiple": { url: "/publications", code:301},
        "/publications/extracranial-rhabdoid-tumours-results-sfce-series-patients-treated-dose-compression": { url: "/publications", code:301},
        "/publications/ezh12-function-mostly-within-canonical-prc2-and-exhibit-proliferation-dependent": { url: "/publications", code:301},
        "/publications/ezh2-promotes-degradation-stalled-replication-forks-recruiting-mus81-through-histone": { url: "/publications", code:301},
        "/publications/ezrin-enrichment-curved-membranes-requires-specific-conformation-or-interaction": { url: "/publications", code:301},
        "/publications/familial-breast-cancer-and-dna-repair-genes-insights-known-and-novel-susceptibility": { url: "/publications", code:301},
        "/publications/family-vertebrate-specific-polycombs-encoded-lcorlcorl-genes-balance-prc2-subtype": { url: "/publications", code:301},
        "/publications/fancd2-maintains-fork-stability-brca12-deficient-tumors-and-promotes-alternative-end": { url: "/publications", code:301},
        "/publications/fancmc5791ct-nonsense-mutation-rs144567652-induces-exon-skipping-affects-dna-repair": { url: "/publications", code:301},
        "/publications/fast-determination-coarse-grained-cell-anisotropy-and-size-epithelial-tissue-images": { url: "/publications", code:301},
        "/publications/fast-dose-fractionation-using-ultra-short-laser-accelerated-proton-pulses-can-increase": { url: "/publications", code:301},
        "/publications/fast-high-resolution-3d-total-internal-reflection-fluorescence-microscopy-incidence": { url: "/publications", code:301},
        "/publications/fda-approved-drug-auranofin-has-dual-inhibitory-effect-sars-cov-2-entry-and-nf-kb": { url: "/publications", code:301},
        "/publications/feasibility-personalized-screening-and-prevention-recommendations-general-population": { url: "/publications", code:301},
        "/publications/fen1-e359k-germline-mutation-disrupts-fen1-wrn-interaction-and-fen1-gen-activity": { url: "/publications", code:301},
        "/publications/ferrocifen-stealth-lncs-and-conventional-chemotherapy-promising-combination-against": { url: "/publications", code:301},
        "/publications/fgfr3-mutational-activation-can-induce-luminal-papillary-bladder-tumor-formation-and": { url: "/publications", code:301},
        "/publications/fgfr3myc-positive-feedback-loop-provides-new-opportunities-targeted-therapies-bladder": { url: "/publications", code:301},
        "/publications/fibroblast-growth-factor-2-bound-specific-dermal-fibroblast-derived-extracellular": { url: "/publications", code:301},
        "/publications/fibroblast-heterogeneity-tumor-micro-environment-role-immunosuppression-and-new": { url: "/publications", code:301},
        "/publications/first-class-polymerase-theta-inhibitor-selectively-targets-homologous-recombination": { url: "/publications", code:301},
        "/publications/first-evaluation-temporal-and-spatial-fractionation-proton-minibeam-radiation-therapy": { url: "/publications", code:301},
        "/publications/fish-chips-turning-microfluidic-fluorescence-situ-hybridization-quantitative-and": { url: "/publications", code:301},
        "/publications/fission-yeast-neddylation-ligase-dcn1-facilitates-cohesin-cleavage-and-chromosome": { url: "/publications", code:301},
        "/publications/five-gene-hedgehog-signature-developed-patient-preselection-tool-hedgehog-inhibitor": { url: "/publications", code:301},
        "/publications/flagellar-microtubule-doublet-assembly-vitro-reveals-regulatory-role-tubulin-c": { url: "/publications", code:301},
        "/publications/flash-irradiation-spares-lung-progenitor-cells-and-limits-incidence-radio-induced": { url: "/publications", code:301},
        "/publications/flow-variable-pulse-frequencies-accelerates-vascular-recellularization-and-remodeling": { url: "/publications", code:301},
        "/publications/fluid-shear-stress-coupled-narrow-constrictions-induce-cell-type-dependent": { url: "/publications", code:301},
        "/publications/fluorescence-intercalator-displacement-assay-screening-g4-ligands-towards-variety-g": { url: "/publications", code:301},
        "/publications/fluorogenic-pt-complexes-distinguish-quantity-and-folding-behavior-rna-g-quadruplexes": { url: "/publications", code:301},
        "/publications/follicular-lymphoma-triggers-phenotypic-and-functional-remodeling-human-lymphoid": { url: "/publications", code:301},
        "/publications/foxe1-polymorphism-interacts-dietary-iodine-intake-differentiated-thyroid-cancer-risk": { url: "/publications", code:301},
        "/publications/french-early-breast-cancer-cohort-fresh-resource-breast-cancer-research-and": { url: "/publications", code:301},
        "/publications/frequency-and-management-hemorrhagic-malignant-wounds-retrospective-single-center": { url: "/publications", code:301},
        "/publications/frequency-and-prognostic-impact-alk-amplifications-and-mutations-european": { url: "/publications", code:301},
        "/publications/frequency-distribution-second-solid-cancer-locations-relation-irradiated-volume-among": { url: "/publications", code:301},
        "/publications/frequent-pten-genomic-alterations-and-activated-phosphatidylinositol-3-kinase-pathway": { url: "/publications", code:301},
        "/publications/frequent-somatic-mutation-adult-intestinal-stem-cells-drives-neoplasia-and-genetic": { url: "/publications", code:301},
        "/publications/full-length-plasmodium-falciparum-myosin-and-essential-light-chain-pfelc-structures": { url: "/publications", code:301},
        "/publications/functional-activity-h33-histone-chaperone-complex-hira-requires-trimerization-hira": { url: "/publications", code:301},
        "/publications/functional-and-conformational-impact-cancer-associated-sf3b1-mutations-depends": { url: "/publications", code:301},
        "/publications/functional-characterization-5p1533-risk-locus-uveal-melanoma-reveals-rs452384": { url: "/publications", code:301},
        "/publications/functional-characterization-histone-chaperones-using-snap-tag-based-imaging-assess-de": { url: "/publications", code:301},
        "/publications/functional-characterization-multi-cancer-risk-locus-chr5p1533-reveals-regulation-tert": { url: "/publications", code:301},
        "/publications/functional-characterization-n-acetylmuramyl-l-alanine-amidase-ami1-mycobacterium": { url: "/publications", code:301},
        "/publications/functional-genomics-analysis-reveals-myc-signature-associated-poor-clinical-prognosis": { url: "/publications", code:301},
        "/publications/functional-landscape-pcgf-proteins-reveals-both-ring1ab-dependent-and-ring1ab": { url: "/publications", code:301},
        "/publications/fuzzy-tandem-repeats-containing-p53-response-elements-may-define-species-specific-p53": { url: "/publications", code:301},
        "/publications/g-protein-coupled-receptors-radioiodine-refractory-thyroid-cancer-era-precision": { url: "/publications", code:301},
        "/publications/g-quadruplex-binding-platinum-complex-induces-cancer-mitochondrial-dysfunction-through": { url: "/publications", code:301},
        "/publications/g4access-identifies-g-quadruplexes-and-their-associations-open-chromatin-and": { url: "/publications", code:301},
        "/publications/gc-content-not-nucleosome-positioning-directly-contributes-intron-splicing-efficiency": { url: "/publications", code:301},
        "/publications/gene-and-pathway-level-analyses-icogs-variants-highlight-novel-signaling-pathways": { url: "/publications", code:301},
        "/publications/gene-copy-number-changes-and-chromosomal-instability-induced-aneuploidy-confer": { url: "/publications", code:301},
        "/publications/gene-expression-profiling-score-prediction-outcome-patients-follicular-lymphoma": { url: "/publications", code:301},
        "/publications/gene-network-and-biological-pathways-associated-susceptibility-differentiated-thyroid": { url: "/publications", code:301},
        "/publications/gene-replacement-strategies-validate-use-functional-tags-centromeric-chromatin-and": { url: "/publications", code:301},
        "/publications/generation-schwann-cell-derived-melanocytes-hpscs-identifies-pro-metastatic-factors": { url: "/publications", code:301},
        "/publications/genetic-alterations-sugp1-mimic-mutant-sf3b1-splice-pattern-lung-adenocarcinoma-and": { url: "/publications", code:301},
        "/publications/genetic-evidence-involvement-mismatch-repair-proteins-pms2-and-mlh3-late-step": { url: "/publications", code:301},
        "/publications/genetic-inhibition-card9-accelerates-development-atherosclerosis-mice-through-cd36": { url: "/publications", code:301},
        "/publications/geniac-automatic-configuration-generator-and-installer-nextflow-pipelines-0": { url: "/publications", code:301},
        "/publications/genome-wide-association-study-identifies-multiple-new-loci-associated-ewing-sarcoma": { url: "/publications", code:301},
        "/publications/genome-wide-identification-genic-and-intergenic-neuronal-dna-regions-bound-tau-protein": { url: "/publications", code:301},
        "/publications/genome-wide-mapping-human-dna-replication-optical-replication-mapping-supports": { url: "/publications", code:301},
        "/publications/genome-wide-measurement-dna-replication-fork-directionality-and-quantification-dna": { url: "/publications", code:301},
        "/publications/genome-wide-natural-variation-h3k27me3-selectively-marks-genes-predicted-be-important": { url: "/publications", code:301},
        "/publications/genome-wide-rnai-screen-identifies-regulators-cholesterol-modified-hedgehog-secretion": { url: "/publications", code:301},
        "/publications/genomic-copy-number-profiling-using-circulating-free-tumor-dna-highlights": { url: "/publications", code:301},
        "/publications/genuage-visualize-and-analyze-multidimensional-single-molecule-point-cloud-data": { url: "/publications", code:301},
        "/publications/germline-mutation-mdm4-major-p53-regulator-familial-syndrome-defective-telomere": { url: "/publications", code:301},
        "/publications/glial-signature-and-wnt7-signaling-regulate-glioma-vascular-interactions-and-tumor": { url: "/publications", code:301},
        "/publications/glutamate-spillover-c-elegans-triggers-repetitive-behavior-through-presynaptic": { url: "/publications", code:301},
        "/publications/glycosylation-dependent-ifn-gr-partitioning-lipid-and-actin-nanodomains-critical-jak": { url: "/publications", code:301},
        "/publications/gm-csf-activated-human-dendritic-cells-promote-type-1-t-follicular-helper-cell": { url: "/publications", code:301},
        "/publications/gold-nanoparticles-incorporated-nematic-gel-micropillars-capable-laser-actuation-room": { url: "/publications", code:301},
        "/publications/golgi-maturation-dependent-glycoenzyme-recycling-controls-glycosphingolipid": { url: "/publications", code:301},
        "/publications/gpr1zdbf2-locus-provides-new-paradigms-transient-and-dynamic-genomic-imprinting": { url: "/publications", code:301},
        "/publications/gradient-developmental-and-injury-response-transcriptional-states-defines-functional": { url: "/publications", code:301},
        "/publications/gradient-glycolytic-activity-coordinates-fgf-and-wnt-signaling-during-elongation-body": { url: "/publications", code:301},
        "/publications/gtpase-rab8-differentially-controls-long-and-short-range-activity-hedgehog-morphogen": { url: "/publications", code:301},
        "/publications/guardian-genome-revisited-p53-downregulates-genes-required-telomere-maintenance-dna": { url: "/publications", code:301},
        "/publications/guide-pratique-pour-la-radiotherapie-des-hemopathies-malignes-dans-la-situation": { url: "/publications", code:301},
        "/publications/guided-tour-selected-image-processing-and-analysis-methods-fluorescence-and-electron": { url: "/publications", code:301},
        "/publications/guidelines-anaesthesia-context-covid-19-pandemic": { url: "/publications", code:301},
        "/publications/guidelines-use-and-interpretation-assays-monitoring-autophagy-4th-edition1": { url: "/publications", code:301},
        "/publications/halofuginone-inhibits-tgf-bbmp-signaling-and-combination-zoledronic-acid-enhances": { url: "/publications", code:301},
        "/publications/haploinsufficiency-ikzf1-ikaros-tumor-suppressor-gene-cooperates-bcr-abl-transgenic": { url: "/publications", code:301},
        "/publications/harnessing-emissive-guanine-surrogate-design-small-molecule-fluorescent-chemosensors": { url: "/publications", code:301},
        "/publications/helping-patients-communicate-oncologists-when-cancer-treatment-resistance-occurs": { url: "/publications", code:301},
        "/publications/hhip-as1-lncrna-promotes-tumorigenicity-through-stabilization-dynein-complex-1-human": { url: "/publications", code:301},
        "/publications/high-content-drug-screening-zebrafish-xenografts-reveals-high-efficacy-dual-mcl-1bcl": { url: "/publications", code:301},
        "/publications/high-dose-proton-therapy-and-tomotherapy-treatment-sacral-chordoma-retrospective": { url: "/publications", code:301},
        "/publications/high-fidelity-3d-live-cell-nanoscopy-through-data-driven-enhanced-super-resolution": { url: "/publications", code:301},
        "/publications/high-grade-childhood-intra-parenchymal-brain-tumor-clustering-atrt-and-expanding": { url: "/publications", code:301},
        "/publications/high-resolution-structures-actomyosin-v-complex-three-nucleotide-states-provide": { url: "/publications", code:301},
        "/publications/high-resolution-visualization-h3-variants-during-replication-reveals-their-controlled": { url: "/publications", code:301},
        "/publications/high-risk-retinoblastoma-subtype-stemness-features-dedifferentiated-cone-states-and": { url: "/publications", code:301},
        "/publications/high-throughput-differentiation-embryonic-stem-cells-cardiomyocytes-microfabricated": { url: "/publications", code:301},
        "/publications/high-throughput-drug-screening-identifies-pazopanib-and-clofilium-tosylate-promising": { url: "/publications", code:301},
        "/publications/high-throughput-large-scale-microfluidic-assembly-iron-oxide-nanoflowersps-b-paa": { url: "/publications", code:301},
        "/publications/high-throughput-single-cell-chip-seq-identifies-heterogeneity-chromatin-states-breast": { url: "/publications", code:301},
        "/publications/high-yap-and-mll1-promote-persistent-regenerative-cell-state-induced-notch-signaling": { url: "/publications", code:301},
        "/publications/highly-efficient-radiosensitization-human-glioblastoma-and-lung-cancer-cells-g": { url: "/publications", code:301},
        "/publications/hira-supports-hepatitis-b-virus-minichromosome-establishment-and-transcriptional": { url: "/publications", code:301},
        "/publications/histone-chaperone-caf-1-cooperates-dna-methyltransferases-maintain-cd4-silencing": { url: "/publications", code:301},
        "/publications/histone-variant-h33-residue-s31-essential-xenopus-gastrulation-regardless-deposition": { url: "/publications", code:301},
        "/publications/histone-variants-h2az-and-h33-coordinately-regulate-prc2-dependent-h3k27me3-deposition": { url: "/publications", code:301},
        "/publications/hlh-2e2a-expression-links-stochastic-and-deterministic-elements-cell-fate-decision": { url: "/publications", code:301},
        "/publications/homeotropically-aligned-main-chain-and-side-liquid-crystalline-elastomer-films-high": { url: "/publications", code:301},
        "/publications/homologous-recombination-deficiency-derived-whole-genome-sequencing-predicts-platinum": { url: "/publications", code:301},
        "/publications/homologous-recombination-deficiency-exploiting-fundamental-vulnerability-ovarian": { url: "/publications", code:301},
        "/publications/homologous-recombination-restarts-blocked-replication-forks-expense-genome": { url: "/publications", code:301},
        "/publications/homophilic-interaction-and-deformation-e-cadherin-and-cadherin-7-probed-single": { url: "/publications", code:301},
        "/publications/homosalate-boosts-release-tumour-derived-extracellular-vesicles-protection-against": { url: "/publications", code:301},
        "/publications/hormad1-overexpression-predicts-response-anthracycline-cyclophosphamide-and-survival": { url: "/publications", code:301},
        "/publications/hotair-lncrna-promotes-epithelial-mesenchymal-transition-redistributing-lsd1": { url: "/publications", code:301},
        "/publications/how-do-mechanics-guide-fibroblast-activity-complex-disruptions-during-emphysema-shape": { url: "/publications", code:301},
        "/publications/how-myosin-vi-traps-its-state-activated-and-dimerizes-0": { url: "/publications", code:301},
        "/publications/hspcs-display-within-family-homogeneity-differentiation-and-proliferation-despite": { url: "/publications", code:301},
        "/publications/human-aortic-valve-interstitial-cells-display-proangiogenic-properties-during-calcific": { url: "/publications", code:301},
        "/publications/human-cytomegalovirus-infection-changes-pattern-surface-markers-small-extracellular": { url: "/publications", code:301},
        "/publications/human-cytomegalovirus-modifies-placental-small-extracellular-vesicle-composition": { url: "/publications", code:301},
        "/publications/human-dominant-disease-genes-are-enriched-paralogs-originating-whole-genome": { url: "/publications", code:301},
        "/publications/human-escrt-iii-polymers-assemble-positively-curved-membranes-and-induce-helical": { url: "/publications", code:301},
        "/publications/human-lymphoid-organ-cdc2-and-macrophages-play-complementary-roles-t-follicular-helper": { url: "/publications", code:301},
        "/publications/human-papilloma-virus-hpv-integration-signature-cervical-cancer-identification-macrod2": { url: "/publications", code:301},
        "/publications/human-papilloma-virus-integration-sites-and-genomic-signatures-head-and-neck-squamous": { url: "/publications", code:301},
        "/publications/human-primary-immune-cells-exhibit-distinct-mechanical-properties-are-modified": { url: "/publications", code:301},
        "/publications/human-regulator-telomere-elongation-helicase-1-rtel1-required-nuclear-and-cytoplasmic": { url: "/publications", code:301},
        "/publications/human-rtel1-associates-poldip3-facilitate-responses-replication-stress-and-r-loop": { url: "/publications", code:301},
        "/publications/human-septin-octamer-complex-sensitive-membrane-curvature-drives-membrane-deformation": { url: "/publications", code:301},
        "/publications/human-telomeric-position-effect-determined-chromosomal-context-and-telomeric-chromatin": { url: "/publications", code:301},
        "/publications/humanized-mice-are-precious-tools-preclinical-evaluation-car-t-and-car-nk-cell": { url: "/publications", code:301},
        "/publications/huntingtin-required-epithelial-polarity-through-rab11a-mediated-apical-trafficking": { url: "/publications", code:301},
        "/publications/ibrutinib-monotherapy-relapse-or-refractory-primary-cns-lymphoma-and-primary": { url: "/publications", code:301},
        "/publications/id1-and-id3-are-regulated-through-matrix-assisted-autocrine-bmp-signaling-and": { url: "/publications", code:301},
        "/publications/identification-human-estrogen-receptor-tetrapeptidic-fragment-dual-antiproliferative": { url: "/publications", code:301},
        "/publications/identification-microrna-clusters-cooperatively-acting-epithelial-mesenchymal": { url: "/publications", code:301},
        "/publications/identification-new-driver-and-passenger-mutations-within-apobec-induced-hotspot": { url: "/publications", code:301},
        "/publications/identification-new-potent-acetylcholinesterase-inhibitors-using-virtual-screening-and": { url: "/publications", code:301},
        "/publications/identification-ohnolog-genes-originating-whole-genome-duplication-early-vertebrates": { url: "/publications", code:301},
        "/publications/identification-pyrrolopyrimidine-derivative-pp-13-novel-microtubule-destabilizing": { url: "/publications", code:301},
        "/publications/identification-tissue-origin-and-guided-therapeutic-applications-cancers-unknown": { url: "/publications", code:301},
        "/publications/igf1r-activation-and-vitro-antiproliferative-efficacy-igf1r-inhibitor-are-inversely": { url: "/publications", code:301},
        "/publications/il2anti-il2-complex-combined-ctla-4-not-pd-1-blockade-rescues-antitumor-nk-cell": { url: "/publications", code:301},
        "/publications/image-biomarker-standardization-initiative-standardized-quantitative-radiomics-high": { url: "/publications", code:301},
        "/publications/imaging-and-multi-omics-datasets-converge-define-different-neural-progenitor-origins": { url: "/publications", code:301},
        "/publications/imaging-newly-synthesized-and-old-histone-variant-dynamics-dependent-chaperones-using": { url: "/publications", code:301},
        "/publications/imaging-response-dna-damage-heterochromatin-domains-reveals-core-principles": { url: "/publications", code:301},
        "/publications/immune-checkpoint-inhibition-anti-pdcd1-anti-pd1-monoclonal-antibody-has-significant": { url: "/publications", code:301},
        "/publications/immune-reprogramming-precision-photodynamic-therapy-peritoneal-metastasis-scalable": { url: "/publications", code:301},
        "/publications/impact-g-quadruplex-structures-and-intronic-polymorphisms-rs17878362-and-rs1642785": { url: "/publications", code:301},
        "/publications/impact-obesity-surgical-and-oncologic-outcomes-patients-endometrial-cancer-treated": { url: "/publications", code:301},
        "/publications/impact-ras-mutations-metastatic-colorectal-cancer-after-potentially-curative-resection": { url: "/publications", code:301},
        "/publications/impaired-ifn-production-plasmacytoid-dendritic-cells-favors-regulatory-t-cell": { url: "/publications", code:301},
        "/publications/impaired-prc2-activity-promotes-transcriptional-instability-and-favors-breast": { url: "/publications", code:301},
        "/publications/improved-3d-tumour-definition-and-quantification-uptake-simulated-lung-tumours-using": { url: "/publications", code:301},
        "/publications/improved-strategy-easy-process-monitoring-and-advanced-purification-recombinant": { url: "/publications", code:301},
        "/publications/improvement-anti-proliferative-activity-peptide-era17p-mcf-7-breast-cancer-cells-using": { url: "/publications", code:301},
        "/publications/inactive-x-chromosome-epigenetically-unstable-and-transcriptionally-labile-breast": { url: "/publications", code:301},
        "/publications/increased-cardiac-risk-after-second-malignant-neoplasm-among-childhood-cancer": { url: "/publications", code:301},
        "/publications/increased-circulating-cd62e-endothelial-extracellular-vesicles-predict-severity-and": { url: "/publications", code:301},
        "/publications/increased-pdt-efficacy-when-associated-nitroglycerin-study-retinoblastoma-xenografted": { url: "/publications", code:301},
        "/publications/increased-risk-bone-tumors-after-growth-hormone-treatment-childhood-population-based": { url: "/publications", code:301},
        "/publications/increasing-ergosterol-levels-delays-formin-dependent-assembly-f-actin-cables-and": { url: "/publications", code:301},
        "/publications/independent-component-analysis-uncovers-landscape-bladder-tumor-transcriptome-and": { url: "/publications", code:301},
        "/publications/inducible-system-silencing-establishment-reveals-stepwise-mechanism-which-anchoring": { url: "/publications", code:301},
        "/publications/induction-emt-twist-proteins-collateral-effect-tumor-promoting-inactivation-premature": { url: "/publications", code:301},
        "/publications/inferring-gene-networks-bone-marrow-hematopoietic-stem-cell-supporting-stromal-niche": { url: "/publications", code:301},
        "/publications/inferring-postimplant-dose-distribution-salvage-permanent-prostate-implant-ppi-after": { url: "/publications", code:301},
        "/publications/infiltration-cxcl10-secreting-macrophages-associated-antitumor-immunity-and-response": { url: "/publications", code:301},
        "/publications/influence-external-forces-actin-dependent-t-cell-protrusions-during-immune-synapse": { url: "/publications", code:301},
        "/publications/influence-spatial-and-temporal-resolutions-analysis-cell-cell-interaction-systematic": { url: "/publications", code:301},
        "/publications/inherited-duplications-ppp2r3b-predispose-nevi-and-melanoma-c21orf91-driven": { url: "/publications", code:301},
        "/publications/inhibition-hiv-infection-structural-proteins-inner-nuclear-membrane-associated-reduced": { url: "/publications", code:301},
        "/publications/inhibition-mtor-downregulates-expression-dna-repair-proteins-and-highly-efficient": { url: "/publications", code:301},
        "/publications/innate-immune-signals-induce-anterograde-endosome-transport-promoting-mhc-class-i": { url: "/publications", code:301},
        "/publications/innate-lymphoid-cells-nk-and-cytotoxic-ilc3-subsets-infiltrate-metastatic-breast": { url: "/publications", code:301},
        "/publications/innate-t-cell-subset-commitment-murine-thymus-independent-tcr-characteristics-and": { url: "/publications", code:301},
        "/publications/ino80-chromatin-remodeler-facilitates-release-rna-polymerase-ii-chromatin-ubiquitin": { url: "/publications", code:301},
        "/publications/insights-animal-septins-using-recombinant-human-septin-octamers-distinct-sept9": { url: "/publications", code:301},
        "/publications/insights-early-gene-regulatory-network-controlling-neural-crest-and-placode-fate": { url: "/publications", code:301},
        "/publications/insights-molecular-architecture-and-histone-h3-h4-deposition-mechanism-yeast-chromatin": { url: "/publications", code:301},
        "/publications/integrated-droplet-microfluidic-device-magnetic-particles-handling-application-dna": { url: "/publications", code:301},
        "/publications/integrated-genomic-analysis-identifies-driver-genes-and-cisplatin-resistant-progenitor": { url: "/publications", code:301},
        "/publications/integrated-high-throughput-screening-and-large-scale-isobolographic-analysis": { url: "/publications", code:301},
        "/publications/integration-cardiac-actin-mutants-causing-hypertrophic-pa295s-and-dilated": { url: "/publications", code:301},
        "/publications/integrative-analysis-multi-platform-reverse-phase-protein-array-data-pharmacodynamic": { url: "/publications", code:301},
        "/publications/integrative-analysis-post-translational-histone-modifications-marine-diatom": { url: "/publications", code:301},
        "/publications/integrative-identification-non-coding-regulatory-regions-driving-metastatic-prostate": { url: "/publications", code:301},
        "/publications/intelligence-artificielle-quels-services-quelles-applications-quels-resultats-et": { url: "/publications", code:301},
        "/publications/interaction-between-igf2-pi3k-axis-and-cancer-associated-fibroblasts-promotes-anal": { url: "/publications", code:301},
        "/publications/interaction-between-molecular-subtypes-and-stromal-immune-infiltration-and-after": { url: "/publications", code:301},
        "/publications/interaction-functionalized-naphthalenophanes-abasic-sites-dna-dna-cleavage-dna": { url: "/publications", code:301},
        "/publications/interferon-l-and-interleukin-22-act-synergistically-induction-interferon-stimulated": { url: "/publications", code:301},
        "/publications/intergroupe-francophone-de-cancerologie-thoracique-societe-de-pneumologie-de-langue": { url: "/publications", code:301},
        "/publications/interleukin-8-release-endothelial-colony-forming-cells-isolated-idiopathic-pulmonary": { url: "/publications", code:301},
        "/publications/international-development-four-eortc-disease-specific-quality-life-questionnaires": { url: "/publications", code:301},
        "/publications/international-phase-4-validation-study-eortc-qlq-swb32-stand-alone-measure-spiritual": { url: "/publications", code:301},
        "/publications/interplay-between-calcium-and-sarcomeres-directs-cardiomyocyte-maturation-during": { url: "/publications", code:301},
        "/publications/interplay-between-cellular-uptake-intracellular-localization-and-cell-death-mechanism": { url: "/publications", code:301},
        "/publications/interplay-between-diabetes-and-pancreatic-ductal-adenocarcinoma-and-insulinoma-role": { url: "/publications", code:301},
        "/publications/intestinal-inhibition-atg7-prevents-tumour-initiation-through-microbiome-influenced": { url: "/publications", code:301},
        "/publications/intra-and-extra-cranial-bcor-itd-tumours-are-separate-entities-within-bcor-rearranged": { url: "/publications", code:301},
        "/publications/intravital-imaging-human-melanoma-cells-mouse-ear-skin-two-photon-excitation": { url: "/publications", code:301},
        "/publications/intrinsically-disordered-region-osbp-acts-entropic-barrier-control-protein-dynamics": { url: "/publications", code:301},
        "/publications/intronic-polyadenylation-isoforms-5-part-genes-constitute-source-microproteins-and-are": { url: "/publications", code:301},
        "/publications/inverse-blebs-operate-hydraulic-pumps-during-mouse-blastocyst-formation-0": { url: "/publications", code:301},
        "/publications/investigation-dna-repair-related-snps-underlying-susceptibility-papillary-thyroid": { url: "/publications", code:301},
        "/publications/irm-du-sein-dans-le-carcinome-lobulaire-infiltrant-aspects-diagnostiques-bilan": { url: "/publications", code:301},
        "/publications/iron-sensitive-prodrugs-trigger-active-ferroptosis-drug-tolerant-pancreatic-cancer": { url: "/publications", code:301},
        "/publications/irradiation-flash-unique-sparing-memory-mice-after-whole-brain-irradiation-dose-rates": { url: "/publications", code:301},
        "/publications/irradiation-prophylactique-des-orifices-de-drainage-dans-le-mesotheliome-pleural-un": { url: "/publications", code:301},
        "/publications/isotopic-tracing-reveals-single-cell-assimilation-macroalgal-polysaccharide-few-marine": { url: "/publications", code:301},
        "/publications/iterative-bayesian-denoising-based-variance-stabilization-using-contourlet-transform": { url: "/publications", code:301},
        "/publications/jarid2-binds-mono-ubiquitylated-h2a-lysine-119-mediate-crosstalk-between-polycomb": { url: "/publications", code:301},
        "/publications/jarid2-methylation-prc2-complex-regulates-h3k27me3-deposition-during-cell": { url: "/publications", code:301},
        "/publications/key-interactions-immunoglobulin-structure-apo-neocarzinostatin-evidence-nuclear": { url: "/publications", code:301},
        "/publications/kinesin-14s-and-microtubule-dynamics-define-fission-yeast-mitotic-and-meiotic-spindle": { url: "/publications", code:301},
        "/publications/kinesin-5-independent-mitotic-spindle-assembly-requires-antiparallel-microtubule": { url: "/publications", code:301},
        "/publications/kinesin-6-klp9-orchestrates-spindle-elongation-regulating-microtubule-sliding-and": { url: "/publications", code:301},
        "/publications/kinesin-6-regulates-cell-size-dependent-spindle-elongation-velocity-keep-mitosis": { url: "/publications", code:301},
        "/publications/kinome-sirna-screen-identifies-hgs-potential-target-liver-cancers-oncogenic-mutations": { url: "/publications", code:301},
        "/publications/la-chromatine-bivalente-preserve-la-plasticite-transcriptionnelle": { url: "/publications", code:301},
        "/publications/la-cryo-microscopie-electronique-revele-une-nouvelle-vision-de-la-cellule-et-de-ses": { url: "/publications", code:301},
        "/publications/la-methylation-des-arnm-une-nouvelle-modalite-de-regulation-de-la-presentation-croisee": { url: "/publications", code:301},
        "/publications/la-socio-esthetique-en-oncologie-impact-des-soins-de-beaute-et-de-bien-etre-evalue": { url: "/publications", code:301},
        "/publications/lack-wip-binding-actin-results-impaired-b-cell-migration-and-altered-humoral-immune": { url: "/publications", code:301},
        "/publications/laminin-binding-integrins-are-essential-maintenance-functional-mammary-secretory": { url: "/publications", code:301},
        "/publications/large-collection-integrated-genomically-characterized-patient-derived-xenografts": { url: "/publications", code:301},
        "/publications/large-scale-pan-cancer-analysis-reveals-broad-prognostic-association-between-tgf-b": { url: "/publications", code:301},
        "/publications/larret-de-la-ranitidine-iv-ou-son-passage-os-sont-ils-possibles-chez-lenfant-en": { url: "/publications", code:301},
        "/publications/late-endocytic-rab39a-gtpase-regulates-multivesicular-bodies-chlamydial-inclusion": { url: "/publications", code:301},
        "/publications/layer-layer-coated-pla-nanoparticles-oppositely-charged-b-cyclodextrin-polymer": { url: "/publications", code:301},
        "/publications/le-cytomegalovirus-est-il-responsable-des-carcinomes-mucoepidermoides-des-glandes": { url: "/publications", code:301},
        "/publications/le-monde-des-jumeaux-aspects-epidemiologiques-et-genetiques-enjeux-obstetricaux": { url: "/publications", code:301},
        "/publications/leukemia-initiating-cell-activity-requires-calcineurin-t-cell-acute-lymphoblastic": { url: "/publications", code:301},
        "/publications/leukocyte-derived-ifn-ab-and-epithelial-ifn-l-constitute-compartmentalized-mucosal": { url: "/publications", code:301},
        "/publications/life-long-epigenetic-programming-cortical-architecture-maternal-western-diet-during": { url: "/publications", code:301},
        "/publications/light-responsive-microstructured-surfaces-liquid-crystalline-network-shape-memory-and": { url: "/publications", code:301},
        "/publications/limited-resection-versus-pancreaticoduodenectomy-duodenal-gastrointestinal-stromal": { url: "/publications", code:301},
        "/publications/linc-complex-lis1-interplay-controls-mt1-mmp-matrix-digest-demand-response-confined": { url: "/publications", code:301},
        "/publications/lineage-tracing-notch1-expressing-cells-intestinal-tumours-reveals-distinct-population": { url: "/publications", code:301},
        "/publications/lingo-1-regulates-oligodendrocyte-differentiation-through-cytoplasmic-gelsolin": { url: "/publications", code:301},
        "/publications/lipid-polyunsaturated-fatty-acid-chains-mouse-kidneys-were-increased-within-5-min": { url: "/publications", code:301},
        "/publications/live-cell-imaging-reveals-square-shape-spindles-and-long-mitosis-duration-silkworm": { url: "/publications", code:301},
        "/publications/live-cell-micromanipulation-genomic-locus-reveals-interphase-chromatin-mechanics-0": { url: "/publications", code:301},
        "/publications/liver-metastasis-facilitated-adherence-circulating-tumor-cells-vascular-fibronectin": { url: "/publications", code:301},
        "/publications/lncrna-regulates-alternative-splicing-establishment-splicing-specific-chromatin": { url: "/publications", code:301},
        "/publications/local-insulin-reservoir-drosophila-alpha-cell-homologs-ensures-developmental": { url: "/publications", code:301},
        "/publications/local-light-activation-src-oncoprotein-epithelial-monolayer-promotes-collective": { url: "/publications", code:301},
        "/publications/locoregional-delivery-car-t-cells-cerebrospinal-fluid-treatment-metastatic": { url: "/publications", code:301},
        "/publications/locoregionally-administered-b7-h3-targeted-car-t-cells-treatment-atypical": { url: "/publications", code:301},
        "/publications/long-term-mortality-second-malignant-neoplasms-5-year-survivors-solid-childhood-tumors": { url: "/publications", code:301},
        "/publications/long-term-t-cell-reconstitution-after-hematopoietic-stem-cell-transplantation-primary": { url: "/publications", code:301},
        "/publications/long-time-diffusion-and-energy-transfer-polydisperse-mixtures-particles-different": { url: "/publications", code:301},
        "/publications/longitudinal-mouse-pet-imaging-reliable-method-estimating-binding-parameters-without": { url: "/publications", code:301},
        "/publications/lorganisation-et-le-suivi-long-terme-des-adultes-traites-pour-un-cancer-dans-lenfance": { url: "/publications", code:301},
        "/publications/loss-cytidine-deaminase-expression-potential-attempt-counteract-process-carcinogenesis": { url: "/publications", code:301},
        "/publications/loss-deglutamylase-ccp5-perturbs-multiple-steps-spermatogenesis-and-leads-male": { url: "/publications", code:301},
        "/publications/loss-metastasis-suppressor-nme1-not-its-highly-related-isoform-nme2-induces-hybrid": { url: "/publications", code:301},
        "/publications/loss-sdhb-promotes-dysregulated-iron-homeostasis-oxidative-stress-and-sensitivity": { url: "/publications", code:301},
        "/publications/loss-stress-sensor-gadd45a-promotes-stem-cell-activity-and-ferroptosis-resistance": { url: "/publications", code:301},
        "/publications/low-frequency-variation-near-common-germline-susceptibility-loci-are-associated-risk": { url: "/publications", code:301},
        "/publications/low-intensity-pulsed-ultrasound-mediated-blood-brain-barrier-opening-increases-anti": { url: "/publications", code:301},
        "/publications/low-level-fibrillarin-ribosome-biogenesis-factor-new-independent-marker-poor-outcome": { url: "/publications", code:301},
        "/publications/low-retinal-toxicity-intravitreal-carboplatin-associated-good-retinal-tumour-control": { url: "/publications", code:301},
        "/publications/luminal-progenitors-undergo-partial-epithelial-mesenchymal-transition-onset-basal": { url: "/publications", code:301},
        "/publications/lung-organotypic-slices-enable-rapid-quantification-acute-radiotherapy-induced": { url: "/publications", code:301},
        "/publications/lymph-node-metastases-can-invade-local-blood-vessels-exit-node-and-colonize-distant": { url: "/publications", code:301},
        "/publications/lymphatic-endothelial-cells-promote-glioblastoma-stem-cell-growth-through-cytokine": { url: "/publications", code:301},
        "/publications/lymphotoxin-b-receptor-microenvironmental-cells-promotes-development-t-cell-acute": { url: "/publications", code:301},
        "/publications/lysate-based-pipeline-characterize-microtubule-associated-proteins-uncovers-unique": { url: "/publications", code:301},
        "/publications/lysosomal-membrane-proteins-lamp1-and-limp2-are-segregated-golgi-apparatus": { url: "/publications", code:301},
        "/publications/macropinocytosis-overcomes-directional-bias-dendritic-cells-due-hydraulic-resistance": { url: "/publications", code:301},
        "/publications/maintenance-heterochromatin-large-subunit-caf-1-replication-coupled-histone-chaperone": { url: "/publications", code:301},
        "/publications/major-b-catenine-cadherin-junctional-binding-site-primary-molecular-mechano": { url: "/publications", code:301},
        "/publications/mapping-histone-variant-genomic-distribution-exploiting-snap-tag-labeling-follow": { url: "/publications", code:301},
        "/publications/mass-spectrometry-based-imaging-techniques-iodine-127-and-iodine-129-detection-and": { url: "/publications", code:301},
        "/publications/massive-intracellular-remodeling-cus-nanomaterials-produces-nontoxic-bioengineered": { url: "/publications", code:301},
        "/publications/maternal-lsd1kdm1a-essential-regulator-chromatin-and-transcription-landscapes-during": { url: "/publications", code:301},
        "/publications/mathematical-model-circadian-clock-and-drug-pharmacology-optimize-irinotecan": { url: "/publications", code:301},
        "/publications/matrix-architecture-defines-preferential-localization-and-migration-t-cells-stroma": { url: "/publications", code:301},
        "/publications/mbrs-47-rapid-molecular-subgrouping-medulloblastoma-based-dna-methylation-nanopore": { url: "/publications", code:301},
        "/publications/mdm4-loss-mice-expressing-p53-hypomorph-alters-tumor-spectrum-without-improving": { url: "/publications", code:301},
        "/publications/mechanical-compartmentalization-intestinal-organoid-enables-crypt-folding-and": { url: "/publications", code:301},
        "/publications/mechanical-signals-trigger-myosin-ii-redistribution-and-mesoderm-invagination": { url: "/publications", code:301},
        "/publications/mechanics-human-embryo-compaction-0": { url: "/publications", code:301},
        "/publications/mechanism-small-molecule-inhibition-plasmodium-falciparum-myosin-informs-antimalarial": { url: "/publications", code:301},
        "/publications/mechanistic-and-structural-basis-activation-cardiac-myosin-force-production-omecamtiv": { url: "/publications", code:301},
        "/publications/mechano-biochemical-marine-stimulation-inversion-gastrulation-and-endomesoderm": { url: "/publications", code:301},
        "/publications/mechanosensitive-caveolae-invadosome-interplay-drives-matrix-remodelling-cancer-cell": { url: "/publications", code:301},
        "/publications/mechanotransduction-mechanically-coupled-pulsating-cells-transition-collective": { url: "/publications", code:301},
        "/publications/mechanotransductive-cascade-myo-ii-dependent-mesoderm-and-endoderm-invaginations": { url: "/publications", code:301},
        "/publications/medullary-breast-carcinoma-triple-negative-breast-cancer-associated-bclg": { url: "/publications", code:301},
        "/publications/meiotic-xpf-ercc1-complex-recognizes-joint-molecule-recombination-intermediates": { url: "/publications", code:301},
        "/publications/melanosome-distribution-keratinocytes-different-skin-types-melanosome-clusters-are-not": { url: "/publications", code:301},
        "/publications/met-functions-tumour-progression-and-therapy-resistance-are-repressed-intronic": { url: "/publications", code:301},
        "/publications/metal-free-activation-csp3-h-bond-and-practical-and-rapid-synthesis-privileged-1": { url: "/publications", code:301},
        "/publications/metastase-choroidienne-dun-adenocarcinome-pulmonaire-se-presentant-comme-une-masse": { url: "/publications", code:301},
        "/publications/metastasis-suppressor-nme1-controls-invasive-switch-breast-cancer-regulating-mt1-mmp": { url: "/publications", code:301},
        "/publications/methanol-induces-cytosolic-calcium-variations-membrane-depolarization-and-ethylene": { url: "/publications", code:301},
        "/publications/methods-separation-and-characterization-extracellular-vesicles-results-worldwide": { url: "/publications", code:301},
        "/publications/methyl-branched-lipids-promote-membrane-adsorption-synuclein-enhancing-shallow-lipid": { url: "/publications", code:301},
        "/publications/mice-engineered-obligatory-mdm4-exon-skipping-express-higher-levels-mdm4-s-isoform": { url: "/publications", code:301},
        "/publications/micropipette-force-probe-quantify-single-cell-force-generation-application-t-cell": { url: "/publications", code:301},
        "/publications/microrna-21-deficiency-alters-survival-ly-6c-lo-monocytes-apoe-mice-and-reduces-early": { url: "/publications", code:301},
        "/publications/microtubules-restrict-f-actin-polymerization-immune-synapse-gef-h1-maintain-polarity": { url: "/publications", code:301},
        "/publications/microvilli-derived-extracellular-vesicles-carry-hedgehog-morphogenic-signals": { url: "/publications", code:301},
        "/publications/minimal-information-studies-extracellular-vesicles-2018-misev2018-position-statement": { url: "/publications", code:301},
        "/publications/minimum-spanning-vs-principal-trees-structured-approximations-multi-dimensional": { url: "/publications", code:301},
        "/publications/mir200-regulated-cxcl12b-promotes-fibroblast-heterogeneity-and-immunosuppression": { url: "/publications", code:301},
        "/publications/mirna-211-maintains-metabolic-homeostasis-medulloblastoma-through-its-target-gene-long": { url: "/publications", code:301},
        "/publications/mistimed-origin-licensing-and-activation-stabilize-common-fragile-sites-under-tight": { url: "/publications", code:301},
        "/publications/modeling-signaling-pathways-biology-maboss-one-single-cell-dynamic-population": { url: "/publications", code:301},
        "/publications/modelling-immune-checkpoint-network-explains-synergistic-effects-combined-immune": { url: "/publications", code:301},
        "/publications/models-including-pathological-and-radiomic-features-vs-clinical-models-predicting": { url: "/publications", code:301},
        "/publications/modular-microfluidic-system-chip-extraction-preconcentration-and-detection-cytokine": { url: "/publications", code:301},
        "/publications/modulation-atmautophagy-pathway-g-quadruplex-ligand-tips-balance-between-senescence": { url: "/publications", code:301},
        "/publications/modulation-cellular-fate-vinyl-triarylamines-through-structural-fine-tuning-stay-or": { url: "/publications", code:301},
        "/publications/modulation-junction-tension-tumor-suppressors-and-proto-oncogenes-regulates-cell-cell": { url: "/publications", code:301},
        "/publications/molecular-atlas-developing-ectoderm-defines-neural-neural-crest-placode-and-nonneural": { url: "/publications", code:301},
        "/publications/molecular-characterization-circulating-tumor-dna-pediatric-rhabdomyosarcoma": { url: "/publications", code:301},
        "/publications/molecular-features-untreated-breast-cancer-and-initial-metastatic-event-inform": { url: "/publications", code:301},
        "/publications/molecular-insights-activation-mre11-rad50-endonuclease-activity-sae2ctip-0": { url: "/publications", code:301},
        "/publications/molecular-interactions-between-tubulin-tails-and-glutamylases-reveal-determinants": { url: "/publications", code:301},
        "/publications/molecular-mechanisms-underlying-tgf-sshippo-signaling-crosstalks-role-baso-apical": { url: "/publications", code:301},
        "/publications/molecular-profiling-non-small-cell-lung-cancer-patients-or-without-brain-metastases": { url: "/publications", code:301},
        "/publications/molecular-profiling-spermatozoa-reveals-correlations-between-morphology-and-gene": { url: "/publications", code:301},
        "/publications/molecular-subgrouping-atypical-teratoidrhabdoid-tumors-reinvestigation-and-current": { url: "/publications", code:301},
        "/publications/molecular-underpinnings-and-environmental-drivers-spontaneous-loss-heterozygosity": { url: "/publications", code:301},
        "/publications/mouse-decellularised-liver-scaffold-improves-human-embryonic-and-induced-pluripotent": { url: "/publications", code:301},
        "/publications/mouse-multipotent-progenitor-5-cells-are-located-interphase-between-hematopoietic-stem": { url: "/publications", code:301},
        "/publications/mpapass-software-enables-stitched-multiplex-multidimensional-ev-repertoire-analysis": { url: "/publications", code:301},
        "/publications/mrna-processing-pathway-suppresses-metastasis-governing-translational-control-0": { url: "/publications", code:301},
        "/publications/mtert-induction-p21-positive-cells-counteracts-capillary-rarefaction-and-pulmonary": { url: "/publications", code:301},
        "/publications/mtor-repression-response-amino-acid-starvation-promotes-ecm-degradation-through-mt1": { url: "/publications", code:301},
        "/publications/multi-omics-comparison-malignant-and-normal-uveal-melanocytes-reveals-molecular": { url: "/publications", code:301},
        "/publications/multicenter-assessment-exclusive-endoscopic-endonasal-approach-treatment-53-olfactory": { url: "/publications", code:301},
        "/publications/multidimensional-proteomic-approach-endothelial-progenitors-demonstrate-expression-kdr": { url: "/publications", code:301},
        "/publications/multidisciplinarite-et-formation-des-specialistes-loncologie-et-lhematologie-maligne": { url: "/publications", code:301},
        "/publications/multidisciplinary-surgical-strategy-dumbbell-neuroblastoma-single-center-experience-32": { url: "/publications", code:301},
        "/publications/multiethnic-genome-wide-association-study-differentiated-thyroid-cancer-epithyr": { url: "/publications", code:301},
        "/publications/multifaceted-functions-homologous-recombination-dealing-replication-associated-dna": { url: "/publications", code:301},
        "/publications/multimodal-analysis-cell-free-dna-whole-genome-sequencing-pediatric-cancers-low": { url: "/publications", code:301},
        "/publications/multimodal-liquid-biopsy-early-monitoring-and-outcome-prediction-chemotherapy": { url: "/publications", code:301},
        "/publications/multimodal-profiling-reveals-site-specific-adaptation-and-tissue-residency-hallmarks": { url: "/publications", code:301},
        "/publications/multiscale-analysis-single-and-double-maternal-zygotic-myh9-and-myh10-mutants-during": { url: "/publications", code:301},
        "/publications/multiscale-design-cell-type-specific-pharmacokineticpharmacodynamic-models": { url: "/publications", code:301},
        "/publications/multiscale-signalling-network-map-innate-immune-response-cancer-reveals-cell": { url: "/publications", code:301},
        "/publications/multiscale-study-bacterial-proliferation-modes-within-novel-e-colisihipe-hybrid": { url: "/publications", code:301},
        "/publications/multivariate-th17-metagene-prognostic-stratification-t-cell-non-inflamed-triple": { url: "/publications", code:301},
        "/publications/muscle-stem-cells-undergo-extensive-clonal-drift-during-tissue-growth-meox1-mediated": { url: "/publications", code:301},
        "/publications/mutagenicity-assessment-environmental-contaminations-hospital-centralized": { url: "/publications", code:301},
        "/publications/mutant-mice-lacking-alternatively-spliced-p53-isoforms-unveil-ackr4-male-specific": { url: "/publications", code:301},
        "/publications/mutation-allele-burden-remains-unchanged-chronic-myelomonocytic-leukaemia-responding": { url: "/publications", code:301},
        "/publications/mutations-calmodulin-binding-domains-trpv46-channels-confer-invasive-properties-colon": { url: "/publications", code:301},
        "/publications/mutations-impairing-gsk3-mediated-maf-phosphorylation-cause-cataract-deafness": { url: "/publications", code:301},
        "/publications/mutations-most-divergent-tubulin-isotype-a8-tubulin-cause-defective-platelet": { url: "/publications", code:301},
        "/publications/mutations-v-atpase-assembly-factorvma21cause-congenital-disorder-glycosylation": { url: "/publications", code:301},
        "/publications/my-child-matters-programme-effect-public-private-partnerships-paediatric-cancer-care": { url: "/publications", code:301},
        "/publications/myo5bstx3-andstxbp2mutations-reveal-common-disease-mechanism-unifies-subset-congenital": { url: "/publications", code:301},
        "/publications/myosin-1b-promotes-axon-formation-regulating-actin-wave-propagation-and-growth-cone": { url: "/publications", code:301},
        "/publications/myosin-vi-and-branched-actin-filaments-mediate-membrane-constriction-and-fission": { url: "/publications", code:301},
        "/publications/n-1-naphthyl-345-trimethoxybenzohydrazide-microtubule-destabilizer-synthesis": { url: "/publications", code:301},
        "/publications/n-terminal-domain-cgas-determines-preferential-association-centromeric-dna-and-innate": { url: "/publications", code:301},
        "/publications/nali-h1-universal-synthetic-library-humanized-nanobodies-providing-highly-functional": { url: "/publications", code:301},
        "/publications/nanobodies-against-surface-biomarkers-enable-analysis-tumor-genetic-heterogeneity": { url: "/publications", code:301},
        "/publications/national-recommendations-french-genetics-and-cancer-group-unicancer-modalities-multi": { url: "/publications", code:301},
        "/publications/nbtxr3-first-class-radioenhancer-hafnium-oxide-nanoparticle-plus-radiotherapy-versus": { url: "/publications", code:301},
        "/publications/neoadjuvant-everolimus-adult-giant-mesenteric-cystic-lymphangioma-mtor-pathway": { url: "/publications", code:301},
        "/publications/nesprins-are-mechanotransducers-discriminate-epithelial-mesenchymal-transition": { url: "/publications", code:301},
        "/publications/neural-border-induction-specification-and-maturation-territory-generates-neural-crest": { url: "/publications", code:301},
        "/publications/neurites-monitoring-neurite-changes-through-transfer-entropy-and-semantic-segmentation": { url: "/publications", code:301},
        "/publications/neurodevelopmental-epigenetic-programme-mediated-smarcd3-dab1-reelin-signalling": { url: "/publications", code:301},
        "/publications/neuron-gated-silicon-nanowire-field-effect-transistors-follow-single-spike-propagation": { url: "/publications", code:301},
        "/publications/neuronal-nsun2-deficiency-produces-trna-epitranscriptomic-alterations-and-proteomic": { url: "/publications", code:301},
        "/publications/new-approach-ultra-focal-brachytherapy-low-and-intermediate-risk-prostate-cancer": { url: "/publications", code:301},
        "/publications/new-hybrid-record-linkage-process-make-epidemiological-databases-interoperable": { url: "/publications", code:301},
        "/publications/new-method-quantification-intratumoral-heterogeneity-feasibility-study-ktrans-maps": { url: "/publications", code:301},
        "/publications/new-pipeline-pathophysiological-analysis-mammary-gland-based-organoid-transplantation": { url: "/publications", code:301},
        "/publications/nfat-transcription-factors-are-essential-and-redundant-actors-leukemia-initiating": { url: "/publications", code:301},
        "/publications/nitazoxanide-analogs-require-nitroreduction-antimicrobial-activity-mycobacterium": { url: "/publications", code:301},
        "/publications/no-impact-seasonality-diagnoses-baseline-tumor-immune-infiltration-response-treatment": { url: "/publications", code:301},
        "/publications/non-conventional-axonal-organelles-control-trpm8-ion-channel-trafficking-and": { url: "/publications", code:301},
        "/publications/noncanonical-splicing-junctions-between-exons-and-transposable-elements-represent": { url: "/publications", code:301},
        "/publications/nonequilibrium-polarity-induced-chemotaxis-emergent-galilean-symmetry-and-exact": { url: "/publications", code:301},
        "/publications/nonsense-mediated-decay-restricts-lncrna-levels-yeast-unless-blocked-double-stranded": { url: "/publications", code:301},
        "/publications/notch-and-wnt-signals-cooperatively-control-cell-proliferation-and-tumorigenesis": { url: "/publications", code:301},
        "/publications/notch-signalling-sensor-and-instructor-microenvironment-coordinate-cell-fate-and-organ": { url: "/publications", code:301},
        "/publications/notch-un-gene-cle-de-lequilibre-entre-proliferation-et-differenciation-des-cellules": { url: "/publications", code:301},
        "/publications/novel-3d-lung-tumor-spheroids-oncoimmunological-assays": { url: "/publications", code:301},
        "/publications/novel-cationic-bisacylhydrazones-modulators-epstein-barr-virus-immune-evasion-acting": { url: "/publications", code:301},
        "/publications/novel-fgfr4-targeting-single-domain-antibodies-multiple-targeted-therapies-against": { url: "/publications", code:301},
        "/publications/novel-intergenically-spliced-chimera-nfatc3-pla2g15-associated-aggressive-t-all": { url: "/publications", code:301},
        "/publications/novel-lncrna-proca11-regulates-cell-plasticity-response-androgen-deprivation-prostate": { url: "/publications", code:301},
        "/publications/nrl-and-crx-define-photoreceptor-identity-and-reveal-subgroup-specific-dependencies": { url: "/publications", code:301},
        "/publications/ntrk-alterations-pediatric-high-risk-malignancies-identified-through-european-clinical": { url: "/publications", code:301},
        "/publications/nuclear-pore-primes-recombination-dependent-dna-synthesis-arrested-forks-promoting": { url: "/publications", code:301},
        "/publications/nucleolin-directly-mediates-epstein-barr-virus-immune-evasion-through-binding-g": { url: "/publications", code:301},
        "/publications/obesogenic-feedforward-loop-involving-pparg-acyl-coa-binding-protein-and-gabaa": { url: "/publications", code:301},
        "/publications/observing-nutrient-gradients-gene-expression-and-growth-variation-using-yeast-machine": { url: "/publications", code:301},
        "/publications/occurrence-intracranial-rhabdoid-tumours-mice-depends-temporal-control-smarcb1": { url: "/publications", code:301},
        "/publications/oligopaint-dna-fish-reveals-telomere-based-meiotic-pairing-dynamics-silkworm-bombyx": { url: "/publications", code:301},
        "/publications/oncogenic-chimeric-transcription-factors-drive-tumor-specific-transcription-processing": { url: "/publications", code:301},
        "/publications/oncogenic-signaling-alters-cell-shape-and-mechanics-facilitate-cell-division-under": { url: "/publications", code:301},
        "/publications/oncologic-outcomes-endometrial-cancer-patients-low-volume-metastasis-sentinel-lymph": { url: "/publications", code:301},
        "/publications/optimisation-imaging-flow-cytometry-analysis-single-extracellular-vesicles-using": { url: "/publications", code:301},
        "/publications/optimization-g-quadruplex-ligands-through-sar-study-combining-parallel-synthesis-and": { url: "/publications", code:301},
        "/publications/optimization-shape-metric-based-information-theory-applied-segmentation-fusion-and": { url: "/publications", code:301},
        "/publications/optimizing-multiplexed-imaging-experimental-design-through-tissue-spatial-segregation": { url: "/publications", code:301},
        "/publications/oral-contraceptive-use-and-ovarian-cancer-risk-brca12-mutation-carriers-international": { url: "/publications", code:301},
        "/publications/oriented-cell-divisions-epithelia-force-generation-force-anisotropy-tension-shape-and": { url: "/publications", code:301},
        "/publications/outcomes-latarjet-procedure-treatment-chronic-anterior-shoulder-instability-patients": { url: "/publications", code:301},
        "/publications/outlier-response-anti-pd1-uveal-melanoma-reveals-germline-mbd4-mutations-hypermutated": { url: "/publications", code:301},
        "/publications/ovarian-tissue-cryopreservation-fertility-preservation-418-girls-and-adolescents-15": { url: "/publications", code:301},
        "/publications/overall-survival-circulating-tumor-cell-count-driven-choice-therapy-advanced-breast": { url: "/publications", code:301},
        "/publications/oxidative-phosphorylation-metabolic-vulnerability-endocrine-therapy-and-palbociclib": { url: "/publications", code:301},
        "/publications/p53-deficiency-induces-cancer-stem-cell-pool-expansion-mouse-model-triple-negative": { url: "/publications", code:301},
        "/publications/paediatric-and-adolescent-hodgkin-lymphoma-information-derived-diffuse-organ-uptake-18": { url: "/publications", code:301},
        "/publications/palliative-care-day-hospital-advanced-cancer-patients-study-protocol-multicentre": { url: "/publications", code:301},
        "/publications/pan-cancer-analysis-lncrna-regulation-supports-their-targeting-cancer-genes-each-tumor": { url: "/publications", code:301},
        "/publications/pan-cancer-analysis-reveals-high-frequency-genetic-alterations-mediators-signaling-tgf": { url: "/publications", code:301},
        "/publications/paracrine-signalling-between-intestinal-epithelial-and-tumour-cells-induces": { url: "/publications", code:301},
        "/publications/parametric-approach-relaxing-independence-assumption-relative-survival-analysis": { url: "/publications", code:301},
        "/publications/parp-2-depletion-results-lower-radiation-cell-survival-cell-line-specific-differences": { url: "/publications", code:301},
        "/publications/parp-inhibitors-and-radiation-potentiate-liver-cell-death-vitro-do-hepatocellular": { url: "/publications", code:301},
        "/publications/parp2-controls-double-strand-break-repair-pathway-choice-limiting-53bp1-accumulation": { url: "/publications", code:301},
        "/publications/path-12-new-powerful-tool-simultaneously-deliver-dna-methylation-profile-and-driver": { url: "/publications", code:301},
        "/publications/patient-specific-circulating-tumor-dna-detection-during-neoadjuvant-chemotherapy": { url: "/publications", code:301},
        "/publications/pazopanib-or-methotrexate-vinblastine-combination-chemotherapy-adult-patients": { url: "/publications", code:301},
        "/publications/pd-l1-expression-after-neoadjuvant-chemotherapy-triple-negative-breast-cancers": { url: "/publications", code:301},
        "/publications/pdx-ou-xenogreffe-derivee-de-la-tumeur-du-patient-la-renaissance-dun-modele-oublie-son": { url: "/publications", code:301},
        "/publications/pediatric-localized-intracranial-ependymomas-multicenter-analysis-societe-francaise-de": { url: "/publications", code:301},
        "/publications/pembrolizumab-treatment-advanced-salivary-gland-carcinoma": { url: "/publications", code:301},
        "/publications/peptide-tlr-78a-conjugate-vaccines-chemically-programmed-nanoparticle-self-assembly": { url: "/publications", code:301},
        "/publications/persistent-cell-migration-emerges-coupling-between-protrusion-dynamics-and-polarized": { url: "/publications", code:301},
        "/publications/personalized-logical-models-investigate-cancer-response-braf-treatments-melanomas-and": { url: "/publications", code:301},
        "/publications/pfkfb4-control-akt-signaling-essential-premigratory-and-migratory-neural-crest": { url: "/publications", code:301},
        "/publications/pharmacokinetic-analysis-18ffaza-dynamic-pet-imaging-acquisitions-highlighting-sacrum": { url: "/publications", code:301},
        "/publications/pharmacologic-reduction-mitochondrial-iron-triggers-noncanonical-baxbak-dependent-cell": { url: "/publications", code:301},
        "/publications/phase-i-dose-escalation-study-nbtxr3-activated-intensity-modulated-radiation-therapy": { url: "/publications", code:301},
        "/publications/phase-iii-study-european-organisation-research-and-treatment-cancer-satisfaction": { url: "/publications", code:301},
        "/publications/phd-finger-protein-spp1-has-distinct-functions-set1-and-meiotic-dsb-formation": { url: "/publications", code:301},
        "/publications/phendv-turn-fluorescent-quadruplex-dna-probe-improving-sensitivity-drug-screening": { url: "/publications", code:301},
        "/publications/phenotypic-profiling-living-biobank-primary-rhabdomyosarcoma-unravels-disease": { url: "/publications", code:301},
        "/publications/phenotyping-polarization-dynamics-immune-cells-using-lipid-droplet-cell-pairing": { url: "/publications", code:301},
        "/publications/photoactivatable-v-shaped-bifunctional-quinone-methide-precursors-new-class-selective": { url: "/publications", code:301},
        "/publications/physiboss-20-sustainable-integration-stochastic-boolean-and-agent-based-modelling-0": { url: "/publications", code:301},
        "/publications/physiboss-multi-scale-agent-based-modelling-framework-integrating-physical-dimension": { url: "/publications", code:301},
        "/publications/physical-principles-and-functional-consequences-nuclear-compartmentalization-budding": { url: "/publications", code:301},
        "/publications/physico-chemical-and-biological-considerations-membrane-wound-evolution-and-repair": { url: "/publications", code:301},
        "/publications/physicochemical-model-reaction-kinetics-supports-peroxyl-radical-recombination-main": { url: "/publications", code:301},
        "/publications/piecewise-stationary-motion-modeling-and-iterative-smoothing-track-heterogeneous": { url: "/publications", code:301},
        "/publications/pif1-helicase-actively-inhibited-during-meiotic-recombination-which-restrains-gene": { url: "/publications", code:301},
        "/publications/plant-and-mouse-eb1-proteins-have-opposite-intrinsic-properties-dynamic-instability": { url: "/publications", code:301},
        "/publications/plasmacytoid-urothelial-carcinoma-uc-are-luminal-tumors-similar-immune": { url: "/publications", code:301},
        "/publications/plasticity-dnmt3l-dependent-and-independent-modes-de-novo-methylation-developing-mouse": { url: "/publications", code:301},
        "/publications/plasticity-neuroblastoma-cell-identity-defines-noradrenergic-mesenchymal-transition": { url: "/publications", code:301},
        "/publications/platelet-activation-and-coronavirus-disease-2019-mortality-insights-coagulopathy": { url: "/publications", code:301},
        "/publications/pleuropneumoblastome-tumeur-de-sertoli-leydig-et-autres-maladies-associees-au-spectre": { url: "/publications", code:301},
        "/publications/plk1-inhibition-exhibits-strong-anti-tumoral-activity-ccnd1-driven-breast-cancer": { url: "/publications", code:301},
        "/publications/polarized-secretion-lysosomes-b-cell-synapse-couples-antigen-extraction-processing-and": { url: "/publications", code:301},
        "/publications/pold3blm-dependent-pathway-handles-dsbs-transcribed-chromatin-upon-excessive-rnadna": { url: "/publications", code:301},
        "/publications/polo-kinase-1-potential-therapeutic-option-combination-conventional-chemotherapy": { url: "/publications", code:301},
        "/publications/poly-implant-prothese-pipr-et-incidence-des-complications-en-reconstruction-mammaire": { url: "/publications", code:301},
        "/publications/polycomb-protein-ezl1-mediates-h3k9-and-h3k27-methylation-repress-transposable": { url: "/publications", code:301},
        "/publications/polycystins-and-intercellular-mechanotransduction-precise-dosage-polycystin-2": { url: "/publications", code:301},
        "/publications/polyfunctional-klrg-1cd57-senescent-cd4-t-cells-infiltrate-tumors-and-are-expanded": { url: "/publications", code:301},
        "/publications/polygenic-risk-scores-and-breast-and-epithelial-ovarian-cancer-risks-carriers-brca1": { url: "/publications", code:301},
        "/publications/polymeric-encapsulation-rutheniumii-polypyridyl-complex-synthesis-vivo-studies-against": { url: "/publications", code:301},
        "/publications/polyphenotypic-malignant-paediatric-brain-tumour-presenting-mn1-patz1-fusion-no": { url: "/publications", code:301},
        "/publications/post-transcriptional-polyadenylation-site-cleavage-maintains-3-end-processing-upon-dna": { url: "/publications", code:301},
        "/publications/pourquoi-les-anesthesistes-reanimateurs-doivent-ils-se-soucier-du-changement": { url: "/publications", code:301},
        "/publications/precision-medicine-cancer-challenges-and-recommendations-eu-funded-cervical-cancer": { url: "/publications", code:301},
        "/publications/precision-rush-trade-offs-between-reproducibility-and-steepness-hunchback-expression": { url: "/publications", code:301},
        "/publications/preclinical-and-clinical-evaluation-new-method-assess-cardiac-insulin-resistance-using": { url: "/publications", code:301},
        "/publications/preclinical-dosimetry-proton-minibeam-radiation-therapy-robustness-analysis-and": { url: "/publications", code:301},
        "/publications/preclinical-efficacy-humanized-non-fcgr-binding-anti-cd3-antibodies-t-cell-acute": { url: "/publications", code:301},
        "/publications/preclinical-evaluation-drug-combinations-identifies-co-inhibition-bcl-2xlw-and-mdm2": { url: "/publications", code:301},
        "/publications/preclinical-evaluation-trabectedin-combination-targeted-inhibitors-treatment": { url: "/publications", code:301},
        "/publications/preclinical-radiotherapy-australian-synchrotrons-imaging-and-medical-beamline": { url: "/publications", code:301},
        "/publications/preclinical-studies-comparing-efficacy-and-toxicity-dna-repair-inhibitors-olaparib-and": { url: "/publications", code:301},
        "/publications/prediction-breast-cancer-treatment-induced-fatigue-machine-learning-using-genome-wide": { url: "/publications", code:301},
        "/publications/predictive-factors-pathologic-complete-response-her2-positive-breast-cancer-after": { url: "/publications", code:301},
        "/publications/preferential-occurrence-chromosome-breakpoints-within-early-replicating-regions": { url: "/publications", code:301},
        "/publications/pregnancy-fertility-concerns-and-fertility-preservation-procedures-national-study": { url: "/publications", code:301},
        "/publications/prevalence-sars-cov-2-area-unrestricted-viral-circulation-mass-seroepidemiological": { url: "/publications", code:301},
        "/publications/primary-cns-lymphoma-patient-derived-orthotopic-xenograft-model-capture-biological-and": { url: "/publications", code:301},
        "/publications/prl-3ptp4a3-phosphatase-regulates-integrin-b1-adhesion-structures-during-migration": { url: "/publications", code:301},
        "/publications/prmt1-recruited-dna-pk-chromatin-where-it-sustains-senescence-associated-secretory": { url: "/publications", code:301},
        "/publications/prmt1-regulates-egfr-and-wnt-signaling-pathways-and-promising-target-combinatorial": { url: "/publications", code:301},
        "/publications/proffered-paper-mechanical-induction-tumourogenic-beta-catenin-pathway-tumour-growth": { url: "/publications", code:301},
        "/publications/prognostic-factors-metastatic-neuroendocrine-carcinoma-under-first-line-treatment": { url: "/publications", code:301},
        "/publications/prognostic-impact-time-ipsilateral-breast-tumor-recurrence-after-breast-conserving": { url: "/publications", code:301},
        "/publications/prognostic-implications-mri-melanin-quantification-and-cytogenetic-abnormalities-liver": { url: "/publications", code:301},
        "/publications/prognostic-value-intratumoral-fusobacterium-nucleatum-and-association-immune-related": { url: "/publications", code:301},
        "/publications/prognostic-value-tumor-mutational-burden-patients-oral-cavity-squamous-cell-carcinoma": { url: "/publications", code:301},
        "/publications/progression-associated-molecular-changes-basalsquamous-and-sarcomatoid-bladder": { url: "/publications", code:301},
        "/publications/proliferation-genes-repressed-tgf-b-are-downstream-slugsnail2-normal-bronchial": { url: "/publications", code:301},
        "/publications/prospective-multicenter-french-study-evaluating-clinical-impact-breast-cancer": { url: "/publications", code:301},
        "/publications/protection-against-de-novo-methylation-instrumental-maintaining-parent-origin": { url: "/publications", code:301},
        "/publications/protein-arginine-methyltransferase-5-novel-therapeutic-target-triple-negative-breast": { url: "/publications", code:301},
        "/publications/protein-dimerization-tyr-residues-highlight-slow-process-co-existence-numerous": { url: "/publications", code:301},
        "/publications/protein-tyrosine-phosphatase-4a3-ptp4a3-promotes-human-uveal-melanoma-aggressiveness": { url: "/publications", code:301},
        "/publications/protein-tyrosine-phosphatase-4a3-ptp4a3-required-xenopus-laevis-cranial-neural-crest": { url: "/publications", code:301},
        "/publications/protein-tyrosine-phosphatase-4a3-ptp4a3prl-3-promotes-aggressiveness-human-uveal": { url: "/publications", code:301},
        "/publications/proteogenomic-characterization-bladder-cancer-reveals-sensitivity-apoptosis-induced": { url: "/publications", code:301},
        "/publications/proteome-and-metabolome-subretinal-fluid-central-serous-chorioretinopathy-and": { url: "/publications", code:301},
        "/publications/proteomic-comparison-defines-novel-markers-characterize-heterogeneous-populations": { url: "/publications", code:301},
        "/publications/protocol-automated-multivariate-quantitative-image-based-cytometry-analysis": { url: "/publications", code:301},
        "/publications/proton-flash-radiation-therapy-and-immune-infiltration-evaluation-orthotopic-glioma": { url: "/publications", code:301},
        "/publications/proton-minibeam-radiation-therapy-spares-normal-rat-brain-long-term-clinical": { url: "/publications", code:301},
        "/publications/protrudin-mediated-er-endosome-contact-sites-promote-mt1-mmp-exocytosis-and-cell": { url: "/publications", code:301},
        "/publications/pt-ttpy-g-quadruplex-binding-platinum-complex-induces-telomere-dysfunction-and-g-rich": { url: "/publications", code:301},
        "/publications/publisher-correction-receptor-dngr-1-signals-phagosomal-rupture-promote-cross": { url: "/publications", code:301},
        "/publications/purification-tubulin-controlled-post-translational-modifications-polymerization": { url: "/publications", code:301},
        "/publications/purification-tubulin-controlled-posttranslational-modifications-and-isotypes-limited": { url: "/publications", code:301},
        "/publications/pyrimidine-pool-disequilibrium-induced-cytidine-deaminase-deficiency-inhibits-parp-1": { url: "/publications", code:301},
        "/publications/pyrrolopyrimidine-colchicine-binding-site-agent-pp-13-reduces-metastatic-dissemination": { url: "/publications", code:301},
        "/publications/qualitative-differences-t-cell-activation-dendritic-cell-derived-extracellular-vesicle": { url: "/publications", code:301},
        "/publications/quality-life-cancer-patients-undergoing-anticoagulant-treatment-lmwh-venous": { url: "/publications", code:301},
        "/publications/quantifying-entropy-production-active-fluctuations-hair-cell-bundle-time": { url: "/publications", code:301},
        "/publications/quantifying-nanotherapeutic-penetration-using-hydrogel-based-microsystem-new-3d-vitro": { url: "/publications", code:301},
        "/publications/quantifying-tubulin-concentration-and-microtubule-number-throughout-fission-yeast-cell": { url: "/publications", code:301},
        "/publications/quantitative-approach-analyzing-spatio-temporal-distribution-3d-intracellular-events": { url: "/publications", code:301},
        "/publications/quantitative-characterization-extracellular-vesicle-uptake-and-content-delivery-within": { url: "/publications", code:301},
        "/publications/quantitative-ubiquitylome-analysis-reveals-specificity-rnf111arkadia-e3-ubiquitin": { url: "/publications", code:301},
        "/publications/quantum-dot-loaded-monofunctionalized-dna-icosahedra-single-particle-tracking": { url: "/publications", code:301},
        "/publications/quarep-limi-community-driven-initiative-establish-guidelines-quality-assessment-and": { url: "/publications", code:301},
        "/publications/quelles-mesures-pour-maitriser-le-risque-infectieux-chez-les-patients-immunodeprimes": { url: "/publications", code:301},
        "/publications/quelles-sont-les-meilleures-methodes-dimagerie-actuelle-pour-statuer-sur-le-stade-n0": { url: "/publications", code:301},
        "/publications/question-prompt-list-advanced-cancer-patients-promoting-advance-care-planning-french": { url: "/publications", code:301},
        "/publications/rab-35-and-arf-6-gtpases-mediate-engulfment-and-clearance-following-linker-cell-type": { url: "/publications", code:301},
        "/publications/rab-gtpases-and-their-interacting-protein-partners-structural-insights-rab-functional": { url: "/publications", code:301},
        "/publications/rab6-and-dynein-drive-post-golgi-apical-transport-prevent-neuronal-progenitor": { url: "/publications", code:301},
        "/publications/rab6-dependent-retrograde-traffic-lat-controls-immune-synapse-formation-and-t-cell": { url: "/publications", code:301},
        "/publications/rab7-harboring-vesicles-are-carriers-transferrin-receptor-through-biosynthetic": { url: "/publications", code:301},
        "/publications/radial-multigradient-echo-dce-mri-3dktransmapping-individual-arterial-input-function": { url: "/publications", code:301},
        "/publications/radiation-therapy-and-late-mortality-second-sarcoma-carcinoma-and-hematological": { url: "/publications", code:301},
        "/publications/radiogenomics-neuroblastomas-relationships-between-imaging-phenotypes-tumor-genomic": { url: "/publications", code:301},
        "/publications/radiological-distribution-brain-metastases-and-its-implication-hippocampus-avoidance": { url: "/publications", code:301},
        "/publications/radiotherapie-en-hypofractionnement-extreme-appliquee-au-traitement-du-cancer-localise": { url: "/publications", code:301},
        "/publications/raf-proteins-exert-both-specific-and-compensatory-functions-during-tumour-progression": { url: "/publications", code:301},
        "/publications/rare-germline-heterozygous-missense-variants-brca1-associated-protein-1-bap1-cause": { url: "/publications", code:301},
        "/publications/ras-activation-induces-synthetic-lethality-mek-inhibition-mitochondrial-oxidative": { url: "/publications", code:301},
        "/publications/ratiometric-quorum-sensing-governs-trade-between-bacterial-vertical-and-horizontal": { url: "/publications", code:301},
        "/publications/rationally-designed-long-wavelength-absorbing-ruii-polypyridyl-complexes": { url: "/publications", code:301},
        "/publications/rbms1-suppresses-colon-cancer-metastasis-through-targeted-stabilization-its-mrna": { url: "/publications", code:301},
        "/publications/real-time-tracking-parental-histones-reveals-their-contribution-chromatin-integrity": { url: "/publications", code:301},
        "/publications/recombination-subtelomeres-regulated-physical-distance-double-strand-break-resection": { url: "/publications", code:301},
        "/publications/recommandations-de-lintergroupe-francophone-de-cancerologie-thoracique-de-la-societe": { url: "/publications", code:301},
        "/publications/recursive-deep-prior-video-super-resolution-algorithm-time-lapse-microscopy-organ-chip": { url: "/publications", code:301},
        "/publications/redox-modifications-cysteine-containing-proteins-cell-cycle-arrest-and-translation": { url: "/publications", code:301},
        "/publications/reference-standards-gene-fusion-molecular-assays-cytological-samples-international": { url: "/publications", code:301},
        "/publications/regardless-deposition-pathway-aminoacid-31-histone-variant-h3-essential-gastrulation": { url: "/publications", code:301},
        "/publications/regeneration-zebrafish-lateral-line-neuromasts-expression-neural-progenitor-cell": { url: "/publications", code:301},
        "/publications/regulation-heat-inducible-hspa1a-gene-expression-during-maternal-embryo-transition-and": { url: "/publications", code:301},
        "/publications/regulation-replicative-histone-rna-metabolism-histone-chaperone-asf1-0": { url: "/publications", code:301},
        "/publications/regulation-replicative-histone-rna-metabolism-histone-chaperone-asf1-1": { url: "/publications", code:301},
        "/publications/relation-between-plasma-trough-concentration-abiraterone-and-prostate-specific-antigen": { url: "/publications", code:301},
        "/publications/release-hiv-1-particles-macrophages-promoted-anchored-cytoskeleton-and-driven": { url: "/publications", code:301},
        "/publications/relevance-molecular-tumour-board-mtb-patients-enrolment-clinical-trials-experience": { url: "/publications", code:301},
        "/publications/renal-globotriaosylceramide-facilitates-tubular-albumin-absorption-and-its-inhibition": { url: "/publications", code:301},
        "/publications/repeated-peripheral-infusions-anti-egfrviii-car-t-cells-combination-pembrolizumab-show": { url: "/publications", code:301},
        "/publications/replicative-histone-chaperone-caf-1-essential-maintenance-identity-and-genome": { url: "/publications", code:301},
        "/publications/residual-antxr1-myofibroblasts-after-chemotherapy-inhibit-anti-tumor-immunity-yap1": { url: "/publications", code:301},
        "/publications/response-mtor-and-pi3k-inhibitors-enzalutamide-resistant-luminal-androgen-receptor": { url: "/publications", code:301},
        "/publications/restarted-replication-forks-are-error-prone-and-cause-cag-repeat-expansions-and": { url: "/publications", code:301},
        "/publications/results-international-survey-about-methods-used-isolate-human-endothelial-colony": { url: "/publications", code:301},
        "/publications/ret-kinase-mediated-mechanical-induction-colon-stem-cells-tumor-growth-pressure": { url: "/publications", code:301},
        "/publications/retracted-article-tumor-necrosis-factor-overcomes-immune-evasion-p53-mutant": { url: "/publications", code:301},
        "/publications/retrograde-and-anterograde-transport-lat-vesicles-during-immunological-synapse": { url: "/publications", code:301},
        "/publications/reversal-hanging-protocol-contrast-enhanced-mammography-leads-similar-diagnostic": { url: "/publications", code:301},
        "/publications/reversible-transitions-between-noradrenergic-and-mesenchymal-tumor-identities-define": { url: "/publications", code:301},
        "/publications/review-experimental-studies-improve-radiotherapy-response-bladder-cancer-comments-and": { url: "/publications", code:301},
        "/publications/review-use-time-varying-covariates-fine-gray-subdistribution-hazard-competing-risk": { url: "/publications", code:301},
        "/publications/revisiting-chemistry-and-photophysics-3-n-methylpyridinium-4-ylcoumarins-designing": { url: "/publications", code:301},
        "/publications/riboflavin-instability-key-factor-underlying-requirement-gut-microbiota-mosquito": { url: "/publications", code:301},
        "/publications/ribosome-biogenesis-dysfunction-leads-p53-mediated-apoptosis-and-goblet-cell": { url: "/publications", code:301},
        "/publications/risk-factors-coronavirus-disease-2019-covid-19-severity-and-mortality-among-solid": { url: "/publications", code:301},
        "/publications/risk-stratification-diffuse-large-b-cell-lymphoma-using-lesion-dissemination-and": { url: "/publications", code:301},
        "/publications/rna-polymerase-ii-ctd-s2p-dispensable-embryogenesis-mediates-exit-developmental": { url: "/publications", code:301},
        "/publications/rnadna-hybrids-okazaki-fragments-contribute-establish-ku-mediated-barrier-replication": { url: "/publications", code:301},
        "/publications/rnai-screen-novel-model-oriented-divisions-identifies-actin-capping-protein-z-b": { url: "/publications", code:301},
        "/publications/roadmap-metal-nanoparticles-radiation-therapy-current-status-translational-challenges": { url: "/publications", code:301},
        "/publications/robotic-surgery-pediatric-oncology-lessons-learned-first-100-tumors-nationwide": { url: "/publications", code:301},
        "/publications/role-cancer-associated-fibroblast-subpopulations-immune-infiltration-new-means": { url: "/publications", code:301},
        "/publications/role-de-la-surveillance-dans-la-detection-precoce-et-la-survie-apres-rechute-dun": { url: "/publications", code:301},
        "/publications/role-dna-repair-variants-and-diagnostic-radiology-exams-differentiated-thyroid-cancer": { url: "/publications", code:301},
        "/publications/role-mr1-driven-signals-and-amphiregulin-recruitment-and-repair-function-mait-cells": { url: "/publications", code:301},
        "/publications/role-n-terminal-transmembrane-domain-endo-lysosomal-targeting-and-function-human-abcb6": { url: "/publications", code:301},
        "/publications/role-neo-bioscore-staging-system-guiding-optimal-strategies-regional-nodal-irradiation": { url: "/publications", code:301},
        "/publications/role-radiation-dose-risk-secondary-leukemia-after-solid-tumor-childhood-treated": { url: "/publications", code:301},
        "/publications/role-short-and-long-lived-reactive-species-selectivity-and-anti-cancer-action-plasma": { url: "/publications", code:301},
        "/publications/role-systematic-lymphadenectomy-low-grade-serous-ovarian-cancer-systematic-review-and": { url: "/publications", code:301},
        "/publications/role-tau-protein-maintaining-ribosomal-dna-stability-and-cytidine-deaminase-deficient": { url: "/publications", code:301},
        "/publications/rotavirus-susceptibility-antibiotic-treated-mice-ascribed-diminished-expression": { url: "/publications", code:301},
        "/publications/rrp1-translocase-and-ubiquitin-ligase-activities-restrict-genome-destabilising-effects": { url: "/publications", code:301},
        "/publications/ruthenium-initiated-polymerization-lactide-route-remarkable-cellular-uptake": { url: "/publications", code:301},
        "/publications/rutheniumii-complex-containing-redox-active-semiquinonate-ligand-potential": { url: "/publications", code:301},
        "/publications/sars-cov-2-nsp3-unique-domain-sud-interacts-guanine-quadruplexes-and-g4-ligands": { url: "/publications", code:301},
        "/publications/scavenger-receptor-cysteine-rich-domains-lysyl-oxidase-like2-regulate-endothelial-ecm": { url: "/publications", code:301},
        "/publications/selection-bis-indolyl-pyridines-and-triphenylamines-new-inhibitors-sars-cov-2-cellular": { url: "/publications", code:301},
        "/publications/selective-control-transposable-element-expression-during-t-cell-exhaustion-and-anti-pd": { url: "/publications", code:301},
        "/publications/senp7-sumo-protease-presents-module-two-hp1-interaction-motifs-locks-hp1-protein": { url: "/publications", code:301},
        "/publications/sense-antisense-rna-interaction-promotes-breast-cancer-metastasis-regulation-nqo1": { url: "/publications", code:301},
        "/publications/sensitization-egfr-wild-type-non-small-cell-lung-cancer-cells-egfr-tyrosine-kinase": { url: "/publications", code:301},
        "/publications/sequential-algorithm-detect-diffusion-switching-along-intracellular-particle": { url: "/publications", code:301},
        "/publications/setting-basis-best-practices-and-standards-curation-and-annotation-logical-models": { url: "/publications", code:301},
        "/publications/severity-endothelial-dysfunction-associated-occurrence-hemorrhagic-complications-copd": { url: "/publications", code:301},
        "/publications/sex-dependent-least-toxic-timing-irinotecan-combined-chronomodulated-chemotherapy": { url: "/publications", code:301},
        "/publications/sex-moderations-relationship-between-aortic-stiffness-cognition-and-cerebrovascular": { url: "/publications", code:301},
        "/publications/shallowhrd-detection-homologous-recombination-deficiency-shallow-whole-genome": { url: "/publications", code:301},
        "/publications/shaping-humoral-immune-response-actin-regulators-modulate-antigen-presentation-and": { url: "/publications", code:301},
        "/publications/shh-signaling-protects-atoh1-degradation-mediated-e3-ubiquitin-ligase-huwe1-neural": { url: "/publications", code:301},
        "/publications/shifting-balance-activating-and-inhibitory-natural-killer-receptor-ligands-brafv600e": { url: "/publications", code:301},
        "/publications/shigellapromotes-major-alteration-gut-epithelial-physiology-and-tissue-invasion": { url: "/publications", code:301},
        "/publications/short-and-long-term-evaluation-impact-proton-minibeam-radiation-therapy-motor": { url: "/publications", code:301},
        "/publications/should-i-stay-or-should-i-go-lancrage-des-cellules-souches-neurales-au-cours-du": { url: "/publications", code:301},
        "/publications/sigh-stress-response-mediates-killing-mycobacterium-tuberculosis-activating": { url: "/publications", code:301},
        "/publications/signaling-mus81-eme2-dependent-dna-damage-elicited-chk1-deficiency-modulates": { url: "/publications", code:301},
        "/publications/silafluorene-promising-core-cell-permeant-highly-bright-and-two-photon-excitable": { url: "/publications", code:301},
        "/publications/simple-synthesis-triangular-all-metal-aromatics-allowing-access-isolobal-all-metal": { url: "/publications", code:301},
        "/publications/simultaneous-dynamic-t-1-and-t-2-measurement-aif-assessment-combined-dce-mri-mouse": { url: "/publications", code:301},
        "/publications/single-cell-analysis-crohns-disease-lesions-identifies-pathogenic-cellular-module": { url: "/publications", code:301},
        "/publications/single-cell-analysis-human-non-small-cell-lung-cancer-lesions-refines-tumor": { url: "/publications", code:301},
        "/publications/single-cell-analysis-reveals-fibroblast-clusters-linked-immunotherapy-resistance": { url: "/publications", code:301},
        "/publications/single-cell-chromatin-accessibility-profiling-glioblastoma-identifies-invasive-cancer": { url: "/publications", code:301},
        "/publications/single-cell-rna-seq-analysis-reveals-dual-sensing-hiv-1-blood-axl-positive-dendritic": { url: "/publications", code:301},
        "/publications/single-cell-rna-seq-based-proteogenomics-identifies-glioblastoma-specific-transposable": { url: "/publications", code:301},
        "/publications/single-cell-transcriptomes-highlight-temporal-hierarchy-fate-decisions-developmental": { url: "/publications", code:301},
        "/publications/single-cell-transcriptomics-reveals-shared-immunosuppressive-landscapes-mouse-and": { url: "/publications", code:301},
        "/publications/single-droplet-digital-polymerase-chain-reaction-comprehensive-and-simultaneous": { url: "/publications", code:301},
        "/publications/single-layer-dual-phase-nematic-elastomer-films-bending-accordion-folding-curling-and": { url: "/publications", code:301},
        "/publications/single-molecule-imaging-reveals-switch-between-spurious-and-functional-ncrna": { url: "/publications", code:301},
        "/publications/single-radio-uhplc-analysis-quality-control-technetium-99m-radiolabelled": { url: "/publications", code:301},
        "/publications/single-residue-variation-skeletal-muscle-myosin-enables-direct-and-selective-drug": { url: "/publications", code:301},
        "/publications/singlet-oxygen-mediated-oxidation-during-uva-radiation-alters-dynamic-genomic-dna": { url: "/publications", code:301},
        "/publications/size-control-mammalian-cells-involves-modulation-both-growth-rate-and-cell-cycle": { url: "/publications", code:301},
        "/publications/skin-tumors-induced-sorafenib-paradoxic-ras-raf-pathway-activation-and-oncogenic": { url: "/publications", code:301},
        "/publications/slow-replication-fork-velocity-homologous-recombination-defective-cells-results": { url: "/publications", code:301},
        "/publications/smarca4-deficient-rhabdoid-tumours-show-intermediate-molecular-features-between": { url: "/publications", code:301},
        "/publications/smooth-muscle-phenotype-idiopathic-pulmonary-hypertension-hyper-proliferative-not": { url: "/publications", code:301},
        "/publications/solid-stress-brain-tumours-causes-neuronal-loss-and-neurological-dysfunction-and-can": { url: "/publications", code:301},
        "/publications/solution-and-gas-phase-stability-dna-junctions-temperature-controlled-electrospray": { url: "/publications", code:301},
        "/publications/sorafenib-metastatic-uveal-melanoma-efficacy-toxicity-and-health-related-quality-life": { url: "/publications", code:301},
        "/publications/spatial-positioning-and-matrix-programs-cancer-associated-fibroblasts-promote-t-cell-0": { url: "/publications", code:301},
        "/publications/spatial-telomere-organization-and-clustering-yeast-saccharomyces-cerevisiae-nucleus": { url: "/publications", code:301},
        "/publications/spatially-modulated-proton-minibeams-results-same-increase-lifespan-uniform-target": { url: "/publications", code:301},
        "/publications/spatiotemporal-control-interferon-induced-jakstat-signalling-and-gene-transcription": { url: "/publications", code:301},
        "/publications/specific-genomic-alterations-high-grade-pulmonary-neuroendocrine-tumours-carcinoid": { url: "/publications", code:301},
        "/publications/specific-subfamilies-transposable-elements-contribute-different-domains-t-lymphocyte": { url: "/publications", code:301},
        "/publications/specificities-exosome-versus-small-ectosome-secretion-revealed-live-intracellular": { url: "/publications", code:301},
        "/publications/spi-1pu1-oncogene-accelerates-dna-replication-fork-elongation-and-promotes-genetic": { url: "/publications", code:301},
        "/publications/spitfire-supermaneuverable-algorithm-fast-denoising-and-deconvolution-3d-fluorescence": { url: "/publications", code:301},
        "/publications/splicing-associated-chromatin-signatures-combinatorial-and-position-dependent-role": { url: "/publications", code:301},
        "/publications/splicing-patterns-sf3b1-mutated-uveal-melanoma-generate-shared-immunogenic-tumor": { url: "/publications", code:301},
        "/publications/spontaneous-mouse-lymphoma-patient-derived-tumor-xenografts-importance-systematic": { url: "/publications", code:301},
        "/publications/spry1-domain-cardiac-ryanodine-receptor-variant-associated-short-coupled-torsade-de": { url: "/publications", code:301},
        "/publications/stag2-loss-function-affects-short-range-genomic-contacts-and-modulates-basal-luminal": { url: "/publications", code:301},
        "/publications/stag2-mutations-alter-ctcf-anchored-loop-extrusion-reduce-cis-regulatory-interactions": { url: "/publications", code:301},
        "/publications/stam-and-hrs-interact-sequentially-ifn-receptor-control-spatiotemporal-jak-stat": { url: "/publications", code:301},
        "/publications/stand-me-fibroblasts-regulation-intestinal-epithelium-during-development-and": { url: "/publications", code:301},
        "/publications/statistical-inference-approach-reconstruct-intercellular-interactions-cell-migration": { url: "/publications", code:301},
        "/publications/stick-slip-dynamics-cell-adhesion-triggers-spontaneous-symmetry-breaking-and": { url: "/publications", code:301},
        "/publications/stiffness-increases-myofibroblast-content-and-collagen-density-mesenchymal-high-grade": { url: "/publications", code:301},
        "/publications/stress-activated-protein-kinase-pathway-modulates-homologous-recombination-fission": { url: "/publications", code:301},
        "/publications/stromal-lymphocyte-infiltration-after-neoadjuvant-chemotherapy-associated-aggressive": { url: "/publications", code:301},
        "/publications/structural-and-functional-analyses-disease-causing-missense-mutations-bloom-syndrome": { url: "/publications", code:301},
        "/publications/structural-characterization-rh1-lzi-tandem-jip34-highlights-rh1-domains-cytoskeletal": { url: "/publications", code:301},
        "/publications/structural-transitions-and-mechanochemical-coupling-nucleoprotein-filament-explain": { url: "/publications", code:301},
        "/publications/structure-based-mutational-analysis-icat-residues-mediating-negative-regulation-b": { url: "/publications", code:301},
        "/publications/structure-property-function-relationships-iron-oxide-multicore-nanoflowers-magnetic": { url: "/publications", code:301},
        "/publications/study-caveolae-dependent-mechanoprotection-human-muscle-cells-using-micropatterning": { url: "/publications", code:301},
        "/publications/study-chromatin-remodeling-genes-implicates-smarca4-putative-player-oncogenesis": { url: "/publications", code:301},
        "/publications/studying-fate-tumor-extracellular-vesicles-high-spatiotemporal-resolution-using": { url: "/publications", code:301},
        "/publications/successive-osteosarcoma-relapses-after-first-line-o2006sarcome-09-trial-what-can-we": { url: "/publications", code:301},
        "/publications/succor-study-international-european-cohort-observational-study-comparing-minimally": { url: "/publications", code:301},
        "/publications/sulfobetaine-phosphonate-block-copolymer-coated-iron-oxide-nanoparticles-genomic-locus": { url: "/publications", code:301},
        "/publications/sumo-based-regulation-nuclear-positioning-spatially-regulate-homologous-recombination": { url: "/publications", code:301},
        "/publications/sumo-protease-and-proteasome-recruitment-nuclear-periphery-differently-affect": { url: "/publications", code:301},
        "/publications/sumoylation-chromatin-governs-coordinated-repression-transcriptional-program-essential": { url: "/publications", code:301},
        "/publications/surface-lsp-1-phenotypic-marker-distinguishing-human-classical-versus-monocyte-derived": { url: "/publications", code:301},
        "/publications/surface-tension-model-tissues-during-malignant-transformation-and-epithelial": { url: "/publications", code:301},
        "/publications/syndrome-associe-aux-variants-pathogenes-constitutionnels-de-dicer1-ou-en-sommes-nous": { url: "/publications", code:301},
        "/publications/syndrome-cmmrd-deficience-constitutionnelle-des-genes-mmr-bases-genetiques-et-aspects": { url: "/publications", code:301},
        "/publications/synthesis-and-antiproliferative-activity-6brcaq-tpp-conjugates-targeting-mitochondrial": { url: "/publications", code:301},
        "/publications/synthesis-and-characterization-epidermal-growth-factor-receptor-selective-ru-ii": { url: "/publications", code:301},
        "/publications/synthetic-reconstruction-hunchback-promoter-specifies-role-bicoid-zelda-and-hunchback": { url: "/publications", code:301},
        "/publications/systematic-analysis-rhogefgap-localizations-uncovers-regulators-mechanosensing-and": { url: "/publications", code:301},
        "/publications/systematic-approach-identifies-p53-dream-pathway-target-genes-associated-blood-or": { url: "/publications", code:301},
        "/publications/systematic-evaluation-benchmark-g4-probes-and-g4-clinical-drugs-using-three": { url: "/publications", code:301},
        "/publications/systematic-multi-omics-cell-line-profiling-uncovers-principles-ewing-sarcoma-fusion": { url: "/publications", code:301},
        "/publications/systems-biology-ewing-sarcoma-network-model-ews-fli1-effect-proliferation-and": { url: "/publications", code:301},
        "/publications/targeted-knockout-b-catenin-adult-melanocyte-stem-cells-using-mouse-line-dctcreert2": { url: "/publications", code:301},
        "/publications/targeted-long-read-sequencing-ewing-sarcoma-6p251-susceptibility-locus-identifies": { url: "/publications", code:301},
        "/publications/targeting-microglial-metabolic-rewiring-synergizes-immune-checkpoint-blockade-therapy": { url: "/publications", code:301},
        "/publications/tbc1d8b-loss-function-mutations-lead-x-linked-nephrotic-syndrome-defective-trafficking": { url: "/publications", code:301},
        "/publications/tcrcd3-complex-leukemogenesis-and-therapeutic-target-t-cell-acute-lymphoblastic": { url: "/publications", code:301},
        "/publications/telomerase-activation-and-atrx-mutations-are-independent-risk-factors-metastatic": { url: "/publications", code:301},
        "/publications/teptdm-et-recidive-biologique-dadenocarcinome-prostatique-apport-du-68-ga-psma-11": { url: "/publications", code:301},
        "/publications/tertiary-lymphoid-structures-marker-cxcl13-associated-better-survival-patients": { url: "/publications", code:301},
        "/publications/tests-immunohistochimiques-pd-l1-dans-les-cancers-du-poumon-non-petites-cellules": { url: "/publications", code:301},
        "/publications/tetraspanin-cd9-controls-migration-and-proliferation-parietal-epithelial-cells-and": { url: "/publications", code:301},
        "/publications/tetratricopeptide-repeat-domain-7a-nuclear-factor-modulates-transcription-and": { url: "/publications", code:301},
        "/publications/tgfb-induced-long-non-coding-rna-linc00313-activates-wnt-signaling-and-promotes": { url: "/publications", code:301},
        "/publications/therapeutic-advantage-targeting-prmt5-combination-chemotherapies-or-egfrher2": { url: "/publications", code:301},
        "/publications/therapeutically-targetable-positive-feedback-loop-between-lnc-hlx-2-7-hlx-and-myc": { url: "/publications", code:301},
        "/publications/thermo-mechanical-and-photo-luminescence-properties-micro-actuators-made-liquid": { url: "/publications", code:301},
        "/publications/thermodynamically-stable-and-genetically-unstable-g-quadruplexes-are-depleted-genomes": { url: "/publications", code:301},
        "/publications/thinking-ethical-when-designing-international-cross-disciplinary-biomedical-research": { url: "/publications", code:301},
        "/publications/thoracic-proton-minibeam-radiation-therapy-tissue-preservation-and-survival-advantage": { url: "/publications", code:301},
        "/publications/three-rounds-external-quality-assessment-france-evaluate-performance-28-platforms": { url: "/publications", code:301},
        "/publications/time-measure-positional-information-maternal-hunchback-required-synchrony-bicoid": { url: "/publications", code:301},
        "/publications/time-pregnancy-obstetrical-and-neonatal-outcomes-after-breast-cancer-study-maternity": { url: "/publications", code:301},
        "/publications/tinengotinib-tt-00420-novel-spectrum-selective-small-molecule-kinase-inhibitor-highly": { url: "/publications", code:301},
        "/publications/tissue-deformation-modulates-twist-expression-determine-anterior-midgut": { url: "/publications", code:301},
        "/publications/tissue-resident-folr2-macrophages-associate-cd8-t-cell-infiltration-human-breast": { url: "/publications", code:301},
        "/publications/tlr-or-nod-receptor-signaling-skews-monocyte-fate-decision-distinct-mechanisms-driven": { url: "/publications", code:301},
        "/publications/tools-and-best-practices-retrotransposon-analysis-using-high-throughput-sequencing": { url: "/publications", code:301},
        "/publications/topoisomerase-1-prevents-replication-stress-r-loop-enriched-transcription-termination": { url: "/publications", code:301},
        "/publications/topoisomerase-i-prevents-transcription-replication-conflicts-transcription-termination": { url: "/publications", code:301},
        "/publications/topology-selective-fluorescent-light-probes-g-quadruplex-dna-based-photoinduced": { url: "/publications", code:301},
        "/publications/total-metabolic-tumor-volume-and-spleen-metabolism-baseline-18f-fdg-petct-independent": { url: "/publications", code:301},
        "/publications/toward-better-understanding-t-cells-cancer": { url: "/publications", code:301},
        "/publications/tp-2rho-sensitive-solvatochromic-red-shifted-probe-monitoring-interactions-between": { url: "/publications", code:301},
        "/publications/traditional-systemic-treatment-options-advanced-low-grade-serous-ovarian-cancer-after": { url: "/publications", code:301},
        "/publications/trajectories-bifurcations-and-pseudo-time-large-clinical-datasets-applications": { url: "/publications", code:301},
        "/publications/trans-scale-mechanotransductive-cascade-biochemical-and-biomechanical-patterning": { url: "/publications", code:301},
        "/publications/transcription-dependent-spreading-dal80-yeast-gata-factor-across-body-highly-expressed": { url: "/publications", code:301},
        "/publications/transcription-mediated-organization-replication-initiation-program-across-large-genes": { url: "/publications", code:301},
        "/publications/transcriptional-analysis-human-skin-lesions-identifies-tryptophan-23-deoxygenase": { url: "/publications", code:301},
        "/publications/transcriptional-and-functional-analysis-cd1c-human-dendritic-cells-identifies-cd163": { url: "/publications", code:301},
        "/publications/transcriptional-programs-define-intratumoral-heterogeneity-ewing-sarcoma-single-cell": { url: "/publications", code:301},
        "/publications/transcriptional-repression-tyrosinase-related-protein-2-gene-transforming-growth": { url: "/publications", code:301},
        "/publications/transient-accumulation-new-class-ii-mhc-molecules-novel-endocytic-compartment-b": { url: "/publications", code:301},
        "/publications/transient-dnmt3l-expression-reinforces-chromatin-surveillance-halt-senescence": { url: "/publications", code:301},
        "/publications/transient-microfluidic-compartmentalization-using-actionable-microfilaments": { url: "/publications", code:301},
        "/publications/transmorph-unifying-computational-framework-modular-single-cell-rna-seq-data": { url: "/publications", code:301},
        "/publications/travel-burden-and-clinical-presentation-retinoblastoma-analysis-1024-patients-43": { url: "/publications", code:301},
        "/publications/treatment-and-outcome-patient-radiation-induced-organizing-pneumonia-context": { url: "/publications", code:301},
        "/publications/trem-1-orchestrates-angiotensin-ii-induced-monocyte-trafficking-and-promotes": { url: "/publications", code:301},
        "/publications/trf2-inhibition-promotes-anchorage-independent-growth-telomerase-positive-human": { url: "/publications", code:301},
        "/publications/tricellular-junction-proteins-promote-disentanglement-daughter-and-neighbour-cells": { url: "/publications", code:301},
        "/publications/triggering-tcr-developmental-checkpoint-activates-therapeutically-targetable-tumor": { url: "/publications", code:301},
        "/publications/tripartite-interactions-between-wnt-signaling-notch-and-myb-stemprogenitor-cell": { url: "/publications", code:301},
        "/publications/triple-extraction-method-enables-high-quality-mass-spectrometry-based-proteomics-and": { url: "/publications", code:301},
        "/publications/trna-processing-defects-induce-replication-stress-and-chk2-dependent-disruption-pirna": { url: "/publications", code:301},
        "/publications/trpml-controls-actomyosin-contractility-and-couples-migration-phagocytosis-fly": { url: "/publications", code:301},
        "/publications/true-checklist-identifying-impactful-artificial-intelligence-based-findings-nuclear": { url: "/publications", code:301},
        "/publications/tubulin-glycylases-are-required-primary-cilia-control-cell-proliferation-and-tumor": { url: "/publications", code:301},
        "/publications/tubulin-glycylation-controls-axonemal-dynein-activity-flagellar-beat-and-male": { url: "/publications", code:301},
        "/publications/tumor-control-rg2-glioma-bearing-rats-comparison-between-proton-minibeam-therapy-and": { url: "/publications", code:301},
        "/publications/tumor-genomic-profiling-and-tp53-germline-mutation-analysis-first-degree-relative": { url: "/publications", code:301},
        "/publications/tumor-invasion-draining-lymph-nodes-associated-treg-accumulation-breast-cancer": { url: "/publications", code:301},
        "/publications/tumospec-nation-wide-study-hereditary-breast-and-ovarian-cancer-families-predicted": { url: "/publications", code:301},
        "/publications/tumour-kinome-re-wiring-governs-resistance-palbociclib-oestrogen-receptor-positive": { url: "/publications", code:301},
        "/publications/tumour-spheres-inverted-polarity-drive-formation-peritoneal-metastases-patients": { url: "/publications", code:301},
        "/publications/tunable-dnmt1-degradation-reveals-dnmt1dnmt3b-synergy-dna-methylation-and-genome": { url: "/publications", code:301},
        "/publications/turning-nadph-oxidase-2-impeding-p67phox-activation-infected-mouse-macrophages-reduced": { url: "/publications", code:301},
        "/publications/two-hira-dependent-pathways-mediate-h33-de-novo-deposition-and-recycling-during": { url: "/publications", code:301},
        "/publications/two-induced-pluripotent-stem-cell-ipsc-lines-derived-patients-affected-waardenburg": { url: "/publications", code:301},
        "/publications/type-i-arginine-methyltransferases-are-intervention-points-unveil-oncogenic-epstein": { url: "/publications", code:301},
        "/publications/tyro3-targeting-radiosensitizing-strategy-bladder-cancer-through-cell-cycle": { url: "/publications", code:301},
        "/publications/tyrosine-containing-motif-transduces-cell-activation-signals-also-determines": { url: "/publications", code:301},
        "/publications/ultrabright-two-photon-excitable-red-emissive-fluorogenic-probes-fast-and-wash-free": { url: "/publications", code:301},
        "/publications/ultrabright-two-photon-excitable-red-emissive-fluorogenic-probes-fast-and-wash-free-0": { url: "/publications", code:301},
        "/publications/ultrabright-two-photon-excitable-red-emissive-fluorogenic-probes-fast-and-wash-free-1": { url: "/publications", code:301},
        "/publications/ultrahigh-dose-rate-flash-irradiation-increases-differential-response-between-normal": { url: "/publications", code:301},
        "/publications/un-nouveau-type-de-matiere-active-explique-la-formation-dagregats-bacteriens-et-leur": { url: "/publications", code:301},
        "/publications/unbiased-proteomic-profiling-host-cell-extracellular-vesicle-composition-and-dynamics": { url: "/publications", code:301},
        "/publications/unconventional-or-preset-ab-t-cells-evolutionarily-conserved-tissue-resident-t-cells": { url: "/publications", code:301},
        "/publications/unconventional-secretion-crystallin-b-requires-autophagic-pathway-and-controlled": { url: "/publications", code:301},
        "/publications/uncoupling-dynamin-polymerization-and-gtpase-activity-revealed-conformation-specific": { url: "/publications", code:301},
        "/publications/une-lesion-hybride-sarcome-fibromyxoide-de-bas-grade-sfbg-et-fibrosarcome-epithelioide": { url: "/publications", code:301},
        "/publications/une-nouvelle-population-de-cellules-dendritiques-sanguines-aux-proprietes": { url: "/publications", code:301},
        "/publications/unique-inflammatory-signature-haemophilic-arthropathy-mirna-changes-due-interaction": { url: "/publications", code:301},
        "/publications/unprecedented-reactivity-polyamines-aldehydic-dna-modifications-structural": { url: "/publications", code:301},
        "/publications/unraveling-ewing-sarcoma-tumorigenesis-originating-patient-derived-mesenchymal-stem": { url: "/publications", code:301},
        "/publications/upfront-brain-radiotherapy-may-improve-survival-unfavorable-prognostic-breast-cancer": { url: "/publications", code:301},
        "/publications/upregulation-mevalonate-pathway-through-ewsr1-fli1egr2-regulatory-axis-confers-ewing": { url: "/publications", code:301},
        "/publications/urinary-extracellular-vesicles-contain-mature-transcriptome-enriched-circular-and-long": { url: "/publications", code:301},
        "/publications/using-dose-finding-benchmark-quantify-loss-incurred-dichotomization-phase-ii-dose": { url: "/publications", code:301},
        "/publications/using-magnetometry-monitor-cellular-incorporation-and-subsequent-biodegradation": { url: "/publications", code:301},
        "/publications/using-microfluidics-and-live-cell-reporters-dissect-dynamics-tgf-b-signaling-mouse": { url: "/publications", code:301},
        "/publications/utilisation-de-longs-arn-non-codants-vers-un-diagnostic-urinaire-du-cancer-de-la": { url: "/publications", code:301},
        "/publications/utilisation-des-medecines-complementaires-et-alternatives-chez-lenfant-et-ladolescent": { url: "/publications", code:301},
        "/publications/validation-2018-figo-classification-cervical-cancer-lymphovascular-space-invasion": { url: "/publications", code:301},
        "/publications/validation-high-performance-functional-assay-individual-radiosensitivity-pediatric": { url: "/publications", code:301},
        "/publications/valproic-acid-decreases-endothelial-colony-forming-cells-differentiation-and-induces": { url: "/publications", code:301},
        "/publications/vamp7-controls-t-cell-activation-regulating-recruitment-and-phosphorylation-vesicular": { url: "/publications", code:301},
        "/publications/variant-erythroferrone-disrupts-iron-homeostasis-sf3b1-mutated-myelodysplastic": { url: "/publications", code:301},
        "/publications/vc-resist-glioblastoma-cell-state-vessel-co-option-key-driver-chemoradiation": { url: "/publications", code:301},
        "/publications/versatile-and-interoperable-computational-framework-analysis-and-modeling-covid-19": { url: "/publications", code:301},
        "/publications/versatile-and-precise-gene-targeting-strategies-functional-studies-mammalian-cell": { url: "/publications", code:301},
        "/publications/vimentin-epigenetic-deregulation-bladder-cancer-associates-acquisition-invasive-and": { url: "/publications", code:301},
        "/publications/viral-infection-disrupts-intestinal-homeostasis-sting-dependent-nf-kb-signaling": { url: "/publications", code:301},
        "/publications/virus-stimulated-dendritic-cells-elicit-t-antiviral-transcriptional-signature-human": { url: "/publications", code:301},
        "/publications/visible-light-induced-insertion-sulfur-dioxide-mediated-electron-donor-acceptor": { url: "/publications", code:301},
        "/publications/visualization-endogenous-mitochondrial-azoreductase-activity-under-normoxic-conditions": { url: "/publications", code:301},
        "/publications/vitro-effects-trastuzumab-emtansine-t-dm1-and-concurrent-irradiation-her2-positive": { url: "/publications", code:301},
        "/publications/vitro-method-studying-subcellular-rearrangements-during-cell-polarization-drosophila": { url: "/publications", code:301},
        "/publications/vivo-assimilation-cus-iron-oxide-and-iron-oxidecus-nanoparticles-mice-6-month-follow": { url: "/publications", code:301},
        "/publications/vivo-efficacy-assessment-cdk46-inhibitor-palbociclib-and-plk1-inhibitor-volasertib": { url: "/publications", code:301},
        "/publications/vivo-genome-wide-crispr-screens-identify-socs1-intrinsic-checkpoint-cd4-t-h-1-cell": { url: "/publications", code:301},
        "/publications/vivo-imaging-mammary-epithelial-cell-dynamics-response-lineage-biased-wntb-catenin-0": { url: "/publications", code:301},
        "/publications/voxel-wise-supervised-analysis-tumors-multimodal-engineered-features-highlight": { url: "/publications", code:301},
        "/publications/vsdc-method-improve-early-recognition-virtual-screening-when-limited-experimental": { url: "/publications", code:301},
        "/publications/wall-shear-stress-feeding-native-conduit-arteries-superficial-arteriovenous": { url: "/publications", code:301},
        "/publications/what-stoichiometries-determined-mass-spectrometry-reveal-about-ligand-binding-mode-g": { url: "/publications", code:301},
        "/publications/whole-cell-biopanning-synthetic-phage-display-library-nanobodies-enabled-recovery": { url: "/publications", code:301},
        "/publications/whole-exome-sequencing-cell-free-dna-reveals-temporo-spatial-heterogeneity-and": { url: "/publications", code:301},
        "/publications/widespread-overexpression-four-dna-hypermethylated-hox-clusters-aggressive-idh-wt": { url: "/publications", code:301},
        "/publications/wnt-dependent-interaction-between-inflammatory-fibroblasts-and-folr2-macrophages": { url: "/publications", code:301},
        "/publications/workflow-exploring-ligand-dissociation-macromolecule-efficient-random-acceleration": { url: "/publications", code:301},
        "/publications/x-rays-minibeam-radiation-therapy-conventional-irradiator-pilot-evaluation-f98-glioma": { url: "/publications", code:301},
        "/publications/xist-dependent-imprinted-x-inactivation-and-early-developmental-consequences-its": { url: "/publications", code:301},
        "/publications/yeast-assay-highlights-intrinsic-genomic-instability-human-pml-intron-6-over-intron-3": { url: "/publications", code:301},
        "/publications/yeast-mammals-nonsense-mediated-mrna-decay-master-regulator-long-non-coding-rnas": { url: "/publications", code:301},
        "/publications/zebrafish-embryo-vivo-model-screening-nanoparticle-formulated-lipophilic-anti": { url: "/publications", code:301},
        "/publications/zip4-protein-directly-couples-meiotic-crossover-formation-synaptonemal-complex": { url: "/publications", code:301},
        "/publications/zn-doped-iron-oxide-nanoparticles-high-magnetization-and-photothermal-efficiency": { url: "/publications", code:301},
        "/publications/zranb2-and-syf2-mediated-splicing-programs-converging-ect2-are-involved-breast-cancer": { url: "/publications", code:301},
        "/publications/zwitterionic-polymer-ligands-ideal-surface-coating-totally-suppress-protein": { url: "/publications", code:301},
        "/pulling-membrane-nanotubes-guvs": { url: "/", code:301},
        "/radiotherapy": { url: "/radiotherapy-institut-curie", code:301},
        "/recombination-dependent-replication": { url: "/", code:301},
        "/replication-fork-repair-and-chromatin-assembly": { url: "/", code:301},
        "/research-and-development": { url: "/research-and-innovation-institut-curie", code:301},
        "/role-cancer-associated-fibroblasts-cancer-invasion-and-therapy-resistance": { url: "/", code:301},
        "/role-fibroblasts-epithelial-gut-homeostasis-and-wound-healing": { url: "/", code:301},
        "/role-macrophages-colon-homeostasis": { url: "/", code:301},
        "/role-rab-gtpases-regulation-intracellular-transport": { url: "/", code:301},
        "/scientific-activities-extracellular-vesicles": { url: "/", code:301},
        "/scientific-journal-team-larue": { url: "/", code:301},
        "/seminars-team-larue": { url: "/", code:301},
        "/septins": { url: "/", code:301},
        "/service-metabolomics-and-lipidomics": { url: "/", code:301},
        "/services-mass-spectrometry": { url: "/", code:301},
        "/services-networks-and-collaborations": { url: "/", code:301},
        "/services-recombinant-antibodies-platform": { url: "/", code:301},
        "/shape-formation-active-liquid-crystals-topological-defects": { url: "/", code:301},
        "/single-ev-characterization": { url: "/", code:301},
        "/sites/default/files/offres-emplois/2022-04/CRYO-ELECTRON%20MICROSCOPY%20OF%20MEMBRANE%20PROTEINS.pdf": { url: "/", code:301},
        "/sites/default/files/offres-emplois/2024-02/Recruitment%20Ad%20Template_EN_Post-doc.202402.CHEN_.Impulscience_IT74%20(003)_1.pdf": { url: "/", code:301},
        "/stick-slip-model-actin-driven-cell-protrusions-cell-polarization-and-crawling": { url: "/", code:301},
        "/study-physical-parameters-underlying-intracellular-transport": { url: "/", code:301},
        "/team-alena-shkumatava": { url: "/research-teams", code:301},
        "/team-alexandre-baffet": { url: "/research-teams", code:301},
        "/team-ana-maria-lennon-dumenil": { url: "/research-teams", code:301},
        "/team-antoine-coulon": { url: "/research-teams", code:301},
        "/team-antonin-morillon": { url: "/research-teams", code:301},
        "/team-arturo-londono-vallejo": { url: "/research-teams", code:301},
        "/team-aurelien-latouche": { url: "/research-teams", code:301},
        "/team-celio-pouponnot": { url: "/research-teams", code:301},
        "/team-christophe-lamaze": { url: "/research-teams", code:301},
        "/team-claire-hivroz": { url: "/research-teams", code:301},
        "/team-daniel-levy": { url: "/research-teams", code:301},
        "/team-daniele-fachinetti": { url: "/research-teams", code:301},
        "/team-deborah-bourchis": { url: "/research-teams", code:301},
        "/team-eliane-piaggio": { url: "/research-teams", code:301},
        "/team-emmanuel-barillot": { url: "/research-teams", code:301},
        "/team-fatima-mechta-grigoriou": { url: "/research-teams", code:301},
        "/team-florence-cavalli": { url: "/research-teams", code:301},
        "/team-franck-perez": { url: "/research-teams", code:301},
        "/team-francois-radvanyi": { url: "/research-teams", code:301},
        "/team-genevieve-almouzni": { url: "/research-teams", code:301},
        "/team-herve-isambert": { url: "/research-teams", code:301},
        "/team-jean-salamero": { url: "/research-teams", code:301},
        "/team-lambert": { url: "/research-teams", code:301},
        "/team-lionel-larue": { url: "/research-teams", code:301},
        "/team-ludger-johannes": { url: "/research-teams", code:301},
        "/team-maitre-0": { url: "/research-teams", code:301},
        "/team-marie-paule-teulade-fichou": { url: "/research-teams", code:301},
        "/team-nathalie-dostatni": { url: "/research-teams", code:301},
        "/team-olivier-ayrault": { url: "/research-teams", code:301},
        "/team-olivier-lantz": { url: "/research-teams", code:301},
        "/team-pascal-hersen": { url: "/research-teams", code:301},
        "/team-pascal-martin": { url: "/research-teams", code:301},
        "/team-patricia-bassereau": { url: "/research-teams", code:301},
        "/team-philippe-chavrier": { url: "/research-teams", code:301},
        "/team-pierre-leopold": { url: "/research-teams", code:301},
        "/team-pierre-sens": { url: "/research-teams", code:301},
        "/team-raphael-margueron": { url: "/research-teams", code:301},
        "/team-raposo": { url: "/research-teams", code:301},
        "/team-renata-basto": { url: "/research-teams", code:301},
        "/team-sabastian-amigorena": { url: "/research-teams", code:301},
        "/team-toledo": { url: "/research-teams", code:301},
        "/team-valerie-borde": { url: "/research-teams", code:301},
        "/team-yolanda-prezado": { url: "/research-teams", code:301},
        "/tipit-towards-integrative-approach-precision-immunotherapy-lung-cancer": { url: "/", code:301},
        "/training": { url: "/education", code:301},
        "/training-mass-spectrometry": { url: "/education", code:301},
        "/training?profile=3": { url: "/education", code:301},
        "/translational-research": { url: "/rd-offer-translational-research", code:301},
        "/understanding-cell-division-epithelial-tissues": { url: "/", code:301},
        "/user/login": { url: "/", code:301},
        "/user/password": { url: "/", code:301},
        "/webform/contact-us": { url: "/contact", code:301},
        "/webform/contact-us?profile=5": { url: "/contact", code:301},
        "/yohanns-bellaiche-team-pictures": { url: "/", code:301},
      },
      mask:{
        /*"/toto/zzzzz/*": {url:"/test",code:301},
        "/toto/*": {url:"/test2",code:301}*/
      }
    }
  }
  


  export default defineNuxtRouteMiddleware((to, from) => {
    
    const nuxtApp = useNuxtApp();
    if(redirects.hasOwnProperty(nuxtApp.$config.public.language)){
      if(redirects[nuxtApp.$config.public.language].single.hasOwnProperty(to.fullPath)){
        return navigateTo(redirects[nuxtApp.$config.public.language].single[to.fullPath].url, { redirectCode: redirects[nuxtApp.$config.public.language].single[to.fullPath].code });
      }else{
        const masks: string[] = Object.keys(getMasksRedirect(nuxtApp.$config.public.language));
        let i = 0;
        let result = null;
        let mask = [];
        while(i<masks.length && result == null){
          mask = /^((\/[a-z1-9-_% ]*)+\/)\*$/.exec(masks[i]);
          if(mask.length > 1 && to.fullPath.startsWith(mask[1])){
            result = masks[i];
          }
          i++;
        }
        if(result !== null){
          return navigateTo(redirects[nuxtApp.$config.public.language].mask[result].url, { redirectCode: redirects[nuxtApp.$config.public.language].mask[result].code });
        }
      }
      return;
    }
  })
  
  
  const getMasksRedirect = (language)=>{
    const masks = redirects[language].mask;
    const sortedKeys = Object.keys(masks).sort((a, b) => b.length - a.length);
    const sortedObj = {};
    sortedKeys.forEach(key => {
      sortedObj[key] = masks[key];
    });
    return sortedObj;
  }