import { default as _91_46_46_46slug_930dPlZ41vEAMeta } from "/home/curie-frontend/www/releases/20241212090548/pages/[...slug].vue?macro=true";
import { default as _91id_93LH039iXppVMeta } from "/home/curie-frontend/www/releases/20241212090548/pages/apply/[id].vue?macro=true";
import { default as homehLfIOBohHeMeta } from "/home/curie-frontend/www/releases/20241212090548/pages/home.vue?macro=true";
import { default as searchCyAu7C2wLwMeta } from "/home/curie-frontend/www/releases/20241212090548/pages/search.vue?macro=true";
import { default as spontaneous_45applicationFmASqIdUq5Meta } from "/home/curie-frontend/www/releases/20241212090548/pages/spontaneous-application.vue?macro=true";
import { default as component_45stubWEmQBuwCctMeta } from "/home/curie-frontend/www/releases/20241212090548/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubWEmQBuwCct } from "/home/curie-frontend/www/releases/20241212090548/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___fr",
    path: "/:slug(.*)*",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/[...slug].vue")
  },
  {
    name: "apply-id___fr",
    path: "/apply/:id()",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/apply/[id].vue")
  },
  {
    name: "apply-id___en",
    path: "/apply/:id()",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/apply/[id].vue")
  },
  {
    name: "home___fr",
    path: "/home",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/home.vue")
  },
  {
    name: "home___en",
    path: "/home",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/home.vue")
  },
  {
    name: "search___fr",
    path: "/search",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/search.vue")
  },
  {
    name: "spontaneous-application___fr",
    path: "/spontaneous-application",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/spontaneous-application.vue")
  },
  {
    name: "spontaneous-application___en",
    path: "/spontaneous-application",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/spontaneous-application.vue")
  },
  {
    name: "spontaneous-application",
    path: "/candidature-spontanee",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/spontaneous-application.vue")
  },
  {
    name: "search",
    path: "/recherche",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/search.vue")
  },
  {
    name: "230",
    path: "/",
    component: () => import("/home/curie-frontend/www/releases/20241212090548/pages/home.vue")
  },
  {
    name: component_45stubWEmQBuwCctMeta?.name,
    path: "/redirection-simple-origine",
    component: component_45stubWEmQBuwCct
  },
  {
    name: component_45stubWEmQBuwCctMeta?.name,
    path: "/redirection-wildcard-origine/*",
    component: component_45stubWEmQBuwCct
  },
  {
    name: component_45stubWEmQBuwCctMeta?.name,
    path: "/redirection-wildcard-prefix-origine/*/suffixe",
    component: component_45stubWEmQBuwCct
  }
]